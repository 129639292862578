import {
  all,
  fork,
  call,
  takeEvery,
  put,
  select,
  take,
  delay,
  race,
} from 'redux-saga/effects';
import * as fcl from '@blocto/fcl';
import { END, eventChannel } from 'redux-saga';
import {
  type QuerySnapshot,
  firestore, functions, trackEvent, trackException,
} from 'global/firebase';
import { setMarketplaceCardPurchaseState, setMarketplacePurchasingState } from 'store/marketplace/marketplaceAction';
import { flowFetchBalancesScript } from 'flow/fetchBalances.script';
import { cardForSalePurchaseError, cardSaleSetCardProgress } from 'store/saleCard/saleCardActions';
import { SESSION_ERROR_CODES_REGEX } from 'util/constants';
import { blowBuyPackUsingUSDCTransaction } from 'flow/packPurchase/buyPackUsingUSDC.tx';
import {
  flowAcceptSaleOfferRequest,
  flowAcceptSaleOfferResponse,
  flowBalanceRequest,
  flowBalanceResponse,
  // flowBurnAllCardsRequest,
  // flowBurnAllCardsResponse,
  flowBurnStarlyCardsRequest,
  flowBurnStarlyCardsResponse,
  flowBuyPackRequest,
  flowBuyPackResponse,
  flowCancelSaleOfferRequest,
  flowCancelSaleOfferResponse,
  flowCreateSaleOfferRequest,
  flowCreateSaleOfferResponse,
  // flowFetchSaleOfferIDsRequest,
  // flowFetchSaleOfferIDsResponse,
  flowFetchStarlyCardItemIDsRequest,
  flowFetchStarlyCardItemIDsResponse,
  flowBloctoLoginRequest,
  flowLoginRequest,
  flowLoginResponse,
  flowLogoutRequest,
  flowLogoutResponse,
  flowWrongModal,
  flowToggleModal,
  flowToggleLoading,
  flowFestLoginRequest,
  flowFestLoginResponse,
  flowFestToggleModal,
  flowFestToggleLoading,
  flowNFTNYCLoginRequest,
  flowNFTNYCLoginResponse,
  flowNFTNYCToggleModal,
  flowNFTNYCToggleLoading,
} from './flowActions';
import { flowFestCheckWalletResponse } from '../flowFest/flowFestActions';
import { flowNFTNYCCheckWalletResponse } from '../flowNFTNYC/flowNFTNYCActions';

import {
  selectFlowWalletAddr,
} from './flowSelectors';

import type { FlowTypes } from '../../types';

import { updateWalletRequest, userRequest, userResponse } from '../user/userActions';

/**
 * Flow scripts
 */
import { blowBuyPackTransaction } from '../../flow/packPurchase/buyPack.tx';
import { blowBuyPackUsingFLOWTransaction } from '../../flow/packPurchase/buyPackUsingFLOW.tx';
import { blowBuyPackUsingSTARLYTransaction } from '../../flow/packPurchase/buyPackUsingSTARLY.tx';
import { blowBuyPackWithConversionTransaction } from '../../flow/packPurchase/buyPackWithConversion.tx';
import { blowBuyFlowPackWithFUSD } from '../../flow/packPurchase/buyFlowPackWithFUSD.tx';
import { buyPackResponse, setPackPurchaseStatus } from '../pack/packActions';

import { flowIsAccountInitializedScript } from '../../flow/isAccountInitialized.script';
import { flowInitializeAccountTransaction } from '../../flow/initializeAccount.tx';
import { flowAcceptSaleOrderTransaction } from '../../flow/acceptSaleOffer.tx';
import { flowAcceptSaleOrderWithConversionTransaction } from '../../flow/acceptSaleOfferWithConversion.tx';
// import { flowBurnAllCardsTransaction } from '../../flow/burnAllCards.tx';
import { flowBurnStarlyCardsTransaction } from '../../flow/burnStarlyCards.tx';
import { flowCancelSaleOfferTransaction } from '../../flow/cancelSaleOffer.tx';
import { flowCreateSaleOfferTransaction } from '../../flow/createSaleOffer.tx';
import { flowFetchStarlyCardItemIDsScript } from '../../flow/fetchStarlyCardItemIDs.script';
// import { flowFetchSaleOfferIDsScript } from '../../flow/fetchSaleOfferIDs.script';

import { flowIsFlowFestAccountInitializedTestnetScript } from '../../flow/flowfest/isFlowFestAccountInitialized.testnet.script';
import { flowInitializeFlowFestAccountTestnetTransaction } from '../../flow/flowfest/initializeFlowFestAccount.testnet.tx';
import { flowIsFlowFestAccountInitializedMainnetScript } from '../../flow/flowfest/isFlowFestAccountInitialized.mainnet.script';
import { flowInitializeFlowFestAccountMainnetTransaction } from '../../flow/flowfest/initializeFlowFestAccount.mainnet.tx';
import {
  flowIsNFTNYCAccountInitializedScript,
} from '../../flow/nftnyc/isNFTNYCAccountInitialized.script';
import { flowInitializeNFTNYCAccountTransaction } from '../../flow/nftnyc/initializeNFTNYCAccount.tx';
import { flowAcceptSaleOfferUsingUSDCTransaction } from '../../flow/acceptSaleOfferUsingUSDC.tx';

interface Balances {
  USDC: string,
  STARLY: string,
  FLOW: string,
  FUSD: string
}

function* waitForConfirmation(transactionId: string) {
  const promise = new Promise((resolve) => {
    firestore.collection('flowTransactions').doc(transactionId).onSnapshot((snapshot) => {
      const snapData = snapshot.data();
      if (snapData && snapData.state === 'processed') {
        resolve(snapData);
      }
    });
  });

  yield race({
    result: promise,
    delay: delay(15000),
  });
}

function* watchFlowAcceptSaleOfferRequest() {
  yield takeEvery(flowAcceptSaleOfferRequest, function* takeEveryFlowAcceptSaleOfferRequest(action) {
    const {
      payload: {
        nftItemId,
        marketCollectionAddress,
        isMarketplace,
        paymentCurrency,
      },
    } = action;
    try {
      let flowAcceptSaleOfferFunction;

      if (paymentCurrency === 'FLOW') {
        flowAcceptSaleOfferFunction = flowAcceptSaleOrderWithConversionTransaction;
      } else if (paymentCurrency === 'USDC') {
        flowAcceptSaleOfferFunction = flowAcceptSaleOfferUsingUSDCTransaction;
      } else {
        flowAcceptSaleOfferFunction = flowAcceptSaleOrderTransaction;
      }
      const result: FlowTypes.TransactionResultType = yield call(flowAcceptSaleOfferFunction, nftItemId, marketCollectionAddress);
      const errMessage = result ? result?.errorMessage as string : '';
      const transactionId = result?.events?.find((event) => event.transactionId)?.transactionId;
      if (result && !errMessage && transactionId) {
        yield call(waitForConfirmation, transactionId);
        const { data }: any = result.events.find((el) => el.data.price);
        trackEvent('card_purchase', {
          transaction_id: transactionId,
          total: Number(data?.price),
          quantity: 1,
          beneficiaryTotal: Number(data?.beneficiarySaleCut?.amount),
          creatorTotal: Number(data?.creatorSaleCut.amount),
          sellerTotal: Number(data?.sellerSaleCut.amount),
          additionalSaleCuts: data?.additionalSaleCuts?.map((cut: any) => Number(cut?.amount)),
          payment_type: 'FLOW chain',
          currencyActual: paymentCurrency,
          currency: 'USD',
          value: Number(data?.price),
        });
        yield put(flowAcceptSaleOfferResponse({ result, nftItemId, isMarketplace }));
      } else {
        yield put(cardSaleSetCardProgress({ nftItemId, inProgress: false }));
        if (isMarketplace) {
          yield put(setMarketplacePurchasingState({ state: 'error', isPurchasing: false }));
          yield put(setMarketplaceCardPurchaseState({ nftItemId, inProgress: false }));
        } else {
          yield put(cardForSalePurchaseError());
        }

        if (errMessage?.match(SESSION_ERROR_CODES_REGEX)) {
          yield put(flowLogoutRequest({}));
        }
      }
    } catch (e) {
      if (isMarketplace) {
        yield put(setMarketplacePurchasingState({ state: 'error', isPurchasing: false }));
        yield put(setMarketplaceCardPurchaseState({ nftItemId, inProgress: false }));
      }
    }

    yield put(flowBalanceRequest({}));
  });
}

function* watchFlowBalanceRequest() {
  yield takeEvery(flowBalanceRequest, function* takeEveryFlowBalanceRequest() {
    try {
      const addr: string = yield select(selectFlowWalletAddr);
      const {
        FLOW,
        FUSD,
        STARLY,
        USDC,
      }: Balances = yield call(flowFetchBalancesScript, addr);
      yield put(flowBalanceResponse({
        FLOW,
        FUSD,
        STARLY,
        USDC,
      }));
    } catch (error) {
      console.error(error);
    }
  });
}

// function* watchFlowBurnAllCardsRequest() {
//   yield takeEvery(flowBurnAllCardsRequest, function* takeEveryFlowBurnAllCardsRequest() {
//     yield call(flowBurnAllCardsTransaction);
//     // yield put(flowBurnAllCardsResponse({}));
//   });
// }

function* watchFlowBurnStarlyCardsRequest() {
  yield takeEvery(flowBurnStarlyCardsRequest, function* takeEveryFlowBurnStarlyCardsRequest(action) {
    yield call(flowBurnStarlyCardsTransaction, action.payload.nftItemIds);
    yield put(flowBurnStarlyCardsResponse({}));
  });
}

export async function getStarlyRate() {
  const rateSnaps: QuerySnapshot = await firestore.collection('cache').doc('starlyRate').collection('history')
    .where('timestamp', '<=', Math.round(Date.now() / 1000))
    .orderBy('timestamp', 'desc')
    .limit(1)
    .get();
  return rateSnaps.docs[0].data();
}

function* watchFlowBuyPackRequest() {
  yield takeEvery(flowBuyPackRequest, function* takeEveryFlowBuyPackRequest(action) {
    const {
      payload: {
        collectionId,
        packIds,
        currency,
        paymentCurrency,
        beneficiaryAddress,
        beneficiaryCutPercent,
        creatorAddress,
        creatorCutPercent,
        additionalCuts,
      },
    } = action;
    let { price } = action.payload;
    const priceUSD = price;

    let flowBuyPackFunction;
    if (currency === 'FLOW') {
      if (paymentCurrency === 'FLOW') {
        flowBuyPackFunction = blowBuyPackUsingFLOWTransaction;
      } else if (paymentCurrency === 'FUSD') {
        flowBuyPackFunction = blowBuyFlowPackWithFUSD;
      } else if (paymentCurrency === 'STARLY') {
        // yield put(buyPackResponse({ status: 'error' }));
        // yield put(flowToggleModal(true));
        // return;

        // const invertedPriceInFusd = yield call(() => fetchFusdToFlowAmount(1 / price));
        // const priceInFUSD = 1 / invertedPriceInFusd;
        // const rate: any = yield call(getStarlyRate);
        // price = priceInFUSD / rate.rate;
        // flowBuyPackFunction = blowBuyPackUsingSTARLYTransaction;
      }
    } else if (currency === 'FUSD') {
      if (paymentCurrency === 'FLOW') {
        flowBuyPackFunction = blowBuyPackWithConversionTransaction;
      } else if (paymentCurrency === 'STARLY') {
        const rate: any = yield call(getStarlyRate);
        price /= rate.rate;
        flowBuyPackFunction = blowBuyPackUsingSTARLYTransaction;
      } else if (paymentCurrency === 'USDC') {
        flowBuyPackFunction = blowBuyPackUsingUSDCTransaction;
      } else {
        flowBuyPackFunction = blowBuyPackTransaction;
      }
    }
    let res: FlowTypes.TransactionResultType;

    try {
      res = yield call(
        flowBuyPackFunction,
        collectionId,
        packIds,
        price,
        beneficiaryAddress,
        beneficiaryCutPercent,
        creatorAddress,
        creatorCutPercent,
        additionalCuts,
      );
    } catch {
      yield put(buyPackResponse({ status: 'error' }));
      yield put(flowToggleModal(true));
      return;
    }

    const errMessage = res ? res?.errorMessage as string : '';

    const transactionId = res?.events?.find((event) => event.transactionId)?.transactionId;
    if (res && !errMessage && transactionId) {
      yield call(waitForConfirmation, transactionId);
      yield put(flowBuyPackResponse({}));
      yield put(buyPackResponse({ status: 'allowed' }));

      trackEvent('pack_purchase', {
        transaction_id: transactionId,
        quantity: packIds.length,
        total: Number(price.toFixed(8).toString()),
        beneficiaryTotal: Number((beneficiaryCutPercent * price).toFixed(8).toString()),
        creatorTotal: Number((creatorCutPercent * price).toFixed(8).toString()),
        currencyActual: paymentCurrency,
        value: priceUSD,
        currency: currency === 'FUSD' ? 'USD' : 'FLOW',
        payment_type: 'FLOW chain',
        items: [{
          index: 0,
          item_id: collectionId,
          item_name: 'Starly Pack',
          item_category: 'Pack',
          quantity: packIds.length,
          price: priceUSD / packIds.length,
        }],
      });
    } else {
      const unreservePacks: any = functions.httpsCallable('unreservePacks');
      yield call(unreservePacks, { collectionId, packIds });
      yield put(buyPackResponse({ status: 'error' }));

      if (errMessage?.match(SESSION_ERROR_CODES_REGEX)) {
        yield put(flowLogoutRequest({}));
      }
    }

    yield put(setPackPurchaseStatus({ status: 'initial' }));
  });
}

function* watchFlowCancelSaleOfferRequest() {
  yield takeEvery(flowCancelSaleOfferRequest, function* takeEveryFlowCancelSaleOfferRequest(action) {
    const {
      payload: {
        nftItemId,
      },
    } = action;
    let result: FlowTypes.TransactionResultType;
    try {
      result = yield call(flowCancelSaleOfferTransaction, nftItemId);
    } catch (error) {
      yield put(flowCancelSaleOfferResponse({
        nftItemId,
        result: {
          errorMessage: error,
        },
      }));
      return;
    }
    const errMessage = result ? result?.errorMessage as string : '';
    const transactionId = result?.events?.find((event) => event.transactionId)?.transactionId;
    if (result && !errMessage && transactionId) {
      yield call(waitForConfirmation, transactionId);
    } else if (errMessage?.match(SESSION_ERROR_CODES_REGEX)) {
      yield put(flowLogoutRequest({}));
    }
    yield put(flowCancelSaleOfferResponse({ nftItemId, result }));
  });
}

function* watchFlowCreateSaleOfferRequest() {
  yield takeEvery(flowCreateSaleOfferRequest, function* takeEveryFlowCreateSaleOfferRequest(action) {
    const {
      payload: {
        nftItemId,
        price,
        beneficiaryAddress,
        beneficiaryCutPercent,
        creatorAddress,
        creatorCutPercent,
        minterAddress,
        mintingCutPercent,
      },
    } = action;
    const result: FlowTypes.TransactionResultType = yield call(
      flowCreateSaleOfferTransaction,
      nftItemId,
      price,
      beneficiaryAddress,
      beneficiaryCutPercent,
      creatorAddress,
      creatorCutPercent,
      minterAddress,
      mintingCutPercent,
    );

    const errMessage = result ? result?.errorMessage as string : '';
    const transactionId = result?.events?.find((event) => event.transactionId)?.transactionId;
    if (result && !errMessage && transactionId) {
      const channel = eventChannel<boolean>((emmiter) => {
        const unsub = firestore.collection('flowTransactions').doc(transactionId).onSnapshot((snapshot) => {
          const snapData = snapshot.data();
          if (snapData && snapData.state === 'processed') {
            emmiter(true);
            emmiter(END);
          }
        });
        return () => unsub();
      });

      try {
        while (true) {
          yield take(channel);
        }
      } finally {
        yield put(flowCreateSaleOfferResponse({ nftItemId, result }));
      }
    } else {
      yield put(cardSaleSetCardProgress({ nftItemId, inProgress: false }));
      if (errMessage?.match(SESSION_ERROR_CODES_REGEX)) {
        yield put(flowLogoutRequest({}));
      }
      yield put(flowCreateSaleOfferResponse({ nftItemId, result }));
    }
  });
}

// function* watchFlowFetchSaleOfferIDsRequest() {
//   yield takeEvery(flowFetchSaleOfferIDsRequest, function* takeEveryFlowFetchSaleOfferIDsRequest(action) {
//     const {
//       payload: {
//         flowAccount,
//       },
//     } = action;
//     const saleOfferIDs: string[] = yield call(flowFetchSaleOfferIDsScript, flowAccount);
//     yield put(flowFetchSaleOfferIDsResponse({ saleOfferIDs: saleOfferIDs.map(Number) }));
//   });
// }

function* watchFlowFetchStarlyCardItemIDsRequest() {
  yield takeEvery(flowFetchStarlyCardItemIDsRequest, function* takeEveryFlowFetchStarlyCardItemIDsRequest(action) {
    const {
      payload: {
        flowAccount,
      },
    } = action;
    const starlyCardIDs: string[] = yield call(flowFetchStarlyCardItemIDsScript, flowAccount);
    yield put(flowFetchStarlyCardItemIDsResponse({ starlyCardIDs: starlyCardIDs.map(Number) }));
  });
}

function* checkInitialization(address: string) {
  const init: FlowTypes.Init = yield call(flowIsAccountInitializedScript, address);

  if (!init.FUSD
    || !init.StarlyCard
    || !init.StarlyCardMarket
    || !init.StarlyToken
    || !init.StakedStarlyCard
    || !init.StarlyTokenStaking
    || !init.USDC) {
    yield call(flowInitializeAccountTransaction, address);

    /**
     * Check is user approved init or not
     */
    const recheck: FlowTypes.Init = yield call(flowIsAccountInitializedScript, address);

    if (!recheck.FUSD
      || !recheck.StarlyCard
      || !recheck.StarlyCardMarket
      || !recheck.StarlyToken
      || !recheck.StakedStarlyCard
      || !recheck.StarlyTokenStaking
      || !recheck.USDC) {
      yield put(flowToggleModal(false));
      yield put(flowToggleLoading(false));

      return false;
    }
  }
  return true;
}

function* watchFlowLoginRequest() {
  yield takeEvery(flowLoginRequest, function* takeEveryFlowLoginRequest() {
    try {
      const wallet: FlowTypes.Wallet = yield call(fcl.logIn);

      /**
       * User closed Flow modal without login to wallet
       */
      if (!wallet.addr) {
        yield put(flowToggleLoading(false));
        yield put(flowToggleModal(false));

        return;
      }

      const userId: string = yield select((state) => state.auth.userId);

      yield put(userRequest({ id: userId }));
      const {
        payload: {
          user,
        },
      } = yield take(userResponse);

      // if user has flow_account same as wallet address then skip updateFlowAccount and checks
      if (user.flow_account !== wallet.addr) {
        const updateFlowAccount = functions.httpsCallable('updateFlowAccount');
        /**
         * User login to own wallet first time
         */
        try {
          yield call(() => updateFlowAccount({ flow_account: wallet.addr }));
        } catch (error) {
          /**
           * Wallet was already connected or belongs to another user
           */
          if (wallet.addr) {
            yield call(fcl.unauthenticate);
            yield put(flowToggleLoading(false));
            yield put(flowWrongModal(
              user.flow_account
                ? { code: 1, message: user.flow_account }
                : { code: 2, message: wallet.addr },
            ));
            return;
          }
        }
      }

      const isInitialized: boolean = yield call(checkInitialization, wallet.addr);
      if (!isInitialized) return;

      const {
        FLOW,
        FUSD,
        STARLY,
        USDC,
      }: Balances = yield call(flowFetchBalancesScript, wallet.addr);

      yield put(updateWalletRequest({
        id: userId,
        address: wallet.addr,
      }));

      yield put(flowLoginResponse({
        wallet,
        flowBalance: FLOW,
        fusdBalance: FUSD,
        starlyBalance: STARLY,
        usdcBalance: USDC,
      }));

      yield put(flowToggleModal(false));

      trackEvent('wallet_connect');
    } catch (error: any) {
      trackException(error.message);
      yield put(flowToggleLoading(false));
    }
  });
}

function* watchBloctoLoginRequest() {
  yield takeEvery(flowBloctoLoginRequest, function* takeEveryBloctoLoginRequest() {
    try {
      const wallet: FlowTypes.Wallet = yield call(fcl.logIn);

      /**
       * User closed Flow modal without login to wallet
       */
      if (!wallet.addr) {
        yield put(flowToggleLoading(false));
        yield put(flowToggleModal(false));

        return;
      }

      const userId: string = yield select((state) => state.auth.userId);

      const init: FlowTypes.Init = yield call(flowIsAccountInitializedScript, wallet.addr);

      if (!init.FUSD || !init.StarlyCard || !init.StarlyCardMarket) {
        yield call(flowInitializeAccountTransaction, wallet.addr);

        /**
         * Check is user approved init or not
         */
        const recheck: FlowTypes.Init = yield call(flowIsAccountInitializedScript, wallet.addr);

        if (!recheck.FUSD || !recheck.StarlyCard || !recheck.StarlyCardMarket) {
          yield put(flowToggleModal(false));
          yield put(flowToggleLoading(false));

          return;
        }
      }

      const {
        FLOW,
        FUSD,
        STARLY,
        USDC,
      }: Balances = yield call(flowFetchBalancesScript, wallet.addr);

      yield put(updateWalletRequest({
        id: userId,
        address: wallet.addr,
      }));

      yield put(flowLoginResponse({
        wallet,
        flowBalance: FLOW,
        fusdBalance: FUSD,
        starlyBalance: STARLY,
        usdcBalance: USDC,
      }));

      yield put(flowToggleModal(false));

      trackEvent('wallet_connect');
    } catch (error: any) {
      trackException(error.message);
      yield put(flowToggleLoading(false));
    }
  });
}

function* watchFlowFestLoginRequest() {
  yield takeEvery(flowFestLoginRequest, function* takeEveryFlowFestLoginRequest() {
    try {
      const wallet: FlowTypes.Wallet = yield call(fcl.logIn);

      /**
       * User closed Flow modal without login to wallet
       */
      if (!wallet.addr) {
        yield put(flowFestToggleLoading(false));
        yield put(flowFestToggleModal(false));

        return;
      }

      const userId: string = yield select((state) => state.auth.userId);

      yield put(userRequest({ id: userId }));
      const {
        payload: {
          user,
        },
      } = yield take(userResponse);

      // if user has flow_account same as wallet address then skip updateFlowAccount and checks
      if (user.flow_account !== wallet.addr) {
        const updateFlowAccount = functions.httpsCallable('updateFlowAccount');
        /**
         * User login to own wallet first time
         */
        try {
          yield call(() => updateFlowAccount({ flow_account: wallet.addr }));
        } catch (error) {
          /**
           * Wallet was already connected or belongs to another user
           */
          if (wallet.addr) {
            yield call(fcl.unauthenticate);
            yield put(flowToggleLoading(false));
            yield put(flowFestToggleModal(false));
            yield put(flowWrongModal(
              user.flow_account
                ? { code: 1, message: user.flow_account }
                : { code: 2, message: wallet.addr },
            ));
            return;
          }
        }
      }

      const script = (process.env.REACT_APP_CHAIN_ENV === 'testnet')
        ? flowIsFlowFestAccountInitializedTestnetScript
        : flowIsFlowFestAccountInitializedMainnetScript;

      const transaction = (process.env.REACT_APP_CHAIN_ENV === 'testnet')
        ? flowInitializeFlowFestAccountTestnetTransaction
        : flowInitializeFlowFestAccountMainnetTransaction;

      const init: FlowTypes.Init = yield call(script, wallet.addr);

      if (Object.values(init).findIndex((v) => !v) > -1) {
        yield call(transaction, wallet.addr);

        /**
         * Check is user approved init or not
         */
        const recheck: FlowTypes.Init = yield call(script, wallet.addr);

        if (Object.values(recheck).findIndex((v) => !v) > -1) {
          yield put(flowFestToggleModal(false));
          yield put(flowFestToggleLoading(false));

          return;
        }
      }
      yield put(flowFestCheckWalletResponse(true));

      const {
        FLOW,
        FUSD,
        STARLY,
        USDC,
      }: Balances = yield call(flowFetchBalancesScript, wallet.addr);

      yield put(updateWalletRequest({
        id: userId,
        address: wallet.addr,
      }));

      yield put(flowFestLoginResponse({
        wallet,
        flowBalance: FLOW,
        fusdBalance: FUSD,
        starlyBalance: STARLY,
        usdcBalance: USDC,
      }));

      yield put(flowLoginResponse({
        wallet,
        flowBalance: FLOW,
        fusdBalance: FUSD,
        starlyBalance: STARLY,
        usdcBalance: USDC,
      }));

      yield put(flowFestToggleModal(false));
      trackEvent('wallet_connect');
    } catch (error: any) {
      trackException(error.message);
      yield put(flowFestToggleLoading(false));
    }
  });
}

function* watchFlowNFTNYCLoginRequest() {
  yield takeEvery(flowNFTNYCLoginRequest, function* takeEveryFlowNFTNYCLoginRequest() {
    try {
      const wallet: FlowTypes.Wallet = yield call(fcl.logIn);

      /**
       * User closed Flow modal without login to wallet
       */
      if (!wallet.addr) {
        yield put(flowNFTNYCToggleLoading(false));
        yield put(flowNFTNYCToggleModal(false));

        return;
      }

      const userId: string = yield select((state) => state.auth.userId);

      yield put(userRequest({ id: userId }));
      const {
        payload: {
          user,
        },
      } = yield take(userResponse);

      // if user has flow_account same as wallet address then skip updateFlowAccount and checks
      if (user.flow_account !== wallet.addr) {
        const updateFlowAccount = functions.httpsCallable('updateFlowAccount');
        /**
         * User login to own wallet first time
         */
        try {
          yield call(() => updateFlowAccount({ flow_account: wallet.addr }));
        } catch (error) {
          /**
           * Wallet was already connected or belongs to another user
           */
          if (wallet.addr) {
            yield call(fcl.unauthenticate);
            yield put(flowToggleLoading(false));
            yield put(flowNFTNYCToggleModal(false));
            yield put(flowWrongModal(
              user.flow_account
                ? { code: 1, message: user.flow_account }
                : { code: 2, message: wallet.addr },
            ));
            return;
          }
        }
      }

      const init: FlowTypes.Init = yield call(flowIsNFTNYCAccountInitializedScript, wallet.addr);

      if (Object.values(init).findIndex((v) => !v) > -1) {
        yield call(flowInitializeNFTNYCAccountTransaction, wallet.addr);

        /**
         * Check is user approved init or not
         */
        const recheck: FlowTypes.Init = yield call(flowIsNFTNYCAccountInitializedScript, wallet.addr);

        if (Object.values(recheck).findIndex((v) => !v) > -1) {
          yield put(flowNFTNYCToggleModal(false));
          yield put(flowNFTNYCToggleLoading(false));

          return;
        }
      }
      yield put(flowNFTNYCCheckWalletResponse(true));

      const {
        FLOW,
        FUSD,
        STARLY,
        USDC,
      }: Balances = yield call(flowFetchBalancesScript, wallet.addr);

      yield put(updateWalletRequest({
        id: userId,
        address: wallet.addr,
      }));

      yield put(flowNFTNYCLoginResponse({
        wallet,
        flowBalance: FLOW,
        fusdBalance: FUSD,
        starlyBalance: STARLY,
        usdcBalance: USDC,
      }));

      yield put(flowLoginResponse({
        wallet,
        flowBalance: FLOW,
        fusdBalance: FUSD,
        starlyBalance: STARLY,
        usdcBalance: USDC,
      }));

      yield put(flowNFTNYCToggleModal(false));
      trackEvent('wallet_connect');
    } catch (error: any) {
      trackException(error.message);
      yield put(flowNFTNYCToggleLoading(false));
    }
  });
}

function* watchFlowLogoutRequest() {
  yield takeEvery(flowLogoutRequest, function* takeEveryLogoutRequest(action) {
    yield call(fcl.unauthenticate);
    yield put(flowLogoutResponse({ action }));

    trackEvent('wallet_disconnect');
  });
}

export default function* flowSaga() {
  yield all([
    fork(watchFlowAcceptSaleOfferRequest),
    fork(watchFlowBalanceRequest),
    // fork(watchFlowBurnAllCardsRequest),
    fork(watchFlowBurnStarlyCardsRequest),
    fork(watchFlowBuyPackRequest),
    fork(watchFlowCancelSaleOfferRequest),
    fork(watchFlowCreateSaleOfferRequest),
    // fork(watchFlowFetchSaleOfferIDsRequest),
    fork(watchFlowFetchStarlyCardItemIDsRequest),
    fork(watchFlowLoginRequest),
    fork(watchBloctoLoginRequest),
    fork(watchFlowLogoutRequest),
    fork(watchFlowFestLoginRequest),
    fork(watchFlowNFTNYCLoginRequest),
  ]);
}
