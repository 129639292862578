import {
  type FC, type MouseEvent, useState, useEffect,
} from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import type { FungibleTokenType } from '@starly/starly-types';

import PopUp from 'components/PopUp';
import wallet from 'helpers/ethereumWallet';
import { numberWithCommas } from 'helpers/TextHelpers';
import backIcon from 'static//wallet/ic-back.svg';
import closeIcon from 'static/ic-close.svg';
import flowLogo from 'static/wallet/ic-flow.svg';
import fusdLogo from 'static/wallet/ic-fusd.svg';
import starlyLogo from 'static/wallet/ic-starly2.svg';
import usdcLogo from 'static/wallet/ic-usdc.svg';
import pointerIcon from 'static/wallet/pointer.svg';
import { ethereumLoginRequest, ethereumLogout } from 'store/ethereum/ethereumActions';
import { getSubscriptionCallback } from 'store/ethereum/ethereumSaga';
import { selectBalance, selectEthereumWalletAddr } from 'store/ethereum/ethereumSelectors';
import {
  selectFlowBalance, selectFUSDBalance, selectStarlyBalance, selectUsdcBalance,
} from 'store/flow/flowSelectors';
import { ethTokensMap } from 'util/constants';

import { CloseBtn, ContentsContainer } from '../../styled';
import TopUp from '../../TopUp';
import Option from './components/Option';
import * as S from './styled';

import type { CurrencyInfo, SelectWalletModalProps } from './types';

const SelectWalletModal: FC<SelectWalletModalProps> = ({
  ethTokens, flowTokens = ['USDC', 'FUSD', 'FLOW'], closeWalletModal, onBuy, onBuyEth,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const fusdBalance = useSelector(selectFUSDBalance);
  const flowBalance = useSelector(selectFlowBalance);
  const starlyBalance = useSelector(selectStarlyBalance);
  const usdcBalance = useSelector(selectUsdcBalance);
  const ethereumAddress = useSelector(selectEthereumWalletAddr);
  const ethereumBalance = useSelector(selectBalance);
  const [isTopUpOpen, setIsTopUpOpen] = useState(false);
  const [topUpCurrency, setTopUpCurrency] = useState('');

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => { document.body.style.overflow = 'auto'; };
  }, []);

  const onTopUp = (e: MouseEvent, currency: string) => {
    e.stopPropagation();
    e.preventDefault();
    setIsTopUpOpen(true);
    setTopUpCurrency(currency);
  };

  const flowCurrencies: { [key in FungibleTokenType]?: CurrencyInfo } = {
    STARLY: {
      balance: numberWithCommas(Number(starlyBalance) || 0),
      logo: starlyLogo,
      isTopUp: false,
    },
    USDC: {
      balance: numberWithCommas(Number(usdcBalance) || 0),
      logo: usdcLogo,
      isTopUp: true,
    },
    FLOW: {
      balance: Number(flowBalance).toFixed(2),
      logo: flowLogo,
      isTopUp: true,
    },
    FUSD: {
      balance: numberWithCommas(Number(fusdBalance) || 0),
      logo: fusdLogo,
      isTopUp: true,
    },
  };

  const availableProviders = wallet.getProvidersInfo();
  return (
    <PopUp padding="50px 50px" mobilePadding="50px 50px" data-test-id="wallet_popup" onHide={closeWalletModal}>
      <ContentsContainer>
        <CloseBtn
          data-test-id="close_icon"
          src={closeIcon}
          onClick={() => {
            closeWalletModal();
            if (ethereumAddress) dispatch(ethereumLogout());
          }}
        />
        <S.SelectWalletTittleText size="walletBalance" textColor="green" font="secondary">
          {t('collection.packs.choosePayment')}
        </S.SelectWalletTittleText>
        {!ethereumAddress
          ? (
            <S.SelectWalletButtonsContainer>
              {flowTokens.map((currency) => flowCurrencies[currency] !== undefined && (
                <S.OptionContainer onClick={() => onBuy(currency)} key={currency}>
                  <S.ProviderLogo src={flowCurrencies[currency]!.logo} />
                  <S.CurrencySubContainer>
                    {currency}
                    <S.BalanceContainer>
                      <S.BalanceRow>
                        <Trans i18nKey="wallet.purchaseModal.balance">
                          Balance:
                          {{ balance: flowCurrencies[currency]!.balance }}
                        </Trans>
                      </S.BalanceRow>
                      {flowCurrencies[currency]!.isTopUp && (
                        <S.BalanceTopup size="small" onClick={(e) => onTopUp(e, currency.toLowerCase())}>
                          {t('wallet.balance.topUp')}
                        </S.BalanceTopup>
                      )}
                    </S.BalanceContainer>
                  </S.CurrencySubContainer>
                  <S.PointerIcon src={pointerIcon} />
                </S.OptionContainer>
              ))}
              {ethTokens && ethTokens.length > 0
                && (availableProviders.length > 0 ? availableProviders.map((provider) => (
                  <Option
                    key={provider.check}
                    provider={provider}
                    onClick={() => dispatch(ethereumLoginRequest({
                      providerId: provider.check,
                      callbacks: getSubscriptionCallback(dispatch),
                    }))}
                  />
                )) : (
                  <Option
                    provider={wallet.getProviderInfoByCheck('isMetaMask')}
                    onClick={() => window.open(
                      `https://metamask.app.link/dapp/${window.location.href}`,
                      '_ blank',
                    )}
                  />
                ))}
              {isTopUpOpen && <TopUp currency={topUpCurrency} setIsOpen={setIsTopUpOpen} />}
            </S.SelectWalletButtonsContainer>
          )
          : (
            <S.SelectWalletButtonsContainer>
              <S.BackBtn data-test-id="close_icon" src={backIcon} onClick={() => dispatch(ethereumLogout())} />
              {ethTokens?.map((token) => ethTokensMap.has(token) && (
                <S.OptionContainer
                  key={token}
                  onClick={() => onBuyEth(token)}
                >
                  <S.ProviderLogo src={ethTokensMap.get(token.toUpperCase())?.icon} />
                  <S.CurrencySubContainer>
                    {token}
                    <S.EthBalanceContainer>
                      <S.EthBalanceRow>
                        <Trans i18nKey="wallet.purchaseModal.balance">
                          Balance:
                          {' '}
                          <b>
                            {{
                              balance: ethereumBalance[token]
                                ? (Number(ethereumBalance[token].balance)
                              / 10 ** ethereumBalance[token].decimals).toFixed(2)
                                : '0.00',
                            }}
                          </b>
                        </Trans>
                      </S.EthBalanceRow>
                      <S.EthPointerIcon src={pointerIcon} />
                    </S.EthBalanceContainer>
                  </S.CurrencySubContainer>
                  <S.PointerIcon src={pointerIcon} />
                </S.OptionContainer>
              ))}
            </S.SelectWalletButtonsContainer>
          )}
      </ContentsContainer>
    </PopUp>
  );
};

export default SelectWalletModal;
