import type { FC } from 'react';
import { Helmet } from 'react-helmet-async';

import type { HelmetProps } from './types';

const HelmetWrapper: FC<HelmetProps> = ({
  title = 'Starly',
  image,
  description,
  canonical,
}) => (
  <Helmet encodeSpecialCharacters={false}>
    <meta charSet="utf-8" />

    {canonical && <link href={window.location.origin + canonical} rel="canonical" />}

    {title && <title>{window.decodeURI(title.replaceAll('%', '%25'))}</title>}
    {description && <meta name="description" content={description} />}

    {/* Twitter MarketplaceCard */}
    <meta name="twitter:card" content="summary_large_image" />
    {description && <meta name="twitter:description" content={description} />}
    {title && <meta name="twitter:title" content={title} />}
    {image && <meta name="twitter:image" content={image} />}

    {/* Open Graph */}
    <meta property="og:type" content="website" />
    <meta property="og:site_name" content="Starly" />
    {description && <meta property="og:description" content={description} />}
    {title && <meta property="og:title" content={title} />}
    {image && <meta property="og:image" content={image} />}
  </Helmet>
);

export default HelmetWrapper;
