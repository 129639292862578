import styled from 'styled-components';
import { mediaDesktop } from '../../breakpoints';
import theme from '../theme';

export const CloseBtn = styled.img`
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 0;
  padding: 10px;
`;

export const BuyStarlyModalContainer = styled.div`
  display: grid;
  grid-gap: 26px;
  width: 100%;
  ${mediaDesktop} {
    grid-gap: 50px;
  }
`;

export const BuyStarlyModalTitle = styled.h3`
  font: bold 16px ${theme.fonts.syncopate};
  text-transform: uppercase;
  text-align: center;
  
  color: #000;
  letter-spacing: .76px;
  ${mediaDesktop} {
    font-size: 21px;
    letter-spacing: 1px;
  }
`;

export const BuyStarlyModalOptionsContainer = styled.div`
  display: grid;
  grid-gap: 10px;
  ${mediaDesktop} {
    grid-gap: 12px;
  }
`;

export const BuyStarlyModalOption = styled.a`
  width: 100%;
  border: 1px solid ${theme.colors.textWhite};
  height: 60px;
  transition: border-color .2s;
  display: grid;
  place-content: center;
  
  ${mediaDesktop} {
    height: 83px;
  }
  
  &:hover {
    border-color: #00b290;
  }
`;

export const BuyStarlyModalOptionContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const BuyStarlyOptionAdditional = styled.div`
  font: bold 12px ${theme.fonts.primary};
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  background: ${theme.colors.kucoinGreen};
  ${mediaDesktop} {
    font-size: 15px;
    letter-spacing: .71px;
    height: 33px;
  }
`;

export const BuyStarlyOptionIcon = styled.img`
  width: 200px;
  height: 40px;
  ${mediaDesktop} {
    width: 290px;
    height: 58px;
  }
`;
