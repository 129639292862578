import { PackRarity } from 'global/constants';
import CommonFrame from 'static/common-frame/common-frame@3x.png';
import LegendaryFrame from 'static/legendary-frame/legendary-frame@3x.png';
import RareFrame from 'static/rare-frame/rare-frame@3x.png';
import CommonPlusFrame from 'static/reward-frame/frame-reward-common.png';
import LegendaryPlusFrame from 'static/reward-frame/frame-reward-legendary.png';
import RarePlusFrame from 'static/reward-frame/frame-reward-rare.png';

export const frames = {
  [PackRarity.common]: CommonFrame,
  [PackRarity.legendary]: LegendaryFrame,
  [PackRarity.rare]: RareFrame,
  [PackRarity.commonPlus]: CommonPlusFrame,
  [PackRarity.legendaryPlus]: LegendaryPlusFrame,
  [PackRarity.rarePlus]: RarePlusFrame,
};
