import {
  type FC, Suspense, useEffect, useRef, useState,
} from 'react';
import { ConnectedRouter } from 'connected-react-router';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import isBetween from 'dayjs/plugin/isBetween';
import utc from 'dayjs/plugin/utc';
import { HelmetProvider } from 'react-helmet-async';
import { Provider, useDispatch, useSelector } from 'react-redux';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import styled, { ThemeProvider } from 'styled-components';

import './i18n/config';

import theme from 'components/theme';
import { AppContext } from 'context/context';
import { auth, trackException } from 'global/firebase';
import Routes from 'routes';
import { authLogoutRequest } from 'store/auth/authActions';
import { selectAuthAuthorized } from 'store/auth/authSelectors';
import { WindowSizeProvider } from 'store/WindowSizeContext';

import ClaimRewardLabel from './components/ClaimRewardLabel';
import Footer from './components/Footer';
import Header from './components/Header';
import RandomTopLabel from './components/RandomTopLabel';
import ScrollToTop from './components/ScrollToTop';
import { isIos } from './helpers/isIos';
import { useIosScale } from './hooks/useIosScale';
import store, { history } from './store/store';
import GetInView from './views/GetInPopUp';

dayjs.extend(utc);
dayjs.extend(isBetween);
dayjs.extend(duration);

const persistor = persistStore(store);

const Root = styled.div<{ $winHeight: number }>`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  min-height: ${({ $winHeight }) => `${$winHeight}px`};
  background: #000;
`;

const Wrapper = styled.main`
  flex-direction: column;
  flex: 1;
  display: flex;
`;

const NotchWrapper = styled.div`
  flex-direction: column;
  flex: 1;
  display: flex;
  position: relative;
  padding-left: env(safe-area-inset-left);
`;

const LogoutWatcher: FC = () => {
  const dispatch = useDispatch();
  const isUserAuthorized = useSelector(selectAuthAuthorized);
  useEffect(() => {
    auth.onAuthStateChanged((currUser) => {
      if (!currUser && isUserAuthorized) {
        dispatch(authLogoutRequest());
      }
    });
  }, []);

  return null;
};

const StorageWatcher: FC = () => {
  const dispatch = useDispatch();

  /**
   * Flush storage on write error
   */
  useEffect(() => {
    const originalSetItem = window.Storage.prototype.setItem;

    window.Storage.prototype.setItem = function setItem() {
      try {
        /* eslint-disable-next-line */
        originalSetItem.apply(this, arguments as any);
      } catch {
        localStorage.clear();

        dispatch(authLogoutRequest());

        /* eslint-disable-next-line */
        console.log(arguments);

        trackException('Quota exceeded error');
      }
    };
  }, []);

  return null;
};

const url = new URL(window.location.href);
const headless = url.searchParams.get('headless');

const App: FC = () => {
  const [winHeight, setWinHeight] = useState(window.innerHeight);
  const scale = useIosScale();
  const updateSize = () => {
    setWinHeight(window.innerHeight * Math.max(window.visualViewport?.scale || 1, 1));
  };

  useEffect(() => {
    if (isIos()) {
      const meta = document.querySelector('meta[name="viewport"]');
      if (meta) {
        meta.setAttribute('content', 'width=device-width,initial-scale=1,minimum-scale=1,maximum-scale=1, viewport-fit=cover');
      }
    }
    window.addEventListener('resize', updateSize);

    const checkNetwork = () => {
      if (navigator.onLine) {
        window.location.reload();
      }
    };
    window.addEventListener('online', checkNetwork);

    return () => window.removeEventListener('resize', updateSize);
  }, []);

  useEffect(() => updateSize(), [scale, window.innerHeight, window.innerWidth]);

  /**
   * Track all uncatched errors via Firebase Events
   */
  useEffect(() => {
    window.onerror = (error) => {
      trackException(error.toString(), true);
    };
  }, []);

  const topRef = useRef<HTMLDivElement>(null);

  return (
    <AppContext.Provider value={{ topRef }}>
      <WindowSizeProvider>
        <Suspense fallback={null}>
          <LogoutWatcher />
          <StorageWatcher />
          <Wrapper>
            <Root $winHeight={winHeight}>
              {!headless && <Header />}
              {!headless && <GetInView />}
              {!headless && <ScrollToTop />}
              <div ref={topRef} />
              <ClaimRewardLabel />
              <RandomTopLabel />
              <NotchWrapper><Routes /></NotchWrapper>
            </Root>
          </Wrapper>
          {!headless && <Footer />}
        </Suspense>
      </WindowSizeProvider>
    </AppContext.Provider>
  );
};

const ProviderComponent: FC = () => (
  <ThemeProvider theme={theme}>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <HelmetProvider>
          <ConnectedRouter history={history}>
            <App />
          </ConnectedRouter>
        </HelmetProvider>
      </PersistGate>
    </Provider>
  </ThemeProvider>
);

export default ProviderComponent;
