import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import { createBrowserHistory } from 'history';
import { routerMiddleware, connectRouter } from 'connected-react-router';

import createSagaMiddleware from 'redux-saga';
import localforage from 'localforage';

/**
 * Application reducers
 */
import appReducer from './app/appReducer';
import authReducer from './auth/authReducer';
import bidsReducer from './bids/bidsReducer';
import cardReducer from './card/cardReducer';
import collectionReducer from './collection/collectionReducer';
import ethereumReducer from './ethereum/ethereumReducer';
import firebaseConfigReducer from './firebaseConfig/firebaseConfigReducer';
import flowFestReducer from './flowFest/flowFestReducer';
import flowNFTNYCReducer from './flowNFTNYC/flowNFTNYCReducer';
import flowReducer from './flow/flowReducer';
import loginReducer from './login/loginReducer';
import marketplaceReducer from './marketplace/marketplaceReducer';
import notificationsReducer from './notifications/notificationsReducer';
import packReducer from './pack/packReducer';
import rankingReducer from './ranking/rankingReducer';
import saleCardReducer from './saleCard/saleCardReducer';
import transactionsReducer from './transactions/transactionsReducer';
import userReducer from './user/userReducer';
import vestingReducer from './vesting/vestingReducer';

/**
 * Application sagas
 */
import appSaga from './app/appSaga';
import authSaga from './auth/authSaga';
import bidsSaga from './bids/bidsSaga';
import cardSaga from './card/cardSaga';
import collectionSaga from './collection/collectionSaga';
import ethereumSaga from './ethereum/ethereumSaga';
import flowFestSaga from './flowFest/flowFestSaga';
import flowNFTNYCSaga from './flowNFTNYC/flowNFTNYCSaga';
import flowSaga from './flow/flowSaga';
import marketplaceSaga from './marketplace/marketplaceSaga';
import notificationsSaga from './notifications/notificationsSaga';
import packSaga from './pack/packSaga';
import rankingSaga from './ranking/rankingSaga';
import saleCardSaga from './saleCard/saleCardSaga';
import transactionsSaga from './transactions/transactionsSaga';
import userSaga from './user/userSaga';
import vestingSaga from './vesting/vestingSaga';

/**
 * Configure persistant storage
 */
const persistConfig = {
  key: process.env.REACT_APP_COMMIT_HASH || 'local',
  whitelist: [
    'auth',
  ],
  storage: localforage,
};

export const history = createBrowserHistory();

const sagaMiddleware = createSagaMiddleware();

const rootReducer = combineReducers({
  app: appReducer,
  auth: persistReducer({
    key: 'auth',
    storage: localforage,
  }, authReducer),
  bids: bidsReducer,
  collections: persistReducer({
    key: 'collections',
    blacklist: [
      'activeCollection',
      'itemsCachedState',
      'lastCreatedCollection',
    ],
    storage: localforage,
  }, collectionReducer),
  ethereum: ethereumReducer,
  vesting: persistReducer({
    key: 'vesting',
    whitelist: ['wallet'],
    storage: localforage,
  }, vestingReducer),
  flow: persistReducer({
    key: 'flow',
    blacklist: [
      'isWrongModalOpen',
      'isWalletModalOpen',
      'isLowBalanceModalOpen',
      'isLoading',
    ],
    storage: localforage,
  }, flowReducer),
  user: persistReducer({
    key: 'user',
    blacklist: [
      'isLoading',
    ],
    storage: localforage,
  }, userReducer),
  login: loginReducer,
  card: cardReducer,
  cardForSale: saleCardReducer,
  pack: persistReducer({
    key: 'pack',
    blacklist: [
      'isLoading',
      'isPackOpening',
      'isProfileUpdating',
      'buying',
    ],
    storage: localforage,
  }, packReducer),
  marketplace: marketplaceReducer,
  ranking: rankingReducer,
  notifications: notificationsReducer,
  transactions: transactionsReducer,
  flowFest: flowFestReducer,
  nftnyc: flowNFTNYCReducer,
  firebaseConfig: firebaseConfigReducer,
  router: connectRouter(history),
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: [
    routerMiddleware(history),
    sagaMiddleware,
  ],
});

/**
 * Run all Sagas
 */
sagaMiddleware.run(appSaga);
sagaMiddleware.run(authSaga);
sagaMiddleware.run(bidsSaga);
sagaMiddleware.run(cardSaga);
sagaMiddleware.run(collectionSaga);
sagaMiddleware.run(ethereumSaga);
sagaMiddleware.run(flowFestSaga);
sagaMiddleware.run(flowNFTNYCSaga);
sagaMiddleware.run(flowSaga);
sagaMiddleware.run(marketplaceSaga);
sagaMiddleware.run(notificationsSaga);
sagaMiddleware.run(packSaga);
sagaMiddleware.run(rankingSaga);
sagaMiddleware.run(saleCardSaga);
sagaMiddleware.run(transactionsSaga);
sagaMiddleware.run(userSaga);
sagaMiddleware.run(vestingSaga);

export type RootState = ReturnType<typeof rootReducer>;

export default store;
