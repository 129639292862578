import type { FungibleTokenType, SaleCut } from '@starly/starly-types';
import { createAction } from '@reduxjs/toolkit';
import { type FlowTypes } from '../../types';

type FlowOtherPayload = any;

type FlowBalancePayload = {
  USDC: string;
  STARLY: string;
  FLOW: string;
  FUSD: string;
};

type FlowBuyPackRequestPayload = {
  collectionId: string
  packIds: string[]
  price: number
  currency: FungibleTokenType
  paymentCurrency: FungibleTokenType
  beneficiaryAddress: string
  beneficiaryCutPercent: number
  creatorAddress: string
  creatorCutPercent: number
  additionalCuts: SaleCut[]
};

type FlowAcceptSaleOfferRequestPayload = {
  nftItemId: number
  marketCollectionAddress: string
  isMarketplace?: boolean
  paymentCurrency: FungibleTokenType
};

type FlowCancelSaleOfferRequestPayload = {
  nftItemId: number
};

type FlowCreateSaleOfferRequestPayload = {
  nftItemId: number
  price: number
  beneficiaryAddress: string
  beneficiaryCutPercent: number
  creatorAddress: string
  creatorCutPercent: number
  minterAddress: string
  mintingCutPercent: number
};

// type FlowFetchSaleOfferIDsRequestPayload = {
//   flowAccount: string
// };

// type FlowFetchSaleOfferIDsResponsePayload = {
//   saleOfferIDs: number[]
// };

type FlowFetchStarlyCardItemIDsRequestPayload = {
  flowAccount: string
};

type FlowFetchStarlyCardItemIDsResponsePayload = {
  starlyCardIDs: number[]
};

type FlowAcceptSaleOfferResponsePayload = {
  result: any,
  nftItemId: number,
  isMarketplace?: boolean
};

type FlowBurnStarlyCardsRequestPayload = {
  nftItemIds: number[]
};

type FlowWrongWalletPayload = {
  code: number,
  message: string
};

type FlowLowBalancePayload = {
  isOpen: boolean,
  currency?: FungibleTokenType,
};

type FlowLoginResponsePayload = {
  wallet: FlowTypes.Wallet,
  flowBalance: string,
  fusdBalance: string,
  starlyBalance: string,
  usdcBalance: string,
};

export const flowAcceptSaleOfferRequest = createAction<FlowAcceptSaleOfferRequestPayload>('flow/acceptSaleOffer/request');

export const flowAcceptSaleOfferResponse = createAction<FlowAcceptSaleOfferResponsePayload>('flow/acceptSaleOffer/response');

export const flowBalanceRequest = createAction<FlowOtherPayload>('flow/balance/request');

export const flowBalanceResponse = createAction<FlowBalancePayload>('flow/balance/response');

// export const flowBurnAllCardsRequest = createAction<FlowOtherPayload>('flow/burnAllCards/request');

// export const flowBurnAllCardsResponse = createAction<FlowOtherPayload>('flow/burnAllCards/response');

export const flowBurnStarlyCardsRequest = createAction<FlowBurnStarlyCardsRequestPayload>('flow/burnStarlyCards/request');

export const flowBurnStarlyCardsResponse = createAction<FlowOtherPayload>('flow/burnStarlyCards/response');

export const flowBuyPackRequest = createAction<FlowBuyPackRequestPayload>('flow/buyPack/request');

export const flowBuyPackResponse = createAction<FlowOtherPayload>('flow/buyPack/response');

export const flowCancelSaleOfferRequest = createAction<FlowCancelSaleOfferRequestPayload>('flow/cancelSaleOffer/request');

export const flowCancelSaleOfferResponse = createAction<FlowOtherPayload>('flow/cancelSaleOffer/response');

export const flowCreateSaleOfferRequest = createAction<FlowCreateSaleOfferRequestPayload>('flow/createSaleOffer/request');

export const flowCreateSaleOfferResponse = createAction<FlowOtherPayload>('flow/createSaleOffer/response');

// export const flowFetchSaleOfferIDsRequest = createAction<FlowFetchSaleOfferIDsRequestPayload>('flow/fetchSaleOfferIDs/request');

// export const flowFetchSaleOfferIDsResponse = createAction<FlowFetchSaleOfferIDsResponsePayload>('flow/fetchSaleOfferIDs/response');

export const flowFetchStarlyCardItemIDsRequest = createAction<FlowFetchStarlyCardItemIDsRequestPayload>(
  'flow/fetchStarlyCardItemIDs/request',
);

export const flowFetchStarlyCardItemIDsResponse = createAction<FlowFetchStarlyCardItemIDsResponsePayload>(
  'flow/fetchStarlyCardItemIDs/response',
);

export const flowLoginRequest = createAction<FlowOtherPayload>('flow/login/request');
export const flowBloctoLoginRequest = createAction<FlowOtherPayload>('flow/blocto-login/request');

export const flowLoginResponse = createAction<FlowLoginResponsePayload>('flow/login/response');

export const flowLogoutRequest = createAction<FlowOtherPayload>('flow/logout/request');

export const flowLogoutResponse = createAction<FlowOtherPayload>('flow/logout/response');

export const flowWrongModal = createAction<FlowWrongWalletPayload | undefined>('flow/WRONG_MODAL');
export const flowToggleModal = createAction<FlowOtherPayload>('flow/TOGGLE_MODAL');
export const flowLowBalanceToggleModal = createAction<FlowLowBalancePayload>('flow/LOW_BALANCE_TOGGLE_MODAL');
export const flowToggleLoading = createAction<FlowOtherPayload>('flow/TOGGLE_LOADING');

export const flowFestLoginRequest = createAction('flowFest/login/request');
export const flowFestLoginResponse = createAction<FlowLoginResponsePayload>('flowFest/login/response');
export const flowFestToggleModal = createAction<FlowOtherPayload>('flowFest/TOGGLE_MODAL');
export const flowFestToggleLoading = createAction<FlowOtherPayload>('flowFest/TOGGLE_LOADING');

export const flowNFTNYCLoginRequest = createAction('flowNFTNYC/login/request');
export const flowNFTNYCLoginResponse = createAction<FlowLoginResponsePayload>('flowNFTNYC/login/response');
export const flowNFTNYCToggleModal = createAction<FlowOtherPayload>('flowNFTNYC/TOGGLE_MODAL');
export const flowNFTNYCToggleLoading = createAction<FlowOtherPayload>('flowNFTNYC/TOGGLE_LOADING');
