import type { SaleCut } from '@starly/starly-types';

import * as fcl from '@blocto/fcl';
import * as t from '@onflow/types';
import { invariant } from '@onflow/util-invariant';
import { onError } from 'helpers/onError';
import { tx } from '../util/tx';

const CODE = fcl.cdc`
import FungibleToken from 0xFUNGIBLE_TOKEN_ADDRESS
import FlowToken from 0xFLOW_TOKEN_ADDRESS
import StarlyCardMarket from 0xSTARLY_CARD_MARKET_ADDRESS
import StarlyPack from 0xSTARLY_PACK_ADDRESS

transaction(
    collectionID: String,
    packIDs: [String],
    price: UFix64,
    beneficiaryAddress: Address,
    beneficiaryCutPercent: UFix64,
    creatorAddress: Address,
    creatorCutPercent: UFix64,
    additionalSaleCutsPercents: {Address: UFix64}) {

    let paymentVault: @FungibleToken.Vault
    let beneficiaryVault: Capability<&FlowToken.Vault{FungibleToken.Receiver}>
    let creatorVault: Capability<&FlowToken.Vault{FungibleToken.Receiver}>
    let buyerAddress: Address
    let beneficiarySaleCutReceiver: StarlyCardMarket.SaleCutReceiverV2
    let creatorSaleCutReceiver: StarlyCardMarket.SaleCutReceiverV2
    let additionalSaleCutReceivers: [StarlyCardMarket.SaleCutReceiverV2]

    prepare(signer: AuthAccount) {
        self.buyerAddress = signer.address;
        let buyerVault = signer.borrow<&FlowToken.Vault>(from: /storage/flowTokenVault)
            ?? panic("Cannot borrow FLOW vault from acct storage")
        self.paymentVault <- buyerVault.withdraw(amount: price)

        let beneficiary = getAccount(beneficiaryAddress);
        self.beneficiaryVault = beneficiary.getCapability<&FlowToken.Vault{FungibleToken.Receiver}>(/public/flowTokenReceiver)!
        assert(self.beneficiaryVault.borrow() != nil, message: "Missing or mis-typed FLOW receiver (beneficiary)")
        self.beneficiarySaleCutReceiver = StarlyCardMarket.SaleCutReceiverV2(receiver: self.beneficiaryVault, percent: beneficiaryCutPercent)

        let creator = getAccount(creatorAddress)
        self.creatorVault = creator.getCapability<&FlowToken.Vault{FungibleToken.Receiver}>(/public/flowTokenReceiver)!
        assert(self.creatorVault.borrow() != nil, message: "Missing or mis-typed FLOW receiver (creator)")
        self.creatorSaleCutReceiver = StarlyCardMarket.SaleCutReceiverV2(receiver: self.creatorVault, percent: creatorCutPercent)

        self.additionalSaleCutReceivers = []
        for address in additionalSaleCutsPercents.keys {
            let additionalAccount = getAccount(address);
            let additionalCutPercent = additionalSaleCutsPercents[address]!
            let additionalVault = additionalAccount.getCapability<&FlowToken.Vault{FungibleToken.Receiver}>(/public/flowTokenReceiver)!
            assert(additionalVault.borrow() != nil, message: "Missing or mis-typed FLOW receiver (additional)")
            let additionalSaleCutReceiver = StarlyCardMarket.SaleCutReceiverV2(receiver: additionalVault, percent: additionalCutPercent)
            self.additionalSaleCutReceivers.append(additionalSaleCutReceiver)
        }
    }

    execute {
        StarlyPack.purchaseV2(
            collectionID: collectionID,
            packIDs: packIDs,
            price: price,
            currency: Type<@FlowToken.Vault>(),
            buyerAddress: self.buyerAddress,
            paymentVault: <- self.paymentVault,
            beneficiarySaleCutReceiver: self.beneficiarySaleCutReceiver,
            creatorSaleCutReceiver: self.creatorSaleCutReceiver,
            additionalSaleCutReceivers: self.additionalSaleCutReceivers)
    }
}`;

export async function blowBuyPackUsingFLOWTransaction(
  collectionID: string,
  packIDs: string[],
  price: number,
  beneficiaryAddress: string,
  beneficiaryCutPercent: number,
  creatorAddress: string,
  creatorCutPercent: number,
  additionalCuts: SaleCut[],
  opts = { onError },
) {
  invariant(collectionID != null, 'collectionID must be supplied');
  invariant(packIDs != null, 'packIDs must be supplied');
  invariant(packIDs.length > 0, 'packIDs must contains some packs ids');
  invariant(price > 0, 'price must be positive');
  invariant(beneficiaryCutPercent > 0, 'beneficiaryCutPercent must be positive');
  invariant(beneficiaryCutPercent <= 1, 'beneficiaryCutPercent must no be greater than 1');
  invariant(creatorCutPercent > 0, 'creatorCutPercent must be positive');
  invariant(creatorCutPercent <= 1, 'creatorCutPercent must no be greater than 1');
  invariant(beneficiaryCutPercent + creatorCutPercent + additionalCuts.reduce(
    ((previous, current) => previous + current.percent), 0.0,
  ) === 1,
  'beneficiaryCutPercent + creatorCutPercent + additionalCuts must no equal to 1');

  // Get latest block info
  const block = await fcl.send([fcl.getBlock(false) as Function]).then(fcl.decode);

  const recordForAdditionalSaleCutCadence = { key: t.Address, value: t.UFix64 };
  const additionalSaleCutsTypeForCadence = new Array(additionalCuts.length).fill(recordForAdditionalSaleCutCadence);

  const additionalCutsArgument: { key: string; value: string }[] = [];
  for (let i = 0; i < additionalCuts.length; i += 1) {
    const additionalCut = additionalCuts[i];
    additionalCutsArgument.push({
      key: additionalCut.address,
      value: String(additionalCut.percent.toFixed(8)),
    });
  }

  return tx(
    [
      fcl.transaction(CODE),
      fcl.args([
        fcl.arg(String(collectionID), t.String),
        fcl.arg(packIDs, t.Array(t.String)),
        fcl.arg(String(price.toFixed(8)), t.UFix64),
        fcl.arg(String(beneficiaryAddress), t.Address),
        fcl.arg(String(beneficiaryCutPercent.toFixed(8)), t.UFix64),
        fcl.arg(String(creatorAddress), t.Address),
        fcl.arg(String(creatorCutPercent.toFixed(8)), t.UFix64),
        fcl.arg(additionalCutsArgument, t.Dictionary(additionalSaleCutsTypeForCadence)),
      ]),
      fcl.payer(fcl.authz),
      fcl.proposer(fcl.authz),
      fcl.authorizations([fcl.authz]),
      fcl.limit(1000),
      fcl.ref(block.id),
    ],
    opts,
  );
}
