import {
  all, call, fork, put, takeEvery,
} from 'redux-saga/effects';
import {
  coinMarketCapRequest, coinMarketCapResponse,
} from './appActions';
import { functions } from '../../global/firebase';

function* watchCoinMarketCapRequest() {
  yield takeEvery(coinMarketCapRequest, function* coinMarketCapRequestWorker() {
    const getStarlyTokenRate = functions.httpsCallable('getStarlyTokenRateFromCoinMarketCap');
    const { data }: any = yield call(() => getStarlyTokenRate());
    yield put(coinMarketCapResponse(data as any));
  });
}

export default function* appSaga() {
  yield all([
    fork(watchCoinMarketCapRequest),
  ]);
}
