import { type FC } from 'react';
import { type Media } from '@starly/starly-types';
import {
  RankingCollectorsRow, RankingDashRune, RankingRowCollectionContainer,
  RankingRowCollectionText, RankingTableTypography, RankingUserAvatar,
} from '../styled';
import { type RankingCollectorsRowProps } from '../types';
import { Column, ColumnLink } from '../../../components/Table';
import { numberWithCommas } from '../../../helpers/TextHelpers';

const RankingCollectorsContentRow: FC<RankingCollectorsRowProps> = ({
  place,
  link,
  collectorsScore: {
    collector_score,
    username,
    name,
    total_cards,
    card_counts,
    user_avatar,
  },
  isOwnProfile,
}) => (
  <RankingCollectorsRow data-test-id="ranking_collectors_row" isDark isOwnProfile={isOwnProfile}>
    <Column data-test-id="ranking_collectors_table_number" justify="center" bold>{place || '>300'}</Column>
    <Column data-test-id="ranking_collectors_table_collection">
      <ColumnLink to={link} $notInlineBlock target="_blank">
        <RankingRowCollectionContainer>
          <RankingUserAvatar data-test-id="ranking_collectors_table_media" media={user_avatar as Media} />
          <RankingRowCollectionText>
            <RankingTableTypography data-test-id="ranking_collectors_table_collection_name" data-name-attribute={`${name}`} bold>
              {name}
            </RankingTableTypography>
            <RankingTableTypography data-test-id="ranking_collectors_table_collection_username" data-name-attribute={`${username}`}>
              @
              {username}
            </RankingTableTypography>
          </RankingRowCollectionText>
        </RankingRowCollectionContainer>
      </ColumnLink>
    </Column>
    <Column data-test-id="ranking_collectors_table_score" data-score-attribute={`${collector_score}`} bold textAlign="center">
      {numberWithCommas(collector_score || 0, 0)}
    </Column>
    <Column
      data-test-id="ranking_collectors_table_legendary_card_count"
      data-legendary-attribute={card_counts ? card_counts.legendary : 0}
      justify="center"
    >
      {
        card_counts
          ? numberWithCommas(card_counts.legendary, 0)
          : <RankingDashRune />
      }
    </Column>
    <Column
      data-test-id="ranking_collectors_table_rare_card_count"
      data-rare-attribute={card_counts ? card_counts.rare : 0}
      justify="center"
    >
      {
        card_counts
          ? numberWithCommas(card_counts.rare, 0)
          : <RankingDashRune />
      }
    </Column>
    <Column
      data-test-id="ranking_collectors_table_common_card_count"
      data-common-attribute={card_counts ? card_counts.common : 0}
      justify="center"
    >
      {
        card_counts
          ? numberWithCommas(card_counts.common, 0)
          : <RankingDashRune />
      }
    </Column>
    <Column data-test-id="ranking_collectors_table_total_card_count" data-total-cards-attribute={`${total_cards}`} bold textAlign="center">
      {numberWithCommas(total_cards || 0, 0)}
    </Column>
  </RankingCollectorsRow>
);

export default RankingCollectorsContentRow;
