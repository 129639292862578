import styled, { css } from 'styled-components';

export const SelectContainer = styled.div<{ $isOpen: boolean }>`
  user-select: none;
  cursor: pointer;
  font: bold 14px ${({ theme }) => theme.fonts.primary};
  color: ${({ theme }) => theme.colors.white};
  text-transform: uppercase;

  position: relative;
  height: 50px;

  border-top: solid 2px ${({ theme }) => theme.colors.black};
  background-color: ${({ theme }) => theme.colors.dark};
`;

export const SelectHeader = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  padding-left: 10px;
  display: flex;
  align-items: center;
`;

export const SelectOptionsList = styled.div<{ $isOpen: boolean }>`
  position: absolute;
  top: 100%;
  display: grid;
  width: 100%;
  /* transition: max-height 0.15s ease-in-out; */
  z-index: 2;

  overflow: hidden;
  max-height: 0;

  ${({ $isOpen }) => $isOpen
    && css`
      max-height: 145px;
    `}
`;

export const SelectOption = styled.div`
  background: #282828;
  /* transition: all 0.3s; */
  padding: 10px 10px;

  &:hover {
    background: #2d2d2d;
  }

  border-top: solid 2px #000;
`;

export const SelectArrow = styled.div<{ $isMenuOpen?: boolean }>`
  position: absolute;
  width: 50px;
  height: 100%;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  border-left: solid 2px #000;
  background-color: #282828;

  svg {
    fill: currentColor;
    ${({ $isMenuOpen }) => $isMenuOpen && css`transform: rotateZ(180deg);`}
  }
`;
