import { type FlowFestProjectId } from '@starly/starly-types';
import { flowFestBuyBeamItemTransaction } from './internal/buyBeamItem.tx';
import { flowFestBuyBlockleteGamesItemTransaction } from './internal/buyBlockleteGamesItem.tx';
import { flowFestBuyChainmonstersRewardsItemTransaction } from './internal/buyChainmonstersRewardsItem.tx';
import { flowFestBuyCraveItemTransaction } from './internal/buyCraveItem.tx';
import { flowFestBuyShardItemTransaction } from './internal/buyShardItem.tx';
import { flowFestBuyEverbloomItemTransaction } from './internal/buyEverbloomItem.tx';
import { flowFestBuyFantastecItemTransaction } from './internal/buyFantastecItem.tx';
import { flowFestBuyCricketMomentsItemTransaction } from './internal/buyCricketMomentsItem.tx';
import { flowFestBuyVouchersItemTransaction } from './internal/buyVouchersItem.tx';
import { flowFestBuyKOTDItemTransaction } from './internal/buyKOTDItem.tx';
import { flowFestBuyKLKTNItemTransaction } from './internal/buyKLKTNItem.tx';
import { flowFestBuyMusicBlockItemTransaction } from './internal/buyMusicBlockItem.tx';
import { flowFestBuyMynftItemTransaction } from './internal/buyMynftItem.tx';
import { flowFestBuyGaiaItemTransaction } from './internal/buyGaiaItem.tx';
import { flowFestBuyNyatheesOVOItemTransaction } from './internal/buyNyatheesOVOItem.tx';
import { flowFestBuyRaceDayItemTransaction } from './internal/buyRaceDayItem.tx';
import { flowFestBuyRareRoomsItemTransaction } from './internal/buyRareRoomsItem.tx';
import { flowFestBuyRCRDSHPItemTransaction } from './internal/buyRCRDSHPItem.tx';
import { flowFestBuySportsIconItemTransaction } from './internal/buySportsIconItem.tx';
import { flowFestBuyStarlyCardItemTransaction } from './internal/buyStarlyCardItem.tx';
import { flowFestBuyTheFabricantMysteryBoxItemTransaction } from './internal/buyTheFabricantMysteryBoxItem.tx';
import { flowFestBuyCaaPassItemTransaction } from './internal/buyCaaPassItem.tx';
import { flowFestBuyTopShotItemTransaction } from './internal/buyTopShotItem.tx';
import { flowFestBuyTuneGoItemTransaction } from './internal/buyTuneGoItem.tx';
import { flowFestBuyMatrixWorldFlowFestItemTransaction } from './internal/buyMatrixWorldFlowFestItem.tx';
import { flowFestBuyCollectibleItemTransaction } from './internal/buyCollectibleItem.tx';

export async function flowFestBuyTransaction(
  project: FlowFestProjectId,
  listingResourceID: number,
  storefrontAddress: string,
  buyPrice: number,
) {
  switch (project) {
    case 'beam_fright':
      return flowFestBuyBeamItemTransaction(listingResourceID, storefrontAddress, buyPrice);
    case 'blocklete_golf':
      return flowFestBuyBlockleteGamesItemTransaction(listingResourceID, storefrontAddress, buyPrice);
    case 'chainmonsters':
      return flowFestBuyChainmonstersRewardsItemTransaction(listingResourceID, storefrontAddress, buyPrice);
    case 'crave':
      return flowFestBuyCraveItemTransaction(listingResourceID, storefrontAddress, buyPrice);
    case 'eternal':
      return flowFestBuyShardItemTransaction(listingResourceID, storefrontAddress, buyPrice);
    case 'everbloom':
      return flowFestBuyEverbloomItemTransaction(listingResourceID, storefrontAddress, buyPrice);
    case 'fantastec_swap':
      return flowFestBuyFantastecItemTransaction(listingResourceID, storefrontAddress, buyPrice);
    case 'faze':
      return flowFestBuyCricketMomentsItemTransaction(listingResourceID, storefrontAddress, buyPrice);
    case 'jambb':
      return flowFestBuyVouchersItemTransaction(listingResourceID, storefrontAddress, buyPrice);
    case 'king_of_the_dot':
      return flowFestBuyKOTDItemTransaction(listingResourceID, storefrontAddress, buyPrice);
    case 'klktn':
      return flowFestBuyKLKTNItemTransaction(listingResourceID, storefrontAddress, buyPrice);
    case 'melos_studio':
      return flowFestBuyMusicBlockItemTransaction(listingResourceID, storefrontAddress, buyPrice);
    case 'mugen_art':
      return flowFestBuyMynftItemTransaction(listingResourceID, storefrontAddress, buyPrice);
    case 'mynft':
      return flowFestBuyMynftItemTransaction(listingResourceID, storefrontAddress, buyPrice);
    case 'nft_genius':
      return flowFestBuyGaiaItemTransaction(listingResourceID, storefrontAddress, buyPrice);
    case 'ovo':
      return flowFestBuyNyatheesOVOItemTransaction(listingResourceID, storefrontAddress, buyPrice);
    case 'raceday_nft':
      return flowFestBuyRaceDayItemTransaction(listingResourceID, storefrontAddress, buyPrice);
    case 'racing_time':
      return flowFestBuyMynftItemTransaction(listingResourceID, storefrontAddress, buyPrice);
    case 'rarerooms':
      return flowFestBuyRareRoomsItemTransaction(listingResourceID, storefrontAddress, buyPrice);
    case 'rcrdshp':
      return flowFestBuyRCRDSHPItemTransaction(listingResourceID, storefrontAddress, buyPrice);
    case 'sportsicon':
      return flowFestBuySportsIconItemTransaction(listingResourceID, storefrontAddress, buyPrice);
    case 'starly':
      return flowFestBuyStarlyCardItemTransaction(listingResourceID, storefrontAddress, buyPrice);
    case 'the_fabricant':
      return flowFestBuyTheFabricantMysteryBoxItemTransaction(listingResourceID, storefrontAddress, buyPrice);
    case 'thing_fund':
      return flowFestBuyCaaPassItemTransaction(listingResourceID, storefrontAddress, buyPrice);
    case 'topshot':
      return flowFestBuyTopShotItemTransaction(listingResourceID, storefrontAddress, buyPrice);
    case 'tunego':
      return flowFestBuyTuneGoItemTransaction(listingResourceID, storefrontAddress, buyPrice);
    case 'voxel_knight':
      return flowFestBuyMynftItemTransaction(listingResourceID, storefrontAddress, buyPrice);
    case 'whitematrix':
      return flowFestBuyMatrixWorldFlowFestItemTransaction(listingResourceID, storefrontAddress, buyPrice);
    case 'xtingles':
      return flowFestBuyCollectibleItemTransaction(listingResourceID, storefrontAddress, buyPrice);
    default:
      throw Error(`Unknown project: ${project}`);
  }
}
