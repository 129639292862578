import { desktopBreakpoint } from 'breakpoints';
import { type IProviderInfo } from 'helpers/ethereumWallet';
import { useWindowSize } from 'hooks/useWindowSize';
import {
  type FC,
} from 'react';
import { Trans } from 'react-i18next';

import pointerIcon from 'static/wallet/pointer.svg';

import * as S from '../styled';

interface OptionProps {
  provider: IProviderInfo;
  onClick: () => void;
}

const Option: FC<OptionProps> = ({ provider, onClick }) => {
  const width = useWindowSize();
  const isMobile = width <= desktopBreakpoint;

  if (isMobile) {
    return null;
  }

  return (
    <S.OptionContainer
      key={provider.check}
      onClick={onClick}
    >
      <S.ProviderLogo src={provider.logo} />
      <S.CurrencySubContainer>
        {provider.name}
        <S.WalletDescriptionContainer>
          {isMobile ? (
            <Trans i18nKey="wallet.purchaseModal.ethTokenSupportMobile">
              <S.SupportedTokens>{{ tokens: 'USDT, USDC, BUSD' }}</S.SupportedTokens>
              {' '}
              on
              {' '}
              BSC
            </Trans>
          ) : (
            <Trans i18nKey="wallet.purchaseModal.ethTokenSupport">
              <div>Supported</div>
              {' '}
              <S.SupportedTokens>{{ tokens: 'USDT, USDC, BUSD' }}</S.SupportedTokens>
              {' '}
              on
              {' '}
              BSC
            </Trans>
          )}
        </S.WalletDescriptionContainer>
      </S.CurrencySubContainer>
      <S.PointerIcon src={pointerIcon} />
    </S.OptionContainer>
  );
};

export default Option;
