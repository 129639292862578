import { push } from 'connected-react-router';
import { trackEvent } from 'global/firebase';
import {
  all, fork, put, select, takeEvery,
} from 'redux-saga/effects';

import { BENEFICIARY_MARKET_CUT_PERCENT } from '../../global/constants';
import { updateCardEditionStatus } from '../card/cardActions';
import {
  flowBalanceRequest,
  flowCancelSaleOfferResponse,
  flowCreateSaleOfferRequest,
  flowCreateSaleOfferResponse,
} from '../flow/flowActions';
import {
  cardForSaleError,
  cardForSalePurchased,
  cardForSaleRequest,
  cardForSaleReset,
  cardForSaleSuccess,
  cardSaleSetCardProgress,
  purchasedCardLoad,
} from './saleCardActions';

function* watchCardForSaleRequest() {
  yield takeEvery(cardForSaleRequest, function* CardForSaleRequestWorker(action) {
    const {
      payload: {
        collectionCreatorFlowAccount,
        nftItemId,
        price,
        creatorCutPercent,
        minterFlowAccount,
        mintingCutPercent,
      },
    } = action;
    yield put(cardSaleSetCardProgress({ nftItemId, inProgress: true }));
    yield put(flowCreateSaleOfferRequest({
      nftItemId,
      price,
      beneficiaryAddress: process.env.REACT_APP_BENEFICIARY_ADDRESS as string,
      beneficiaryCutPercent: BENEFICIARY_MARKET_CUT_PERCENT,
      creatorAddress: collectionCreatorFlowAccount,
      creatorCutPercent,
      minterAddress: minterFlowAccount,
      mintingCutPercent,
    }));
    trackEvent('card_placed_for_sale');
  });
}

function* watchCardForSaleOfferResponse() {
  yield takeEvery(flowCreateSaleOfferResponse, function* cardForSaleOfferWorker({ payload }) {
    const { pathname } = window.location;
    const [cardId, edition] = pathname.split('/').filter((_, i, arr) => i >= arr.length - 2);
    if (payload.result && !payload.result.errorMessage) {
      yield put(updateCardEditionStatus({
        cardId, edition, status: 'for_sale',
      }));
      yield put(cardForSaleSuccess());
    } else {
      yield put(cardForSaleError());
    }
    const owner: string = yield select((state) => state.card.items[cardId].collection.creator.username) || 'c';
    yield put(push(pathname.replace('card-for-sale', owner)));
    yield put(cardSaleSetCardProgress({ nftItemId: payload.nftItemId, inProgress: false }));
  });
}

function* watchRemoveCardFromSale() {
  yield takeEvery(flowCancelSaleOfferResponse, function* CardRemoveFromSaleWorker({ payload }) {
    const { pathname } = window.location;
    const [cardId, edition] = pathname.split('/').filter((_, i, arr) => i >= arr.length - 2);
    yield put(cardForSaleReset());
    if (payload.result && !payload.result.errorMessage) {
      yield put(updateCardEditionStatus({
        cardId, edition, status: 'created',
      }));
    } else {
      yield put(cardForSaleError());
    }
    yield put(cardSaleSetCardProgress({ nftItemId: payload.nftItemId, inProgress: false }));
  });
}

function* watchLoadedCardPurchased() {
  yield takeEvery(purchasedCardLoad, function* LoadedCardPurchaseWorker() {
    yield put(flowBalanceRequest({}));
    yield put(cardForSalePurchased());
  });
}

export default function* saleCardSaga() {
  yield all([
    fork(watchCardForSaleRequest),
    fork(watchRemoveCardFromSale),
    fork(watchCardForSaleOfferResponse),
    fork(watchLoadedCardPurchased),
  ]);
}
