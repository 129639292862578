import { createSelector } from '@reduxjs/toolkit';
import { type RootState } from '../store';

const selectApp = ({ app }: RootState) => app;

export const selectIsHeaderVisible = createSelector(selectApp, ({ isHeaderVisible }) => isHeaderVisible);

export const selectIsFooterVisible = createSelector(selectApp, ({ isFooterVisible }) => isFooterVisible);

export const selectActiveCard = createSelector(selectApp, ({ activeCard }) => activeCard);

export const selectPrevPathname = createSelector(selectApp, ({ prevPathname }) => prevPathname);

export const selectCoinMarketCapInfo = createSelector(selectApp, ({ coinMarketCapInfo }) => coinMarketCapInfo);
