import { createSelector } from '@reduxjs/toolkit';
import { type RootState } from '../store';

const selectRanking = ({ ranking }: RootState) => ranking;

export const selectRankingState = createSelector(
  selectRanking, ({ rankingCollections }) => rankingCollections,
);

export const selectRankingCollectorsState = createSelector(
  selectRanking, ({ rankingCollectors }) => rankingCollectors,
);

export const selectUserRanking = createSelector(
  selectRanking, ({ userRanking }) => userRanking,
);

export const selectUserRankingScoreById = (id: string) => createSelector(
  selectRanking, ({ userRanking: { userRankingsMap } }) => userRankingsMap[id],
);

export const selectRankingRewards = createSelector(
  selectRanking, ({ rewards }) => rewards,
);

export const selectRewardToClaim = createSelector(
  selectRanking, ({ rewardToClaim }) => rewardToClaim,
);
