import * as fcl from '@blocto/fcl';
import * as t from '@onflow/types';
import { onError } from 'helpers/onError';
import { tx } from '../util/tx';
// import additionalAuthorization from '../services/additionalAuthorization';

const CODE = fcl.cdc`
  import FiatToken from 0xFIAT_TOKEN_ADDRESS
  import FlowToken from 0xFLOW_TOKEN_ADDRESS
  import FungibleToken from 0xFUNGIBLE_TOKEN_ADDRESS
  import FUSD from 0xFUSD_ADDRESS
  import NonFungibleToken from 0xNON_FUNGIBLE_TOKEN_ADDRESS
  import StakedStarlyCard from 0xSTAKED_STARLY_CARD_ADDRESS
  import StarlyCard from 0xSTARLY_CARD_ADDRESS
  import StarlyCardBidV3 from 0xSTARLY_CARD_BID_ADDRESS
  import StarlyCardMarket from 0xSTARLY_CARD_MARKET_ADDRESS
  import StarlyToken from 0xSTARLY_TOKEN_ADDRESS

  transaction(bidID: UInt64, bidderAddress: Address, cardStakeId: UInt64?) {

    let sellerFungibleReceiver: Capability<&{FungibleToken.Receiver}>
    let sellerCardCollection: Capability<&StarlyCard.Collection{NonFungibleToken.Provider}>
    let sellerCardStakeingCollectionRef: &StakedStarlyCard.Collection
    let sellerMarketCollection: &StarlyCardMarket.Collection

    prepare(signer: AuthAccount) {        
      let StarlyCardCollectionProviderPrivatePath = /private/starlyCardCollectionProvider
      if !signer.getCapability<&StarlyCard.Collection{NonFungibleToken.Provider, StarlyCard.StarlyCardCollectionPublic}>(StarlyCardCollectionProviderPrivatePath)!.check() {
          signer.link<&StarlyCard.Collection{NonFungibleToken.Provider, StarlyCard.StarlyCardCollectionPublic}>(StarlyCardCollectionProviderPrivatePath, target: StarlyCard.CollectionStoragePath)
      }

      self.sellerCardCollection = signer.getCapability<&StarlyCard.Collection{NonFungibleToken.Provider}>(StarlyCardCollectionProviderPrivatePath)!
      assert(self.sellerCardCollection.borrow() != nil, message: "Missing or mis-typed StarlyCardCollection provider")
     
      let bidCollectionRef = getAccount(bidderAddress).getCapability<&StarlyCardBidV3.Collection{StarlyCardBidV3.CollectionPublic}>(StarlyCardBidV3.CollectionPublicPath).borrow()
          ?? panic("Could not borrow Bid collection from bidderAddress")
      let bidVaultType = bidCollectionRef.borrowBid(bidID: bidID)!.bidVaultType
      switch bidVaultType {
        case Type<@FiatToken.Vault>():
          self.sellerFungibleReceiver = signer.getCapability<&{FungibleToken.Receiver}>(FiatToken.VaultReceiverPubPath)!
        case Type<@FlowToken.Vault>():
          self.sellerFungibleReceiver = signer.getCapability<&{FungibleToken.Receiver}>(/public/flowTokenReceiver)!
        case Type<@StarlyToken.Vault>():
          self.sellerFungibleReceiver = signer.getCapability<&{FungibleToken.Receiver}>(StarlyToken.TokenPublicReceiverPath)!
        default:
          self.sellerFungibleReceiver = signer.getCapability<&{FungibleToken.Receiver}>(/public/fusdReceiver)!
      }

      self.sellerCardStakeingCollectionRef = signer.borrow<&StakedStarlyCard.Collection>(from: StakedStarlyCard.CollectionStoragePath)
          ?? panic("Could not borrow a reference to the StakedStarlyCard collection!")

      self.sellerMarketCollection = signer.borrow<&StarlyCardMarket.Collection>(from: StarlyCardMarket.CollectionStoragePath)
          ?? panic("Missing or mis-typed StarlyCardMarket Collection")
    }

    execute {
      let bidderAccount = getAccount(bidderAddress)
      let bidCollection = bidderAccount
          .getCapability<&StarlyCardBidV3.Collection{StarlyCardBidV3.CollectionPublic}>(
              StarlyCardBidV3.CollectionPublicPath
          )!
          .borrow()
          ?? panic("Could not borrow bid collection")

      let bidderCardCollection = bidderAccount
          .getCapability<&StarlyCard.Collection{StarlyCard.StarlyCardCollectionPublic}>(
              StarlyCard.CollectionPublicPath
          )!
          .borrow()
          ?? panic("Could not borrow starly card collection")

      bidCollection.accept(
          bidID: bidID,
          bidderCardCollection: bidderCardCollection,
          sellerFungibleReceiver: self.sellerFungibleReceiver,
          sellerCardCollection: self.sellerCardCollection,
          sellerStakedCardCollection: self.sellerCardStakeingCollectionRef,
          sellerMarketCollection: self.sellerMarketCollection,
          cardStakeId: cardStakeId
      )
    }
  }
`;

export async function flowAcceptBidTransaction(
  bidID: number,
  bidderAddress: string,
  cardStakeId?: number | null,
  opts = { onError },
) {
  // Get latest block info
  const block = await fcl.send([fcl.getBlock(false) as Function]).then(fcl.decode);

  const cardStakeIdParam = cardStakeId ? String(cardStakeId) : null;

  return tx(
    [
      fcl.transaction(CODE),
      fcl.args([
        fcl.arg(BigInt(bidID).toString(), t.UInt64),
        fcl.arg(bidderAddress, t.Address),
        fcl.arg(cardStakeIdParam, t.Optional(t.UInt64)),
      ]),
      fcl.payer(fcl.authz),
      fcl.proposer(fcl.authz),
      fcl.authorizations([fcl.authz]),
      fcl.limit(9999),
      fcl.ref(block.id),
    ],
    opts,
  );
}
