import {
  type FC, useCallback, useEffect, useRef, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import FullscreenPortal from 'components/FullscreenPortal/FullscreenPortal';
import NotificationsLoader from 'components/NotificationsLoader/NotificationsLoader';
import { auth, functions } from 'global/firebase';
import { isMiBrowser } from 'helpers/isIos';
import { useOutsideClickHandle } from 'hooks/useOutsideClick';
import closeIcon from 'static/ic-close-black.svg';
import poweredBy from 'static/img-powered-by-moonpay@2x.png';
import { flowBalanceRequest } from 'store/flow/flowActions';
import { selectFlowWalletAddr } from 'store/flow/flowSelectors';

import {
  IframeCloseIcon,
  IframeContainer,
  IframeFrame,
  IframePoweredBy,
  IframeTitle,
  IframeWrapper,
  ModalButton,
  ModalButtonContainer,
  ModalContainer,
  ModalText,
} from '../styled';
import { type TopUpProps } from '../types';

const MOONPAY_API_KEY = process.env.REACT_APP_MOONPAY_API_KEY || '';
const MOONPAY_URL = process.env.REACT_APP_MOONPAY_URL || 'https://buy.moonpay.com/';

const MoonpayOption: FC<TopUpProps> = ({ currency, setIsOpen }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const isXiaomi = isMiBrowser();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isFrameLoaded, setIsFrameLoaded] = useState(isXiaomi);
  const [moonpayUrl, setMoonpayUrl] = useState('');

  const addr = useSelector(selectFlowWalletAddr);
  const ref = useRef<any>(null);

  useOutsideClickHandle(ref, useCallback(() => {
    if (isFrameLoaded) setIsModalOpen(true);
  }, [isFrameLoaded, isModalOpen]));

  useEffect(() => {
    const topUpEmail = auth.currentUser?.email;
    const url = `${MOONPAY_URL}?apiKey=${MOONPAY_API_KEY}&currencyCode=${currency}&walletAddress=${addr}${
      topUpEmail ? `&email=${encodeURIComponent(topUpEmail)}` : ''
    }`;
    functions.httpsCallable('signMoonpayUrl')({ url })
      .then((res) => {
        setMoonpayUrl(res.data.url);
      })
      .catch(() => {});
  }, [currency]);

  return (
    <FullscreenPortal>
      <IframeContainer>
        {moonpayUrl ? (
          <>
            <IframeFrame ref={ref} isLoaded={isFrameLoaded}>
              {isFrameLoaded && (
                <IframeCloseIcon
                  src={closeIcon}
                  onClick={() => setIsModalOpen(true)}
                />
              )}
              { isFrameLoaded && <IframeTitle>{t('wallet.topUp.title')}</IframeTitle> }
              <IframeWrapper isLoaded={isFrameLoaded}>
                <iframe
                  title="moonpay-frame"
                  allow="accelerometer; autoplay; camera; gyroscope; payment"
                  src={moonpayUrl}
                  frameBorder="0"
                  onLoad={() => setIsFrameLoaded(true)}
                  height="100%"
                  width="100%"
                >
                  <p>{t('wallet.topUp.iframeError')}</p>
                </iframe>
              </IframeWrapper>
              { isFrameLoaded && <IframePoweredBy src={poweredBy} />}
            </IframeFrame>
            { isModalOpen && (
              <ModalContainer>
                <ModalText>{t('wallet.topUp.exitConfirmation')}</ModalText>
                <ModalButtonContainer>
                  <ModalButton ref={ref} onClick={() => setIsModalOpen(false)}>{t('wallet.topUp.cancel')}</ModalButton>
                  <ModalButton onClick={() => {
                    dispatch(flowBalanceRequest({}));
                    setIsModalOpen(false);
                    setIsFrameLoaded(isXiaomi);
                    setIsOpen(false);
                  }}
                  >
                    {t('wallet.topUp.exit')}
                  </ModalButton>
                </ModalButtonContainer>
              </ModalContainer>
            )}
          </>
        ) : <NotificationsLoader isBlack transparent />}
      </IframeContainer>
    </FullscreenPortal>
  );
};

export default MoonpayOption;
