import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import Button from '../../components/Button';
import { mediaDesktop } from '../../breakpoints';
import Typography from '../../components/Typography';

export const MarketplaceTableContainer = styled.div`
  overflow-x: auto;
  max-width: 100%;
  width: 100%;
  ${mediaDesktop} {
   margin: 0; 
  }
`;

export const MarketplaceContainer = styled.div`
  max-width: ${({ theme }) => theme.width.mainContainer};
  flex: 1;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 0 auto;
  width: 100%;
  padding: 16px 16px 50px;
  ${mediaDesktop} {
    padding: 50px 0 100px;
  }
`;

export const MarketplaceHeader = styled.header<{ withoutMargin?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${({ withoutMargin }) => (withoutMargin ? css`
    margin: 0 !important;
  ` : '')}
  margin-bottom: 16px;
  ${mediaDesktop} {
    margin-bottom: 20px;
  }
`;

export const LoadMarketplaceButton = styled(Button)<{ $loading?: boolean }>`
  width: 100%;
  max-width: 320px;
  margin: 50px auto auto;
  height: 40px;

  ${mediaDesktop} {
    padding-top: 0;
    margin-top: 64px;
    max-width: 468px;
    height: 60px;
  }

  ${({ $loading }) => ($loading && css`
    padding-top: 5px;

    ${mediaDesktop} {
      padding-top: 8px;
    }
  `)}
`;

export const CardsWrapper = styled.div`
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  min-height: 256px;
  max-height: 100%;
  flex: 1;
  
  ${mediaDesktop} {
    margin-top: 0;
  }
`;

export const CardsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 32px;
  ${mediaDesktop} {
    gap: 64px;
    justify-content: center;
  }
`;

export const MarketplaceRowLink = styled(Link)`
  max-width: 100%;
  display: inline-flex;
  color: inherit;
  text-decoration: none;
`;

export const MarketplaceLink = styled(MarketplaceRowLink)<{ $isActive?: boolean }>`
  transition: color .2s;

  ${({ $isActive }) => ($isActive ? css`
    color: #fff;
  ` : '')}

  :hover {
    color: ${({ $isActive }) => ($isActive ? '#fff' : '#b8b8b8')};
  }
`;
export const MarketplaceExactLink = styled.a`
  padding: 15px;
  margin: -15px;
`;

export const MarketplaceLinkSeparator = styled.span`
  display: inline-block;
  text-align: center;
  width: 42px;
`;

export const MarketplaceSalesContainer = styled.div`
  margin-top: 16px;
  min-height: 256px;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  margin-right: -16px;
  margin-left: -16px;
  & > ${Typography} {
    width: 100%;
    text-align: left;
  }
  ${mediaDesktop} {
    margin: 0;
  }
`;
