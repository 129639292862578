import { type ReactElement, type FC } from 'react';
import ReactDOM from 'react-dom';

const ComponentPortal: FC<{
  children: ReactElement;
  element?: HTMLElement | null;
  pureRender?: boolean;
}> = ({ children, element, pureRender }) => {
  if (pureRender) {
    return children;
  }

  if (!element) {
    return null;
  }

  return ReactDOM.createPortal(children, element);
};

export default ComponentPortal;
