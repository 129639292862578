import { createSelector } from '@reduxjs/toolkit';
import i18n from 'i18next';
import { type RootState } from '../store';
import { type MarketplacePurchasingState } from './marketplaceTypes';

const selectMarketplace = (state: RootState) => state.marketplace;

export const selectMarketplacePopUpState = createSelector(
  selectMarketplace, ({ purchasingState: { state, currentCardPage } }) => {
    type TextObject = { [key in MarketplacePurchasingState]: string };
    const text: TextObject = {
      error: i18n.t('collection.purchase.error'),
      purchased: i18n.t('card.sale.purchasedMessage'),
      warning: i18n.t('card.sale.warningMessage'),
      initial: '',
    };
    const closeButtonText: TextObject = {
      purchased: i18n.t('common.close'),
      warning: 'Ok',
      error: 'Ok',
      initial: '',
    };
    return {
      isOpen: state !== 'initial',
      modalText: text[state],
      cardUrl: currentCardPage,
      forSaleState: state,
      closeButtonText: closeButtonText[state],
    };
  },
);

export const selectMarketplaceCollectionsState = createSelector(
  selectMarketplace, ({ collections }) => ({ ...collections }),
);

export const selectMarketplacePurchasingState = createSelector(
  selectMarketplace, ({ purchasingState }) => purchasingState,
);

export const selectMarketplaceSalesFilters = createSelector(
  selectMarketplace, ({ salesFilters }) => salesFilters,
);

export const selectMarketplaceCardsState = createSelector(
  selectMarketplace, ({ cardState }) => cardState,
);

export const selectMarketplaceSalesState = createSelector(
  selectMarketplace, ({ salesState }) => salesState,
);

export const selectMarketplaceFilters = createSelector(
  selectMarketplace, ({ filters }) => filters,
);
