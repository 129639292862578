export const sortTypes = [
  { value: 'create_time_desc', label: '' },
  { value: 'price_asc', label: '' },
  { value: 'price_desc', label: '' },
  { value: 'edition_asc', label: '' },
  { value: 'edition_desc', label: '' },
];

export const sortMapping = {
  [sortTypes[0].value]: 'create_time',
  [sortTypes[1].value]: 'price',
  [sortTypes[2].value]: 'price',
  [sortTypes[3].value]: 'edition',
  [sortTypes[4].value]: 'edition',
};

export const defaultProjectOption = { value: 'all-projects', label: '' };
