import * as fcl from '@blocto/fcl';
import * as t from '@onflow/types';
import { invariant } from '@onflow/util-invariant';
import { onError } from 'helpers/onError';
import { tx } from '../../util/tx';

const CODE = fcl.cdc`
import FungibleToken from 0xFUNGIBLE_TOKEN_ADDRESS
import NonFungibleToken from 0xNON_FUNGIBLE_TOKEN_ADDRESS
import NFTStorefront from 0xNFT_STOREFRONT_ADDRESS
import FlowToken from 0xFLOW_TOKEN_ADDRESS
import KOTD from 0xKOTD_ADDRESS

transaction(listingResourceID: UInt64, storefrontAddress: Address, buyPrice: UFix64) {
    let paymentVault: @FungibleToken.Vault
    let nftCollection: &KOTD.Collection{NonFungibleToken.Receiver}
    let storefront: &NFTStorefront.Storefront{NFTStorefront.StorefrontPublic}
    let listing: &NFTStorefront.Listing{NFTStorefront.ListingPublic}

    prepare(signer: AuthAccount) {
        if signer.borrow<&KOTD.Collection>(from: KOTD.CollectionStoragePath) == nil {
            signer.save(<-KOTD.createEmptyCollection(), to: KOTD.CollectionStoragePath)
            signer.link<&{KOTD.NiftoryCollectibleCollectionPublic}>(KOTD.CollectionPublicPath, target: KOTD.CollectionStoragePath)
        }

        self.storefront = getAccount(storefrontAddress)
            .getCapability<&NFTStorefront.Storefront{NFTStorefront.StorefrontPublic}>(NFTStorefront.StorefrontPublicPath)
            .borrow()
            ?? panic("Could not borrow Storefront from provided address")

        self.listing = self.storefront.borrowListing(listingResourceID: listingResourceID)
            ?? panic("No Offer with that ID in Storefront")
        let price = self.listing.getDetails().salePrice

        assert(buyPrice == price, message: "buyPrice is NOT same with salePrice")

        let flowTokenVault = signer.borrow<&FlowToken.Vault>(from: /storage/flowTokenVault)
            ?? panic("Cannot borrow FlowToken vault from signer storage")
        self.paymentVault <- flowTokenVault.withdraw(amount: price)

        self.nftCollection = signer.borrow<&KOTD.Collection{NonFungibleToken.Receiver}>(from: KOTD.CollectionStoragePath)
            ?? panic("Cannot borrow NFT collection receiver from account")
    }

    execute {
        let item <- self.listing.purchase(payment: <-self.paymentVault)
        self.nftCollection.deposit(token: <-item)
        self.storefront.cleanup(listingResourceID: listingResourceID)
    }
}`;

export async function flowFestBuyKOTDItemTransaction(
  listingResourceID: number,
  storefrontAddress: string,
  buyPrice: number,
  opts = { onError },
) {
  invariant(listingResourceID != null, 'listingResourceID must be supplied');
  invariant(storefrontAddress != null, 'storefrontAddress must be supplied');
  invariant(buyPrice > 0, 'buyPrice must be positive');

  // Get latest block info
  const block = await fcl.send([fcl.getBlock(false) as Function]).then(fcl.decode);

  return tx(
    [
      fcl.transaction(CODE),
      fcl.args([
        fcl.arg(listingResourceID, t.UInt64),
        fcl.arg(String(storefrontAddress), t.Address),
        fcl.arg(String(buyPrice.toFixed(8)), t.UFix64),
      ]),
      fcl.payer(fcl.authz),
      fcl.proposer(fcl.authz),
      fcl.authorizations([fcl.authz]),
      fcl.limit(1000),
      fcl.ref(block.id),
    ],
    opts,
  );
}
