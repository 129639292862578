import styled from 'styled-components/macro';
import { css } from 'styled-components';
import { Link } from 'react-router-dom';
import theme from '../theme';
import { mediaDesktop } from '../../breakpoints';

const Table = styled.div`
  width: 100%;
  color: #fff;
  display: grid;
  grid-gap: 2px;
  grid-auto-rows: minmax(64px, max-content);
`;

export const Row = styled.div<{ header?: boolean, isDark?: boolean }>`
  background: ${({ isDark }) => (isDark ? theme.colors.dark : theme.colors.black)};
  align-items: center;
  display: grid;
  grid-auto-flow: column;
  padding: 0 24px;
  font-family: ${theme.fonts.primary};
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  width: 100%;
  ${({ header }) => (header ? css`
    font-family: ${theme.fonts.secondary};
    letter-spacing: 1.2px;
    font-weight: 600;
  ` : '')}
  ${mediaDesktop} {
    padding: 0 32px;
  }
`;

type ColumnAlign = 'center' | 'right' | 'left';

export const Column = styled.div<{ bold?: boolean, justify?: ColumnAlign, textAlign?: ColumnAlign }>`
  max-width: 100%;
  ${({ bold }) => bold && 'font-weight: 600;'}
  ${({ justify }) => (justify ? css`justify-self: ${justify};` : '')}
  ${({ textAlign }) => (textAlign ? css`text-align: ${textAlign};` : '')}
`;

const linkCss = css`
  color: inherit;
  text-decoration: none;
`;

export const ColumnLink = styled(Link)<{ $notInlineBlock?: boolean }>`
  ${linkCss}
  ${({ $notInlineBlock }) => ($notInlineBlock ? '' : css`
    display: inline-block;
  `)};
`;

export const ColumnTextShortenerContainer = styled.div`
  max-width: 100%;
  display: inline-flex;
`;

export const ColumnTextShortener = styled.div`
  height: auto;
  overflow: hidden;
  display: inline-block;
  max-width: 100%;
  text-overflow: ellipsis;
`;

export const ColumnExactLink = styled.a`
  ${linkCss}
`;

export default Table;
