import Button from 'components/Button';
import Typography from 'components/Typography';
import NotificationsLoader from 'components/NotificationsLoader/NotificationsLoader';
import { mediaDesktop } from 'breakpoints';
import styled from 'styled-components/macro';

export const TittleText = styled(Typography)`
  margin-bottom: 50px;
  line-height: 24px;
  letter-spacing: 1px;
  text-align: center;
  ${mediaDesktop} {
    line-height: 37px;
  };
`;

export const SubtitleText = styled(Typography)`
  margin-bottom: 30px;
  line-height: 24px;
  letter-spacing: 1px;
  text-align: center;
  ${mediaDesktop} {
    line-height: 37px;
  };
`;

export const ButtonsContainer = styled.div`
  display: grid;
  grid-gap: 16px;
  width: 100%;
  ${mediaDesktop} {
    grid-gap: 20px; 
  };
`;

export const ButtonGradient = styled(Button)`
  background-image: linear-gradient(82deg, #63ffee 0%, #ff73f3 103%);
  color: #000000;

  &:hover:enabled {
    background-image: linear-gradient(74deg, #40ffea 0%, #ff4df0 103%);
  }
`;

export const LoadingSpinner = styled(NotificationsLoader)`
  transform: scale(0.7);

  ${mediaDesktop} {
    transform: scale(1);
  }
`;
