import type { FormEvent } from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { flowLoginRequest, flowWrongModal, flowToggleModal } from 'store/flow/flowActions';
import { useDispatch, useSelector } from 'react-redux';
import { selectWrongModal, selectFlowModal } from 'store/flow/flowSelectors';
import Button from 'components/Button';
import PopUp from 'components/PopUp';
import {
  ButtonsContainer,
  SubtitleText,
  TittleText,
} from './styled';

const WalletWrong = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const wallet = useSelector(selectWrongModal)!;
  const isFlowModalOpen = useSelector(selectFlowModal);

  if (isFlowModalOpen) {
    dispatch(flowToggleModal(false));
  }

  const closeWalletModal = () => dispatch(flowWrongModal());

  const onWalletLogin = (e: FormEvent) => {
    e.preventDefault();

    closeWalletModal();

    dispatch(flowLoginRequest({}));
  };

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => { document.body.style.overflow = 'auto'; };
  }, []);

  return (
    <PopUp>
      {wallet.code === 1 ? (
        <>
          <SubtitleText size="popUpTitle" textColor="black" font="secondary">
            {t('wallet.wrong.addressSubtitle')}
            {' '}
            {wallet.message}
          </SubtitleText>
          <TittleText size="popUpTitle" textColor="black" font="secondary">
            {t('wallet.wrong.addressTitle')}
          </TittleText>
        </>
      ) : (
        <>
          <SubtitleText size="popUpTitle" textColor="black" font="secondary">
            {t('wallet.wrong.accountSubtitle')}
            {' '}
            {wallet.message}
          </SubtitleText>
          <TittleText size="popUpTitle" textColor="black" font="secondary">
            {t('wallet.wrong.accountTitle')}
          </TittleText>
        </>
      )}
      <ButtonsContainer>
        <Button onClick={(e) => onWalletLogin(e)}>{t('wallet.connectWallet')}</Button>
        <Button background="black" onClick={closeWalletModal}>{t('wallet.notNow')}</Button>
      </ButtonsContainer>
    </PopUp>
  );
};

export default WalletWrong;
