import type { FC } from 'react';
import dayjs from 'dayjs';

import { Column } from 'components/Table';
import { capitalize, formatTokenWithCurrency } from 'helpers/TextHelpers';
import goTo from 'static/ic-go-to.svg';
import { MarketplaceExactLink, MarketplaceRowLink } from 'views/Marketplace/styled';

import {
  MarketplaceCardRow, MarketplaceSaleCardContainer, MediaCardContainer, MediaThumbnail, SaleTableTypography,
} from './styled';
import type { MarketplaceSalesRowProps } from './types';

const MarketplaceSaleRow: FC<MarketplaceSalesRowProps> = ({
  rarity,
  allEditions,
  time,
  edition,
  buyerUsername,
  sellerUsername,
  price,
  currency,
  flowTransactionLink,
  card: {
    media, cardTitle, cardLink, collectionTitle, creatorName,
  },
}) => (
  <MarketplaceCardRow data-test-id="mp_card_row" isDark>
    <Column justify="left" data-test-id="mp_card_data">
      <MarketplaceRowLink to={cardLink} target="_blank">
        <MarketplaceSaleCardContainer>
          <MediaCardContainer>
            <MediaThumbnail media={media} />
          </MediaCardContainer>
          <SaleTableTypography data-test-id="data_card_title" uppercase bold>{cardTitle}</SaleTableTypography>
          <SaleTableTypography data-test-id="data_collection_title" uppercase>{collectionTitle}</SaleTableTypography>
          <SaleTableTypography data-test-id="data_creator_name">{creatorName}</SaleTableTypography>
        </MarketplaceSaleCardContainer>
      </MarketplaceRowLink>
    </Column>
    <Column data-test-id="mp_card_edition" data-edition-attribute={`${edition}`}>
      #
      {edition}
      /
      {allEditions}
    </Column>
    <Column data-test-id="mp_card_rarity">{capitalize(rarity).replaceAll('_', ' ')}</Column>
    <Column data-test-id="mp_card_buyer">
      <MarketplaceRowLink to={`/${buyerUsername}`} target="_blank">
        <SaleTableTypography>
          @
          {buyerUsername}
        </SaleTableTypography>
      </MarketplaceRowLink>
    </Column>
    <Column data-test-id="mp_card_seller">
      <MarketplaceRowLink to={`/${sellerUsername}`} target="_blank">
        <SaleTableTypography>
          @
          {sellerUsername}
        </SaleTableTypography>
      </MarketplaceRowLink>
    </Column>
    <Column data-test-id="mp_card_price" data-price-attribute={price} bold textAlign="center">
      {formatTokenWithCurrency(price, currency)}
    </Column>
    <Column data-test-id="mp_card_time" data-date-attribute={`${dayjs(time).format('hh:mm A MMM D YYYY')}`} textAlign="center">
      {dayjs(time).format('hh:mm A')}
      <div />
      {dayjs(time).format('MMM D, YYYY')}
    </Column>
    <Column data-test-id="mp_transaction_link">
      <MarketplaceExactLink href={flowTransactionLink} target="_blank" title="Go to Flowscan">
        <img src={goTo} alt="go to" />
      </MarketplaceExactLink>
    </Column>
  </MarketplaceCardRow>
);

export default MarketplaceSaleRow;
