import { createReducer } from '@reduxjs/toolkit';
import type { TransactionHistoryItem } from '@starly/starly-types';
import {
  transactionsLoading,
  transactionsResponse,
} from './transactionsActions';

interface TransactionsSlice {
  transactions: TransactionHistoryItem[],
  limit: number,
  isLoading: boolean,
  isFirstLoaded: boolean,
}

const initialState: TransactionsSlice = {
  transactions: [],
  limit: 30,
  isLoading: false,
  isFirstLoaded: false,
};

const transactionsReducer = createReducer<TransactionsSlice>(initialState, (builder) => {
  builder.addCase(transactionsResponse, (state, { payload: { transactions } }) => {
    state.transactions = [...transactions].filter((v, i, a) => a.indexOf(v) === i);
    state.isFirstLoaded = true;
  });
  builder.addCase(transactionsLoading, (state, { payload: { isLoading } }) => {
    state.isLoading = isLoading;
  });
});

export default transactionsReducer;
