import styled from 'styled-components';
import { mediaDesktop } from 'breakpoints';
import theme from 'components/theme';
import Button from 'components/Button';
import type { TypographyProps } from 'components/Typography';

export const PopUpOverlay = styled.div`
  position: fixed;
  inset: 0;
  height: 100%;
  overflow-y: auto;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  touch-action: none;

  animation: fadeout 0.3s forwards;
  @keyframes fadeout {
    to {
      background-color: rgba(0, 0, 0, .5);
    }
  }
`;

export const PopUpWrapper = styled.div<{ $padding?: string, $mobilePadding?: string, $width?: string }>`
  width: 100%;
  max-width: 420px;
  ${({ $mobilePadding }) => $mobilePadding && { padding: $mobilePadding } || { padding: '64px 48px' }};
  background-color: ${theme.colors.white};
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  max-height: 90vh;
  ${mediaDesktop} {
    ${({ $padding }) => $padding && { padding: $padding } || { padding: '64px 120px' }};
    max-width: ${({ $width }) => ($width || '652px')}
  }
`;

export const PopUpPortalWrapper = styled(PopUpWrapper)`
  max-height: 80vh;
  padding: 0;
`;

export const PopUpText = styled.p`
  text-transform: uppercase;
  font-family: ${theme.fonts.secondary}, sans-serif;
  font-size: 20px;
  letter-spacing: 1px;
  text-align: center;
  color: ${theme.colors.black};
  margin-bottom: 64px;
  ${mediaDesktop} {
    margin-bottom: 50px;
    font-size: 30px;
  }
`;

export const PopUpTextMain = styled.p`
  font-family: ${theme.fonts.secondary};
  font-size: 20px;
  width: 100%;
  text-align: center;
  font-weight: 600;
  letter-spacing: 1px;
  padding-bottom: 32px;
  ${mediaDesktop}{
    font-size: 30px;
  }
`;

export const PopUpClose = styled.div`
  position: absolute;
  cursor: pointer;
  right: 20px;
  top: 20px;
  padding: 15px;
  margin: -15px;
`;

export const PopUpGroupBtn = styled(Button)<{ $background?: TypographyProps['textColor'] | 'inherit'; $color?: string }>`
  width: 100%;
  margin-top: 20px;
  ${({ $background }) => $background && { backgroundColor: theme.colors[$background] }};
  ${({ $color }) => $color && { color: $color }};
`;
