import { type FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';

import logoBeta from 'static/starly-logo-beta.png';
import logoBeta2x from 'static/starly-logo-beta@2x.png';
import logoBeta3x from 'static/starly-logo-beta@3x.png';
import menu from 'static/starly-menu.svg';
import logo from 'static/starly-new-logo.svg';
import { selectIsHeaderVisible } from 'store/app/appSelectors';
import { bidsFetchBids } from 'store/bids/bidsActions';
import { vestingRewardFetchVestings } from 'store/vesting/vestingActions';
import { checkFollowers } from 'views/FaceControl/utils';

import { RouteTypes } from '../../RouteTypes';
import { selectAuthAuthorized, selectAuthUserId, selectIsAdmin } from '../../store/auth/authSelectors';
import { selectActiveStep } from '../../store/collection/collectionSelectors';
import { flowBalanceRequest } from '../../store/flow/flowActions';
import { setAuthRedirect, setLoginModal } from '../../store/login/loginActions';
import { notificationsSetRealtimeListener } from '../../store/notifications/notificationsActions';
import { selectUnreadNotificationsCount } from '../../store/notifications/notificationsSelectors';
import { userPacksCountRequest } from '../../store/pack/packActions';
import { selectSideMenuItems } from '../../store/sideMenu/sideMenuSelectors';
import { userRequest } from '../../store/user/userActions';
import { selectUserById } from '../../store/user/userSelectors';
import { marketplacePages } from '../../views/Marketplace/Marketplace';
import { rankingPages } from '../../views/Ranking/Ranking';
import Dropdown from '../Dropdown';
import DropdownHeaderLink from './components/DropdownHeaderLink';
import GlobalModals from './components/GlobalModals';
import HeaderAvatar from './components/HeaderAvatar';
import HeaderIcon from './components/HeaderIcon';
import HeaderLinkWrapper from './components/HeaderLinkWrapper';
import MenuNav from './components/MenuNav';
import NotificationMenu from './components/NotificationMenu/NotificationMenu';
import SideMenu from './components/SideMenu';
import {
  AdminBadge,
  GetInDesktop,
  GetInMobile,
  HeaderLogo,
  HeaderLogoDesktop,
  HeaderRow,
  HeaderWrapper,
  IconsContainer,
  MobileButtons,
  StyledHeaderLink,
  StyledIcon,
  StyledList,
} from './styled';

const Header: FC = () => {
  const dispatch = useDispatch();
  const path = useLocation();
  const { t } = useTranslation();

  const isAuthorized = useSelector(selectAuthAuthorized);
  const isAdmin = useSelector(selectIsAdmin);
  const [isNotificationsOpen, setIsNotificationsOpen] = useState(false);
  const [isNavOpen, setIsNavOpen] = useState(false);
  const userId = useSelector(selectAuthUserId);
  const user = useSelector(selectUserById(userId));
  const userPath = RouteTypes.User.replace(':username', user?.username);
  const activeStep = useSelector(selectActiveStep);
  const menuItems = useSelector(selectSideMenuItems);
  const unreadNotifications = useSelector(selectUnreadNotificationsCount);

  const isHeaderVisible = useSelector(selectIsHeaderVisible);
  const [isSideMenuOpen, setIsSideMenuOpen] = useState(false);
  const pushGetIn = () => {
    dispatch(setAuthRedirect({ onAuthRedirect: path.pathname }));
    dispatch(setLoginModal({ isOpen: true }));
  };

  useEffect(() => {
    if (isAuthorized) {
      dispatch(userRequest({ id: userId }));
      dispatch(notificationsSetRealtimeListener());
    }
  }, [isAuthorized]);

  const connections = user?.connections;
  const faceControlState = user?.face_control_state;
  useEffect(() => {
    checkFollowers({
      faceControlState, connections, activeStep, id: user?.id, dispatch,
    });
  }, [connections, faceControlState, isAuthorized]);

  useEffect(() => {
    setIsNotificationsOpen(false);
    setIsNavOpen(false);
    if (isAuthorized) {
      dispatch(userPacksCountRequest());
      dispatch(flowBalanceRequest({}));
      dispatch(vestingRewardFetchVestings());
      dispatch(bidsFetchBids({}));
    }
  }, [isAuthorized, path.pathname]);

  if (!isHeaderVisible) return null;

  const onLogoClick = () => {
    if (path.pathname === RouteTypes.Drops) {
      window.scrollTo({ left: 0, top: 0 });
    }
  };

  return (
    <>
      <SideMenu isOpen={isSideMenuOpen} setIsOpen={setIsSideMenuOpen} />
      {isAdmin && (
        <AdminBadge>admin</AdminBadge>
      )}
      <GlobalModals userPath={userPath} />
      <HeaderWrapper>
        <HeaderRow data-test-id="header">
          <Link to={RouteTypes.Drops} onClick={onLogoClick}>
            <HeaderLogo src={logo} srcSet={logo} alt={t('common.starlyLogo')} data-test-id="starly_logo" />
            <HeaderLogoDesktop
              src={logoBeta}
              srcSet={`${logoBeta2x} 2x, ${logoBeta3x} 3x`}
              alt={t('common.starlyLogo')}
              data-test-id="starly_logo"
            />
          </Link>
          <StyledList data-test-id="header_all_tabs">
            <HeaderLinkWrapper data-test-id="drops_link_wrapper" to={RouteTypes.Drops}>
              <StyledHeaderLink data-test-id="drops">{t('header.drops')}</StyledHeaderLink>
            </HeaderLinkWrapper>
            <DropdownHeaderLink
              data-test-id="marketplace"
              links={marketplacePages}
              to={RouteTypes.Marketplace}
            >
              {t('header.marketplace')}
            </DropdownHeaderLink>
            <DropdownHeaderLink
              data-test-id="ranking"
              links={rankingPages}
              to={RouteTypes.Ranking}
            >
              {t('header.ranking')}
            </DropdownHeaderLink>
            <HeaderLinkWrapper data-test-id="token_link_wrapper" to={RouteTypes.TokenLanding}>
              <StyledHeaderLink data-test-id="Token">{t('header.token')}</StyledHeaderLink>
            </HeaderLinkWrapper>
            <HeaderLinkWrapper data-test-id="staking_link_wrapper" to={RouteTypes.StakingNft}>
              <StyledHeaderLink data-test-id="staking">{t('header.staking')}</StyledHeaderLink>
            </HeaderLinkWrapper>
            {!isAuthorized && (
              <GetInDesktop size="small" onClick={pushGetIn} data-test-id="get_in">
                <StyledHeaderLink>{t('header.getIn')}</StyledHeaderLink>
              </GetInDesktop>
            )}
            <IconsContainer>
              {isAuthorized && (
                <>
                  <HeaderAvatar avatar={user?.avatar} pathLink={userPath} data-test-id="picture_avatar" />
                  <Dropdown
                    menu={<NotificationMenu />}
                    onOutsideClick={() => setIsNotificationsOpen(false)}
                    isOpen={isNotificationsOpen}
                  >
                    <HeaderIcon
                      type="bell"
                      onClick={() => {
                        setIsNotificationsOpen(!isNotificationsOpen);
                        setIsNavOpen(false);
                      }}
                      isRed={unreadNotifications > 0}
                      isOpen={isNotificationsOpen}
                    />
                  </Dropdown>
                </>
              )}
              <Dropdown
                menu={<MenuNav menuItems={menuItems} />}
                onOutsideClick={() => setIsNavOpen(false)}
                isOpen={isNavOpen}
                data-test-id="menu1"
              >
                <HeaderIcon
                  type="down"
                  onClick={() => {
                    setIsNavOpen(!isNavOpen);
                    setIsNotificationsOpen(false);
                  }}
                  isOpen={isNavOpen}
                />
              </Dropdown>
            </IconsContainer>
          </StyledList>
          <MobileButtons>
            {!isAuthorized && (
              <GetInMobile size="small" onClick={pushGetIn} data-test-id="get_in_mobile">
                {t('header.getIn')}
              </GetInMobile>
            )}
            <StyledIcon data-test-id="menu_mobile" src={menu} alt="menu" onClick={() => setIsSideMenuOpen(true)} />
          </MobileButtons>
        </HeaderRow>
      </HeaderWrapper>
    </>
  );
};

export default Header;
