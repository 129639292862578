import ReactDOM from 'react-dom';
import {
  type FC, type MouseEvent, useCallback, useEffect, useRef,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { remoteConfig, trackException } from 'global/firebase';

import { useHistory, useLocation } from 'react-router';
import Typography from 'components/Typography';
import GoogleIcon from 'static/ic-signin-google.png';
import TwitterIcon from 'static/ic-signin-twitter.png';
import FacebookIcon from 'static/ic-signin-facebook.png';
import WechatIcon from 'static/ic-signin-wechat.png';
import MailIcon from 'static/ic-signin-mail.svg';

import {
  authError,
  authLoginFacebookRequest,
  authLoginTwitterRequest,
  authLoginGoogleRequest,
  authLoginWechatRequest,
} from 'store/auth/authActions';
import { RouteTypes } from 'RouteTypes';
import closeIcon from 'static/ic-close-black.svg';
import {
  selectAuthAuthorized,
  selectAuthIsProfileCreating,
  selectAuthError,
} from 'store/auth/authSelectors';
import { useOutsideClickHandle } from 'hooks/useOutsideClick';
import { activateAuthRedirect, setAuthRedirect, setLoginModal } from 'store/login/loginActions';
import { selectIsLoginModalOpen } from 'store/login/loginSelectors';
import { formatFirebaseError } from 'helpers/TextHelpers';
import { useModal } from 'hooks/useModal';
import EmailAuthModal from 'views/Modals/EmailAuth';
import {
  setFacebookLogin,
  setGoogleLogin,
  setTwitterLogin,
  setWechatLogin,
  setEmailLogin,
  setCustomTokenLogin,
} from 'store/firebaseConfig/firebaseConfigActions';
import {
  selectGoogleLogin,
  selectTwitterLogin,
  selectFacebookLogin,
  selectWechatLogin,
  selectEmailLogin,
} from 'store/firebaseConfig/firebaseConfigSelectors';
import {
  ButtonsContainer,
  GetInContainer,
  Icon,
  WhiteSpan,
  GetInWrapper,
  CloseIcon,
  GetInButton,
  ErrorContainer,
} from './styled';

const GetInPortal: FC = ({ children }) => {
  const root = document.querySelector('#fullscreen-root') as Element;
  return ReactDOM.createPortal(
    children,
    root,
  );
};

const GetInView = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const isOpenModal = useSelector(selectIsLoginModalOpen);
  const isAuthorized = useSelector(selectAuthAuthorized);
  const isProfileCreating = useSelector(selectAuthIsProfileCreating);
  const error = useSelector(selectAuthError);

  /**
   * Allowed auth providers according to firebase config
   */
  const googleLogin = useSelector(selectGoogleLogin);
  const twitterLogin = useSelector(selectTwitterLogin);
  const facebookLogin = useSelector(selectFacebookLogin);
  const wechatLogin = useSelector(selectWechatLogin);
  const emailLogin = useSelector(selectEmailLogin);

  const closeWindow = useCallback(() => {
    dispatch(setLoginModal({ isOpen: false }));
    if ([RouteTypes.Settings, RouteTypes.Packs].includes(pathname as RouteTypes) && !isAuthorized) {
      return history.push(RouteTypes.Drops);
    }
    if (error) {
      return dispatch(authError({ error: { code: 200, message: undefined } }));
    }
    if (isAuthorized && !isProfileCreating) {
      return dispatch(activateAuthRedirect());
    }
    return dispatch(setAuthRedirect({}));
  }, [isAuthorized, error, isProfileCreating]);

  const { isOpen, openModal, closeModal } = useModal();

  const ref = useRef(null);

  useOutsideClickHandle(ref, () => !isOpen && isOpenModal && closeWindow());

  /**
   * Get auth configuration from Firebase
   */
  useEffect(() => {
    if (isOpenModal) {
      remoteConfig.fetchAndActivate().then(() => {
        dispatch(setGoogleLogin({ googleLogin: remoteConfig.getValue('googleLogin').asBoolean() }));
        dispatch(setTwitterLogin({ twitterLogin: remoteConfig.getValue('twitterLogin').asBoolean() }));
        dispatch(setFacebookLogin({ facebookLogin: remoteConfig.getValue('facebookLogin').asBoolean() }));
        dispatch(setWechatLogin({ wechatLogin: remoteConfig.getValue('weChatLogin').asBoolean() }));
        dispatch(setEmailLogin({ emailLogin: remoteConfig.getValue('emailLogin').asBoolean() }));
        dispatch(setCustomTokenLogin({ customTokenLogin: remoteConfig.getValue('customTokenLogin').asBoolean() }));
      })
        .catch((e) => trackException(e));
    }
  }, [isOpenModal]);

  useEffect(() => {
    if (isAuthorized) closeWindow();
  }, [isAuthorized]);

  useEffect(() => () => {
    if (isOpen) closeModal();
  }, []);

  const onGoogleLogin = (e: MouseEvent) => {
    e.preventDefault();
    dispatch(authLoginGoogleRequest());
  };

  const onTwitterLogin = (e: MouseEvent) => {
    e.preventDefault();
    dispatch(authLoginTwitterRequest());
  };

  const onFacebookLogin = (e: MouseEvent) => {
    e.preventDefault();
    dispatch(authLoginFacebookRequest());
  };

  const onWechatLogin = (e: MouseEvent) => {
    e.preventDefault();
    dispatch(authLoginWechatRequest({}));
  };

  return isOpenModal ? (
    <GetInPortal>
      <GetInWrapper>
        {error && (
          <ErrorContainer data-test-id="error_container">
            <Typography size="unmedia21">{formatFirebaseError(error)}</Typography>
          </ErrorContainer>
        )}
        {isOpen && (
          <EmailAuthModal closeModal={closeModal} />
        )}
        <GetInContainer data-test-id="get_in_container" ref={ref}>
          <CloseIcon data-test-id="close_icon" src={closeIcon} onClick={closeWindow} />
          <Typography
            data-test-id="get_in_title"
            size="header4"
            font="secondary"
            textColor="black"
            uppercase
            bold
          >
            {t('auth.getIn')}
          </Typography>
          <ButtonsContainer>
            {googleLogin && (
              <GetInButton textColor="black" border="black" background="white" fluid onClick={onGoogleLogin} data-test-id="sign_in_google">
                <Icon src={GoogleIcon} />
                  {t('auth.signIn', { provider: 'Google' })}
              </GetInButton>
            )}
            {twitterLogin && (
              <GetInButton
                textColor="black"
                border="black"
                background="white"
                fluid
                onClick={onTwitterLogin}
                data-test-id="sign_in_twitter"
              >
                <Icon src={TwitterIcon} />
                  {t('auth.signIn', { provider: 'Twitter' })}
              </GetInButton>
            )}
            {facebookLogin && (
              <GetInButton
                textColor="black"
                border="black"
                background="white"
                fluid
                onClick={onFacebookLogin}
                data-test-id="sign_in_facebook"
              >
                <Icon src={FacebookIcon} />
                  {t('auth.signIn', { provider: 'Facebook' })}
              </GetInButton>
            )}
            {wechatLogin && (
              <GetInButton textColor="black" border="black" background="white" fluid onClick={onWechatLogin} data-test-id="sign_in_wechat">
                <Icon src={WechatIcon} />
                  {t('auth.signIn', { provider: 'WeChat' })}
              </GetInButton>
            )}
            {emailLogin && (
              <GetInButton textColor="black" border="black" background="white" fluid onClick={openModal} data-test-id="sign_in_email">
                <Icon src={MailIcon} />
                  {t('auth.signIn', { provider: 'E-mail' })}
              </GetInButton>
            )}
            <Typography
              data-test-id="terms_privacy"
              textColor="black"
              font="tertiary"
              lineHeight="1.3"
            >
              By continuing you agree to our
              {' '}
              <WhiteSpan href={RouteTypes.TermsOfUse} target="_blank">Terms</WhiteSpan>
              {' '}
              and
              {' '}
              <WhiteSpan href={RouteTypes.PrivacyPolicy} target="_blank">Privacy Policy</WhiteSpan>
            </Typography>
          </ButtonsContainer>
        </GetInContainer>
      </GetInWrapper>
    </GetInPortal>
  ) : null;
};

export default GetInView;
