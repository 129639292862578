import * as fcl from '@blocto/fcl';
import * as t from '@onflow/types';

const CODE = fcl.cdc`
import StarlyCardStaking from 0xSTARLY_CARD_STAKING_ADDRESS

pub fun main(records: { String: String }): {String: AnyStruct} {
    let ret: {String: AnyStruct} = {}
    for starlyID in records.keys {
        let collectionID: String = records[starlyID]!
        ret.insert(key: starlyID, StarlyCardStaking.getRemainingResource(collectionID: collectionID, starlyID: starlyID))
    }
    return ret
}`;

export function flowFetchStakedCardRemainingResourceScript(records: any) {
  return fcl.send([
    fcl.script(CODE),
    fcl.args([
      fcl.arg(records, t.Dictionary(
        { key: t.String, value: t.String },
      )),
    ]),
  ]).then(fcl.decode);
}
