import { type FC } from 'react';

import BuyStarlyModal from 'components/BuyStarlyModal';
import { remoteConfig } from 'global/firebase';

import { type TopUpProps } from '../types';
import MoonpayOption from './MoonpayOption';
import RampOption from './RampOption';

const TopUpModal: FC<TopUpProps> = ({ currency, setIsOpen }) => {
  const currencyLowerCased = currency.toLowerCase();

  if (currencyLowerCased === 'starly') {
    return <BuyStarlyModal onClose={() => setIsOpen(false)} />;
  }
  const topUpMode = remoteConfig.getValue('topUpMode').asString();
  if (topUpMode === 'moonpay' || currencyLowerCased === 'flow') {
    return <MoonpayOption currency={currency} setIsOpen={setIsOpen} />;
  }

  return <RampOption currency={currency} setIsOpen={setIsOpen} />;
};

export default TopUpModal;
