import {
  all, call, fork, put, takeEvery,
} from 'redux-saga/effects';
import type { Collection, Listing } from '@starly/starly-types';
import { replace } from 'connected-react-router';
import { cardForSalePurchaseError, cardSaleSetCardProgress } from 'store/saleCard/saleCardActions';
import { cardRequest } from 'store/card/cardActions';
import {
  cardMarketplaceRequest,
  cardMarketplaceResponse,
  setMarketplaceCardPurchaseState,
  setMarketplacePurchasingState,
  setMarketplaceLoadState,
  setMarketplacePage,
  collectionMarketplaceResponse,
  collectionsMarketplaceRequest,
  setMarketplaceFilters,
  salesMarketplaceRequest,
  setMarketplaceSalesPage,
  salesMarketplaceResponse,
  setMarketplaceSalesLoadState,
  setMarketplaceSalesFilters,
} from './marketplaceAction';
import {
  type DocumentSnapshot, type OrderByDirection, type Query, type QuerySnapshot,
  firestore,
} from '../../global/firebase';
import { chunkArray } from '../../util/chunkArray';
import { RouteTypes } from '../../RouteTypes';
import type {
  ExtendedSalesHistoryItem,
  MarketplaceCard, MetaCollectionsCache, Option,
} from './marketplaceTypes';
import { flowAcceptSaleOfferResponse } from '../flow/flowActions';
import type { MarketplaceSalesRowProps } from '../../views/Marketplace/components/MarketplaceSaleRow/types';

const LISTING_DATA_LIMIT = 21;
const SALES_DATA_LIMIT = 30;

function* watchMarketplaceCardRequest() {
  yield takeEvery(cardMarketplaceRequest, function* marketplaceCardRequestWorker(action) {
    try {
      yield put(setMarketplaceLoadState({ isLoading: true }));
      const {
        payload: {
          rarity,
          sortBy,
          collection,
          page,
        },
      } = action;
      const allowedRarity = rarity.filter((r) => r.active).map((r) => r.value);
      let listingsQuery: Query = firestore.collectionGroup('listings')
        .limit(LISTING_DATA_LIMIT);

      const orderChunks = chunkArray(sortBy.split(' '), 2);
      orderChunks.forEach((order) => {
        listingsQuery = listingsQuery.orderBy(...order as [string, OrderByDirection]);
      });

      if (!sortBy.startsWith('create_time')) {
        listingsQuery = listingsQuery.orderBy('create_time', 'desc');
      }

      if (page) {
        listingsQuery = listingsQuery.startAfter(page);
      }

      if (collection.value) {
        listingsQuery = listingsQuery.where('collection_id', '==', collection.value);
      }

      if (allowedRarity.length > 0) {
        listingsQuery = listingsQuery.where('rarity', 'in', allowedRarity);
      } else {
        listingsQuery = listingsQuery.where('rarity', '==', '');
      }

      const listings: QuerySnapshot = yield call(() => listingsQuery.get());

      const collectionIds: Set<string> = new Set();
      const listingDocs: Listing[] = [];

      for (let i = 0; i < listings.docs.length; i += 1) {
        const listingDoc = listings.docs[i];
        if (i === listings.docs.length - 1) yield put(setMarketplacePage({ page: listingDoc }));
        const data = listingDoc.data() as Listing;
        collectionIds.add(data.collection_id);
        listingDocs.push(data);
      }

      const requiredCollectionIds = Array.from(collectionIds);

      if (requiredCollectionIds.length > 0) {
        const idChunks = chunkArray(requiredCollectionIds, 10);
        const result: MarketplaceCard[] = [];
        for (let i = 0; i < idChunks.length; i += 1) {
          const collectionDocs: QuerySnapshot = yield call(() => firestore
            .collectionGroup('collections')
            .where('state', '==', 'created')
            .where('id', 'in', idChunks[i])
            .limit(10)
            .get());

          const collectionsMap: { [key: string] : Collection } = {};
          collectionDocs.docs.forEach((doc) => {
            collectionsMap[doc.id] = doc.data() as Collection;
          });

          listingDocs.forEach((listing, index) => {
            const currentCollection = collectionsMap[listing.collection_id];

            if (currentCollection && currentCollection._cached_cards && !result[index]) {
              const currentCard = currentCollection._cached_cards[+listing.card_id - 1];

              const card: MarketplaceCard = {
                card: {
                  media: currentCard.media,
                  author: currentCollection.creator.name,
                  collection: currentCollection.title,
                  cardId: listing.card_id,
                  edition: listing.edition_id.toString(),
                  totalEditions: currentCard.editions,
                  rarity: listing.rarity,
                  qrCodeUrl: '',
                  title: currentCard.title,
                },
                cardUrl: RouteTypes.Card
                  .replace(':username', currentCollection.creator.username)
                  .replace(':collectionId', currentCollection.id || '')
                  .replace(':cardId', currentCard.id)
                  .replace(':edition?', listing.edition_id.toString()),
                marketCollectionAddress: listing.owner?.flow_account || '',
                nftItemId: listing.nft_item_id,
                price: listing.price,
                inProgress: false,
                purchased: false,
              };

              result[index] = card;
            }
          });
        }
        yield put(cardMarketplaceResponse({ cards: result, isInitial: !page }));
      }

      if (listingDocs.length < LISTING_DATA_LIMIT) {
        yield put(setMarketplaceLoadState({ isMoreToLoad: false }));
      }

      yield put(setMarketplaceLoadState({ isLoad: true, isLoading: false }));
    } catch (e) {
      console.error(e);
    }
  });
}

function* watchMarketplaceSalesRequest() {
  yield takeEvery(salesMarketplaceRequest, function* marketplaceSalesRequestWatcher({ payload }) {
    try {
      yield put(setMarketplaceSalesLoadState({ isLoading: true }));
      const {
        rarity, sortBy, collection, page,
      } = payload;

      const allowedRarity = rarity.filter((r) => r.active).map((r) => r.value);
      const order = sortBy.split(' ') as [string, OrderByDirection];

      let salesQuery: Query = firestore.collectionGroup('salesHistory')
        .orderBy(...order)
        .limit(SALES_DATA_LIMIT);

      if (!sortBy.startsWith('create_time')) {
        salesQuery = salesQuery.orderBy('create_time', 'desc');
      }

      if (page) {
        salesQuery = salesQuery.startAfter(page);
      }
      if (collection.value) {
        salesQuery = salesQuery.where('collection_id', '==', collection.value);
      }

      if (allowedRarity.length > 0) {
        salesQuery = salesQuery.where('rarity', 'in', allowedRarity);
      } else {
        salesQuery = salesQuery.where('rarity', '==', '');
      }
      const salesSnapshot: QuerySnapshot = yield call(() => salesQuery.get());

      const collectionIds: Set<string> = new Set();
      const salesDocs: ExtendedSalesHistoryItem[] = [];

      for (let i = 0; i < salesSnapshot.docs.length; i += 1) {
        const saleDoc = salesSnapshot.docs[i];
        if (i === salesSnapshot.docs.length - 1) {
          yield put(setMarketplaceSalesPage({ page: saleDoc }));
        }
        const data = saleDoc.data() as ExtendedSalesHistoryItem;
        data.card_id = saleDoc.ref.parent?.parent?.id || '';
        collectionIds.add(data.collection_id);
        salesDocs.push(data);
      }

      const requiredCollectionIds = Array.from(collectionIds);

      if (requiredCollectionIds.length > 0) {
        const idChunks = chunkArray(requiredCollectionIds, 10);
        const result: MarketplaceSalesRowProps[] = [];
        for (let i = 0; i < idChunks.length; i += 1) {
          const collectionDocs: QuerySnapshot = yield call(() => firestore
            .collectionGroup('collections')
            .where('state', '==', 'created')
            .where('id', 'in', idChunks[i])
            .limit(10)
            .get());

          const collectionsMap: { [key: string]: Collection } = {};
          collectionDocs.docs.forEach((doc) => {
            collectionsMap[doc.id] = doc.data() as Collection;
          });

          salesDocs.forEach((saleItem, index) => {
            const currentCollection = collectionsMap[saleItem.collection_id];

            if (currentCollection?._cached_cards && !result[index]) {
              const currentCard = currentCollection._cached_cards[+saleItem.card_id - 1];
              const salesRowProps: MarketplaceSalesRowProps = {
                card: {
                  media: currentCard.media,
                  collectionTitle: currentCollection.title,
                  creatorName: currentCollection.creator.name,
                  cardTitle: currentCard.title,
                  cardLink: RouteTypes.Card
                    .replace(':username', currentCollection.creator.username)
                    .replace(':collectionId', currentCollection.id || '')
                    .replace(':cardId', currentCard.id)
                    .replace(':edition?', saleItem.edition_id.toString()),
                },

                sellerUsername: saleItem.seller.username,
                buyerUsername: saleItem.buyer.username,
                flowTransactionLink: `${process.env.REACT_APP_FLOW_TRANSACTION_VIEW_URL}/${saleItem.flow_transaction_id}`,
                time: saleItem.create_time.toDate(),
                rarity: saleItem.rarity,
                edition: saleItem.edition_id.toString(),
                allEditions: currentCard.editions,
                price: saleItem.price,
                currency: saleItem.currency,
              };

              result[index] = salesRowProps;
            }
          });
        }
        yield put(salesMarketplaceResponse({ saleItems: result, isInitial: !page }));
      }

      if (salesDocs.length < SALES_DATA_LIMIT) {
        yield put(setMarketplaceSalesLoadState({ isMoreToLoad: false }));
      }

      yield put(setMarketplaceSalesLoadState({ isLoad: true, isLoading: false }));
    } catch (e) {
      console.error(e);
    }
  });
}

function* watchCollectionsMarketplaceRequest() {
  yield takeEvery(collectionsMarketplaceRequest, function* collectionsMarketplaceRequestWorker() {
    const collections: DocumentSnapshot = yield call(() => firestore
      .collection('cache')
      .doc('collectionsMeta')
      .get());
    const collectionsData: Option[] = (collections.data() as MetaCollectionsCache)
      ._cached_collections_meta
      .sort((a, b) => b.create_date?.toDate() - a.create_date?.toDate())
      .map(
        ({ id, name, creator: { name: user } }) => ({ label: `${name} (by ${user})`, value: id }),
      );
    yield put(collectionMarketplaceResponse({ collections: collectionsData }));
  });
}

function* watchSetMarketplaceFilters() {
  yield takeEvery(setMarketplaceFilters, function* setMarketplaceFiltersWorker({
    payload: {
      collection, rarity, sortBy, withoutUrlChange,
    },
  }) {
    if (!withoutUrlChange) {
      const params = (new URL(document.location.toString())).searchParams;
      if (collection) {
        params.set('collectionId', collection.value);
        params.set('collectionName', collection.label);
      }
      if (sortBy) {
        params.set('sortBy', sortBy);
      }
      if (rarity) {
        const rarStr = rarity
          .map(({ value, active }) => (active ? value : ''))
          .filter((val) => !!val)
          .join(' ');
        params.set('rarity', rarStr);
      }
      yield put(replace({ search: params.toString() }));
    }
  });
}

function* watchSetMarketplaceSalesFilters() {
  yield takeEvery(setMarketplaceSalesFilters, function* setMarketplaceSalesFiltersWorker({
    payload: {
      collection, rarity, sortBy, withoutUrlChange,
    },
  }) {
    if (!withoutUrlChange) {
      const params = (new URL(document.location.toString())).searchParams;
      if (collection) {
        params.set('collectionId', collection.value);
        params.set('collectionName', collection.label);
      }
      if (sortBy) {
        params.set('sortBy', sortBy);
      }
      if (rarity) {
        const rarStr = rarity
          .map(({ value, active }) => (active ? value : ''))
          .filter((val) => !!val)
          .join(' ');
        params.set('rarity', rarStr);
      }
      yield put(replace({ search: params.toString() }));
    }
  });
}

function* watchAcceptSaleOfferResponse() {
  yield takeEvery(flowAcceptSaleOfferResponse, function* acceptSaleOfferRequestWorker(
    { payload: { nftItemId, result, isMarketplace } },
  ) {
    if (result && !result.error) {
      if (isMarketplace) {
        yield put(setMarketplacePurchasingState({ state: 'purchased', isPurchasing: false }));
      }
      yield put(setMarketplaceCardPurchaseState({ nftItemId, inProgress: false, purchased: true }));

      const { pathname } = window.location;
      const [collectionId, cardId, edition] = pathname.split('/').filter((_, i, arr) => i >= arr.length - 3);
      if (collectionId && cardId && edition) {
        yield put(cardRequest({
          cardId,
          edition,
          collectionId,
          background: true,
          purchase: true,
        }));
      }
    } else {
      if (isMarketplace) {
        yield put(setMarketplacePurchasingState({ state: 'error', isPurchasing: false }));
      }
      yield put(setMarketplaceCardPurchaseState({ nftItemId, inProgress: false }));

      yield put(cardForSalePurchaseError());
    }
    yield put(cardSaleSetCardProgress({ nftItemId, inProgress: false }));
  });
}

export default function* marketplaceSaga() {
  yield all([
    fork(watchMarketplaceCardRequest),
    fork(watchAcceptSaleOfferResponse),
    fork(watchCollectionsMarketplaceRequest),
    fork(watchSetMarketplaceFilters),
    fork(watchSetMarketplaceSalesFilters),
    fork(watchMarketplaceSalesRequest),
  ]);
}
