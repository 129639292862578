import * as fcl from '@blocto/fcl';
import { Address } from '@onflow/types';

const CODE = fcl.cdc`
import NonFungibleToken from 0xNON_FUNGIBLE_TOKEN_ADDRESS
import StarlyCard from 0xSTARLY_CARD_ADDRESS

// This script returns an array of all the NFT IDs in an account's collection.

pub fun main(address: Address): [UInt64] {
    let account = getAccount(address)

    let collectionRef = account.getCapability(StarlyCard.CollectionPublicPath)!.borrow<&{NonFungibleToken.CollectionPublic}>()
        ?? panic("Could not borrow capability from public collection")
    
    return collectionRef.getIDs()
}`;

export function flowFetchStarlyCardItemIDsScript(address: string) {
  if (address == null) return Promise.resolve(false);

  return fcl.send([
    fcl.script(CODE),
    fcl.args([
      fcl.arg(address, Address),
    ]),
  ]).then(fcl.decode);
}
