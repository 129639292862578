import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Card from 'components/Card';
import NotificationsLoader from 'components/NotificationsLoader/NotificationsLoader';
import Typography from 'components/Typography';
import { capitalize, numberWithCommas } from 'helpers/TextHelpers';
import boughtIcon from 'static/ic-bought.svg';

import {
  MarketplaceCardButton,
  MarketplaceCardContainer,
  MarketplaceCardIcon,
  MarketplaceCardProgressButton,
  MarketplaceCardRarity,
  MarketplaceCardTitle,
  MarketplaceControlContainer,
  PriceContainer,
} from './styled';
import type { MarketplaceCardProps } from './types';

const MarketplaceCard: FC<MarketplaceCardProps> = ({
  card,
  price,
  cardUrl,
  inProgress,
  purchased,
  marketCollectionAddress,
  nftItemId,
  onBuyClick,
  currentWalletAddress,
  soundControl,
  'data-test-id': dataTestId,
}) => {
  const { t } = useTranslation();
  const isOwned = currentWalletAddress === marketCollectionAddress;
  return (
    <MarketplaceCardContainer data-test-id={dataTestId}>
      <div>
        <Card
          card={card}
          cardUrl={cardUrl}
          isTrimmedCard={!soundControl}
          collectionId={card.collection}
        />
      </div>
      <MarketplaceControlContainer>
        <MarketplaceCardTitle lSpacing={1} uppercase bold>{card.title}</MarketplaceCardTitle>
        <MarketplaceCardRarity>
          <Typography data-test-id={`rarity_${dataTestId}`} size="unmedia21" bold>
            {capitalize(card.rarity.replaceAll('_', ' '))}
          </Typography>
          <Typography data-test-id={`edition_${dataTestId}`} size="unmedia21" bold>{`#${card.edition}/${card.totalEditions}`}</Typography>
        </MarketplaceCardRarity>
        <PriceContainer>
          <Typography data-test-id={`price_label_${dataTestId}`}>Price:</Typography>
          <Typography data-test-id={`price_${dataTestId}`} data-price-attribute={price} lSpacing={1} size="title" bold>
            $
            {numberWithCommas(price)}
          </Typography>
        </PriceContainer>
        {
          inProgress && (
            <MarketplaceCardProgressButton size="regular" background="darkTet" noneHover nonePointer>
              <NotificationsLoader small />
            </MarketplaceCardProgressButton>
          )
        }
        {
          !inProgress && !purchased && !isOwned && (
            <MarketplaceCardButton
              data-test-id={`buy_button_${dataTestId}`}
              size="regular"
              onClick={() => {
                onBuyClick(nftItemId, marketCollectionAddress, price);
              }}
            >
              {t('common.buy')}
            </MarketplaceCardButton>
          )
        }
        {
          (isOwned || purchased) && !inProgress && (
            <MarketplaceCardButton size="regular" background="darkTet" nonePointer>
              <MarketplaceCardIcon src={boughtIcon} />
            </MarketplaceCardButton>
          )
        }
      </MarketplaceControlContainer>
    </MarketplaceCardContainer>
  );
};

export default MarketplaceCard;
