import { type FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RampInstantEventTypes, RampInstantSDK } from '@ramp-network/ramp-instant-sdk';

import { desktopBreakpoint } from 'breakpoints';
import { useWindowSize } from 'hooks/useWindowSize';
import { flowBalanceRequest } from 'store/flow/flowActions';
import { selectFlowWalletAddr } from 'store/flow/flowSelectors';

import { type TopUpProps } from '../types';

const RAMP_API_KEY = process.env.REACT_APP_RAMP_API_KEY || '';
const RAMP_WIDGET_URL = process.env.REACT_APP_RAMP_WIDGET_URL || 'https://buy.ramp.network/';

const RampOption: FC<TopUpProps> = ({ currency, setIsOpen }) => {
  const dispatch = useDispatch();

  const addr = useSelector(selectFlowWalletAddr);
  const isMobile = useWindowSize() < desktopBreakpoint;

  useEffect(() => {
    setIsOpen(false);
    const swapAsset = `FLOW_${currency.toUpperCase()}`;
    const instance = new RampInstantSDK({
      userAddress: addr,
      hostAppName: 'Starly',
      hostLogoUrl: 'https://storage.googleapis.com/starly-prod.appspot.com/assets/starly-logo-776x184.png',
      fiatValue: '50',
      swapAsset,
      defaultAsset: swapAsset,
      hostApiKey: RAMP_API_KEY,
      url: RAMP_WIDGET_URL,
      variant: isMobile ? 'mobile' : 'desktop',
    });
    instance.show();
    instance.on(
      RampInstantEventTypes.WIDGET_CLOSE,
      () => {
        dispatch(flowBalanceRequest({}));
      },
    );
  });

  return null;
};

export default RampOption;
