// Regexp

export const emojiRegex = /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g;
/* eslint-disable-next-line */
export const emailRegex = /^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/i;
export const youTubeLink = /^(?:http:\/\/|https:\/\/)?(?:www\.)?(youtube\.com|youtu.be)\/(embed\/[.-\w]+|watch\?v=\S+|[.-\w]+)$/;
export const youTubeEmbedLink = /^(?:http:\/\/|https:\/\/)?(?:www\.)?(youtube\.com|youtu.be)\/embed\/\S+$/;
export const vimeoLink = /^(?:http:\/\/|https:\/\/)?(?:www\.)?(player\.)?(vimeo\.com\/)(album\/\S+\/video\/|channels\/\S+\/|groups\/\S+\/videos\/|ondemand\/\S+\/|video\/)?([0-9]+)$/;

export const starlyInstLink = 'https://www.instagram.com/starlynft';
export const starlyTwitLink = 'https://twitter.com/StarlyNFT';
export const starlyTgLink = 'https://t.me/starlynft';
export const starlyDsLink = 'https://discord.com/invite/urcYcm9fV5';

const packCutPercent = process.env.REACT_APP_BENEFICIARY_PACK_CUT_PERCENT;
const marketCutPercent = process.env.REACT_APP_BENEFICIARY_MARKET_CUT_PERCENT;

export const BENEFICIARY_ADDRESS = process.env.REACT_APP_BENEFICIARY_ADDRESS || '';
export const BENEFICIARY_PACK_CUT_PERCENT = packCutPercent ? +packCutPercent : 0.2;
export const BENEFICIARY_MARKET_CUT_PERCENT = marketCutPercent ? +marketCutPercent : 0.05;

export const PREV_PAGE_REDIRECT_KEY = 'prev-page-redirect_key';

export const PackRarity = {
  rare: 'rare',
  common: 'common',
  legendary: 'legendary',
  rarePlus: 'rare_plus',
  commonPlus: 'common_plus',
  legendaryPlus: 'legendary_plus',
};

export const knownBadNFTIds = [
  12,
  13,
  14,
  75,
  76,
  77,
  78,
  79,
  191,
  192,
  193,
  207,
  208,
  209,
  218,
  219,
  220,
  363,
  364,
  365,
  366,
  367,
  368,
  369,
  370,
  371,
  372,
  373,
  374,
  375,
  376,
  377,
  378,
  379,
  380,
  508,
  509,
  510,
  519,
  516,
  520,
  517,
  521,
  518,
  511,
  512,
  513,
  514,
  515,
  525,
  526,
  527,
  522,
  523,
  524,
  528,
  529,
  530,
  531,
  532,
  533,
  542,
  543,
  544,
  545,
  550,
  569,
  546,
  551,
  570,
  547,
  552,
  571,
  548,
  553,
  572,
  549,
  554,
  573,
  563,
  564,
  565,
  629,
  630,
  631,
  645,
  646,
  647,
  680,
  675,
  681,
  676,
  682,
  677,
  683,
  678,
  684,
  679,
  690,
  695,
  691,
  696,
  692,
  697,
  693,
  698,
  694,
  699,
  756,
  757,
  758,
  770,
  771,
  772,
  815,
  816,
  817,
  835,
  840,
  836,
  841,
  837,
  842,
  838,
  843,
  839,
  844,
  887,
  888,
  889,
  898,
  899,
  900,
  901,
  902,
  911,
  912,
  913,
  914,
  915,
  921,
  922,
  923,
  924,
  939,
  925,
  940,
  926,
  941,
  927,
  942,
  928,
  943,
  929,
  930,
  931,
  932,
  933,
  934,
  954,
  935,
  955,
  936,
  956,
  937,
  957,
  938,
  958,
  944,
  949,
  968,
  1154,
  945,
  950,
  969,
  1155,
  946,
  951,
  970,
  1156,
  947,
  952,
  971,
  1157,
  948,
  953,
  972,
  1158,
  959,
  960,
  961,
  962,
  963,
  964,
  965,
  966,
  967,
  973,
  974,
  975,
  976,
  977,
  1029,
  1032,
  1035,
  1030,
  1033,
  1036,
  1031,
  1034,
  1037,
  1051,
  1052,
  1053,
  1054,
  1108,
  1109,
  1110,
  1111,
  1112,
  1270,
  1271,
  1272,
  1273,
  1274,
  1342,
  1343,
  1344,
  1345,
  1346,
  1347,
  1348,
  1349,
  1358,
  1359,
  1360,
  1361,
  1362,
  1373,
  1374,
  1375,
  1376,
  1377,
  1398,
  1399,
  1400,
  1401,
  1402,
  1423,
  1424,
  1425,
  1426,
  1427,
  1467,
  1468,
  1469,
  1470,
  1471,
  1477,
  1478,
  1479,
  1480,
  1481,
  1492,
  1493,
  1494,
  1495,
  1491,
  1502,
  1503,
  1504,
  1505,
  1506,
  1599,
  1600,
  1601,
  1602,
  1603,
  1851,
  1854,
  1857,
  1852,
  1855,
  1858,
  1853,
  1856,
  1859,
  1863,
  1864,
  1865,
  1897,
  1898,
  1899,
  1927,
  1928,
  1929,
  1930,
  1950,
  1951,
  1952,
  1953,
  2060,
  2061,
  2062,
  2063,
  2064,
  2075,
  2076,
  2077,
  2078,
  2079,
  2107,
  2108,
  2109,
  2110,
  2119,
  2120,
  2121,
  2122,
  2132,
  2133,
  2134,
  2135,
  2192,
  2193,
  2194,
  2195,
  2196,
  2197,
  2251,
  2252,
  2253,
  2254,
  2359,
  2360,
  2361,
  2362,
  2437,
  2438,
  2439,
  2440,
  2475,
  2476,
  2477,
  2478,
  4280,
  4281,
  4282,
  4283,
];
