import styled, { css } from 'styled-components';
import theme from '../../components/theme';
import { mediaDesktop } from '../../breakpoints';

export const HowToPlayContainer = styled.div`
  color: #fff;
  padding: 50px 32px 100px;
  position: relative;
  ${mediaDesktop} {
    padding-top: 70px;
  }
`;

export const HowToPlayHeading = styled.h1`
  font-family: ${theme.fonts.syncopate};  
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 50px;
  font-size: 24px;
  ${mediaDesktop} {
    font-size: 35px;
    margin-bottom: 70px;
  }
`;

export const HowToPlayFlexContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const HowToPlayImagesContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 67px;
  margin-left: 64px;
`;

export const HoToPlayImage = styled.img`
  height: 320px;
  &:nth-child(1) {
    margin-top: 26px;
    margin-bottom: 120px;
  }
  &:nth-child(2) {
    margin-bottom: 130px;
  }
  &:nth-child(3) {
    margin-bottom: 220px;
  }
  &:nth-child(4) {
    margin-bottom: 200px;
  }
  &:nth-child(5) {
    margin-bottom: 145px;
  }
`;

export const HowToPlayStepsContainer = styled.div`
  position: relative;
  display: grid;
  grid-gap: 50px;
  ${mediaDesktop} {
    grid-gap: 0;
  }
`;

export const HowToPlayStepContainer = styled.div<{ bgImage?: string }>`
  display: grid;
  justify-items: center;
  padding-top: 64px;
  grid-gap: 16px;
  min-height: 300px;
  position: relative;
  z-index: 1;
  ${mediaDesktop} {
    padding-top: initial;
    justify-items: normal;
    grid-template-columns: max-content 462px;
    grid-row-gap: 0;
    grid-column-gap: 64px;
    
    &:nth-child(2) {
      margin-bottom: 100px;
    }
    &:nth-child(3) {
      margin-bottom: 147px;
    }
    &:nth-child(4) {
      margin-bottom: 138px;
    }
    &:nth-child(5) {
      margin-bottom: 120px;
    }
    &:nth-child(6) {
      margin-bottom: 140px;
    }
  }
`;

export const NumberContainer = styled.div`
  height: 42px;
  width: 42px;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000;
  font-weight: bold;
  font-family: ${theme.fonts.primary};
  background: #48f5fc;
  border-radius: 100%;
  z-index: 2;
`;

export const RoadLine = styled.div`
  height: 100%;
  position: absolute;
  left: 21px;
  width: 1px;
  background-image: linear-gradient(to top, #45ffc1 -2%, #d773ff 104%);
  display: none;
  ${mediaDesktop} {
    display: block;
  }
`;

export const StepTitle = styled.h3`
  font-family: ${theme.fonts.syncopate};
  font-weight: bold;
  font-size: 16px;
  text-align: center;
  ${mediaDesktop} {
    text-align: initial;
    align-self: center;
    font-size: 20px;
  }
`;

export const HowToPlayStepImageContainer = styled.div<{ bgImage: string }>`
  position: absolute;
  z-index: -1;
  height: 300px;
  width: calc(100% + 64px);
  max-width: 360px;
  margin-left: -32px;
  margin-right: -32px;
  ${({ bgImage }) => css`
    background-position: top;
    background-size: cover;
    opacity: .4;
    background-image: url("${bgImage}");
  `}
`;

export const StepDescription = styled.div`
  font-family: ${theme.fonts.spaceMono};
  max-width: 100%;
  line-height: normal;
  text-align: center;
  letter-spacing: .44px;
  ${mediaDesktop} {
    text-align: left;
    letter-spacing: 1px;
    font-size: 15px;
    grid-column: 2 / 3;
    & > p {
      margin-bottom: 8px;
      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
`;
