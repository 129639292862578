import { useEffect, useState } from 'react';

export const useIosScale = () => {
  const getScale = () => document.body.clientWidth / window.innerWidth;
  const [scale, setScale] = useState<number>(getScale());
  useEffect(() => {
    setScale(getScale);
  }, [document.body.clientWidth, window.innerWidth]);
  return scale;
};
