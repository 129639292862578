import { createSelector } from '@reduxjs/toolkit';
import { type RootState } from 'store/store';

const selectFlow = ({ flow }: RootState) => flow;

const selectState = (state: RootState) => state;

// Hack for KYC
export const selectFlowWalletAddr = createSelector(selectState, ({ auth, flow, user }) => {
  const id = auth?.userId;
  const addr = flow?.wallet?.addr;
  const current = user?.items?.[id];

  if (current?.flow_account && addr) {
    return current.flow_account;
  }

  return undefined;
});

export const selectFlowWalletServices = createSelector(selectFlow, ({ wallet }) => wallet?.services);

export const selectFlowBalance = createSelector(selectFlow, ({ flowBalance }) => flowBalance);

export const selectFUSDBalance = createSelector(selectFlow, ({ fusdBalance }) => fusdBalance);

export const selectStarlyBalance = createSelector(selectFlow, ({ starlyBalance }) => starlyBalance);

export const selectUsdcBalance = createSelector(selectFlow, ({ usdcBalance }) => usdcBalance);

export const selectWrongModal = createSelector(selectFlow, ({ isWrongModalOpen }) => isWrongModalOpen);

export const selectFlowModal = createSelector(selectFlow, ({ isWalletModalOpen }) => isWalletModalOpen);

export const selectLowBalanceModal = createSelector(selectFlow, ({ lowBalanceModal }) => lowBalanceModal);

export const selectFlowIsLoading = createSelector(selectFlow, ({ isLoading }) => isLoading);

export const selectBadNFTIds = createSelector(selectFlow, ({ badNFTIds }) => badNFTIds);

export const selectFlowFestModal = createSelector(selectFlow, ({ isFlowFestWalletModalOpen }) => isFlowFestWalletModalOpen);

export const selectFlowNFTNYCModal = createSelector(selectFlow, ({ isFlowNFTNYCWalletModalOpen }) => isFlowNFTNYCWalletModalOpen);
