import {
  all,
  delay,
  fork,
  call,
  takeEvery,
  put,
  select,
} from 'redux-saga/effects';
import { type FungibleTokenType } from '@starly/starly-types';
import { ethers } from 'ethers';
import i18n from 'i18next';
import { type Dispatch } from '@reduxjs/toolkit';

import { trackEvent, trackException } from 'global/firebase';
import { getStarlyRate } from 'store/flow/flowSaga';
import {
  ERC_20_ABI, ethConfig, ethTokensMap, STARLY_PACK_ABI,
} from 'util/constants';

import wallet from '../../helpers/ethereumWallet';
import { buyPackResponse, setPackPurchaseStatus } from '../pack/packActions';
import {
  ethereumBalanceResponse,
  ethereumBuyPackRequest,
  ethereumLoginRequest,
  ethereumLoginResponse,
  ethereumLogout,
  ethereumToggleModal,
  ethereumWalletUpdate,
  type TokenBalance,
} from './ethereumActions';
import { selectEthereumWalletAddr } from './ethereumSelectors';

function* checkAllowance(userAddress: string, creatorAddress: string, token: FungibleTokenType) {
  const { ethersProvider } = wallet;

  const tokenContract = new ethers.Contract(ethTokensMap.get(token.toUpperCase())?.address!, ERC_20_ABI, ethersProvider);

  const allowance: bigint = yield call(() => tokenContract.allowance(userAddress, creatorAddress));

  return allowance !== 0n;
}

function* getTokenDecimals(token: FungibleTokenType) {
  const { ethersProvider } = wallet;

  const tokenContract = new ethers.Contract(ethTokensMap.get(token.toUpperCase())?.address!, ERC_20_ABI, ethersProvider);

  const decimals: bigint = yield call(() => tokenContract.decimals());

  return decimals;
}

function* ethereumApproveRequest(token: FungibleTokenType) {
  try {
    const { ethersProvider } = wallet;

    const signer: ethers.JsonRpcSigner = yield call(() => ethersProvider.getSigner());
    const tokenContract = new ethers.Contract(ethTokensMap.get(token.toUpperCase())?.address!, ERC_20_ABI, signer);

    let gas: bigint = yield call(() => tokenContract.approve.estimateGas(
      process.env.REACT_APP_CONTRACT_ETH_STARLY_PACK,
      '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff',
    ));
    gas = gas * 125n / 100n;

    const receipt: ethers.TransactionResponse = yield call(() => tokenContract.approve(
      process.env.REACT_APP_CONTRACT_ETH_STARLY_PACK,
      '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff',
      {
        gasLimit: gas,
      },
    ));

    return receipt;
  } catch (error: any) {
    console.error('er', error);
    trackException(error.message);
    yield put(ethereumToggleModal({ isOpen: true, errorMessage: i18n.t('ethereum.error.approveFailed') }));
    yield put(ethereumLogout());
    yield put(setPackPurchaseStatus({ status: 'initial' }));
    return null;
  }
}

// function* watchEthereumBalanceRequest() {
//   yield takeEvery(ethereumBalanceRequest, function* takeEveryLogout(action) {
//     try {
//       const {
//         payload: {
//           token,
//         },
//       } = action;
//       const userAddress = yield select(selectEthereumWalletAddr);
//       const { web3 } = wallet;

//       const tokenContract = new web3.eth.Contract(
//         ERC_20_ABI,
//         ethTokensMap.get(token.toUpperCase())?.address,
//       );

//       const balance: number = yield call(
//         tokenContract.methods.balanceOf(userAddress).call,
//       );
//       yield put(ethereumBalanceResponse({
//         balance,
//       }));
//     } catch (error) {
//       console.error(error);
//     }
//   });
// }

function* watchEthereumBuyPackRequest() {
  yield takeEvery(ethereumBuyPackRequest, function* takeEveryEthereumBuyPackRequest(action) {
    const {
      payload: {
        collectionId,
        packIds,
        currency,
        paymentCurrency,
        beneficiaryAddress,
        beneficiaryCutPercent,
        creatorAddress,
        creatorCutPercent,
        additionalCuts,
      },
    } = action;
    let { price } = action.payload;
    const priceUSD = price; // we assume future collections will be only in FUSD

    if (!ethers.isAddress(creatorAddress) || !ethers.isAddress(beneficiaryAddress)) {
      trackException('Invalid purchase receiver address');
    }

    const userId: string = yield select((state) => state.auth.userId);
    const userAddress: ReturnType<typeof selectEthereumWalletAddr> = yield select(
      selectEthereumWalletAddr,
    );
    if (!userAddress || !process.env.REACT_APP_CONTRACT_ETH_STARLY_PACK) return;
    const { ethersProvider } = wallet;

    if (currency === 'FUSD' && paymentCurrency === 'STARLY') {
      const rate: any = yield call(getStarlyRate);
      price /= rate.rate;
    }

    try {
      const isAllowanceSet: boolean = yield call(() => checkAllowance(
        userAddress,
        process.env.REACT_APP_CONTRACT_ETH_STARLY_PACK!,
        paymentCurrency,
      ));
      if (!isAllowanceSet) {
        const isApproved: boolean = yield call(ethereumApproveRequest, paymentCurrency);
        if (!isApproved) return;
      }

      const signer: ethers.JsonRpcSigner = yield call(() => ethersProvider.getSigner());
      const starlyPack = new ethers.Contract(process.env.REACT_APP_CONTRACT_ETH_STARLY_PACK, STARLY_PACK_ABI, signer);

      const tokenDecimals: bigint = yield call(getTokenDecimals, paymentCurrency);
      const priceOriginal = price;
      price = Math.floor(price * 10 ** Number(tokenDecimals));
      let beneficiaryAmount = Math.round((price * (beneficiaryCutPercent * 100) / 100));
      const creatorAmount = Math.floor((price * (creatorCutPercent * 100) / 100));
      const cuts = additionalCuts.map((cut) => ({
        receiverAddress: cut.address,
        amount: Math.floor((price * (cut.percent * 100) / 100)),
      }));
      beneficiaryAmount += price - beneficiaryAmount - creatorAmount
        - cuts.reduce((acc, cur) => acc + cur.amount, 0);

      let gas: bigint = yield call(() => starlyPack.purchase.estimateGas(
        collectionId,
        packIds,
        userId,
        price.toString(),
        ethTokensMap.get(paymentCurrency.toUpperCase())?.address,
        {
          receiverAddress: beneficiaryAddress,
          amount: beneficiaryAmount.toString(),
        },
        {
          receiverAddress: creatorAddress,
          amount: creatorAmount.toString(),
        },
        cuts,
      ));
      gas = gas * 125n / 100n;

      const receipt: ethers.TransactionResponse = yield call(() => starlyPack.purchase(
        collectionId,
        packIds,
        userId,
        price.toString(),
        ethTokensMap.get(paymentCurrency.toUpperCase())?.address,
        {
          receiverAddress: beneficiaryAddress,
          amount: beneficiaryAmount.toString(),
        },
        {
          receiverAddress: creatorAddress,
          amount: creatorAmount.toString(),
        },
        cuts,
        {
          gasLimit: gas,
        },
      ));
      yield call(() => receipt.wait());

      yield delay(5000);

      yield put(buyPackResponse({ status: 'allowed' }));

      trackEvent('pack_purchase', {
        transaction_id: receipt.hash,
        quantity: packIds.length,
        total: priceOriginal.toFixed(8).toString(),
        beneficiaryTotal: (beneficiaryCutPercent * priceOriginal).toFixed(8).toString(),
        creatorTotal: (creatorCutPercent * priceOriginal).toFixed(8).toString(),
        currencyActual: paymentCurrency,
        value: priceUSD,
        currency: 'USD',
        payment_type: 'BSC chain',
        items: [{
          index: 0,
          item_id: collectionId,
          item_name: 'Starly Pack',
          item_category: 'Pack',
          quantity: packIds.length,
          price: priceUSD / packIds.length,
        }],
      });
    } catch (error) {
      console.error('er2', error);
      yield put(ethereumToggleModal({ isOpen: true, errorMessage: i18n.t('ethereum.error.purchaseFailed') }));
    }

    yield put(ethereumLogout());
    yield put(setPackPurchaseStatus({ status: 'initial' }));
  });
}

function* getTokenBalance(token: FungibleTokenType) {
  const userAddress: ReturnType<typeof selectEthereumWalletAddr> = yield select(
    selectEthereumWalletAddr,
  );
  const { ethersProvider } = wallet;

  const tokenContract = new ethers.Contract(ethTokensMap.get(token.toUpperCase())?.address!, ERC_20_ABI, ethersProvider);

  const balance: bigint = yield call(() => tokenContract.balanceOf(userAddress));
  const decimals: bigint = yield call(() => tokenContract.decimals());

  return {
    balance: balance.toString(),
    decimals: Number(decimals),
  };
}

function* updateTokenBalance(tokens: FungibleTokenType[]) {
  for (let i = 0; i < tokens.length; i += 1) {
    const token = tokens[i];
    const balance: TokenBalance = yield call(getTokenBalance, token);
    yield put(ethereumBalanceResponse({
      token,
      balance,
    }));
  }
}

function* watchEthereumWalletUpdate() {
  yield takeEvery(ethereumWalletUpdate, function* takeEveryLogout() {
    try {
      yield call(updateTokenBalance, Array.from(ethTokensMap.keys()) as FungibleTokenType[]);
    } catch (error) {
      console.error(error);
    }
  });
}

export function getSubscriptionCallback(dispatch: Dispatch) {
  return {
    onDisconnect(error: Error) {
      trackException(`Disconnected from RPC: ${error}`);
      dispatch(ethereumToggleModal({ isOpen: true, errorMessage: i18n.t('ethereum.error.disconnect') }));
      dispatch(ethereumLogout());
    },
    onAccountsChanged(accounts: string[]) {
      if (accounts.length === 0) {
        dispatch(ethereumLogout());
        return;
      }
      dispatch(ethereumWalletUpdate({ address: accounts[0] }));
    },
    onChainChanged() {
      trackException('Invalid ChainId');
      dispatch(ethereumToggleModal({ isOpen: true, errorMessage: i18n.t('ethereum.error.invalidChain'), errorCode: 1 }));
      dispatch(ethereumLogout());
    },
  };
}

function* watchEthereumLoginRequest() {
  yield takeEvery(ethereumLoginRequest, function* takeEveryEthereumLoginRequest(action) {
    try {
      const {
        payload: {
          providerId,
          callbacks,
        },
      } = action;
      const provider = yield call(wallet.connectTo, providerId);
      if (!provider) return;

      const { ethersProvider } = wallet;
      const { chainId }: ethers.Network = yield call(() => ethersProvider.getNetwork());

      if (chainId !== BigInt(ethConfig.chain.id)) {
        trackException('Invalid ChainId');
        yield put(ethereumToggleModal({ isOpen: true, errorMessage: i18n.t('ethereum.error.invalidChain'), errorCode: 1 }));
        yield put(ethereumLogout());
        return;
      }

      yield call(wallet.subscribeProvider, provider, callbacks.onDisconnect, callbacks.onAccountsChanged, callbacks.onChainChanged);
      const { address }: ethers.JsonRpcSigner = yield call(() => ethersProvider.getSigner());

      yield put(ethereumWalletUpdate({ address }));

      yield put(ethereumLoginResponse({ address }));

      trackEvent('wallet_connect');
    } catch (error: any) {
      console.error('er', error);
      trackException(error?.message);
      yield put(ethereumToggleModal({ isOpen: true, errorMessage: i18n.t('ethereum.error.loginFailed') }));
    }
  });
}

function* watchEthereumLogout() {
  yield takeEvery(ethereumLogout, function* takeEveryLogout() {
    try {
      if (wallet.ethersProvider.removeAllListeners) {
        yield wallet.ethersProvider.removeAllListeners();
      }
      wallet.unsubscribeProvider(wallet.provider);
      trackEvent('wallet_disconnect');
    } catch (err) {
      console.error(err);
    }
  });
}

export default function* flowSaga() {
  yield all([
    // fork(watchEthereumBalanceRequest),
    fork(watchEthereumBuyPackRequest),
    fork(watchEthereumLoginRequest),
    fork(watchEthereumLogout),
    fork(watchEthereumWalletUpdate),
  ]);
}
