import {
  useState, useEffect, useRef, forwardRef, useImperativeHandle, type RefObject,
} from 'react';
import { type ChildRefFunc } from 'components/Media';
import { type VideoProps } from './types';
import { VideoWrapper, VideoVideo } from './styled';

const Video = forwardRef<ChildRefFunc, VideoProps>(({
  url,
  poster,
  mp4,
  ogv,
  webm,
  width,
  height,
  lazy,
  loop,
  muted,
  autoplay,
  className,
  onClick,
  onLoad,
  isVideoPlaying,
  'data-test-id': dataTestId,
  setMuted,
}, parentRef) => {
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const [videoMp4, setVideoMp4] = useState<string>(lazy ? '' : mp4 || '');
  const [videoOgv, setVideoOgv] = useState<string>(lazy ? '' : ogv || '');
  const [videoWebm, setVideoWebm] = useState<string>(lazy ? '' : webm || '');
  const videoWidth = typeof width === 'number' ? `${width}px` : width || 'auto';
  const videoHeight = typeof height === 'number' ? `${height}px` : height || 'auto';
  const videoRef = useRef<HTMLVideoElement>(null);

  useImperativeHandle(parentRef, () => ({
    pauseVideo() {
      if (isPlaying) {
        videoRef.current?.pause();
      }
    },
    playVideo() {
      if (videoRef.current && !isPlaying) {
        const playPromise = videoRef.current?.play();
        if (playPromise !== null) {
          playPromise.then().catch((e) => {
            // eslint-disable-next-line no-console
            console.warn(e);
            // eslint-disable-next-line no-console
            videoRef.current?.play().then().catch((err) => console.warn(err));
            if (setMuted) setMuted();
          });
        }
      }
    },
  }));

  /**
   * Load video only when it's available in viewport
   */
  useEffect(() => {
    if (!window.IntersectionObserver) return undefined;
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setVideoMp4(mp4 || '');
          setVideoOgv(ogv || '');
          setVideoWebm(webm || '');

          try {
            videoRef?.current?.load();
          } catch {
            // Catch what?
          }
        }
      });
    });

    if (lazy && videoRef.current) {
      observer.observe(videoRef.current);
    }

    return () => {
      if (videoRef.current) {
        observer.unobserve(videoRef.current);
      }
    };
  }, [videoRef]);

  /**
   * Pause / resume video according to autoplay attribute change
   */
  const onCanPlay = () => {
    if ((autoplay || isVideoPlaying) && videoRef.current) {
      videoRef.current.play();
    }
  };

  const onPlaying = () => {
    setIsPlaying(true);
  };

  const onPause = () => {
    setIsPlaying(false);
  };

  return (
    <VideoWrapper
      ref={parentRef as RefObject<HTMLDivElement>}
      $width={videoWidth}
      $height={videoHeight}
      className={className}
      onClick={onClick}
    >
      <VideoVideo
        data-test-id={dataTestId}
        src={url}
        ref={videoRef}
        loop={loop}
        muted={muted}
        poster={poster}
        autoPlay={autoplay}
        playsInline
        onLoadedData={onLoad}
        onCanPlay={onCanPlay}
        onPlaying={onPlaying}
        onPause={onPause}
      >
        {videoMp4 && <source src={videoMp4} type="video/mp4" />}
        {videoOgv && <source src={videoOgv} type="video/ogg" />}
        {videoWebm && <source src={videoWebm} type="video/webm" />}
      </VideoVideo>
    </VideoWrapper>
  );
});

export default Video;
