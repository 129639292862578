export interface ITheme {
  colors: {
    black: string;
    dark: string;
    dark100: string;
    dark200: string;
    darkGray: string;
    darkLighter: string;
    darkSec: string;
    darkTet: string;
    darkBorder: string,
    green: string;
    kucoinGreen: string;
    inputPlaceholder: string;
    lightGray: string;
    lightGreen: string;
    lightTransparent: string;
    lightTransparentDark: string;
    lightTransparentRed: string;
    mediumLightTransparentRed: string;
    primary: string;
    primaryHover: string;
    red: string;
    secondary: string;
    semiGray: string;
    silverLight: string;
    textGray: string;
    textWhite: string;
    transparent: string;
    white: string;
    whiteGray: string;
    yellow: string;
  }
  fonts: {
    primary: string;
    secondary: string;
    tertiary: string;
    syncopate: string;
    spaceMono: string;
    gibson: string;
  }
  gradients: {
    legendary: string;
    rare: string;
    common: string;
    legendary_plus: string;
    rare_plus: string;
    common_plus: string;
  }
  hover: ITheme['colors']
  width: {
    mainContainer: string;
  }
}

const colors: ITheme['colors'] = {
  primary: '#8b36ff',
  primaryHover: '#6c00ff',
  secondary: '',
  white: '#FFF',
  textWhite: '#d8d8d8',
  textGray: '#888',
  black: '#000',
  yellow: '#ffdc00',
  green: '#3bd86c',
  lightGreen: '#1aff99',
  kucoinGreen: '#00b290',
  red: '#fa2f00',
  dark: '#171717',
  darkSec: '#5f5f5f',
  darkTet: '#242424',
  darkBorder: '#2c2c2c',
  darkLighter: '#262626',
  dark100: '#333',
  dark200: '#3f3f3f',
  lightGray: '#666',
  silverLight: '#f1f1f1',
  darkGray: '#929292',
  semiGray: '#2E2E2E',
  whiteGray: '#E8E8E8',
  lightTransparent: 'rgba(255, 255, 255, .3)',
  lightTransparentDark: 'rgba(0, 0, 0, .5)',
  transparent: 'rgba(255, 255, 255, 0)',
  inputPlaceholder: '#666',
  lightTransparentRed: 'rgba(250, 47, 0, 0.3)',
  mediumLightTransparentRed: 'rgba(250, 47, 0, 0.5)',
};

const theme: ITheme = {
  colors,
  fonts: {
    primary: 'Kanit, sans-serif', // 'Kanit'
    secondary: 'bebas-neue-by-fontfabric, sans-serif', // 'BebasNeue'
    tertiary: '\'Montserrat\'', // Proxima Nova
    syncopate: 'Syncopate, sans-serif', // Syncopate
    spaceMono: 'Space Mono, monospace', // Space Mono
    gibson: 'Gibson', // Gibson
  },
  gradients: {
    legendary: 'linear-gradient(81deg, #eb67ef 0%, #f5dffc 46%, #75feff 77%, #eeffff 103%);',
    rare: 'linear-gradient(78deg, #efd367 0%, #fff6d4 103%);',
    common: 'linear-gradient(82deg, #cbcbcb 0%, #ffffff 103%);',
    legendary_plus: 'linear-gradient(81deg, #eb67ef 0%, #f5dffc 46%, #75feff 77%, #eeffff 103%);',
    rare_plus: 'linear-gradient(78deg, #efd367 0%, #fff6d4 103%);',
    common_plus: 'linear-gradient(82deg, #cbcbcb 0%, #ffffff 103%);',
  },
  hover: {
    ...colors,
    primary: '#7109FF',
    lightTransparent: '#fff',
    green: '#32E86B',
    yellow: '#FFC500',
    red: '#FA0000',
    white: '#ECECEC',
    black: '#1F1F1F',
    lightGray: '#333',
    dark: '#242424',
    darkTet: '#333333',
    dark100: '#3F3F3F',
    dark200: '#4b4b4b',
    semiGray: '#3B3B3B',
    lightGreen: '#62FFB9',
  },
  width: {
    mainContainer: '1100px',
  },
};

export default theme;
