import { RouteTypes } from '../RouteTypes';

export const checkIsActivePage = (link: string, location: string): boolean => {
  if (!link) {
    return false;
  }

  if (link === location) return true;

  if (link.startsWith('/steps/') && location.startsWith('/steps/')) return true;

  if (link.startsWith('/flowfest') && location.startsWith('/flowfest')) return true;

  if (link.startsWith(RouteTypes.Ranking) && location.startsWith(RouteTypes.Ranking)) return true;

  if (
    link.startsWith(RouteTypes.Marketplace) && location.startsWith(RouteTypes.Marketplace)
  ) return true;

  if (link.startsWith(RouteTypes.Staking) && location.startsWith(RouteTypes.Staking)) return true;

  // if (location.startsWith(link)) return true;

  if (link === RouteTypes.TermsOfUse && location === RouteTypes.PrivacyPolicy) return true;

  return false;
};
