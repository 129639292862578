import i18n from 'i18next';
import type { SetMarketplaceFiltersPayload } from '../../store/marketplace/marketplaceAction';
import {
  defaultMarketplaceCollectionOption,
  defaultMarketplaceSortBy,
  defaultRarityFilter,
} from '../../store/marketplace/marketplaceReducer';
import type {
  FormFilters,
  MarketplaceFilters,
  FilterOption,
} from '../../store/marketplace/marketplaceTypes';

const allowedSortBy = [
  'create_time desc',
  'price asc edition_id asc',
  'price desc edition_id asc',
  'edition_id asc price asc',
  'edition_id desc price asc',
  'collector_score desc price asc',
  'score_price_ratio desc',
];

export const paramsToFilters = (urlParams: string): SetMarketplaceFiltersPayload => {
  const filters: SetMarketplaceFiltersPayload = {
    collection: defaultMarketplaceCollectionOption,
  };
  const params = new URLSearchParams(urlParams);

  if (params.has('collectionId') && params.has('collectionName')) {
    const collectionId = params.get('collectionId');
    const collectionName = params.get('collectionName');
    if (collectionId && collectionName) {
      filters.collection = {
        value: collectionId,
        label: collectionName,
      };
    }
  }

  if (params.has('sortBy')) {
    const sortBy = params.get('sortBy') || undefined;
    if (sortBy && allowedSortBy.includes(sortBy)) filters.sortBy = sortBy;
  } else {
    filters.sortBy = defaultMarketplaceSortBy;
  }

  if (params.has('rarity')) {
    const rarity = (params.get('rarity') || '');
    const formFiltersObj: FormFilters = {
      rarity: defaultRarityFilter,
    };

    const rarityArr = rarity.split(' ');
    formFiltersObj.rarity = defaultRarityFilter.map(
      ({ value }) => ({ value, active: rarityArr.includes(value) }),
    );

    filters.rarity = formFiltersObj.rarity;
  } else {
    filters.rarity = defaultRarityFilter;
  }
  return filters;
};

export const checkIsEqualRarities = (rarity: FilterOption[], defaultRarity: FilterOption[]) => {
  let res: boolean = true;

  res = rarity.every((v, i) => v.active === defaultRarity[i].active) && res;

  return res;
};

export const checkIsDefaultFilters = (filters: FormFilters): boolean => {
  let res: boolean = true;
  const { rarity, status } = filters;

  res = rarity.every((v, i) => v.active === defaultRarityFilter[i].active);

  if (status) {
    res = status.every((v) => !v.active) && res;
  }

  return res;
};

export const isPayloadEqualsToFilters = (filters: MarketplaceFilters, compareFilters: SetMarketplaceFiltersPayload): boolean => {
  let result = true;

  if (compareFilters.sortBy) {
    result = filters.sortBy === compareFilters.sortBy;
  } else {
    result = filters.sortBy === defaultMarketplaceSortBy;
  }

  if (!result) return false;

  if (compareFilters.collection) {
    result = (
      filters.collection.value === compareFilters.collection.value
      && filters.collection.label === compareFilters.collection.label);
  } else {
    result = (
      filters.collection.value === defaultMarketplaceCollectionOption.value
      && filters.collection.label === i18n.t('common.filters.allCollections'));
  }

  if (!result) return false;

  if (compareFilters.rarity) {
    const { rarity } = compareFilters;
    result = filters.rarity.every((r, i) => (
      r.value === rarity[i].value && r.active === rarity[i].active));
  } else {
    result = checkIsDefaultFilters({ rarity: filters.rarity });
  }

  return result;
};
