import { type FC, useState } from 'react';
import { desktopBreakpoint } from 'breakpoints';
import { useWindowSize } from 'hooks/useWindowSize';

import { type TabSelectOption } from '../../../TabsSelector/types';
import { StyledHeaderLink as StyledLabel } from '../../styled';
import HeaderLinkWrapper from '../HeaderLinkWrapper';
import {
  StyledFlowFestButton, StyledLink, StyledLinks, StyledLinksWrapper,
} from './styled';

const DropdownHeaderLink: FC<{
  links: TabSelectOption[];
  to: string;
  'data-test-id'?: string;
}> = ({
  links, children, to, 'data-test-id': dataTestId,
}) => {
  const [isActive, setIsActive] = useState<boolean>(false);

  const isMobile = useWindowSize() < desktopBreakpoint;

  const onMouseEnter = () => setIsActive(true);
  const onMouseLeave = () => setIsActive(false);

  return isMobile ? (
    <HeaderLinkWrapper data-test-id={dataTestId} to={to}>
      <StyledLabel>{children}</StyledLabel>
    </HeaderLinkWrapper>
  ) : (
    <StyledFlowFestButton onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} onClick={() => setIsActive(false)}>
      <HeaderLinkWrapper data-test-id={dataTestId} to={to}>
        <StyledLabel>{children}</StyledLabel>
      </HeaderLinkWrapper>
      <StyledLinksWrapper data-test-id={`drop_down_menu_${dataTestId}`} $isActive={isActive}>
        <StyledLinks>
          {links.map((link) => (
            <StyledLink key={link.id} to={link.href} onClick={() => setIsActive(false)}>
              {link.label}
            </StyledLink>
          ))}
        </StyledLinks>
      </StyledLinksWrapper>
    </StyledFlowFestButton>
  );
};

export default DropdownHeaderLink;
