import { createSelector } from '@reduxjs/toolkit';
import { type SocialTypes } from '../../views/FaceControl/types';
import { type UserProps } from '../../components/User';
import { type RootState } from '../store';

const selectUser = ({ user }: RootState) => user;

export const selectAllUsers = createSelector(
  selectUser, ({ items }) => items,
);

export const selectUserById = (id: string) => createSelector(
  selectUser, ({ items }) => items[id],
);

export const selectUserWalletById = (id: string) => createSelector(
  selectUser, ({ items }) => items[id]?.flow_account || '',
);

export const selectUserByUsername = (username: string) => createSelector(
  selectUser, ({ items }) => Object.values(items)
    .find((item) => item.username?.toLowerCase() === username?.toLowerCase()),
);

export const selectAboutCreatorById = (userId: string) => createSelector(
  selectUser, (userSlice) => {
    const user = userSlice.items[userId];
    const instUsername = user?.connections?.instagram?.username;
    const twitUsername = user?.connections?.twitter?.screen_name;
    const youtubeChannel = user?.connections?.youtube?.channel_id;
    if (user) {
      const aboutCreatorData: UserProps = {
        username: user.username,
        id: user.id,
        avatar: user.avatar,
        name: user.name,
        copy: user.bio,
        isCreatedCollections: true,
        url: user.url,
        instagram: instUsername,
        twitter: twitUsername,
        youtube: youtubeChannel,
      };
      return aboutCreatorData;
    }
    return null;
  },
);

export const selectIsLoading = () => createSelector(
  selectUser, ({ isLoading }) => isLoading,
);

export const selectIsDisconnecting = (id: string, social: SocialTypes) => createSelector(
  selectUser, ({ items }) => items[id]?.connections?.[social]?.isLoading,
);

export const selectPackBattleWinner = (id: string) => createSelector(
  selectUser, ({ items }) => items[id]?.pack_battle_winner,
);
