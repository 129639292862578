import * as fcl from '@blocto/fcl';
import { Address } from '@onflow/types';

const CODE = fcl.cdc`
import FiatToken  from 0xFIAT_TOKEN_ADDRESS
import FungibleToken from 0xFUNGIBLE_TOKEN_ADDRESS
import FUSD from 0xFUSD_ADDRESS
import StarlyToken from 0xSTARLY_TOKEN_ADDRESS

pub fun main(address: Address): {String: UFix64} {
    let ret: {String: UFix64} = {}
    let account = getAccount(address)
    ret.insert(key: "FLOW", account.balance)
    if let vault = account.getCapability<&{FungibleToken.Balance}>(FiatToken.VaultBalancePubPath).borrow() {
        ret.insert(key: "USDC", vault.balance)
    }
    if let vault = account.getCapability<&{FungibleToken.Balance}>(/public/fusdBalance).borrow() {
        ret.insert(key: "FUSD", vault.balance)
    }
    if let vault = account.getCapability<&{FungibleToken.Balance}>(StarlyToken.TokenPublicBalancePath).borrow() {
        ret.insert(key: "STARLY", vault.balance)
    }
    return ret
}`;

export function flowFetchBalancesScript(address: string) {
  if (address == null) return Promise.resolve(false);

  return fcl.send([
    fcl.script(CODE),
    fcl.args([
      fcl.arg(address, Address),
    ]),
  ]).then(fcl.decode);
}
