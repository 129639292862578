import { mediaDesktop, mediaSmartPhones } from 'breakpoints';
import theme from 'components/theme';
import Typography from 'components/Typography';
import styled from 'styled-components/macro';

export const ContentsContainer = styled.div`
  width: 100%;
  /* overflow-y: auto; */
  scrollbar-width: none;
  color: #171717;

  ${mediaDesktop} {
    grid-gap: 20px;
  };
  &::-webkit-scrollbar {
    width: 0;
  }
`;

export const CloseBtn = styled.img`
  cursor: pointer;
  object-fit: contain;
  justify-self: right;
  user-select: none;
  position: absolute;
  top: 20px;
  right: 20px;
  filter: invert(100%);
  padding: 15px;
  margin: -15px;
`;

export const TittleText = styled(Typography)`
  margin-bottom: 50px;
  line-height: 24px;
  letter-spacing: 1px;
  text-align: center;
  white-space: pre-wrap;
  ${mediaDesktop} {
    line-height: 37px;
  };
`;

export const ConnectingButtonContainer = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const LoaderWrapper = styled.div`
  position: absolute;
  top: 5px;
  right: 0;
  > div {
    min-width: 30px;
  }
  ${mediaDesktop} {
    top: 9px;
    > div {
      min-width: 40px;
    }
  }
`;

export const OverviewTittleText = styled(Typography)`
  color: #00ef8b;
  font-size: 20px;
  margin-bottom: 35px;
  line-height: 24px;
  letter-spacing: 1px;
  text-align: center;
  ${mediaDesktop} {
    font-size: 40px;
    margin-bottom: 50px;
    letter-spacing: 2px;
    line-height: 37px;
  };
`;

export const OverviewContainer = styled.div`
  width: 100%;
  gap: 10px;
  display: flex;
  flex-wrap: wrap;
  color: ${theme.colors.lightGray};
  margin-bottom: 32px;
  ${mediaDesktop} {
    gap: 12px;
    margin-bottom: 50px;
  }
`;

export const OverviewItem = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  width: 100%;
  font-size: 16px;
  letter-spacing: 1px;
  font-family: ${theme.fonts.secondary};


  ${mediaDesktop} {
    font-size: 30px;
    letter-spacing: 1.25px;
  }
`;

export const OverviewMainItem = styled(OverviewItem)`
  color: ${theme.colors.black};
`;

export const ButtonsContainer = styled.div`
  display: grid;
  grid-gap: 16px;
  width: 100%;
  ${mediaDesktop} {
    grid-gap: 20px; 
  };
`;

export const IframeContainer = styled.div`
  position: fixed;
  display: grid;
  place-content: center;
  height: 100%;
  width: 100%;
  z-index: 120;
  background: rgba(166, 174, 185, 0.7);
`;

export const IframeWrapper = styled.div<{ isLoaded?: boolean }>`
  border-radius: 8px;
  height: 500px;
  width: 400px;
  margin: 16px 0;

  ${({ isLoaded }) => (isLoaded ? 'border: 2px solid rgba(6, 6, 6, .1);' : '')}
  & iframe {
    border-radius: 8px;
  }
`;

export const IframeCloseIcon = styled.img`
  cursor: pointer;
  position: absolute;
  right: 34px;
  top: 26px;
  padding: 15px;
  margin: -15px;
`;

export const IframeTitle = styled.h3`
  font-family: Kanit, sans-serif;
  font-size: 21px;
  font-weight: 600;
  color: black;
`;

export const IframePoweredBy = styled.img`
  width: 180px;
`;

// Cool name bob
export const IframeFrame = styled.div<{ isLoaded?: boolean }>`
  position: relative;
  border-radius: 10px;
  text-align: center;
  transform: scale(.75);
  ${({ isLoaded }) => (isLoaded ? `
    background: #fff;
    padding: 17px 32px;
  ` : '')}
  ${mediaSmartPhones} {
    transform: scale(.9);
  }
  
  ${mediaDesktop} {
    transform: scale(1);
  }
`;

export const ModalButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  border: 2px solid #EDEEF3;
  border-radius: 74px;
  width: 152px;
  height: 56px;
  cursor: pointer;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #2B2D56;
  text-transform: uppercase;
`;

export const ModalButtonContainer = styled.div`
  width: 318px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  & > ${ModalButton}:last-child {
    background: #DD3E56;
    box-shadow: 0px 8px 34px rgb(221 62 86 / 40%);
    color: #fff;
    border-color: transparent;
  }
`;

export const ModalContainer = styled.div`
  font-family: 'Poppins', sans-serif;
  width: 678px;
  height: 276px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) scale(.65);
  z-index: 9999;
  box-shadow: 0px 54px 200px rgb(36 37 57 / 20%);
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  padding: 35px;
  border-radius: 8px;
  background: #fff;
  box-sizing: border-box;
  
  ${mediaSmartPhones} {
    transform: translate(-50%, -50%) scale(.8);
  }
  
  ${mediaDesktop} {
    transform: translate(-50%, -50%) scale(1);
  }
`;

export const ModalText = styled.div`
  margin: 40px auto 30px;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  text-align: center;
  color: #242539;
`;

export const MakeOfferTittleText = styled(Typography)`
  margin-bottom: 10px;
  font-size: 20px;
  ${mediaDesktop} {
    font-size: 24px;
  }
`;

export const MakeOfferCurrencyContainer = styled.div`
  margin-bottom: 32px;
`;

export const MakeOfferDescription = styled(Typography)`
  margin-bottom: 20px;
  ${mediaDesktop} {
    margin-bottom: 24px;
  }
`;

export const AmountInput = styled.input<{ $error?: boolean }>`
  width: 100%;
  font-family: ${theme.fonts.primary};
  font-size: 16px;
  color: ${({ $error }) => ($error ? theme.colors.red : theme.colors.dark)};
  text-overflow: ellipsis;
  letter-spacing: 0.8px;

  border: solid 1px #d8d8d8;
  padding: 0 25px 0 56px;
  height: 62px;
  ${mediaDesktop} {
    font-size: 21px;
    letter-spacing: 1px;
    height: 90px;
    padding: 0 25px 0 92px;
  }

  :focus {
    outline: none;
    box-shadow: 0 0 10px ${theme.colors.primary};
  }

  ::placeholder {
    color: #a5a5a5;
    opacity: 1;
  }
`;

export const TokenLogo = styled.img`
  width: 30px;
  position: absolute;
  margin-left: 16px;
  ${mediaDesktop} {
    width: 42px;
    margin-left: 25px;
  }
`;

export const OfferInputContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 32px;
  ${mediaDesktop} {
    margin-bottom: 50px;
  }
`;
