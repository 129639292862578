import { createReducer } from '@reduxjs/toolkit';
import {
  closeLoginWithoutAction, setAuthLogout, setAuthRedirect, setCreateProfileRedirect, setLoginModal,
} from './loginActions';

interface LoginSlice {
  isOpen: boolean,
  isLogout: boolean,
  onAuthRedirect?: string,
  createProfileRedirect: string,
}

const initialState: LoginSlice = {
  isOpen: false,
  isLogout: false,
  onAuthRedirect: sessionStorage.getItem('onAuthRedirect') || '',
  createProfileRedirect: '',
};

const loginReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(setLoginModal, (state, { payload: { isOpen } }) => {
      state.isOpen = isOpen;
    })
    .addCase(setAuthRedirect, (state, { payload: { onAuthRedirect } }) => {
      state.onAuthRedirect = onAuthRedirect;
      sessionStorage.setItem('onAuthRedirect', onAuthRedirect || '');
    })
    .addCase(setCreateProfileRedirect, (state, { payload: { redirect } }) => {
      state.createProfileRedirect = redirect;
    })
    .addCase(setAuthLogout, (state, { payload: { isLogout } }) => {
      state.isLogout = isLogout;
    })
    .addCase(closeLoginWithoutAction, (state) => {
      state.isOpen = false;
    });
});

export default loginReducer;
