import styled, { css } from 'styled-components';
import theme from '../theme';
import { mediaDesktop } from '../../breakpoints';

export const InputLabel = styled.label`
  margin-bottom: 9px;
  font-family: ${theme.fonts.secondary};
  font-weight: lighter;
  font-size: 19px;
  letter-spacing: 1.3px;
  color: ${theme.colors.textWhite};

  ${mediaDesktop} {
    margin-bottom: 13px;
    font-size: 30px;
    letter-spacing: 1.5px;
  }
`;

interface InputInputProps {
  $block: boolean;
  danger: boolean;
  gradient: boolean;
  disabled: boolean;
}

export const InputInput = styled.input<InputInputProps>`
  height: 40px;
  width: ${({ $block }) => ($block ? '100%' : 'auto')};
  padding: 0 10px;
  border: 1.5px solid ${(p) => (p.danger ? theme.colors.red : theme.colors.textWhite)};
  border-radius: 0;
  box-sizing: border-box;
  font-family: ${theme.fonts.primary}, sans-serif;
  font-size: 16px;
  letter-spacing: -0.3px;
  color: ${(p) => (p.disabled ? theme.colors.inputPlaceholder : theme.colors.textWhite)};
  ${(p) => (p.gradient
    ? { backgroundImage: 'linear-gradient(to top, #000000, #171717 59%)' }
    : { backgroundColor: 'transparent' })}

  ::placeholder {
    color: ${(p) => (p.danger ? theme.colors.red : theme.colors.inputPlaceholder)};
  }

  :focus {
    outline: none;
    box-shadow: 0 0 10px ${theme.colors.primary};
  }

  ${mediaDesktop} {
    height: 60px;
    padding: 0 15px;
    font-size: 24px;
    letter-spacing: -0.5px;
  }
  
`;

export const InputErrorMessage = styled.p`
  font: 11px ${theme.fonts.primary};
  letter-spacing: 2px;
  color: ${theme.colors.red};
  margin-top: 10px;
  text-transform: uppercase;

  ${mediaDesktop} {
    font-size: 14px;
  }
`;

const paginatorCss = css`
  min-width: unset;
  ${InputInput} {
    text-align: center;
    ${mediaDesktop} {
      padding: 0 5px;
      height: 40px;
      font-size: 14px;
      width: 60px;
    }  
  }
`;

const filterCss = css`
  min-width: unset;
  ${InputInput} {
    color: #fff;
    background: ${theme.colors.dark100};
    border: none;
    font-size: 16px;
    ${mediaDesktop} {
      padding: 8px 10px;
      height: 40px;
      width: 100%;
    }   
  }
  ${InputLabel} {
    font-family: ${theme.fonts.primary};
    font-size: 16px;
    letter-spacing: normal;
    ${mediaDesktop} {
      color: #fff;
      letter-spacing: normal;
      margin-bottom: 7px;
    }
  }
`;

export const InputWrapper = styled.div<{ $block: boolean, isPaginator?: boolean, isFilter?: boolean }>`
  display: ${({ $block }) => ($block ? 'flex' : 'inline-flex')};
  width: ${({ $block }) => ($block ? '100%' : 'auto')};
  flex-direction: column;
  min-width: 200px;
  ${({ isFilter }) => (isFilter ? filterCss : '')}
  ${({ isPaginator }) => (isPaginator ? paginatorCss : '')}
`;
