import { createReducer } from '@reduxjs/toolkit';
import type {
  FlowFestCard,
  FlowFestPack,
  FlowFestProject,
} from '@starly/starly-types';
import { getQRCodeUrl } from 'helpers/getQrCode';

import type { ExtendedFFCard } from './flowFestActions';
import {
  buyFFCardFailure,
  buyFFCardRequest,
  buyFFCardResponse,
  ffOpenPackFailure,
  ffOpenPackRequest,
  ffOpenPackResponse,
  ffReservePackFailure,
  ffReservePackRequest,
  ffReservePackResponse,
  ffShowPackFailure,
  ffShowPackRequest,
  ffShowPackResponse,
  ffValidateCaptchaFailure,
  ffValidateCaptchaRequest,
  ffValidateCaptchaResponse,
  flowFestCheckWalletFailure,
  flowFestCheckWalletRequest,
  flowFestCheckWalletResponse,
  flowFestProjectResponse,
  getFlowFestPackStateFailure,
  getFlowFestPackStateRequest,
  getFlowFestPackStateResponse,
  loadFFCardsFailure,
  loadFFCardsRequest,
  loadFFCardsResponse,
  loadFFProjectsFailure,
  loadFFProjectsRequest,
  loadFFProjectsResponse,
  loadMyFFCardsFailure,
  loadMyFFCardsRequest,
  loadMyFFCardsResponse,
  redeemCodeFailure,
  redeemCodeRequest,
  redeemCodeReset,
  redeemCodeResponse,
  removeFromSaleFFCardFailure,
  removeFromSaleFFCardRequest,
  removeFromSaleFFCardResponse,
  resetFFCards,
  resetFlowFestState,
  resetIsFFCardPurchased,
  saleFFCardFailure,
  saleFFCardRequest,
  saleFFCardResponse,
  setIsFFCardsLoading,
  setIsMoreFFCardsLoading,
} from './flowFestActions';

interface FlowFestSlice {
  projects: {
    [key: string]: FlowFestProject;
  }
  packs: FlowFestPack[]
  cardsToOpen: FlowFestCard[]
  isValidateCaptchaLoading: boolean
  isValidateCaptchaLoaded: boolean
  validateCaptchaError: string
  isLoading: boolean
  isMoreLoading: boolean
  isLoad: boolean
  isRedeemCodeLoaded: boolean
  isRedeemCodeLoading: boolean
  redeemCodeError: string
  isReserveLoaded: boolean
  isReserveLoading: boolean
  reserveError: string
  isShowPackLoading: boolean
  isShowPackLoaded: boolean
  openPackLoading: boolean
  openPackLoaded: boolean
  openPackTime: string
  openedCount: number
  stateLoading: boolean
  stateLoaded: boolean
  packViewed: boolean
  isFFCheckLoading: boolean
  isFFCheck: boolean
  state: string
  allProjects: FlowFestProject[]
  isProjectsLoading: boolean
  isCardPurchased: boolean
  cards: ExtendedFFCard[]
  isLastCards: boolean
  myCards: FlowFestCard[]
  isMyCardsLoading: boolean
  isCardSelling: boolean
  isCardRemovingFromSale: boolean
  error: string
}

const initialState: FlowFestSlice = {
  cardsToOpen: [],
  isShowPackLoaded: false,
  isShowPackLoading: false,
  isValidateCaptchaLoaded: false,
  isValidateCaptchaLoading: false,
  validateCaptchaError: '',
  projects: {},
  isLoading: false,
  isMoreLoading: false,
  isLoad: false,
  packs: [],
  isRedeemCodeLoaded: false,
  isRedeemCodeLoading: false,
  redeemCodeError: '',
  isReserveLoaded: false,
  isReserveLoading: false,
  reserveError: '',
  openPackLoading: false,
  openPackLoaded: false,
  openPackTime: '',
  openedCount: 0,
  stateLoading: false,
  stateLoaded: false,
  packViewed: false,
  isFFCheckLoading: false,
  isFFCheck: false,
  state: '',
  allProjects: [],
  isProjectsLoading: false,
  isCardPurchased: false,
  cards: [],
  isLastCards: false,
  myCards: [],
  isCardSelling: false,
  isMyCardsLoading: false,
  isCardRemovingFromSale: false,
  error: '',
};

const flowFestReducer = createReducer(
  initialState,
  (builder) => {
    builder
      .addCase(resetFlowFestState, (state) => {
        Object.keys(initialState).forEach((key) => {
          const ignoredKeys = ['isMyCardsLoading'];

          if (!ignoredKeys.includes(key)) {
            state[key] = initialState[key];
          }
        });
      })
      .addCase(flowFestCheckWalletRequest, (state) => {
        state.isFFCheckLoading = true;
      })
      .addCase(flowFestCheckWalletResponse, (state, { payload }) => {
        state.isFFCheckLoading = false;
        state.isFFCheck = payload;
      })
      .addCase(flowFestCheckWalletFailure, (state) => {
        state.isFFCheckLoading = false;
        state.isFFCheck = false;
      })
      .addCase(flowFestProjectResponse, (state, { payload: { project } }) => {
        if (project.id) {
          state.projects[project.id] = project;
        }
      })
      .addCase(redeemCodeRequest, (state) => {
        state.isRedeemCodeLoading = true;
        state.isRedeemCodeLoaded = false;
        state.redeemCodeError = '';
      })
      .addCase(redeemCodeResponse, (state) => {
        state.isRedeemCodeLoading = false;
        state.isRedeemCodeLoaded = true;
        state.redeemCodeError = '';
      })
      .addCase(redeemCodeFailure, (state, { payload: { error } }) => {
        state.isRedeemCodeLoading = false;
        state.isRedeemCodeLoaded = true;
        state.redeemCodeError = error;
      })
      .addCase(redeemCodeReset, (state) => {
        state.isRedeemCodeLoaded = false;
        state.redeemCodeError = '';
      })
      .addCase(ffReservePackRequest, (state) => {
        state.isReserveLoading = true;
        state.isReserveLoaded = false;
        state.reserveError = '';
      })
      .addCase(ffReservePackResponse, (state) => {
        state.isReserveLoading = false;
        state.isReserveLoaded = true;
        state.reserveError = '';
      })
      .addCase(ffReservePackFailure, (state, { payload: { error } }) => {
        state.isReserveLoading = false;
        state.isReserveLoaded = true;
        state.reserveError = error;
      })
      .addCase(ffShowPackRequest, (state) => {
        state.isShowPackLoading = true;
        state.isShowPackLoaded = false;
      })
      .addCase(ffShowPackResponse, (state, { payload: { cards, packs } }) => {
        state.isShowPackLoading = false;
        state.isShowPackLoaded = true;
        state.cardsToOpen = cards.map((card) => ({
          ...card,
          data: card,
          creator: {
            name: 'FLOW FEST',
          },
        }));
        state.packs = packs;
      })
      .addCase(ffShowPackFailure, (state) => {
        state.isShowPackLoading = false;
        state.isShowPackLoaded = true;
      })
      .addCase(getFlowFestPackStateRequest, (state) => {
        state.stateLoading = true;
        state.stateLoaded = false;
      })
      .addCase(getFlowFestPackStateResponse, (state, {
        payload: {
          state: packState, availability_time, viewed, opened_count,
        },
      }) => {
        state.stateLoading = false;
        state.stateLoaded = true;
        state.state = packState;
        state.packViewed = viewed;
        state.openPackTime = availability_time;
        state.openedCount = opened_count;
      })
      .addCase(getFlowFestPackStateFailure, (state, { payload: { error } }) => {
        state.stateLoading = false;
        state.stateLoaded = true;
        state.state = error;
        state.openPackTime = '';
      })
      .addCase(ffValidateCaptchaRequest, (state) => {
        state.isValidateCaptchaLoading = true;
        state.isValidateCaptchaLoaded = false;
        state.validateCaptchaError = '';
      })
      .addCase(ffValidateCaptchaResponse, (state, { payload: { availability_time } }) => {
        state.isValidateCaptchaLoading = false;
        state.isValidateCaptchaLoaded = true;
        state.reserveError = '';
        state.openPackTime = availability_time;
      })
      .addCase(ffValidateCaptchaFailure, (state, { payload: { error } }) => {
        state.isValidateCaptchaLoading = false;
        state.isValidateCaptchaLoaded = true;
        state.reserveError = error;
        state.openPackTime = '';
      })
      .addCase(ffOpenPackRequest, (state) => {
        state.openPackLoading = true;
        state.openPackLoaded = false;
      })
      .addCase(ffOpenPackFailure, (state) => {
        state.openPackLoading = false;
        state.openPackLoaded = true;
      })
      .addCase(ffOpenPackResponse, (state, { payload: { cards, pack } }) => {
        state.openPackLoading = false;
        state.openPackLoaded = true;
        state.cardsToOpen = cards.map((card) => ({
          ...card,
          data: card,
          creator: {
            name: 'FLOW FEST',
          },
        }));
        state.packs = [pack, ...state.packs];
      })
      .addCase(loadFFProjectsRequest, (state) => {
        state.isProjectsLoading = true;
      })
      .addCase(loadFFProjectsResponse, (state, { payload: { projects } }) => {
        state.isProjectsLoading = false;
        state.allProjects = projects;
      })
      .addCase(loadFFProjectsFailure, (state, { payload: { error } }) => {
        state.isProjectsLoading = false;
        state.error = error;
      })
      .addCase(setIsFFCardsLoading, (state, { payload }) => {
        state.isLoading = payload;
      })
      .addCase(setIsMoreFFCardsLoading, (state, { payload }) => {
        state.isMoreLoading = payload;
      })
      .addCase(loadFFCardsRequest, (state) => {
        state.isLastCards = false;
      })
      .addCase(loadFFCardsResponse, (state, { payload: { cards, isLastCards } }) => {
        state.isLoading = false;
        state.isLastCards = isLastCards;
        state.isMoreLoading = false;
        state.cards = [...state.cards, ...cards].map(
          (card: ExtendedFFCard) => ({
            ...card,
            qrcode: getQRCodeUrl(card.id),
            creator: { name: 'Flow Fest' },
          }),
        );
      })
      .addCase(loadFFCardsFailure, (state, { payload: { error } }) => {
        state.isLoading = false;
        state.isLastCards = false;
        state.isMoreLoading = false;
        state.error = error;
      })
      .addCase(resetFFCards, (state) => {
        state.cards = [];
      })
      .addCase(buyFFCardRequest, (state, { payload: { listingResourceID } }) => {
        state.isCardPurchased = false;
        state.cards = state.cards.map(
          (card) => (card.listingResourceID === listingResourceID
            ? { ...card, isBuying: true }
            : { ...card }),
        );
      })
      .addCase(buyFFCardResponse, (state, { payload: { listingResourceID } }) => {
        state.isCardPurchased = true;
        state.cards = state.cards.map(
          (card) => (card.listingResourceID === listingResourceID
            ? { ...card, isBuying: false }
            : { ...card }),
        );
      })
      .addCase(buyFFCardFailure, (state, { payload: { error = '', listingResourceID } }) => {
        state.error = error;
        state.isCardPurchased = false;
        state.cards = state.cards.map(
          (card) => (card.listingResourceID === listingResourceID
            ? { ...card, isBuying: false }
            : { ...card }),
        );
      })
      .addCase(resetIsFFCardPurchased, (state) => {
        state.isCardPurchased = false;
      })
      .addCase(loadMyFFCardsRequest, (state) => {
        state.isMyCardsLoading = true;
      })
      .addCase(loadMyFFCardsResponse, (state, { payload: { cards } }) => {
        state.isMyCardsLoading = false;
        state.myCards = cards.map(
          (card) => ({
            ...card,
            qrcode: getQRCodeUrl(card.id),
            creator: { name: 'Flow Fest' },
          }),
        );
      })
      .addCase(loadMyFFCardsFailure, (state) => {
        state.isMyCardsLoading = false;
      })
      .addCase(saleFFCardRequest, (state) => {
        state.isCardSelling = true;
      })
      .addCase(saleFFCardResponse, (state) => {
        state.isCardSelling = false;
      })
      .addCase(saleFFCardFailure, (state) => {
        state.isCardSelling = false;
      })
      .addCase(removeFromSaleFFCardRequest, (state) => {
        state.isCardRemovingFromSale = true;
      })
      .addCase(removeFromSaleFFCardResponse, (state) => {
        state.isCardRemovingFromSale = false;
      })
      .addCase(removeFromSaleFFCardFailure, (state) => {
        state.isCardRemovingFromSale = false;
      });
  },
);

export default flowFestReducer;
