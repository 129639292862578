import { type FC, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import styled, { css } from 'styled-components';

import { mediaDesktop } from 'breakpoints';
import closeIcon from 'static/ic-close.svg';
import logo from 'static/starly-transparent-logo.svg';
import { selectAuthAuthorized } from 'store/auth/authSelectors';
import { selectSideMenuItems } from 'store/sideMenu/sideMenuSelectors';

import MenuItem from './MenuItem/MenuItem';

const Portal: FC = ({ children }) => {
  const domElement = document.querySelector('#side-root');
  if (!domElement) {
    return <div>{children}</div>;
  }
  return ReactDOM.createPortal(children, domElement);
};

const SideMenuContainer = styled.div<{ $isOpen?: boolean }>`
  z-index: 10000;
  position: fixed;
  overflow: hidden;
  top: 0;
  right: 0;
  left: 0;
  height: 120vh;

  overflow-y: auto;
  width: 100%;
  max-height: 100vh;
  transition: max-height 0.15s ease-in-out;
  will-change: max-height;

  max-height: 0;

  ${({ $isOpen }) => $isOpen
    && css`
      max-height: 100vh;
      transition: max-height 0.5s ease-in-out;
    `};
  background-color: ${({ theme }) => theme.colors.black};

  ${mediaDesktop} {
    display: none;
  }
`;

const MenuLogo = styled.img`
  width: 89px;
  height: 22px;
  padding-top: 1px;
`;

const MenuList = styled.ul`
  list-style: none;
  text-align: center;
  padding-bottom: 80px;
`;

const MenuCloseIcon = styled.img`
  width: 50px;
  height: 51px;
  padding: 17px;
  margin: -17px;
`;

const MenuHeaderContainer = styled.div`
  background: #000;
  display: flex;
  padding: 0 20px 0 16px;
  height: 50px;
  align-items: center;
  margin-bottom: 40px;
  justify-content: space-between;
`;

interface SideMenuProps {
  isOpen: boolean,
  setIsOpen: (isOpen: boolean) => void,
}

const SideMenu: FC<SideMenuProps> = ({ isOpen, setIsOpen }) => {
  const { pathname, key } = useLocation();
  const { t } = useTranslation();
  const menuData = useSelector(selectSideMenuItems);
  const isAuthorized = useSelector(selectAuthAuthorized);
  useEffect(() => {
    document.body.style.overflow = isOpen ? 'hidden' : 'initial';
    return () => {
      document.body.style.overflow = 'initial';
    };
  }, [isOpen]);

  const onSideMenuToggle = (open: boolean) => {
    setIsOpen(open);
  };

  useEffect(() => {
    onSideMenuToggle(false);
  }, [pathname, key, isAuthorized]);

  return (
    <Portal>
      <SideMenuContainer $isOpen={isOpen}>
        <MenuHeaderContainer>
          <MenuLogo src={logo} alt={t('common.starlyLogo')} />
          <MenuCloseIcon src={closeIcon} onClick={() => onSideMenuToggle(false)} alt="close" />
        </MenuHeaderContainer>
        <MenuList>
          {menuData.map((el) => (el.text === t('header.create') ? (
            <MenuItem key={String(t('header.createCollection'))} {...el} text={t('header.createCollection')} />
          ) : (
            <MenuItem key={el.text} {...el} />
          )))}
        </MenuList>
      </SideMenuContainer>
    </Portal>
  );
};

export default SideMenu;
