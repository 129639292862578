import { type FC, useEffect } from 'react';
import { createPortal } from 'react-dom';

import closeIcon from '../../static/ic-close-grey.svg';
import {
  PopUpClose, PopUpOverlay, PopUpPortalWrapper, PopUpText,
} from './styled';

import type { PopUpPortalProps } from './types';

const PopUpPortal: FC<PopUpPortalProps> = ({
  children, style, text, onHide, open,
}) => {
  if (typeof window === undefined) {
    return null;
  }

  if (!document.getElementById('popup-root')) {
    const portalRoot = document.createElement('div');
    portalRoot.id = 'popup-root';
    document.body.appendChild(portalRoot);
  }

  // Prevent scroll when PopUp open
  useEffect(() => {
    if (open) {
      document.body.style.overflow = 'hidden';
      return () => { document.body.style.overflow = 'unset'; };
    }
    return () => {};
  }, [open]);

  return createPortal(
    open && (
      <PopUpOverlay style={{ zIndex: 9999, ...style }}>
        <PopUpPortalWrapper>
          {!!text && <PopUpText>{text}</PopUpText>}
          {children}
          <PopUpClose
            data-test-id="close_icon"
            onClick={onHide}
          >
            <img src={closeIcon} alt="close" />
          </PopUpClose>

        </PopUpPortalWrapper>
      </PopUpOverlay>
    ),
    document.getElementById('popup-root')!,
  );
};

export default PopUpPortal;
