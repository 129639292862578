import {
  type ChangeEvent, type FC, type MouseEventHandler, useEffect, useRef, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { type UseFormRegister } from 'react-hook-form';

import Typography from 'components/Typography';
import { setActiveCard } from 'store/app/appActions';
import { selectActiveCard } from 'store/app/appSelectors';
import { writeCardImageRequest, type CreateCardRarity } from 'store/collection/collectionActions';
import { type RootState } from 'store/store';
import { getDiffByRarity } from 'util/rarity';

import { CARD_MEDIA_MAX_SIZE_BYTES, isSupportedMediaFormat } from '../../util/supportedMedia';
import MainMedia from './components/MainMedia';
import {
  StyledCardBackground,
  StyledCardContainer,
  StyledCardFixedWrapper,
  StyledCardInfoContainer,
  StyledContent,
  StyledContentText,
  StyledHeader,
  StyledImage,
  StyledImageLoadContainer,
  StyledQRCode,
  StyledQRCodeContainer,
  StyledTypeContainer,
} from './styled';
import { type CardProps } from './types';

const CreateCard: FC<{
  card: Omit<CardProps, | 'rarity'> & { rarity: CreateCardRarity };
  total: number
  edition?: any,
  errors: any,
  isVideo: boolean,
  uploadProcess: number,
  isUpload: boolean,
  mediaType: string,
  currMedia: string,
  colId: string
  getValues: (data?: string) => any
  mediaState: string,
  register: UseFormRegister<any>,
  onMediaError: () => void,
}> = ({
  errors,
  card: {
    title,
    edition,
    rarity,
    cardRarity = 'common',
    author,
    collection,
    totalEditions,
    qrCodeUrl,
  },
  uploadProcess,
  isUpload,
  mediaType,
  currMedia,
  colId,
  mediaState,
  total,
  getValues,
  register,
  onMediaError,
}) => {
  const id = `create-card-${total}`;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const userId = useSelector((state: RootState) => (state.auth?.userId || ''));
  const cardInfo = useSelector((currState: RootState) => currState.collections.activeCollection.cardInfo);

  const activeCard = useSelector(selectActiveCard);
  const isActiveCard = activeCard === `${colId}/${id}/${rarity}`;

  const [isMuted, setIsMuted] = useState(!isActiveCard);
  const switchMuted: MouseEventHandler<HTMLDivElement> = (e) => {
    setIsMuted(!isMuted);
    e.preventDefault();

    if (colId) dispatch(setActiveCard(isMuted ? `${colId}/${id}/${rarity}` : null));
  };
  const loadPhoto = (e: ChangeEvent<HTMLInputElement>) => {
    const { files } = e.target;
    if (files?.[0] && files[0]) {
      if (!isSupportedMediaFormat(files[0]) || files[0].size >= CARD_MEDIA_MAX_SIZE_BYTES) {
        onMediaError();
        return;
      }
      const cardId = (total ?? 1) + getDiffByRarity(rarity, cardInfo);
      dispatch(writeCardImageRequest({
        colId,
        userId,
        file: files[0],
        cardId,
        values: getValues(),
      }));
      if (register) register(`image${total}`).onChange(e);
    }
  };

  const cardContainer = useRef<HTMLDivElement>(null);

  const [adaptiveStyles, setAdaptiveStyles] = useState({
    fonts: {
      header: 8,
      title: 24,
      cardNumber: 24,
      cardCount: 13,
    },
    height: {
      title: 98,
      header: 22,
    },
  });

  const onResize = () => {
    if (cardContainer.current) {
      const { width, height } = cardContainer.current.getBoundingClientRect();

      setAdaptiveStyles({
        fonts: {
          header: width / 64,
          title: width / 21,
          cardNumber: width / 21,
          cardCount: width / 39,
        },
        height: {
          title: height / 10.5,
          header: height / 33,
        },
      });
    }
  };

  useEffect(() => {
    setIsMuted(!isActiveCard);
  }, [activeCard]);

  useEffect(() => {
    onResize();
    window.addEventListener('resize', onResize);

    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, []);

  return (
    <StyledCardFixedWrapper
      as="div"
      isCreateCard
      className="starly-card"
    >
      <StyledCardBackground
        rarity={cardRarity}
        ref={cardContainer}
      >
        <StyledCardContainer>
          <StyledImageLoadContainer
            isUpload={isUpload}
            currMedia={currMedia}
            onClick={(e) => e.stopPropagation()}
          >
            <MainMedia
              errors={errors}
              total={total}
              isUpload={isUpload}
              uploadProcess={uploadProcess}
              currMedia={currMedia}
              id={id}
              switchMuted={switchMuted}
              isMuted={isMuted}
              mediaState={mediaState}
              mediaType={mediaType}
            />
            <StyledImage {...register(`image${total}`)} type="file" onChange={loadPhoto} id={id} name={`image${total}`} />
          </StyledImageLoadContainer>
          <StyledCardInfoContainer>
            <StyledHeader
              style={{
                lineHeight: `${adaptiveStyles.height.header}px`,
              }}
            >
              <Typography
                lSpacing={0.8}
                lineHeight="normal"
                uppercase
                font="spaceMono"
                textColor="white"
                style={{
                  fontSize: `${adaptiveStyles.fonts.header}px`,
                  display: 'inline-block',
                  verticalAlign: 'middle',
                  lineHeight: 'normal',
                }}
              >
                {`${author} // `}
                <span style={{ fontWeight: 'bold' }}>{collection}</span>
              </Typography>
            </StyledHeader>
            <StyledContent>
              <StyledContentText
                lSpacing={-0.75}
                bold
                uppercase
                style={{
                  maxHeight: `${adaptiveStyles.height.title}px`,
                  fontSize: `${adaptiveStyles.fonts.title}px`,
                }}
              >
                {title || t('steps.two.card.titleNa')}
              </StyledContentText>
              <StyledQRCodeContainer>
                <StyledQRCode src={qrCodeUrl} />
              </StyledQRCodeContainer>
            </StyledContent>
            <StyledTypeContainer edition={edition}>
              <Typography
                lSpacing={0.5}
                font="syncopate"
                textColor="white"
                style={{
                  fontSize: `${edition ? adaptiveStyles.fonts.cardNumber : adaptiveStyles.fonts.cardCount}px`,
                  marginRight: `${edition ? '1px' : '0px'}`,
                  color: edition ? 'white' : '#aaaaaa',
                }}
                bold
              >
                {edition ? `#${edition}` : '#'}
              </Typography>
              <Typography
                lSpacing={-0.75}
                font="syncopate"
                style={{ fontSize: `${adaptiveStyles.fonts.cardCount}px`, color: '#aaaaaa' }}
                bold
              >
                /
              </Typography>
              <Typography
                lSpacing={-0.75}
                font="syncopate"
                style={{ fontSize: `${adaptiveStyles.fonts.cardCount}px`, color: '#aaaaaa' }}
                bold
              >
                {totalEditions}
              </Typography>
            </StyledTypeContainer>
          </StyledCardInfoContainer>
        </StyledCardContainer>
      </StyledCardBackground>
    </StyledCardFixedWrapper>
  );
};

export default CreateCard;
