import styled, { css } from 'styled-components';
import theme from '../theme';
import { mediaDesktop } from '../../breakpoints';

const fixedPopup = css`
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 100;

  animation: fadeout 0.3s forwards;
  @keyframes fadeout {
    to {
      background-color: rgba(0, 0, 0, .8);
    }
  }
`;
export const ContentPopUpWrapper = styled.div<{ $isMobileFixed?: boolean }>`

  ${({ $isMobileFixed }) => ($isMobileFixed ? fixedPopup : (`
    flex: 1;
    display: flex;
    flex-direction: column;
  `))}

  ${mediaDesktop} {
    ${fixedPopup};
    display: grid;
    place-content: center;
  }
`;

export const CloseBtn = styled.img`
  cursor: pointer;
  object-fit: contain;
  justify-self: right;
  user-select: none;
  position: absolute;
  top: 16px;
  right: 24px;
  padding: 15px;
  margin: -15px;
  z-index: 1;
  ${mediaDesktop} {
    position: static;
  }
`;

export const PopUpContainer = styled.div`
  background: ${theme.colors.dark};
  color: #fff;
  position: relative;
  height: 100%;
  flex: 1;
  display: grid;
  align-content: start;
  
  ${mediaDesktop} {
    width: 976px;
    max-height: 760px;
    padding: 32px 32px 0;
    border: 1px solid ${theme.colors.lightGray};
  }
`;
