import { createSelector } from '@reduxjs/toolkit';
import { type RootState } from 'store/store';

const selectVesting = ({ vesting }: RootState) => vesting;

export const selectVestingWallet = createSelector(selectVesting, ({ wallet }) => wallet);

export const selectVestingBalance = createSelector(selectVesting, ({ balance }) => balance);

export const selectVestingRewardBalance = createSelector(selectVesting,
  ({ rewardBalance }) => rewardBalance);

export const selectVestingCurrentTime = createSelector(
  selectVesting, ({ currentTime }) => currentTime,
);

export const selectVestingWalletIsLoading = (walletId: string) => createSelector(selectVesting,
  ({ walletsInProgress }) => walletsInProgress[walletId]);

export const selectVestingModal = createSelector(selectVesting, ({ modal }) => modal);

export const selectAllVestingHistory = createSelector(
  selectVesting, ({ history }) => history,
);

export const selectVestingHistoryByPage = (page: number) => createSelector(
  selectAllVestingHistory, ({ transactions }) => transactions.slice(0, 30 * (page + 1)),
);

export const selectVestingIsLoading = createSelector(selectVesting,
  ({ isLoading }) => isLoading);

export const selectVestingRewardIsLoading = createSelector(selectVesting,
  ({ isRewardLoading }) => isRewardLoading);
