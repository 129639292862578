export const isIos = () => {
  const userAgent = window.navigator.userAgent.toLowerCase();
  return /iphone|ipad|ipod|mac os/.test(userAgent);
};

export const isIosMobile = () => {
  const userAgent = window.navigator.userAgent.toLowerCase();
  return /iphone|ipad|ipod/.test(userAgent);
};

export const isMiBrowser = () => /miui/ig.test(navigator.userAgent);
