import * as fcl from '@blocto/fcl';
import * as t from '@onflow/types';

const CODE = fcl.cdc`
import BlindBoxRedeemVoucher from 0xBLIND_BOX_REDEEM_VOUCHER_ADDRESS
import NonFungibleToken from 0xNON_FUNGIBLE_TOKEN_ADDRESS

pub fun main(address: Address): {String: [UInt64]} {
    let account = getAccount(address)
    let ret: {String: [UInt64]} = {}

    if (account.getCapability<&{BlindBoxRedeemVoucher.CollectionPublic}>(BlindBoxRedeemVoucher.CollectionPublicPath).check()) {
        let collectionRef = account.getCapability(BlindBoxRedeemVoucher.CollectionPublicPath)!.borrow<&{BlindBoxRedeemVoucher.CollectionPublic}>()
            ?? panic("Could not borrow Beam receiver reference")
        ret["blindbox"] = beamCollectionRef.getIDs();
    }

    return ret;
}`;

export function flowFetchNFTIDsScript(address: string) {
  if (address == null) return Promise.resolve(false);

  return fcl.send([
    fcl.script(CODE),
    fcl.args([
      fcl.arg(address, t.Address),
    ]),
  ]).then(fcl.decode);
}
