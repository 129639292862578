import { type FC } from 'react';
import { Spinner, SpinnerContainer } from './styled';

interface NotificationsLoaderProps {
  isBlack?: boolean,
  inline?: boolean,
  small?: boolean,
  transparent?: boolean,
  className?: string,
}

const NotificationsLoader: FC<NotificationsLoaderProps> = ({
  isBlack,
  inline,
  small,
  transparent,
  className,
}) => (
  <SpinnerContainer $isWhite={isBlack && !transparent} className={className}>
    <Spinner data-test-id="spinner_loader" $isBlack={isBlack} $inline={inline} $small={small}>
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
    </Spinner>
  </SpinnerContainer>
);

export default NotificationsLoader;
