import type { FC } from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import type { FungibleTokenType } from '@starly/starly-types';

import { desktopBreakpoint } from 'breakpoints';
import { fetchFusdToFlowAmount } from 'flow/fetchFusdToFlowAmount.script';
import { useWindowSize } from 'hooks/useWindowSize';
import { selectAuthAuthorized } from 'store/auth/authSelectors';
import { flowAcceptSaleOfferRequest, flowLowBalanceToggleModal, flowToggleModal } from 'store/flow/flowActions';
import {
  selectFlowBalance, selectFlowWalletAddr, selectFUSDBalance, selectUsdcBalance,
} from 'store/flow/flowSelectors';
import { setAuthRedirect, setLoginModal } from 'store/login/loginActions';
import {
  setMarketplaceCardPageUrlByNftId,
  setMarketplaceCardPurchaseState,
  setMarketplacePurchasingState,
} from 'store/marketplace/marketplaceAction';
import { selectMarketplaceCardsState, selectMarketplacePurchasingState } from 'store/marketplace/marketplaceSelectors';
import SelectWalletModal from 'views/Modals/WalletModal/components/SelectWalletModal/SelectWalletModal';

import { CardsContainer } from '../../../styled';
import MarketplaceCard from '../../MarketplaceCard/MarketplaceCard';

const MarketplaceCardsList: FC = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const isMobile = useWindowSize() < desktopBreakpoint;

  const { cards } = useSelector(selectMarketplaceCardsState);

  const { isPurchasing } = useSelector(selectMarketplacePurchasingState);

  const isAuthorized = useSelector(selectAuthAuthorized);
  const addr = useSelector(selectFlowWalletAddr);
  const usdcBalance = useSelector(selectUsdcBalance) || 0;
  const fusdBalance = useSelector(selectFUSDBalance) || 0;
  const flowBalance = useSelector(selectFlowBalance) || 0;
  const [isWalletModalOpen, setIsWalletModalOpen] = useState(false);
  const [cardInfo, setCardInfo] = useState({
    nftItemId: 0,
    price: 0,
    marketCollectionAddress: '',
  });

  const onBuyCard = async (paymentCurrency: FungibleTokenType) => {
    setIsWalletModalOpen(false);
    if (paymentCurrency === 'USDC' && Number(usdcBalance) < cardInfo.price) {
      return dispatch(flowLowBalanceToggleModal({ isOpen: true, currency: 'USDC' }));
    }
    if (paymentCurrency === 'FUSD' && Number(fusdBalance) < cardInfo.price) {
      return dispatch(flowLowBalanceToggleModal({ isOpen: true }));
    }
    if (paymentCurrency === 'FLOW') {
      const priceInFlow = await fetchFusdToFlowAmount(cardInfo.price);
      if (Number(flowBalance) < priceInFlow) {
        return dispatch(flowLowBalanceToggleModal({ isOpen: true, currency: 'FLOW' }));
      }
    }
    if (isPurchasing) {
      return dispatch(setMarketplacePurchasingState({ state: 'warning' }));
    }
    dispatch(setMarketplacePurchasingState({ isPurchasing: true }));
    dispatch(setMarketplaceCardPageUrlByNftId({ nftItemId: cardInfo.nftItemId }));
    dispatch(
      setMarketplaceCardPurchaseState({
        nftItemId: cardInfo.nftItemId,
        inProgress: true,
      }),
    );
    return dispatch(
      flowAcceptSaleOfferRequest({
        marketCollectionAddress: cardInfo.marketCollectionAddress,
        isMarketplace: true,
        nftItemId: cardInfo.nftItemId,
        paymentCurrency,
      }),
    );
  };

  const onBuyClick = (nftItemId: number, marketCollectionAddress: string, price: number) => {
    // TODO: Gets repeated loads of times
    if (isAuthorized) {
      if (!addr) {
        return dispatch(flowToggleModal(true));
      }
    } else {
      dispatch(setLoginModal({ isOpen: true }));
      return dispatch(setAuthRedirect({ onAuthRedirect: pathname }));
    }

    setCardInfo({ nftItemId, marketCollectionAddress, price });
    return setIsWalletModalOpen(true);
  };

  return (
    <>
      {!!cards.length && (
        <CardsContainer>
          {cards.map((cardProps) => (
            <MarketplaceCard
              data-test-id="mp_card_container"
              key={cardProps.nftItemId}
              {...cardProps}
              currentWalletAddress={addr}
              soundControl={!isMobile}
              onBuyClick={onBuyClick}
            />
          ))}
        </CardsContainer>
      )}
      {isWalletModalOpen && <SelectWalletModal closeWalletModal={() => setIsWalletModalOpen(false)} onBuy={onBuyCard} />}
    </>
  );
};

export default MarketplaceCardsList;
