import { ethers } from 'ethers';
import { ethConfig } from 'util/constants';

import * as injectedProviders from './injectedProviders';

interface IProviderDisplay {
  name: string;
  logo: string;
}

export interface IProviderInfo extends IProviderDisplay {
  check: string;
}

const ConnectToInjected = async () => {
  let provider: ethers.Eip1193Provider;
  if (typeof window.ethereum !== 'undefined') {
    provider = window.ethereum;
    try {
      await provider.request?.({ method: 'eth_requestAccounts' });
    } catch (error) {
      throw new Error('User Rejected');
    }
  } else if (window.web3) {
    provider = window.web3.currentProvider;
  } else if (window.celo) {
    provider = window.celo;
  } else {
    throw new Error('No Web3 Provider found');
  }
  return provider;
};

export default class Wallet {
  static ethersProvider: ethers.BrowserProvider;

  static provider: ethers.Eip1193Provider;

  static providerId: string;

  static verifyInjectedProvider(check: string): boolean {
    return window.ethereum
      ? window.ethereum[check]
      : window.web3?.currentProvider?.[check];
  }

  static checkInjected(): IProviderInfo[] {
    const result = {};
    if (!!window.ethereum || !!window.web3) {
      let fallbackProvider = true;
      Object.values(injectedProviders).forEach((provider) => {
        const isAvailable = Wallet.verifyInjectedProvider(provider.check);
        if (isAvailable) {
          result[provider.check] = {
            name: provider.name,
            logo: provider.logo ?? injectedProviders.FALLBACK.logo,
            check: provider.check,
          };
          fallbackProvider = false;
        }
      });

      // const browser = env.detect();

      // if (browser && browser.name === 'opera') {
      //   result[injectedProviders.OPERA.check] = true;
      //   fallbackProvider = false;
      // }

      if (fallbackProvider) {
        result[injectedProviders.FALLBACK.check] = true;
      }
    }

    return Object.values(result);
  }

  static getProviderInfoByCheck(check: string): IProviderInfo {
    const result = Object.values(injectedProviders).filter((provider) => provider.check === check);
    return {
      name: result[0].name ?? injectedProviders.FALLBACK.name,
      logo: result[0].logo ?? injectedProviders.FALLBACK.logo!,
      check: result[0].check ?? injectedProviders.FALLBACK.check,
    };
  }

  static async requestToSwitchChains(loginCallback?: () => void) {
    try {
      await Wallet.provider.request?.({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: ethers.toBeHex(ethConfig.chain.id) }],
      });
      loginCallback?.();
    } catch (err: any) {
      if (err.code === 4902) {
        await Wallet.provider.request?.({
          method: 'wallet_addEthereumChain',
          params: [{
            chainId: ethers.toBeHex(ethConfig.chain.id),
            chainName: ethConfig.chain.name,
            nativeCurrency: {
              symbol: ethConfig.chain.symbol,
              decimals: 18,
            },
            rpcUrls: [ethConfig.chain.rpcUrl],
            blockExplorerUrls: [ethConfig.chain.blockExplorerUrl],
          }],
        });
      }
    }
  }

  static getProvidersInfo(): IProviderInfo[] {
    // const providers: (IProviderInfo | null)[] = [];
    const providers = Wallet.checkInjected();
    providers.concat(providers);

    return providers.filter(<T>(provider: T | null): provider is T => provider !== null);
  }

  static async connectTo(id: string) {
    try {
      const provider = await ConnectToInjected();
      Wallet.provider = provider;
      Wallet.providerId = id;
      const web3Provider = new ethers.BrowserProvider(provider);
      // const jsonProvider = new ethers.providers.JsonRpcBatchProvider(provider);
      Wallet.ethersProvider = web3Provider;

      return provider;
    } catch (err) {
      return null;
    }
  }

  static subscribeProvider(
    provider: any,
    onDisconnect: (error: Error) => void,
    onAccountsChanged: (accounts: string[]) => void,
    onChainChanged: () => void,
  ) {
    if (!provider.on) {
      return;
    }
    provider.on('disconnect', onDisconnect);
    provider.on('accountsChanged', onAccountsChanged);
    provider.on('chainChanged', onChainChanged);
  }

  static unsubscribeProvider(provider: any) {
    try {
      provider.removeAllListeners('disconnect');
      provider.removeAllListeners('accountsChanged');
      provider.removeAllListeners('chainChanged');
    } catch (err) {
      window.location.reload();
    }
  }
}
