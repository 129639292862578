import { createSelector } from '@reduxjs/toolkit';
import { type RootState } from 'store/store';

const selectEthereum = ({ ethereum }: RootState) => ethereum;

export const selectEthereumWalletAddr = createSelector(selectEthereum, ({ wallet }) => wallet.address);

export const selectBalance = createSelector(selectEthereum, ({ balance }) => balance);

export const selectEthereumModal = createSelector(
  selectEthereum,
  ({ modal }) => modal,
);

export const selectEthereumIsLoading = createSelector(
  selectEthereum,
  ({ isLoading }) => isLoading,
);
