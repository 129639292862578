import styled from 'styled-components';
import theme from '../theme';
import { mediaDesktop } from '../../breakpoints';

import inst from '../../static/ft-instagram.svg';
import twit from '../../static/ft-twitter.svg';
import tg from '../../static/ft-telegram.svg';
import ds from '../../static/ic-footer-discord.svg';

export const socialIcons = {
  instagram: inst,
  twitter: twit,
  telegram: tg,
  discord: ds,
};

export const FooterContainer = styled.footer`
  border-top: 1px solid #000;
  width: 100%;
  height: 100px;
  flex-shrink: 0;
  display: flex;
  padding: 16px 24px 0;
  justify-content: space-between;
  background: ${theme.colors.dark};
  z-index: 1;

  ${mediaDesktop} {
    padding: 0;
    border-top-width: 2px;
    align-items: center;
    justify-content: space-around;
  }
`;

export const FooterLinksContainer = styled.div`
  display: none;
  ${mediaDesktop} {
    grid-auto-flow: column;
    display: grid;
    grid-gap: 32px;
  }
`;

export const FooterTextSection = styled.div`
  display: grid;
  grid-auto-flow: column;
  ${mediaDesktop} {
    align-items: center;
  }
`;

const FooterText = styled.p`
  font-family: ${theme.fonts.primary};
  font-size: 13px;
  font-weight: 600;
  text-decoration: none;
  color: ${theme.colors.white};
  letter-spacing: .5px;
  ${mediaDesktop} {
    font-size: 16px;
    letter-spacing: 1px;
  }
  &:hover {
    text-decoration: underline;
  }
`;

export const StarlyText = styled(FooterText)`
  margin-top: 4px;
  ${mediaDesktop} {
    margin-top: 0;
    padding-right: 32px;
  }
  &:hover {
    text-decoration: none;
  }
`;

export const StarlyBuildHash = styled.span`
  display: block;
  max-width: 250px;
  line-break: anywhere;
`;

export const ExactLink = styled.a`
  text-decoration: none;
`;

export const FooterIconsContainer = styled.div`
  display: inline-grid;
  grid-auto-flow: column;
  grid-gap: 20px;
  grid-auto-rows: min-content;
  ${mediaDesktop} {
    grid-gap: 32px;
  }
`;

export const FooterIcon = styled.img`
  width: 24px;
  height: 24px;
  opacity: .9;
  ${mediaDesktop} {
    width: auto;
    height: auto;
  }
  &:hover {
    opacity: 1;
  }
`;

export const BuildOnFlow = styled.div`
  background: ${theme.colors.whiteGray};
  height: 36px;
  display: flex;
  width: 197px;
  border-radius: 30px;
  align-items: center;
  justify-content: space-between;
  padding-right: 11px;
  padding-left: 13px;
`;

export const BuildOnFlowText = styled.p`
  font-size: 12px;
  letter-spacing: 1px;
  color: ${theme.colors.dark};
  font-family: ${theme.fonts.syncopate};
  text-transform: uppercase;
  font-weight: bold;
`;

export const BuildOnFlowImage = styled.img`
  width: 22px;
  height: 22px;
`;
