import { createAction } from '@reduxjs/toolkit';

interface CardForSaleRequestPayload {
  collectionId: string,
  collectionCreatorFlowAccount: string,
  cardId: string,
  edition: string,
  nftItemId: number,
  price: number,
  creatorCutPercent: number,
  minterFlowAccount: string,
  mintingCutPercent: number,
}

export const cardForSaleRequest = createAction<CardForSaleRequestPayload>('cardSale/request');

export const cardFromSaleRemoving = createAction('cardSale/remove');

export const cardForSaleError = createAction('cardSale/error');

export const cardForSalePurchaseError = createAction('cardSale/purchaseError');

export const cardForSaleSuccess = createAction('cardForSale/success');

export const cardForSalePurchased = createAction('cardForSale/purchased');

export const cardForSaleReset = createAction('cardForSale/reset');

export const purchasedCardLoad = createAction('cardForSale/purchasedCardLoad');

export const cardSaleSetCardProgress = createAction<{ nftItemId: number, inProgress: boolean }>('cardSale/set-card-progress');
