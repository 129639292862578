import styled from 'styled-components';
import { mediaDesktop } from '../../../../breakpoints';

export const ButtonsContainer = styled.div`
  width: 100%;
  display: grid;
  grid-gap: 15px;
  
  ${mediaDesktop} {
    grid-gap: 20px;
  }
`;
