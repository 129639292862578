import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';
import { mediaDesktop } from '../../breakpoints';
import Typography from '../Typography';

export const NotificationWrapper = styled.div`
  display: grid;
  grid-template-columns: max-content 1fr max-content;
  align-items: center;
  grid-column-gap: 15px;
  grid-row-gap: 5px;
  color: ${({ theme }) => theme.colors.textWhite};

  ${mediaDesktop} {
    grid-template-columns: max-content 270px max-content;
    grid-column-gap: 23px;

    & > ${Typography}:first-of-type {
      align-self: end;
    }
  }
`;

export const Thumbnail = styled(Link)`
  grid-row: 1 / -1;
  grid-column: -2 / -1;
  align-self: start;
  width: 29px;
  height: 42px;
  > * {
    border-radius: 2px;
    width: 29px;
    height: 42px;
    object-fit: cover;
  }
`;

export const NotificationAvatar = styled(Link)`
  grid-row: 1 / -1;
  align-self: start;
  width: 42px;
  height: 42px;
  > * {
    object-fit: cover;
    border-radius: 100%;
    width: 42px;
    height: 42px;
  }
`;

export const InfoContainer = styled.div`
  display: grid;
  line-height: 1.2;
  ${mediaDesktop} {
    line-height: 1.45;
  }
  > * {
    text-decoration: none;
    color: inherit;
  }
`;
