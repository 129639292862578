import { createReducer } from '@reduxjs/toolkit';
import type { MarketplaceSlice, FilterOption } from './marketplaceTypes';
import {
  cardMarketplaceResponse, resetMarketplaceCardState,
  setMarketplaceCardPageUrlByNftId, setMarketplaceCardPurchaseState,
  setMarketplaceFilters, setMarketplacePurchasingState,
  setMarketplaceLoadState,
  setMarketplacePage, collectionMarketplaceResponse,
  setMarketplaceSalesFilters,
  salesMarketplaceResponse, setMarketplaceSalesPage,
  setMarketplaceSalesLoadState, resetMarketplaceSalesState,
} from './marketplaceAction';

export const defaultRarityFilter: FilterOption[] = [
  {
    value: 'common',
    active: true,
  },
  {
    value: 'rare',
    active: true,
  },
  {
    value: 'legendary',
    active: true,
  },
  {
    value: 'common_plus',
    active: true,
  },
  {
    value: 'rare_plus',
    active: true,
  },
  {
    value: 'legendary_plus',
    active: true,
  },
];
export const defaultMarketplaceSortBy = 'create_time desc';
// do not use it
export const defaultMarketplaceCollectionOption = {
  value: '',
  label: 'All collections',
};

const initialState: MarketplaceSlice = {
  salesFilters: {
    rarity: defaultRarityFilter,
    sortBy: defaultMarketplaceSortBy,
    collection: defaultMarketplaceCollectionOption,
  },
  filters: {
    sortBy: defaultMarketplaceSortBy,
    collection: defaultMarketplaceCollectionOption,
    rarity: defaultRarityFilter,
  },
  salesState: {
    page: null,
    saleItems: [],
    isLoad: false,
    isLoading: false,
    isMoreToLoad: true,
  },
  cardState: {
    cards: [],
    isLoad: false,
    isLoading: false,
    isMoreToLoad: true,
    page: null,
    nftToCardMap: {},
  },
  purchasingState: {
    isPurchasing: false,
    state: 'initial',
    currentCardPage: '',
  },
  collections: {
    collections: [],
    isLoad: false,
  },
  scroll: 0,
};

const marketplaceReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(cardMarketplaceResponse, (state, { payload: { cards, isInitial } }) => {
      if (isInitial) {
        state.cardState.cards = cards;
      } else {
        state.cardState.cards.push(...cards);
      }
      const allCards = state.cardState.cards;
      state.cardState.nftToCardMap = {
        ...state.cardState.nftToCardMap,
        ...Object.fromEntries(cards.map(
          ({ nftItemId }, i) => [nftItemId, i + allCards.length - cards.length],
        )),
      };
    })
    .addCase(salesMarketplaceResponse, (state, { payload: { saleItems, isInitial } }) => {
      if (isInitial) {
        state.salesState.saleItems = saleItems;
      } else {
        state.salesState.saleItems.push(...saleItems);
      }
    })
    .addCase(collectionMarketplaceResponse, (state, { payload: { collections } }) => {
      state.collections.collections.push(...collections);
      state.collections.isLoad = true;
    })
    .addCase(setMarketplaceFilters, (state, {
      payload: { collection, rarity, sortBy },
    }) => {
      let isChanged = false;

      if (collection) {
        isChanged = state.filters.collection.value !== collection.value;
        state.filters.collection = collection;
      }

      if (rarity) {
        const { rarity: initialRarity } = state.filters;
        isChanged = JSON.stringify({ rarity }) !== JSON.stringify(initialRarity) || isChanged;
        state.filters.rarity = rarity;
      }

      if (sortBy) {
        isChanged = state.filters.sortBy !== sortBy || isChanged;
        state.filters.sortBy = sortBy;
      }

      if (isChanged) {
        state.cardState.page = null;
        state.cardState.isMoreToLoad = true;
        state.cardState.cards = [];
        state.cardState.nftToCardMap = {};
        state.cardState.isLoad = false;
      }
    })
    .addCase(setMarketplaceSalesFilters, (state, {
      payload: { rarity, sortBy, collection },
    }) => {
      let isChanged = false;

      if (collection) {
        isChanged = state.salesFilters.collection.value !== collection.value;
        state.salesFilters.collection = collection;
      }

      if (rarity) {
        const { rarity: initialRarity } = state.salesFilters;
        isChanged = JSON.stringify({ rarity }) !== JSON.stringify(initialRarity) || isChanged;
        state.salesFilters.rarity = rarity;
      }

      if (sortBy) {
        isChanged = state.salesFilters.sortBy !== sortBy || isChanged;
        state.salesFilters.sortBy = sortBy;
      }

      if (isChanged) {
        state.salesState.page = null;
        state.salesState.isMoreToLoad = true;
        state.salesState.saleItems = [];
        state.salesState.isLoad = false;
        state.salesState.isLoading = false;
      }
    })
    .addCase(setMarketplacePage, (state, { payload: { page } }) => {
      state.cardState.page = page;
    })
    .addCase(setMarketplaceSalesPage, (state, { payload: { page } }) => {
      state.salesState.page = page;
    })
    .addCase(setMarketplaceLoadState, (
      state,
      {
        payload: {
          isLoad,
          isLoading,
          isMoreToLoad,
        },
      },
    ) => {
      if (typeof isLoad === 'boolean') state.cardState.isLoad = isLoad;
      if (typeof isLoading === 'boolean') state.cardState.isLoading = isLoading;
      if (typeof isMoreToLoad === 'boolean') state.cardState.isMoreToLoad = isMoreToLoad;
    })
    .addCase(setMarketplaceSalesLoadState, (
      state,
      {
        payload: {
          isLoad,
          isLoading,
          isMoreToLoad,
        },
      },
    ) => {
      if (typeof isLoad === 'boolean') state.salesState.isLoad = isLoad;
      if (typeof isLoading === 'boolean') state.salesState.isLoading = isLoading;
      if (typeof isMoreToLoad === 'boolean') state.salesState.isMoreToLoad = isMoreToLoad;
    })
    .addCase(resetMarketplaceCardState, (state) => {
      state.cardState = initialState.cardState;
      state.collections = initialState.collections;
    })
    .addCase(resetMarketplaceSalesState, (state) => {
      state.salesState = initialState.salesState;
    })
    .addCase(setMarketplaceCardPurchaseState, (
      state, { payload: { inProgress, purchased, nftItemId } },
    ) => {
      const cardIndex = state.cardState.nftToCardMap[nftItemId];

      if (typeof cardIndex !== 'number') {
        return;
      }

      const currentCard = state.cardState.cards[cardIndex];

      if (currentCard && typeof inProgress === 'boolean') {
        currentCard.inProgress = inProgress;
      }

      if (currentCard && typeof purchased === 'boolean') {
        currentCard.purchased = purchased;
      }
    })
    .addCase(setMarketplacePurchasingState, (state,
      { payload: { isPurchasing, state: status, cardPageUrl } }) => {
      if (typeof isPurchasing === 'boolean') {
        state.purchasingState.isPurchasing = isPurchasing;
      }

      if (status) {
        state.purchasingState.state = status;
      }

      if (typeof cardPageUrl !== 'undefined') {
        state.purchasingState.currentCardPage = cardPageUrl;
      }
    })
    .addCase(setMarketplaceCardPageUrlByNftId, (state, { payload: { nftItemId } }) => {
      const currentCardIndex = state.cardState.nftToCardMap[nftItemId];
      const currentCard = state.cardState.cards[currentCardIndex];
      if (currentCard?.cardUrl) {
        state.purchasingState.currentCardPage = state.cardState.cards[currentCardIndex].cardUrl;
      }
    });
});

export default marketplaceReducer;
