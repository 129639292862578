import { type RefObject, useLayoutEffect } from 'react';

type HandleEventTypes = 'click' | 'mousedown';

export const useOutsideClickHandle = (ref: RefObject<any>, handleFn: () => void, eventType?: HandleEventTypes) => {
  useLayoutEffect(() => {
    function handleClickOutside(e: MouseEvent) {
      if ((ref.current && !ref.current.contains(e.target)) || (!ref.current)) handleFn();
    }
    document.addEventListener(eventType || 'mousedown', handleClickOutside);
    return () => {
      document.removeEventListener(eventType || 'mousedown', handleClickOutside);
    };
  }, [ref, handleFn]);
};
