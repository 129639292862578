import * as fcl from '@blocto/fcl';
import * as t from '@onflow/types';
import { invariant } from '@onflow/util-invariant';
import { onError } from 'helpers/onError';
import { tx } from './util/tx';

const CODE = fcl.cdc`
import FungibleToken from 0xFUNGIBLE_TOKEN_ADDRESS
import NonFungibleToken from 0xNON_FUNGIBLE_TOKEN_ADDRESS
import FiatToken from 0xFIAT_TOKEN_ADDRESS
import FUSD from 0xFUSD_ADDRESS
import UsdcUsdtSwapPair from 0xUSDC_USDT_SWAP_PAIR_ADDRESS
import FusdUsdtSwapPair from 0xFUSD_USDT_SWAP_PAIR_ADDRESS
import StarlyCard from 0xSTARLY_CARD_ADDRESS
import StarlyCardMarket from 0xSTARLY_CARD_MARKET_ADDRESS

transaction(itemID: UInt64, marketCollectionAddress: Address) {
    let paymentVault: @FungibleToken.Vault
    let starlyCardCollection: &StarlyCard.Collection{NonFungibleToken.Receiver}
    let marketCollection: &StarlyCardMarket.Collection{StarlyCardMarket.CollectionPublic}
    let buyerAddress: Address

    prepare(signer: AuthAccount) {
        self.buyerAddress = signer.address;

        self.starlyCardCollection = signer.borrow<&StarlyCard.Collection{NonFungibleToken.Receiver}>(
            from: StarlyCard.CollectionStoragePath
        ) ?? panic("Cannot borrow StarlyCard collection receiver from acct")

        self.marketCollection = getAccount(marketCollectionAddress)
            .getCapability<&StarlyCardMarket.Collection{StarlyCardMarket.CollectionPublic}>(
                StarlyCardMarket.CollectionPublicPath
            )!
            .borrow()
            ?? panic("Could not borrow market collection from market address")

        let saleItem = self.marketCollection.borrowSaleItem(itemID: itemID)
                    ?? panic("No item with that ID")

        let fusdPrice = saleItem.price

        let fiatTokenVault = signer.borrow<&FiatToken.Vault>(from: FiatToken.VaultStoragePath) 
            ?? panic("Could not borrow a reference to Vault")

        let token0Vault <- fiatTokenVault.withdraw(amount: fusdPrice) as! @FiatToken.Vault
        let token1Vault <- UsdcUsdtSwapPair.swapToken1ForToken2(from: <- token0Vault)
        let token2Vault <- FusdUsdtSwapPair.swapToken2ForToken1(from: <- token1Vault)

        let fusdVault = signer.borrow<&FUSD.Vault>(from: /storage/fusdVault)
            ?? panic("Could not borrow a reference to Vault")

        fusdVault.deposit(from: <- token2Vault)
        self.paymentVault <- fusdVault.withdraw(amount: fusdPrice)
    }

    execute {
        self.marketCollection.purchase(
            itemID: itemID,
            buyerCollection: self.starlyCardCollection,
            buyerPayment: <- self.paymentVault,
            buyerAddress: self.buyerAddress
        )
    }
}`;

export async function flowAcceptSaleOfferUsingUSDCTransaction(
  itemID: number,
  marketCollectionAddress: string,
  opts = { onError },
) {
  invariant(itemID != null, 'itemID must be supplied');
  invariant(marketCollectionAddress != null, 'marketCollectionAddress must be supplied');

  // Get latest block info
  const block = await fcl.send([fcl.getBlock(false) as Function]).then(fcl.decode);

  return tx(
    [
      fcl.transaction(CODE),
      fcl.args([
        fcl.arg(BigInt(itemID).toString(), t.UInt64),
        fcl.arg(String(marketCollectionAddress), t.Address),
      ]),
      fcl.payer(fcl.authz),
      fcl.proposer(fcl.authz),
      fcl.authorizations([fcl.authz]),
      fcl.limit(1000),
      fcl.ref(block.id),
    ],
    opts,
  );
}
