import { createAction } from '@reduxjs/toolkit';
import { type TransactionHistoryItem } from '@starly/starly-types';

interface TransactionRequestPayload {
  addr: string
}

interface TransacationsResponsePayload {
  transactions: TransactionHistoryItem[]
}

interface SetLoadingPayload {
  isLoading: boolean,
}

export const transactionsRequest = createAction<TransactionRequestPayload>('transactions/request');

export const transactionsResponse = createAction<TransacationsResponsePayload>('transactions/response');

export const transactionsLoading = createAction<SetLoadingPayload>('transactions/set-loading');
