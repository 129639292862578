import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';

import { RouteTypes } from '../../RouteTypes';
import { selectAuthAuthorized, selectAuthUserId } from '../../store/auth/authSelectors';
import { selectRewardToClaim } from '../../store/ranking/rankingSelectors';
import JoinDiscordLabel from '../JoinDiscordLabel/JoinDiscordLabel';

const RandomTopLabel = () => {
  const { pathname } = useLocation();

  const isAuthorized = useSelector(selectAuthAuthorized);
  const userId = useSelector(selectAuthUserId);
  const { reward } = useSelector(selectRewardToClaim);

  if (pathname === RouteTypes.Blog || (userId && reward) || isAuthorized) {
    return null;
  }
  return <JoinDiscordLabel />;
};

export default RandomTopLabel;
