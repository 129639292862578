import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Share from 'components/Share';
import { setMarketplacePurchasingState } from 'store/marketplace/marketplaceAction';
import { selectMarketplacePopUpState } from 'store/marketplace/marketplaceSelectors';
import { cardForSaleReset } from 'store/saleCard/saleCardActions';
import CardPagePopUp from 'views/Card/componets/PopUp/CardPagePopUp';

const MarketplacePurchasePopup = () => {
  const dispatch = useDispatch();

  const popUpState = useSelector(selectMarketplacePopUpState);

  const [isShareOpen, setIsShareOpen] = useState(false);

  return (
    <>
      {popUpState.isOpen && (
        <CardPagePopUp
          onSaleReset={() => {
            dispatch(cardForSaleReset());
            dispatch(setMarketplacePurchasingState({ state: 'initial', cardPageUrl: '', isPurchasing: false }));
          }}
          onShareClick={() => {
            dispatch(cardForSaleReset());
            dispatch(setMarketplacePurchasingState({ state: 'initial', isPurchasing: false }));
            setIsShareOpen(true);
          }}
          {...popUpState}
        />
      )}
      {isShareOpen && (
        <Share
          onHide={() => setIsShareOpen(false)}
          type="card"
          url={`${window.location.protocol}//${window.location.host}${popUpState.cardUrl}`}
        />
      )}
    </>
  );
};

export default MarketplacePurchasePopup;
