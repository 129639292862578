import { useContext, useLayoutEffect, useState } from 'react';
import {
  JoinDiscordLabelContainer,
  JoinDiscordButton,
  JoinDiscordText,
  JoinDiscordLabelContent,
  DiscordLogo,
  JoinDiscordLabelLink,
} from './styled';
import { starlyDsLink } from '../../global/constants';
import ComponentPortal from '../ComponentPortal';
import { AppContext } from '../../context/context';

const JoinDiscordLabel = () => {
  const { topRef } = useContext(AppContext);
  const [, update] = useState(0);

  useLayoutEffect(() => {
    update(1);
  }, []);

  return (
    <ComponentPortal element={topRef?.current}>
      <JoinDiscordLabelLink
        data-test-id="join_discord_label_link"
        href={starlyDsLink}
        target="_blank"
        rel="noreferrer"
      >
        <JoinDiscordLabelContainer>
          <JoinDiscordLabelContent>
            <JoinDiscordText>
              Join our Discord and win a pack! Free airdrops for Discord members!
            </JoinDiscordText>
            <JoinDiscordButton background="white">
              <DiscordLogo />
              <p>Join discord</p>
            </JoinDiscordButton>
          </JoinDiscordLabelContent>
        </JoinDiscordLabelContainer>
      </JoinDiscordLabelLink>
    </ComponentPortal>
  );
};

export default JoinDiscordLabel;
