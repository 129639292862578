import {
  all, call, fork, put, takeEvery, delay,
} from 'redux-saga/effects';
import { push } from 'connected-react-router';
import {
  type DocumentSnapshot,
  type QuerySnapshot, type Query,
  firestore,
  functions,
  trackException,
  trackEvent,
} from 'global/firebase';
import type {
  Card,
  FlowFestCard,
  FlowFestPack,
  FlowFestProject,
} from '@starly/starly-types';
import { RouteTypes } from 'RouteTypes';
import {
  defaultProjectOption,
  sortMapping,
  sortTypes,
} from 'views/FlowMarketplace/utils/data';
import { flowFestBuyTransaction } from 'flow/flowfest/flowFestBuyFacade';
import { flowFestSellTransaction } from 'flow/flowfest/flowFestSellFacade';
import { flowFetchNFTIDsScript } from 'flow/nftnyc/fetchNFTIDs.script';
import { flowFestRemoveListingItemTransaction } from 'flow/flowfest/flowFestRemoveListing.tx';
import { cardForSalePurchased, cardForSalePurchaseError } from 'store/saleCard/saleCardActions';
import { LISTINGS_LIMIT } from 'util/constants';
import type { FlowTypes } from 'types';
import {
  type ExtendedFFCard,
  flowNFTNYCProjectRequest,
  redeemCodeRequest,
  redeemCodeResponse,
  redeemCodeFailure,
  ffReservePackRequest,
  ffReservePackResponse,
  ffReservePackFailure,
  ffShowPackRequest,
  ffShowPackResponse,
  ffShowPackFailure,
  ffOpenPackRequest,
  ffOpenPackResponse,
  ffOpenPackFailure,
  getFlowFestPackStateRequest,
  getFlowFestPackStateResponse,
  getFlowFestPackStateFailure,
  ffValidateCaptchaRequest,
  ffValidateCaptchaResponse,
  ffValidateCaptchaFailure,
  ffViewCardRequest,
  ffViewPackRequest,
  flowNFTNYCCheckWalletRequest,
  flowNFTNYCCheckWalletResponse,
  flowNFTNYCCheckWalletFailure,
  ffClearReservePackRequest,
  loadNFTNYCProjectsRequest,
  loadNFTNYCProjectsResponse,
  loadNFTNYCProjectsFailure,
  loadFFCardsRequest,
  loadFFCardsResponse,
  loadFFCardsFailure,
  buyFFCardRequest,
  buyFFCardResponse,
  buyFFCardFailure,
  resetFFCards,
  saleFFCardRequest,
  loadMyFFCardsRequest,
  loadMyFFCardsResponse,
  loadMyFFCardsFailure,
  removeFromSaleFFCardRequest,
  saleFFCardResponse,
  removeFromSaleFFCardResponse,
  saleFFCardFailure,
  removeFromSaleFFCardFailure,
} from './flowNFTNYCActions';

import { flowIsNFTNYCAccountInitializedScript } from '../../flow/nftnyc/isNFTNYCAccountInitialized.script';
import { flowFestProjectResponse } from '../flowFest/flowFestActions';

function* watchProjectRequest() {
  yield takeEvery(flowNFTNYCProjectRequest, function* takeEveryCollectionRequest(action) {
    try {
      const { payload: { id } } = action;
      const collectionRef:DocumentSnapshot = yield call(() => firestore.collection('nftnycProjects').doc(id).get());
      const project = collectionRef.data() as FlowFestProject;
      yield put(flowFestProjectResponse({ project }));
    } catch (error: any) {
      trackException(error.message);
    }
  });
}

function* watchffOpenPack() {
  yield takeEvery(ffOpenPackRequest,
    function* takeffOpenPackRequest() {
      try {
        const openPack = functions.httpsCallable('openFlowNFTNYCPack');
        const { data } = yield call(openPack);

        if (data.error) {
          if (data.error.startsWith('Not available until')) {
            yield put(ffOpenPackFailure({ error: 'Your pack is not ready yet, please try again in a few seconds' }));
          } else {
            yield put(ffOpenPackFailure({ error: data.error }));
          }
        } else {
          const cardRefs: DocumentSnapshot[] = yield all((data.card_ids || []).map(
            (cardId) => call(() => firestore.collection('nftnycCards').doc(cardId).get()),
          ));
          const cards = cardRefs.map((cardRef) => cardRef.data() as Card);

          yield put(ffOpenPackResponse({ pack: data, cards }));
        }
      } catch (error: any) {
        trackException(error.message);
        yield put(ffOpenPackFailure({ error }));
      }
    });
}

function* watchffShowPack() {
  yield takeEvery(ffShowPackRequest,
    function* takeffShowPackRequest() {
      try {
        const openPack = functions.httpsCallable('showFlowNFTNYCPack');
        const { data } = yield call(openPack);

        if (data.error) {
          yield put(ffShowPackFailure());
        } else {
          const cardIds: string[] = data.map((pack: FlowFestPack) => pack.card_ids).flat();

          const cardRefs: DocumentSnapshot[] = yield all((cardIds || []).map(
            (cardId) => call(() => firestore.collection('nftnycCards').doc(cardId).get()),
          ));
          const cards = cardRefs.map((cardRef) => cardRef.data() as FlowFestCard);

          yield put(ffShowPackResponse({ packs: data, cards }));
        }
      } catch (error: any) {
        trackException(error.message);
        yield put(ffShowPackFailure());
      }
    });
}

// ?Really not sure what it does
function* watchffViewPack() {
  yield takeEvery(ffViewPackRequest,
    function* takeffViewPackRequest({ payload: { pack_id } }) {
      try {
        const viewPack = functions.httpsCallable('viewFlowNFTNYCPack');
        yield call(() => viewPack({ pack_id }));
      } catch (error: any) {
        trackException(error.message);
      }
    });
}

// ?Really not sure what it does
function* watchffViewCard() {
  yield takeEvery(ffViewCardRequest,
    function* takeffViewCardRequest({ payload: { pack_id, card_id } }) {
      try {
        const viewCard = functions.httpsCallable('viewFlowNFTNYCCard');
        yield call(() => viewCard({ pack_id, card_id }));
      } catch (error: any) {
        trackException(error.message);
      }
    });
}

function* watchffReservePack() {
  yield takeEvery(ffReservePackRequest,
    function* takeffReservePackRequest() {
      try {
        const reservePack = functions.httpsCallable('reserveFlowNFTNYCPack');
        const { data } = yield call(() => reservePack());
        if (data.error) {
          yield put(ffReservePackFailure(data));
        } else {
          yield put(ffReservePackResponse());
          trackEvent('flow_fest_claim_started');
        }
      } catch (error: any) {
        trackException(error.message);
        yield put(ffReservePackFailure({ error: 'Error' }));
      }
    });
}

function* watchffValidateCaptcha() {
  yield takeEvery(ffValidateCaptchaRequest,
    function* takeffValidateCaptchaRequest({ payload: { captcha_response } }) {
      try {
        const validateCaptcha = functions.httpsCallable('validateFlowNFTNYCCaptcha');
        const { data } = yield call(() => validateCaptcha({ captcha_response }));
        if (data.error) {
          yield put(ffValidateCaptchaFailure(data));
        } else {
          yield put(ffValidateCaptchaResponse(data));
        }
      } catch (error: any) {
        trackException(error.message);
        yield put(ffValidateCaptchaFailure({ error: 'Error' }));
      }
    });
}

function* watchGetFlowFestPackState() {
  yield takeEvery(getFlowFestPackStateRequest,
    function* takeGetFlowFestPackStateRequest() {
      try {
        const reservePack = functions.httpsCallable('getFlowNFTNYCPackState');
        const { data } = yield call(() => reservePack());
        if (data.error) {
          yield put(getFlowFestPackStateFailure(data));
        } else {
          yield put(getFlowFestPackStateResponse(data));
        }
      } catch (error: any) {
        trackException(error.message);
        yield put(getFlowFestPackStateFailure({ error: 'Error' }));
      }
    });
}

function* watchRedeemCodeRequest() {
  yield takeEvery(redeemCodeRequest,
    function* takeRedeemCode({ payload: { code } }) {
      try {
        const redeemCode = functions.httpsCallable('redeemFlowNFTNYCCode');
        const { data } = yield call(() => redeemCode({ code }));
        if (data.error) {
          yield put(redeemCodeFailure(data));
        } else {
          yield put(redeemCodeResponse());
        }
      } catch (error: any) {
        trackException(error.message);
        yield put(redeemCodeFailure({ error: 'Error' }));
      }
    });
}

function* watchFlowFestCheckWallet() {
  yield takeEvery(flowNFTNYCCheckWalletRequest,
    function* takeEveryFlowFestCheckWalletRequest({ payload: { wallet } }) {
      try {
        if (!wallet) {
          yield put(flowNFTNYCCheckWalletResponse(false));
          return;
        }

        const script = flowIsNFTNYCAccountInitializedScript;

        const init: FlowTypes.Init = yield call(script, wallet);

        if (Object.values(init).findIndex((v) => !v) > -1) {
          yield put(flowNFTNYCCheckWalletResponse(false));
          return;
        }
        yield put(flowNFTNYCCheckWalletResponse(true));
      } catch (error: any) {
        trackException(error.message);
        yield put(flowNFTNYCCheckWalletFailure());
      }
    });
}

function* watchClearPackReserve() {
  yield takeEvery(ffClearReservePackRequest,
    function* takeRedeemCode() {
      try {
        const clearReserve = functions.httpsCallable('clearUserReservedFlowNFTNYCPack');
        yield call(() => clearReserve());
        yield put(getFlowFestPackStateRequest());
      } catch (error: any) {
        trackException(error.message);
      }
    });
}

function* watchProjectsRequest() {
  yield takeEvery(loadNFTNYCProjectsRequest, function* takeProjectsRequest() {
    try {
      const collectionRef:DocumentSnapshot = yield call(() => firestore.collection('cache').doc('nftnycProjects').get());
      const projects = collectionRef.data() || {};

      yield put(loadNFTNYCProjectsResponse({
        projects: projects._cached_nftnyc_projects.sort((a, b) => a.name.localeCompare(b.name)),
      }));
    } catch (error) {
      yield put(loadNFTNYCProjectsFailure({ error }));
      console.error(error);
    }
  });
}

let cursor: any = null;

function* watchCardsRequest() {
  yield takeEvery(loadFFCardsRequest, function* takeFFCardsRequest({
    payload: {
      filter = defaultProjectOption.value,
      sort = sortTypes[0].value,
      isResetCursor,
      rarity,
    },
  }) {
    try {
      let query: Query;
      let sortedQuery: Query;
      const isDefaultProject: boolean = filter === defaultProjectOption.value;

      if (isDefaultProject) {
        sortedQuery = firestore.collection('nftnycListings')
          .where('state', '==', 'created')
          .orderBy(sortMapping[sort], sort.includes('desc') ? 'desc' : 'asc');
      } else {
        sortedQuery = firestore.collection('nftnycListings')
          .where('state', '==', 'created')
          .where('project_id', '==', filter)
          .orderBy(sortMapping[sort], sort.includes('desc') ? 'desc' : 'asc');
      }

      if (rarity) {
        const rarityFilter = rarity
          .filter(({ active }) => active)
          .map(({ value }) => value);

        if (rarityFilter.length !== 0) {
          sortedQuery = sortedQuery.where('rarity', 'in', rarityFilter);
        }
      }

      if (isResetCursor) {
        cursor = null;
        yield put(resetFFCards());

        query = sortedQuery.limit(LISTINGS_LIMIT);
      } else {
        query = sortedQuery.startAfter(cursor).limit(LISTINGS_LIMIT);
      }

      const { docs: listingDocs }: QuerySnapshot = yield call(() => query.get());

      cursor = listingDocs[listingDocs.length - 1];

      const listings = listingDocs.map((doc) => doc.data());

      if (listings.length) {
        const cardsRef: QuerySnapshot = yield call(() => firestore.collection('nftnycCards')
          .where('id', 'in', listings.map((listing) => listing.card_id))
          .limit(10)
          .get());

        const unsortedCards = cardsRef.docs.map((doc) => doc.data() as FlowFestCard);
        const cards = listings.map((listing) => {
          // Combine card fields with listings
          const card = unsortedCards.find((_card) => _card.id === listing.card_id) || {};

          return {
            ...card,
            price: listing.price,
            storefrontAddress: listing.storefrontAddress,
            ftType: listing.ftType,
            projectName: listing.project_name,
            listingResourceID: parseInt(listing.id, 10),
          } as ExtendedFFCard;
        });

        yield put(loadFFCardsResponse({ cards, isLastCards: listings.length < LISTINGS_LIMIT }));
      } else {
        yield put(loadFFCardsResponse({
          cards: [],
          isLastCards: listings.length < LISTINGS_LIMIT,
        }));
      }
    } catch (error) {
      yield put(loadFFCardsFailure({ error }));
      console.error(error);
    }
  });
}

function* watchBuyCardRequest() {
  yield takeEvery(buyFFCardRequest, function* takeBuyFFCardRequest(action) {
    const {
      payload: {
        project,
        listingResourceID,
        storefrontAddress,
        buyPrice,
      },
    } = action;

    try {
      const result: object = yield call(
        flowFestBuyTransaction,
        project,
        listingResourceID,
        storefrontAddress,
        buyPrice,
      );

      if (!result || result.errorMessage) {
        yield put(cardForSalePurchaseError());
        yield put(buyFFCardFailure({ error: '', listingResourceID }));
      } else {
        yield delay(15000);
        yield put(cardForSalePurchased());
        yield put(buyFFCardResponse({ listingResourceID }));
      }
    } catch (error) {
      yield put(buyFFCardFailure({ error, listingResourceID }));
    }
  });
}

function* watchSaleFFCardRequest() {
  yield takeEvery(saleFFCardRequest, function* takeEverySaleRequest({
    payload: {
      projectId,
      itemId,
      price,
    },
  }) {
    try {
      const docSnapshot: DocumentSnapshot = yield call(() => firestore.collection('nftnycProjects').doc(projectId).get());
      const {
        marketplace_flow_account: marketplaceFlowAccount,
        marketplace_sale_cut_percents: marketplaceSaleCutPercents,
      } = docSnapshot.data() as FlowFestProject;

      yield call(
        () => flowFestSellTransaction(
          projectId,
          itemId,
          price,
          marketplaceFlowAccount,
          marketplaceSaleCutPercents,
        ),
      );

      yield delay(10000);
      yield put(saleFFCardResponse());
      yield put(push(RouteTypes.FlowFestMarketplace));
    } catch (error) {
      yield put(saleFFCardFailure());
      console.error(error);
    }
  });
}

function* watchRemoveFromSaleFFCardRequest() {
  yield takeEvery(removeFromSaleFFCardRequest, function* takeEveryRequest({ payload }) {
    try {
      yield call(flowFestRemoveListingItemTransaction, payload.id);

      yield delay(10000);
      yield put(removeFromSaleFFCardResponse());
      yield put(loadMyFFCardsRequest(payload.userWallet));
    } catch (error) {
      yield put(removeFromSaleFFCardFailure());
      console.error(error);
    }
  });
}

function* watchLoadMyFFCardsRequest() {
  yield takeEvery(loadMyFFCardsRequest, function* takeEverySaleRequest({ payload }) {
    try {
      const result: { [key: string]: number[] } = yield call(flowFetchNFTIDsScript, payload);
      const queries: Query[] = [];
      const cards: ExtendedFFCard[] = [];

      // create queries for my cards
      Object.keys(result).forEach(async (project: string) => {
        const idsNumber = result[project]?.length || 0;
        const ids = result[project];

        if (idsNumber === 0) return;

        for (let i = 0; i < Math.ceil(idsNumber / 10); i += 1) {
          const query: Query = firestore.collection('nftnycCards')
            .where('parent_project_id', '==', project)
            .where('nft_item_id', 'in', ids.slice(i * 10, (i + 1) * 10))
            .limit(10);

          queries.push(query);
        }
      });

      const queriesSnapshot: QuerySnapshot[] = yield all(
        queries.map((query) => call(() => query.get())),
      );

      queriesSnapshot.forEach((querySnapshot) => {
        querySnapshot.docs.forEach((doc) => {
          cards.push(doc.data() as ExtendedFFCard);
        });
      });

      // create queries to get listing for my card
      const newQueries = cards.map((card: ExtendedFFCard) => firestore.collection('nftnycListings')
        .where('storefrontAddress', '==', payload)
        .where('project_id', '==', card.project_id)
        .where('nft_item_id', '==', card.nft_item_id)
        .where('state', '==', 'created')
        .limit(1));

      const newQueriesSnapshot: QuerySnapshot[] = yield all(
        newQueries.map((query) => call(() => query.get())),
      );

      newQueriesSnapshot.forEach((querySnapshot, index) => {
        if (querySnapshot.docs.length > 0) {
          const listing = querySnapshot.docs[0].data();

          cards[index].isForSale = true;
          cards[index].listingResourceID = Number(listing.id);
          cards[index].price = Number(listing.price);
          cards[index].ftType = listing.ftType;
          cards[index].storefrontAddress = payload;
        } else {
          cards[index].isForSale = false;
          cards[index].storefrontAddress = payload;
        }
      });

      yield put(loadMyFFCardsResponse({ cards }));
    } catch (error) {
      console.error(error);

      yield put(loadMyFFCardsFailure({ error }));
    }
  });
}

export default function* flowNFTNYCSaga() {
  yield all([
    fork(watchProjectRequest),
    fork(watchRedeemCodeRequest),
    fork(watchffReservePack),
    fork(watchffOpenPack),
    fork(watchffShowPack),
    fork(watchffValidateCaptcha),
    fork(watchGetFlowFestPackState),
    fork(watchffViewCard),
    fork(watchffViewPack),
    fork(watchFlowFestCheckWallet),
    fork(watchClearPackReserve),
    fork(watchProjectsRequest),
    fork(watchCardsRequest),
    fork(watchBuyCardRequest),
    fork(watchSaleFFCardRequest),
    fork(watchLoadMyFFCardsRequest),
    fork(watchRemoveFromSaleFFCardRequest),
  ]);
}
