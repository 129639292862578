import * as fcl from '@blocto/fcl';
import * as t from '@onflow/types';
import { invariant } from '@onflow/util-invariant';
import { onError } from 'helpers/onError';
import { tx } from '../util/tx';

const CODE = fcl.cdc`
import NFTStorefront from 0xNFT_STOREFRONT_ADDRESS

transaction(listingResourceID: UInt64) {
    let storefront: &NFTStorefront.Storefront{NFTStorefront.StorefrontManager}

    prepare(signer: AuthAccount) {
        self.storefront = signer.borrow<&NFTStorefront.Storefront{NFTStorefront.StorefrontManager}>(from: NFTStorefront.StorefrontStoragePath)
            ?? panic("Missing or mis-typed NFTStorefront.Storefront")
    }

    execute {
        self.storefront.removeListing(listingResourceID: listingResourceID)
    }
}`;

export async function flowFestRemoveListingItemTransaction(
  listingResourceID: number,
  opts = { onError },
) {
  invariant(listingResourceID != null, 'itemID must be supplied');

  // Get latest block info
  const block = await fcl.send([fcl.getBlock(false) as Function]).then(fcl.decode);

  return tx(
    [
      fcl.transaction(CODE),
      fcl.args([
        fcl.arg(BigInt(listingResourceID).toString(), t.UInt64),
      ]),
      fcl.payer(fcl.authz),
      fcl.proposer(fcl.authz),
      fcl.authorizations([fcl.authz]),
      fcl.limit(1000),
      fcl.ref(block.id),
    ],
    opts,
  );
}
