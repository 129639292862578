import styled, { css } from 'styled-components';
import theme from '../theme';

export const TopCollectorsRewardContainer = styled.div`
  display: flex;
  align-items: center;
  width: max-content;
`;

export const TopCollectorsRewardImg = styled.img`
  width: 182px;
  height: 182px;
  margin-right: 16px;
`;

export const TopCollectorsRewardTextContainer = styled.div`
`;

export const TopCollectorsRegularText = styled.p<{ isLink?: boolean }>`
  font-family: ${theme.fonts.primary};
  color: ${theme.colors.white};
  font-size: 18px;
  line-height: 24px;
  ${({ isLink }) => (
    isLink ? css`
      text-decoration: underline;
      display: inline-block;
      cursor: pointer;
      color: white;
    ` : ''
  )}
`;

export const TopCollectorsRewardTextInfo = styled.div`
  color: #fff;
  font-family: ${theme.fonts.secondary};
  padding-right: 32px;
  margin-right: 32px;
`;

export const TopCollectorsRewardInfoContainer = styled.div`
  display: flex;
  margin-bottom: 16px;
  & > ${TopCollectorsRewardTextInfo} {
    border-right: 1px solid ${theme.colors.textGray};
    &:last-of-type {
      border-right: none;
    }
  }
`;

export const TopCollectorsRewardInfoHeading = styled.h4`
  font-size: 16px;
  margin-bottom: 8px;
`;

export const TopCollectorsRewardInfoText = styled.h4`
  letter-spacing: 1px;
  font-size: 30px;
`;
