import type { FC } from 'react';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components/macro';

import { checkIsActivePage } from '../../../helpers/checkIsActivePage';
import theme from '../../theme';

const LinkWrapper = styled(Link)<{ $isActive?: boolean }>`
  text-decoration: none;
  position: relative;
  padding: 15px 0;
  &:after {
    bottom: 8px;
    position: absolute;
    content: '';
    height: 2px;
    width: 100%;
    transform: scaleX(0);
    transform-origin: right;
    display: block;
    transition: transform .5s, opacity .5s;
    background: ${theme.colors.primary};
    opacity: 0;
  }

  ${({ $isActive }) => ($isActive
    ? css`
          &:after {
            transform: scaleX(1);
            background: ${theme.colors.primary};
            transform-origin: left;
            opacity: 1;
            transition: transform .5s, opacity .5s;
          }
        `
    : css`
          &:hover {
            &:after {
              transform: scaleX(1);
              transform-origin: left;
              opacity: .5;
              transition: transform .5s;
            }
          }
        `)}
`;

const HeaderLinkWrapper: FC<{ to: string; 'data-test-id'?: string }> = ({ children, to, 'data-test-id': dataTestId }) => {
  const { pathname } = useLocation();
  return (
    <LinkWrapper data-test-id={dataTestId} to={to} $isActive={checkIsActivePage(to, pathname)}>
      {children}
    </LinkWrapper>
  );
};

export default HeaderLinkWrapper;
