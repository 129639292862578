import { push } from 'connected-react-router';
import i18n from 'i18next';
import {
  all, call, fork, put, select, takeEvery,
} from 'redux-saga/effects';

import * as fcl from '@blocto/fcl';

import type { AuthProvider } from 'firebase/auth';
import type { User } from '@starly/starly-types';

import {
  auth,
  type DocumentSnapshot,
  FacebookAuthProvider,
  type FirebaseUser,
  firestore,
  functions,
  GoogleAuthProvider,
  type IdTokenResult,
  trackEvent,
  trackException,
  TwitterAuthProvider,
} from 'global/firebase';
import { RouteTypes } from 'RouteTypes';
import { flowLogoutRequest } from 'store/flow/flowActions';

import type { AdditionalUserInfo } from 'types';
import { createWechatCallbackUri, getLinkAndRedirect } from '../../helpers/oauthCallbacks';
import { activateAuthRedirect, setAuthRedirect } from '../login/loginActions';
import { notificationLogoutReset } from '../notifications/notificationsActions';
import {
  authError,
  authLoginCustomTokenRequest,
  authLoginCustomTokenResponse,
  authLoginEmailRequest,
  authLoginEmailResponse,
  authLoginFacebookRequest,
  authLoginFacebookResponse,
  authLoginGoogleRequest,
  authLoginGoogleResponse,
  authLoginTwitterRequest,
  authLoginTwitterResponse,
  authLoginWechatRequest,
  authLoginWechatResponse,
  authLogoutRequest,
  authLogoutResponse,
  authSetIsAuthorized,
  authSetIsProfileCreating,
  authSignupEmailRequest,
  authSignupEmailResponse,
} from './authActions';

import type { AuthErrorPayload } from './authActions';

interface UserCredential {
  additionalUserInfo?: AdditionalUserInfo;
  user: FirebaseUser;
}

interface ISignInPayload {
  response: UserCredential,
  error: Error,
}

function* watchLogoutRequest() {
  yield takeEvery(authLogoutRequest, function* takeEveryLogoutRequest() {
    try {
      sessionStorage.setItem('FFwaitLogin', 'false');
      localStorage.clear();
      yield call(() => auth.signOut());
      yield put(authLogoutResponse());
      yield put(notificationLogoutReset());
      trackEvent('user_logout');
    } catch (error: any) {
      trackException(error.message);
      yield put(authError({
        error: error as AuthErrorPayload,
      }));
    }
  });
}

function* watchLoginCustomTokenRequest() {
  yield takeEvery(authLoginCustomTokenRequest, function* takeEveryCustomTokenRequest(action) {
    const { payload: { id, closeModal } } = action;
    try {
      const token: { data: { token: string } } = yield call(() => functions.httpsCallable('createCustomUserToken')({ id }));
      yield put(authLogoutRequest());
      const {
        additionalUserInfo, user: { uid },
      }: UserCredential = yield call(() => (auth.signInWithCustomToken(token.data.token)));

      const idTokenResult: IdTokenResult = yield call(() => auth.currentUser?.getIdTokenResult());
      const userRef: DocumentSnapshot = yield call(() => firestore.collection('users').doc(uid).get());
      const user = (userRef.data() || {}) as User;

      yield put(authLoginCustomTokenResponse({
        additionalUserInfo,
        uid,
        isAdmin: idTokenResult.claims?.role === 'admin',
      }));

      yield call(closeModal);

      yield put(push(RouteTypes.Drops));
      trackEvent('user_login');

      const { addr }: { addr: string | undefined } = yield call(fcl.currentUser().snapshot);

      if (addr && user.flow_account === addr) {
        fcl.authenticate().then((e: any) => console.error(e));
      } else {
        yield put(flowLogoutRequest({}));
      }
    } catch (error: any) {
      trackException(error.message);
    }
  });
}

function* watchLoginEmailRequest() {
  yield takeEvery(authLoginEmailRequest, function* takeEveryLoginEmailRequest(action) {
    const {
      payload: {
        email, password, closeModal,
      },
    } = action;
    try {
      const { additionalUserInfo, user: { uid } }: UserCredential = yield call(() => auth.signInWithEmailAndPassword(email, password));

      const idTokenResult: IdTokenResult = yield call(() => auth.currentUser?.getIdTokenResult());
      const userRef: DocumentSnapshot = yield call(() => firestore.collection('users').doc(uid).get());
      const user = (userRef.data() || {}) as User;

      yield put(authLoginEmailResponse({
        additionalUserInfo,
        uid,
        isAdmin: idTokenResult.claims?.role === 'admin',
      }));

      yield call(closeModal);

      if (!user.username) {
        yield put(authSetIsProfileCreating(true));
        yield put(authSetIsAuthorized(false));
        yield put(push(RouteTypes.CreateProfile));
      }

      const { addr }: { addr: string | undefined } = yield call(fcl.currentUser().snapshot);

      if (addr && user.flow_account === addr) {
        fcl.authenticate().then((e: any) => console.error(e));
      } else {
        yield put(flowLogoutRequest({}));
      }
    } catch (error: any) {
      trackException(error.message);
      yield put(authSignupEmailRequest({
        email, password, closeModal,
      }));
    }
  });
}

const signInWithPopup = (provider: AuthProvider) => auth.signInWithPopup(provider)
  .then((response) => ({ response }))
  .catch((error) => ({ error }));

function* watchLoginGoogleRequest() {
  yield takeEvery(authLoginGoogleRequest, function* takeEveryGoogleLoginRequest() {
    try {
      const provider = new GoogleAuthProvider();

      const { response, error }: ISignInPayload = yield call(signInWithPopup, provider);

      if (error) {
        const providerError: AuthErrorPayload = { code: 400, message: error.message };
        trackException(error.message);
        yield put(authError({ error: providerError }));
      } else {
        const {
          additionalUserInfo,
          user: { uid },
        } = response;

        const idTokenResult: IdTokenResult = yield call(() => auth.currentUser?.getIdTokenResult());
        const userRef: DocumentSnapshot = yield call(() => firestore.collection('users').doc(uid).get());
        const user = (userRef.data() || {}) as User;

        yield put(authLoginGoogleResponse({
          additionalUserInfo,
          uid,
          isAdmin: idTokenResult.claims?.role === 'admin',
        }));

        if (!user.username) {
          yield put(authSetIsProfileCreating(true));
          yield put(authSetIsAuthorized(false));

          yield put(push(RouteTypes.CreateProfile));
          trackEvent('user_sign_up');
        } else {
          yield put(authSetIsProfileCreating(false));
          yield put(authSetIsAuthorized(true));
          trackEvent('user_login');
        }

        const { addr }: { addr: string | undefined } = yield call(fcl.currentUser().snapshot);

        if (addr && user.flow_account === addr) {
          fcl.authenticate().then((e: any) => console.error(e));
        } else {
          yield put(flowLogoutRequest({}));
        }
      }
    } catch (error: any) {
      trackException(error.message);
      yield put(authError({
        error: error as AuthErrorPayload,
      }));
    }
  });
}

function* watchLoginTwitterRequest() {
  yield takeEvery(authLoginTwitterRequest, function* takeEveryTwitterLoginRequest() {
    try {
      const provider = new TwitterAuthProvider();

      const { response, error }: ISignInPayload = yield call(signInWithPopup, provider);

      if (error) {
        const providerError: AuthErrorPayload = { code: 400, message: error.message };

        yield put(authError({ error: providerError }));
      } else {
        const {
          additionalUserInfo,
          user: { uid },
        } = response;

        const idTokenResult: IdTokenResult = yield call(() => auth.currentUser?.getIdTokenResult());
        const userRef: DocumentSnapshot = yield call(() => firestore.collection('users').doc(uid).get());
        const user = userRef.data() || {} as User;

        yield put(authLoginTwitterResponse({
          additionalUserInfo,
          uid,
          isAdmin: idTokenResult.claims?.role === 'admin',
        }));

        if (!user.username) {
          yield put(authSetIsProfileCreating(true));
          yield put(authSetIsAuthorized(false));

          yield put(push(RouteTypes.CreateProfile));
          trackEvent('user_sign_up');
        } else {
          yield put(authSetIsProfileCreating(false));
          yield put(authSetIsAuthorized(true));
          trackEvent('user_login');
        }

        const { addr }: { addr: string | undefined } = yield call(fcl.currentUser().snapshot);

        if (addr && user.flow_account === addr) {
          fcl.authenticate().then((e: any) => console.error(e));
        } else {
          yield put(flowLogoutRequest({}));
        }
      }
    } catch (error: any) {
      trackException(error.message);
      yield put(authError({
        error: error as AuthErrorPayload,
      }));
    }
  });
}

function* watchLoginFacebookRequest() {
  yield takeEvery(authLoginFacebookRequest, function* takeEveryFacebookLoginRequest() {
    try {
      const provider = new FacebookAuthProvider();

      const { response, error }: ISignInPayload = yield call(signInWithPopup, provider);

      if (error) {
        const providerError: AuthErrorPayload = { code: 400, message: error.message };

        yield put(authError({ error: providerError }));
      } else {
        const {
          additionalUserInfo,
          user: { uid },
        } = response;

        const idTokenResult: IdTokenResult = yield call(() => auth.currentUser?.getIdTokenResult());
        const userRef: DocumentSnapshot = yield call(() => firestore.collection('users').doc(uid).get());
        const user = (userRef.data() || {}) as User;

        yield put(authLoginFacebookResponse({
          additionalUserInfo,
          uid,
          isAdmin: idTokenResult.claims?.role === 'admin',
        }));

        if (!user.username) {
          yield put(authSetIsProfileCreating(true));
          yield put(authSetIsAuthorized(false));

          yield put(push(RouteTypes.CreateProfile));
          trackEvent('user_sign_up');
        } else {
          yield put(authSetIsProfileCreating(false));
          yield put(authSetIsAuthorized(true));
          trackEvent('user_login');
        }

        const { addr }: { addr: string | undefined } = yield call(fcl.currentUser().snapshot);

        if (addr && user.flow_account === addr) {
          fcl.authenticate().then((e: any) => console.error(e));
        } else {
          yield put(flowLogoutRequest({}));
        }
      }
    } catch (error: any) {
      trackException(error.message);
      yield put(authError({
        error: error as AuthErrorPayload,
      }));
    }
  });
}

function* watchLoginWechatRequest() {
  yield takeEvery(authLoginWechatRequest, function* takeEveryWechatLoginRequest(action) {
    try {
      const { payload: { token } } = action;
      if (token) {
        const userCredential: UserCredential = yield call(() => auth.signInWithCustomToken(token));
        if (userCredential && userCredential.user?.uid) {
          const {
            additionalUserInfo,
            user: { uid },
          } = userCredential;

          const idTokenResult: IdTokenResult = yield call(
            () => auth.currentUser?.getIdTokenResult(),
          );
          const userRef: DocumentSnapshot = yield call(() => firestore.collection('users').doc(uid).get());
          const user = (userRef.data() || {}) as User;

          yield put(authLoginWechatResponse({
            additionalUserInfo,
            uid,
            isAdmin: idTokenResult.claims?.role === 'admin',
          }));

          if (!user.username) {
            yield put(authSetIsProfileCreating(true));
            yield put(authSetIsAuthorized(false));
            sessionStorage.setItem('wechatRegister', 'true');

            yield put(push(RouteTypes.CreateProfile));
            trackEvent('user_sign_up');
          } else {
            yield put(authSetIsProfileCreating(false));
            yield put(authSetIsAuthorized(true));
            trackEvent('user_login');
          }

          const { addr }: { addr: string | undefined } = yield call(fcl.currentUser().snapshot);

          if (addr && user.flow_account === addr) {
            fcl.authenticate().then((e: any) => console.error(e));
          } else {
            yield put(flowLogoutRequest({}));
          }
        }
      } else if (!auth.currentUser) {
        yield put(authSetIsProfileCreating(true));
        const redirect = sessionStorage.getItem('onAuthRedirect');
        if (!redirect) sessionStorage.setItem('onAuthRedirect', window.location.pathname);

        yield getLinkAndRedirect(`/api/generateWechatAuthLink?redirectUri=${createWechatCallbackUri()}`);
      }
    } catch (error: any) {
      trackException(error.message);
      yield put(authError({
        error: error as AuthErrorPayload,
      }));
    }
  });
}

function* watchSignupEmailRequest() {
  yield takeEvery(authSignupEmailRequest, function* takeEverySignupEmailRequest(action) {
    const {
      payload: {
        email, password, closeModal,
      },
    } = action;
    try {
      const {
        additionalUserInfo,
        user: { uid },
      }: UserCredential = yield call(
        () => auth.createUserWithEmailAndPassword(email, password),
      );

      const idTokenResult: IdTokenResult = yield call(() => auth.currentUser?.getIdTokenResult());
      const userRef: DocumentSnapshot = yield call(() => firestore.collection('users').doc(uid).get());
      const user = (userRef.data() || {}) as User;

      yield put(
        authSignupEmailResponse({
          additionalUserInfo,
          uid,
          isAdmin: idTokenResult.claims?.role === 'admin',
        }),
      );

      yield call(closeModal);

      if (!user.username) {
        yield put(authSetIsProfileCreating(true));
        yield put(authSetIsAuthorized(false));
        yield put(push(RouteTypes.CreateProfile));
      } else {
        yield put(push(RouteTypes.Drops));
      }
      trackEvent('user_sign_up');
    } catch (error: any) {
      trackException(error.message);
      yield put(authError({
        error: {
          code: error.code,
          message: error.code === 'auth/email-already-in-use'
            ? i18n.t('auth.error.emailPassword')
            : error.message,
        } as AuthErrorPayload,
      }));
      yield call(closeModal);
    }
  });
}

function* watchActivateAuthRedirect() {
  yield takeEvery(activateAuthRedirect, function* activateAuthRedirectWorker() {
    const path: string | undefined = yield select((state) => state.login.onAuthRedirect);
    if (path) {
      /* eslint-disable-next-line */
      if (path !== location.pathname) { yield put(push(path)); }
      yield put(setAuthRedirect({ onAuthRedirect: '' }));
    }
  });
}

export default function* collectionSaga() {
  yield all([
    fork(watchLogoutRequest),
    fork(watchLoginCustomTokenRequest),
    fork(watchLoginEmailRequest),
    fork(watchLoginGoogleRequest),
    fork(watchLoginTwitterRequest),
    fork(watchLoginFacebookRequest),
    fork(watchLoginWechatRequest),
    fork(watchSignupEmailRequest),
    fork(watchActivateAuthRedirect),
  ]);
}
