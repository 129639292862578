import styled from 'styled-components';
import { mediaDesktop } from 'breakpoints';
import HeaderLink from './components/HeaderLink';
import Button from '../Button';

export const HeaderWrapper = styled.header`
  height: 55px;
  z-index: 40;

  position: sticky;
  top: 0;
  width: 100%;
  max-width: 100vw;
  background: ${({ theme }) => theme.colors.dark};
  box-shadow: 0 0 10px #000;

  ${mediaDesktop} {
    height: 100px;
  }
`;

export const StyledIcon = styled.img`
  width: 50px;
  height: 51px;
  padding: 13px;
  margin: -13px;
  margin-left: 12px;
  ${mediaDesktop} {
    display: none;
  }
`;

export const StyledList = styled.div`
  display: none;
  text-transform: uppercase;
  ${mediaDesktop} {
    grid-column-gap: 30px;
    align-items: center;
    display: inline-grid;
    grid-auto-flow: column;
  }
`;

export const HeaderLogo = styled.img`
  width: 89px;
  height: 21px;
  ${mediaDesktop} {
    display: none;
  }
`;

export const HeaderLogoDesktop = styled.img`
  display: none;
  ${mediaDesktop} {
    display: initial;
    width: 160px;
    height: 26px;
  }
`;

export const StyledHeaderLink = styled(HeaderLink)`
  letter-spacing: 1px;
  margin-top: 3px;
  line-height: 24px;
`;

export const HeaderRow = styled.div`
  display: flex;
  padding: 0 16px;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 55px;

  ${mediaDesktop} {
    margin: auto;
    padding: 0;
    height: 100px;
    max-width: ${({ theme }) => theme.width.mainContainer};
    justify-content: space-between;
  }
`;

export const MobileButtons = styled.div`
  display: flex;
  align-items: center;
  
  ${mediaDesktop} {
    display: none;
  }
`;

export const GetInMobile = styled(Button)`
  letter-spacing: 1px;
  display: grid;
  padding: 3px 10px;
  font-size: 12px;
  justify-items: center;

  ${mediaDesktop} {
    display: none;
  }
`;

export const IconsContainer = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-gap: 20px;
`;

export const GetInDesktop = styled(Button)`
  height: 36px;
  padding: 0 20px;
  min-width: 65px;
`;

export const AdminBadge = styled.div`
  position: fixed;
  padding: 0 20px;
  font-family: ${({ theme }) => theme.fonts.primary};
  color: ${({ theme }) => theme.colors.white};
  font-size: 14px;
  font-weight: bold;
  right: -20px;
  bottom: 8px;
  z-index: 45;
  transform: rotate(-45deg);
  background: #a9a9a9;
  text-transform: uppercase;
`;
