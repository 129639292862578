import { createReducer } from '@reduxjs/toolkit';
import { type FlowTypes } from '../../types';
import {
  flowLoginResponse,
  flowLogoutResponse,
  flowBalanceResponse,
  flowWrongModal,
  flowToggleModal,
  flowLowBalanceToggleModal,
  flowLoginRequest,
  flowBloctoLoginRequest,
  flowToggleLoading,
  flowFetchStarlyCardItemIDsResponse,
  flowFestLoginRequest,
  flowFestLoginResponse,
  flowFestToggleModal,
  flowFestToggleLoading,
  flowNFTNYCToggleModal, flowNFTNYCToggleLoading, flowNFTNYCLoginRequest, flowNFTNYCLoginResponse,
} from './flowActions';
import { knownBadNFTIds } from '../../global/constants';

interface FlowSlice {
  wallet?: FlowTypes.Wallet;
  usdcBalance?: string;
  flowBalance?: string;
  fusdBalance?: string;
  starlyBalance?: string;
  isWrongModalOpen?: {
    code: number,
    message: string
  };
  isWalletModalOpen: boolean;
  lowBalanceModal: {
    isOpen: boolean;
    currency: string;
  };
  isLoading: boolean;
  isFlowFestWalletModalOpen: boolean;
  isFlowNFTNYCWalletModalOpen: boolean;
  badNFTIds: number[];
}

const initialState: FlowSlice = {
  wallet: undefined,
  flowBalance: undefined,
  fusdBalance: undefined,
  starlyBalance: undefined,
  isWalletModalOpen: false,
  lowBalanceModal: {
    isOpen: false,
    currency: '',
  },
  isLoading: false,
  isFlowFestWalletModalOpen: false,
  isFlowNFTNYCWalletModalOpen: false,
  badNFTIds: [],
};

const authReducer = createReducer(
  initialState,
  (builder) => builder
    .addCase(flowToggleLoading, (state, { payload }) => {
      state.isLoading = payload;
    })
    .addCase(flowFestToggleLoading, (state, { payload }) => {
      state.isLoading = payload;
    })
    .addCase(flowNFTNYCToggleLoading, (state, { payload }) => {
      state.isLoading = payload;
    })
    .addCase(flowFestLoginRequest, (state) => {
      state.isLoading = true;
    })
    .addCase(flowNFTNYCLoginRequest, (state) => {
      state.isLoading = true;
    })
    .addCase(flowBloctoLoginRequest, (state) => {
      state.isLoading = true;
    })
    .addCase(flowFestLoginResponse, (state, {
      payload: {
        wallet, flowBalance, fusdBalance, starlyBalance, usdcBalance,
      },
    }) => {
      state.wallet = wallet;
      state.flowBalance = flowBalance;
      state.fusdBalance = fusdBalance;
      state.starlyBalance = starlyBalance;
      state.usdcBalance = usdcBalance;
      state.isLoading = false;
    })
    .addCase(flowNFTNYCLoginResponse, (state, {
      payload: {
        wallet, flowBalance, fusdBalance, starlyBalance, usdcBalance,
      },
    }) => {
      state.wallet = wallet;
      state.flowBalance = flowBalance;
      state.fusdBalance = fusdBalance;
      state.starlyBalance = starlyBalance;
      state.usdcBalance = usdcBalance;
      state.isLoading = false;
    })
    .addCase(flowFestToggleModal, (state, { payload }) => {
      state.isFlowFestWalletModalOpen = payload;
    })
    .addCase(flowNFTNYCToggleModal, (state, { payload }) => {
      state.isFlowNFTNYCWalletModalOpen = payload;
    })
    .addCase(flowLoginRequest, (state) => {
      state.isLoading = true;
    })
    .addCase(flowLoginResponse, (state, {
      payload: {
        wallet, flowBalance, fusdBalance, starlyBalance, usdcBalance,
      },
    }) => {
      state.wallet = wallet;
      state.flowBalance = flowBalance;
      state.fusdBalance = fusdBalance;
      state.starlyBalance = starlyBalance;
      state.usdcBalance = usdcBalance;
      state.isLoading = false;
    })
    .addCase(flowLogoutResponse, (state) => {
      state.wallet = undefined;
      state.flowBalance = undefined;
      state.fusdBalance = undefined;
      state.starlyBalance = undefined;
    })
    .addCase(flowBalanceResponse, (state, {
      payload: {
        FLOW, FUSD, STARLY, USDC,
      },
    }) => {
      state.flowBalance = FLOW;
      state.fusdBalance = FUSD;
      state.starlyBalance = STARLY;
      state.usdcBalance = USDC;
    })
    .addCase(flowWrongModal, (state, { payload }) => {
      state.isWrongModalOpen = payload;
    })
    .addCase(flowToggleModal, (state, { payload }) => {
      state.isWalletModalOpen = payload;
    })
    .addCase(flowLowBalanceToggleModal, (state, { payload: { currency, isOpen } }) => {
      state.lowBalanceModal.isOpen = isOpen;
      state.lowBalanceModal.currency = currency || 'FUSD';
    })
    .addCase(flowFetchStarlyCardItemIDsResponse, (state, { payload }) => {
      if (payload.starlyCardIDs.length) {
        const knownBadNFTIdsSet = new Set(knownBadNFTIds);
        state.badNFTIds = Array.from(new Set([...payload.starlyCardIDs].filter((x) => knownBadNFTIdsSet.has(x))));
      }
    }),
);

export default authReducer;
