import styled from 'styled-components';
import theme from 'components/theme';
import { mediaDesktop } from 'breakpoints';

export const TitleText = styled.p`
  font-family: ${theme.fonts.secondary};
  margin-top: -18px;
  letter-spacing: 1px;
  font-weight: 600;
  font-size: 20px;
  padding-bottom: 50px;
  ${mediaDesktop} {
    margin: 0;
    font-size: 30px;
  }
`;

export const ShareWrapper = styled.div`
  display: flex;
  padding-bottom: 50px;
  width: 100%;
  height: 40px;
  box-sizing: content-box;
  ${mediaDesktop} {
    height: 60px;
  }
`;

export const ShareInput = styled.input`
  appearance: none;
  height: 100%;
  font-family: ${theme.fonts.primary};
  font-size: 16px;
  font-weight: 600;
  color: ${theme.colors.primary};
  padding: 0 10px;
  border: 0;
  flex-grow: 1;
  background: ${theme.colors.silverLight};
  text-overflow: ellipsis;
  overflow: hidden; 
  ${mediaDesktop} {
    font-size: 21px;
    padding: 0 20px;
  }
`;

export const ShareBtn = styled.div`
  background-color: ${theme.colors.primary};
  color: ${theme.colors.white};
  text-transform: uppercase;
  font-size: 12px;
  padding: 15px 12px 12px;
  display: grid;
  place-content: center;
  font-family: ${theme.fonts.primary};
  font-weight: 600;
  letter-spacing: 1.5px;
  transition: background-color .2s ease-in;

  &:hover {
    cursor: pointer;
    background: ${theme.colors.primaryHover};
  }

  ${mediaDesktop} {
    font-size: 18px;
    padding: 10px 19px;
  }
`;

export const CopiedText = styled.div<{ isCopied: boolean }>`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  transform: translateY(-100%);
  background: ${theme.colors.green};
  font-family: ${theme.fonts.primary};
  display: grid;
  place-content: center;
  transition: all .1s ease-in-out;
  font-size: 16px;
  font-weight: bold;
  max-height: 40px;
  
  ${({ isCopied }) => (isCopied ? `
    height: 60px;
    opacity: 1;
  ` : `
    opacity: 0;
    height: 0;
  `)};
  
  ${mediaDesktop} {
    max-height: 60px;
    font-size: 20px;
  }
`;
