import { RouteTypes } from '../RouteTypes';

export const useProtocolHostnamePort = () => `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}`;

export const createInstagramCallbackUri = () => useProtocolHostnamePort() + RouteTypes.OAuthInstagramCallback;

export const createTwitterCallbackUri = () => useProtocolHostnamePort() + RouteTypes.OAuthTwitterCallback;

export const createWechatCallbackUri = () => useProtocolHostnamePort() + RouteTypes.OAuthWechatCallback;

export const createYoutubeCallbackUri = () => useProtocolHostnamePort() + RouteTypes.OAuthYoutubeCallback;

export const getLinkAndRedirect = async (link: string) => {
  try {
    const response = await fetch(link);
    const { data } = await response.json();

    window.open(data, '_parent', data);
  } catch (error) {
    console.error(error);
  }
};
