import {
  type ComponentType, type FC,
  lazy, memo, Suspense, useEffect, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  type RouteComponentProps, type RouteProps,
  Route, Switch, useHistory, useLocation,
} from 'react-router';

import { PackRarity } from 'global/constants';
import {
  selectAuthAuthorized,
  selectAuthIsProfileCreating,
} from 'store/auth/authSelectors';
import { selectAuthLogout } from 'store/login/loginSelectors';
import { setAuthLogout, setAuthRedirect, setLoginModal } from 'store/login/loginActions';
import { setPrevPathname } from 'store/app/appActions';

import { RouteTypes } from 'RouteTypes';
import Loader from 'components/Loader';
import HowToPlay from './views/HowToPlayView';

/**
 * All application pages
 */
const Blog = lazy(() => import(/* webpackChunkName: "Blog" */ './views/Blog'));
const Bids = lazy(() => import(/* webpackChunkName: "Bids" */ './views/Bids'));
const CardPage = lazy(() => import(/* webpackChunkName: "CardPage" */ './views/Card'));
const CardPreview = lazy(() => import(/* webpackChunkName: "CardPreview" */ './views/CardPreview'));
const CardSalePage = lazy(() => import(/* webpackChunkName: "CardSalePage" */ './views/PlaceCardForSale'));
const CreateProfile = lazy(() => import(/* webpackChunkName: "CreateProfile" */ './views/CreateProfile'));
const CurrentCollection = lazy(() => import(/* webpackChunkName: "CurrentCollection" */ './views/CurrentCollection'));
const DropsView = lazy(() => import(/* webpackChunkName: "DropsView" */ './views/Drops'));
const EditCardsView = lazy(() => import(/* webpackChunkName: "EditCardsView" */ './views/EditCards'));
const EditCollectionView = lazy(() => import(/* webpackChunkName: "EditCollectionView" */ './views/EditCollection'));
const FlowFestView = lazy(() => import(/* webpackChunkName: "FlowFestView" */ './views/FlowFestView'));
const FlowNFTNYCView = lazy(() => import(/* webpackChunkName: "FlowFestView" */ './views/FlowNFTNYCView/FlowNFTNYCView'));
const FaceControlPage = lazy(() => import(/* webpackChunkName: "FaceControlPage" */ './views/FaceControl'));
const KycView = lazy(() => import(/* webpackChunkName: "KycView" */ './views/Kyc'));
const NotFound = lazy(() => import(/* webpackChunkName: "NotFound" */ './views/NotFound'));
const NotificationsView = lazy(() => import(/* webpackChunkName: "NotificationsView" */ './views/Notifications'));
const Marketplace = lazy(() => import(/* webpackChunkName: "Marketplace" */ './views/Marketplace'));
const OAuthCallback = lazy(() => import(/* webpackChunkName: "OAuthCallback" */ './views/OAuthCallback'));
const PackPurchaseView = lazy(() => import(/* webpackChunkName: "PackPurchaseView" */ './views/PackPurchase'));
const PacksView = lazy(() => import(/* webpackChunkName: "PacksView" */ './views/PacksPage'));
const RevealPackView = lazy(() => import(/* webpackChunkName: "RevealPackView" */ './views/RevealPack'));
const Settings = lazy(() => import(/* webpackChunkName: "Settings" */ './views/Settings'));
const Staking = lazy(() => import(/* webpackChunkName: "Staking" */ './views/Staking'));
const StepsPage = lazy(() => import(/* webpackChunkName: "StepsPage" */ './views/Steps'));
// const TestView = lazy(() => import(/* webpackChunkName: "TestView" */ './views/Test'));
const TermsAndPrivacyView = lazy(() => import(/* webpackChunkName: "TermsAndPrivacyView" */ './views/TermsAndPrivacy'));
const TokenLanding = lazy(() => import(/* webpackChunkName: "TokenLanding" */ './views/TokenLanding'));
const TransactionsView = lazy(() => import(/* webpackChunkName: "TransactionsView" */ './views/Transactions'));
const Ranking = lazy(() => import(/* webpackChunkName: "Ranking" */ './views/Ranking'));
const UserView = lazy(() => import(/* webpackChunkName: "UserView" */ './views/User'));
const VestingPrize = lazy(() => import(/* webpackChunkName: "VestingPrize" */ './views/VestingPrize'));
const VestingHistoryFlow = lazy(
  () => import(/* webpackChunkName: "VestingHistoryFlow" */ './views/VestingHistory/VestingHistoryFlow'),
);
const Vesting = lazy(() => import(/* webpackChunkName: "Vesting" */ './views/Vesting'));
const VestingHistory = lazy(() => import(/* webpackChunkName: "VestingHistory" */ './views/VestingHistory/VestingHistoryInvestor'));
const Wallet = lazy(() => import(/* webpackChunkName: "Wallet" */ './views/Wallet'));
const WriteUs = lazy(() => import(/* webpackChunkName: "WriteUs" */ './views/WriteUs'));

interface AuthRouteProps extends RouteProps {
  component: ComponentType<RouteComponentProps<any>> | ComponentType<any>,
  authCallback: (pathname: string) => void,
  routeProps?: {},
  isAuthorized: boolean,
  isRegisterPage?: boolean,
}

const AuthRoute = ({
  component: Component,
  authCallback,
  routeProps,
  isAuthorized,
  isRegisterPage,
  ...args
}: AuthRouteProps) => {
  const { pathname } = useLocation();
  const isLogout = useSelector(selectAuthLogout);
  const history = useHistory();
  useEffect(() => {
    if (isLogout && typeof authCallback === 'function') {
      history.push(RouteTypes.Drops);
      return;
    }
    if (!isAuthorized && typeof authCallback === 'function') {
      // temporary solution
      const withoutRedirect = sessionStorage.getItem('wechatRegister') || isRegisterPage;
      sessionStorage.removeItem('wechatRegister');
      if (!withoutRedirect) history.push(RouteTypes.Drops);
      authCallback(pathname);
    }
  }, [isAuthorized, isLogout]);
  return (
    <Route {...args} render={(props) => <Component {...props} {...routeProps} />} />
  );
};

const RoutesInner: FC = memo(() => {
  const dispatch = useDispatch();
  const isAuthorized = useSelector(selectAuthAuthorized);
  const isProfileCreating = useSelector(selectAuthIsProfileCreating);
  const isLogout = useSelector(selectAuthLogout);

  const login = (pathname: string) => {
    dispatch(setLoginModal({ isOpen: true }));
    dispatch(setAuthRedirect({ onAuthRedirect: pathname }));
  };
  const loginWithRedirect = (pathname: string) => {
    sessionStorage.setItem('authRedirect', RouteTypes.FaceControl);
    dispatch(setLoginModal({ isOpen: true }));
    dispatch(setAuthRedirect({ onAuthRedirect: pathname }));
  };

  const voidCallback = () => {};
  useEffect(() => {
    if (isLogout) dispatch(setAuthLogout({ isLogout: false }));
  }, [isLogout]);
  return (
    <Switch>
      <Route path={RouteTypes.BlogPost} component={Blog} />
      <Route path={RouteTypes.Blog} component={Blog} />
      <Route path={RouteTypes.CardQR} component={CardPage} exact />
      <Route exact path={RouteTypes.Drops} component={DropsView} />
      {
        Object.values(PackRarity).map((cardType) => (
          <AuthRoute
            key={cardType}
            path={`${RouteTypes.EditCards}/${cardType}`}
            component={EditCardsView}
            authCallback={login}
            isAuthorized={isAuthorized}
            routeProps={{ editCardsType: cardType }}
            exact
          />
        ))
      }
      <AuthRoute
        path={RouteTypes.EditCollection}
        component={EditCollectionView}
        authCallback={login}
        isAuthorized={isAuthorized}
        exact
      />
      <AuthRoute
        path={RouteTypes.Wallet}
        component={Wallet}
        authCallback={login}
        isAuthorized={isAuthorized}
        exact
      />
      <AuthRoute
        path={RouteTypes.Transactions}
        component={TransactionsView}
        authCallback={login}
        isAuthorized={isAuthorized}
      />
      <AuthRoute
        path={RouteTypes.PackPurchase}
        component={PackPurchaseView}
        authCallback={login}
        isAuthorized={isAuthorized}
        exact
      />
      <AuthRoute
        path={`${RouteTypes.PackPurchase}/:collectionId/:rarity`}
        component={PackPurchaseView}
        authCallback={login}
        isAuthorized={isAuthorized}
        exact
      />
      <AuthRoute
        path={RouteTypes.Packs}
        component={PacksView}
        authCallback={login}
        isAuthorized={isAuthorized}
        exact
      />
      <Route path={`${RouteTypes.RevealPack}/:collectionId/:packId/:rarity`} component={RevealPackView} exact />
      <AuthRoute
        path={RouteTypes.PlaceCardForSale}
        component={CardSalePage}
        authCallback={login}
        isAuthorized={isAuthorized}
        exact
      />
      <AuthRoute
        path={RouteTypes.Notifications}
        component={NotificationsView}
        authCallback={login}
        isAuthorized={isAuthorized}
      />
      <AuthRoute
        path={RouteTypes.StepDefault}
        component={StepsPage}
        authCallback={loginWithRedirect}
        isAuthorized={isAuthorized}
        exact
      />
      <AuthRoute
        path={RouteTypes.Steps}
        component={StepsPage}
        authCallback={loginWithRedirect}
        isAuthorized={isAuthorized}
      />
      <AuthRoute
        path={RouteTypes.CreateProfile}
        component={CreateProfile}
        authCallback={voidCallback}
        isAuthorized={isProfileCreating}
        isRegisterPage
        exact
      />
      <AuthRoute
        path={RouteTypes.Settings}
        component={Settings}
        authCallback={login}
        isAuthorized={isAuthorized}
        exact
      />
      <AuthRoute
        path={RouteTypes.Staking}
        component={Staking}
        authCallback={login}
        isAuthorized={isAuthorized}
      />
      <AuthRoute
        path={RouteTypes.VestingPrize}
        component={VestingPrize}
        authCallback={login}
        isAuthorized={isAuthorized}
        exact
      />
      <AuthRoute
        path={RouteTypes.VestingHistoryFlow}
        component={VestingHistoryFlow}
        authCallback={login}
        isAuthorized={isAuthorized}
        exact
      />
      <Route
        path={RouteTypes.Vesting}
        component={Vesting}
        exact
      />
      <Route
        path={RouteTypes.VestingHistory}
        component={VestingHistory}
        exact
      />
      <AuthRoute
        path={RouteTypes.FaceControl}
        component={FaceControlPage}
        authCallback={loginWithRedirect}
        isAuthorized={isAuthorized}
        exact
      />
      {/* <AuthRoute
        path={RouteTypes.Test}
        component={TestView}
        authCallback={login}
        isAuthorized={isAuthorized}
      /> */}
      <Route
        path={RouteTypes.FlowFest}
        component={FlowFestView}
      />
      <Route
        path={RouteTypes.FlowNFTNYC}
        component={FlowNFTNYCView}
      />
      <AuthRoute
        path={RouteTypes.Bids}
        component={Bids}
        authCallback={login}
        isAuthorized={isAuthorized}
      />
      <Route path={[RouteTypes.PrivacyPolicy, RouteTypes.TermsOfUse]} component={TermsAndPrivacyView} />
      <Route path={RouteTypes.WriteUs} component={WriteUs} />
      <Route path={RouteTypes.Kyc} component={KycView} />
      <Route exact path={RouteTypes.OAuthInstagramCallback} component={OAuthCallback} />
      <Route exact path={RouteTypes.OAuthTwitterCallback} component={OAuthCallback} />
      <Route exact path={RouteTypes.OAuthWechatCallback} component={OAuthCallback} />
      <Route exact path={RouteTypes.OAuthYoutubeCallback} component={OAuthCallback} />
      <Route exact path={RouteTypes.HowToPlay} component={HowToPlay} />
      <Route path={RouteTypes.UserById} component={UserView} />
      <Route path={RouteTypes.Ranking} component={Ranking} />
      <Route path={RouteTypes.Marketplace} component={Marketplace} />
      <Route
        exact
        path={RouteTypes.Collection}
        render={({ match }) => <CurrentCollection key={match.params.collectionId} match={match} />}
      />
      <Route exact path={RouteTypes.CardPreview} component={CardPreview} />
      <Route exact path={RouteTypes.Card} component={CardPage} />
      <Route exact path={RouteTypes.TokenLanding} component={TokenLanding} />
      <Route path={RouteTypes.NotFound} component={NotFound} />
      <Route path={RouteTypes.User} component={UserView} />
      <Route path={RouteTypes.Any} component={NotFound} />
    </Switch>
  );
});

const Routes = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [pathname, setPathname] = useState(history.location.pathname);
  useEffect(() => {
    dispatch(setPrevPathname({
      prev: false,
      pathname,
    }));
    history.listen((location) => {
      setPathname((prevPathname) => {
        if (prevPathname !== location.pathname) {
          dispatch(setPrevPathname({
            prev: true,
            pathname: prevPathname,
          }));
        }
        return location.pathname;
      });
    });
  }, []);
  return (<Suspense fallback={<Loader />}><RoutesInner /></Suspense>);
};

export default Routes;
