import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Button from 'components/Button';
import PopUp from 'components/PopUp';
import { flowLowBalanceToggleModal } from 'store/flow/flowActions';
import { selectLowBalanceModal } from 'store/flow/flowSelectors';
import TopUp from 'views/Modals/WalletModal/TopUp/TopUp';

import { ButtonsContainer, TittleText } from './styled';

const InsufficientBalanceModal = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const closeTopUpModal = () => dispatch(flowLowBalanceToggleModal({ isOpen: false }));
  const { currency } = useSelector(selectLowBalanceModal);
  const [isTopUpOpen, setIsTopUpOpen] = useState(false);

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);
  return (
    <>
      <PopUp onHide={closeTopUpModal}>
        <TittleText size="popUpTitle" textColor="black" font="secondary">
          {t('wallet.balance.notEnough', { currency: currency.toLowerCase() === 'flow' ? 'flow' : 'funds' })}
        </TittleText>
        <ButtonsContainer>
          <Button onClick={() => setIsTopUpOpen(true)}>{t('wallet.balance.topUp')}</Button>
          <Button background="black" onClick={closeTopUpModal}>
            {t('wallet.notNow')}
          </Button>
        </ButtonsContainer>
      </PopUp>
      {isTopUpOpen && (
        <TopUp
          currency={currency}
          setIsOpen={() => {
            closeTopUpModal();
            setIsTopUpOpen(false);
          }}
        />
      )}
    </>
  );
};

export default InsufficientBalanceModal;
