import * as fcl from '@blocto/fcl';
import * as t from '@onflow/types';
import { type FungibleTokenType } from '@starly/starly-types';
import { onError } from 'helpers/onError';
import { tx } from '../util/tx';
// import additionalAuthorization from '../services/additionalAuthorization';

type TokenTemplate = {
  title: string;
  address: string;
  vault: string;
  receiver: string;
};
const tokensMap: { [key in FungibleTokenType]?: TokenTemplate } = {
  FUSD: {
    title: 'FUSD',
    address: '0xFUSD_ADDRESS',
    vault: '/storage/fusdVault',
    receiver: '/public/fusdReceiver',
  },
  STARLY: {
    title: 'StarlyToken',
    address: '0xSTARLY_TOKEN_ADDRESS',
    vault: 'StarlyToken.TokenStoragePath',
    receiver: 'StarlyToken.TokenPublicReceiverPath',
  },
  FLOW: {
    title: 'FlowToken',
    address: '0xFLOW_TOKEN_ADDRESS',
    vault: '/storage/flowTokenVault',
    receiver: '/public/flowTokenReceiver',
  },
  USDC: {
    title: 'FiatToken',
    address: '0xFIAT_TOKEN_ADDRESS',
    vault: 'FiatToken.VaultStoragePath',
    receiver: 'FiatToken.VaultReceiverPubPath',
  },
};

const CODE = (token: FungibleTokenType) => fcl.cdc`
  import FungibleToken from 0xFUNGIBLE_TOKEN_ADDRESS
  import NonFungibleToken from 0xNON_FUNGIBLE_TOKEN_ADDRESS
  import ${tokensMap[token]?.title} from ${tokensMap[token]?.address}
  import StarlyIDParser from 0xSTARLY_ID_PARSER_ADDRESS
  import StarlyCard from 0xSTARLY_CARD_ADDRESS
  import StarlyCardBidV3 from 0xSTARLY_CARD_BID_ADDRESS
  import StarlyCardMarket from 0xSTARLY_CARD_MARKET_ADDRESS
  import StarlyRoyalties from 0xSTARLY_ROYALTIES_ADDRESS

  transaction(nftID: UInt64, starlyID: String, bidPrice: UFix64) {
    let bidVault: &FungibleToken.Vault
    let bidCollection: &StarlyCardBidV3.Collection{StarlyCardBidV3.CollectionManager}
    let bidderAddress: Address
    let bidderFungibleReceiver: Capability<&{FungibleToken.Receiver}>

    let beneficiaryCutPercent: UFix64
    let creatorCutPercent: UFix64
    let minterCutPercent: UFix64

    let beneficiarySaleCutReceiver: Capability<&{FungibleToken.Receiver}>
    let creatorSaleCutReceiver: Capability<&{FungibleToken.Receiver}>
    let minterSaleCutReceiver: Capability<&{FungibleToken.Receiver}>

    prepare(signer: AuthAccount) {
        self.bidderAddress = signer.address;

        if signer.borrow<&StarlyCardBidV3.Collection>(from: StarlyCardBidV3.CollectionStoragePath) == nil {
          let collection <- StarlyCardBidV3.createEmptyCollection() as! @StarlyCardBidV3.Collection
          signer.save(<- collection, to: StarlyCardBidV3.CollectionStoragePath)

          signer.link<&StarlyCardBidV3.Collection{StarlyCardBidV3.CollectionPublic}>(StarlyCardBidV3.CollectionPublicPath, target: StarlyCardBidV3.CollectionStoragePath)
        }

        self.bidCollection = signer.borrow<&StarlyCardBidV3.Collection{StarlyCardBidV3.CollectionManager}>(from: StarlyCardBidV3.CollectionStoragePath)!
        self.bidVault = signer.borrow<&FungibleToken.Vault>(from: ${tokensMap[token]?.vault})
            ?? panic("Cannot borrow ${tokensMap[token]?.title} vault from acct storage")

        let parsedStarlyID = StarlyIDParser.parse(starlyID: starlyID)
        let collectionID = parsedStarlyID.collectionID
        let starlyRoyalty = StarlyRoyalties.getStarlyRoyalty()
        let collectionRoyalty = StarlyRoyalties.getCollectionRoyalty(collectionID: collectionID)
            ?? panic("Could not get creator royalty")
        let minterRoyalty = StarlyRoyalties.getMinterRoyalty(collectionID: collectionID, starlyID: starlyID)
            ?? panic("Could not get minter royalty")

        self.beneficiaryCutPercent = starlyRoyalty.cut
        self.creatorCutPercent = collectionRoyalty.cut
        self.minterCutPercent = minterRoyalty.cut

        let beneficiary = getAccount(starlyRoyalty.address);
        self.beneficiarySaleCutReceiver = beneficiary.getCapability<&{FungibleToken.Receiver}>(${tokensMap[token]?.receiver})!
        assert(self.beneficiarySaleCutReceiver.borrow() != nil, message: "Missing or mis-typed ${tokensMap[token]} receiver (beneficiary)")
        
        let creator = getAccount(collectionRoyalty.address)
        self.creatorSaleCutReceiver = creator.getCapability<&{FungibleToken.Receiver}>(${tokensMap[token]?.receiver})!
        assert(self.creatorSaleCutReceiver.borrow() != nil, message: "Missing or mis-typed ${tokensMap[token]} receiver (creator)")

        let minter = getAccount(minterRoyalty.address)
        self.minterSaleCutReceiver = minter.getCapability<&{FungibleToken.Receiver}>(${tokensMap[token]?.receiver})!
        assert(self.minterSaleCutReceiver.borrow() != nil, message: "Missing or mis-typed ${tokensMap[token]} receiver (minter)")

        self.bidderFungibleReceiver = signer.getCapability<&{FungibleToken.Receiver}>(${tokensMap[token]?.receiver})!
        assert(self.bidderFungibleReceiver.borrow() != nil, message: "Missing or mis-typed ${tokensMap[token]} receiver (bidder)")

        assert(self.beneficiaryCutPercent + self.creatorCutPercent + self.minterCutPercent < 1.0,
          message: "Sum of beneficiaryCutPercent, creatorCutPercent and minterCutPercent should be below 1.0")
    }

    execute {
        let bid <- StarlyCardBidV3.createBid(
            nftID: nftID,
            starlyID: starlyID,
            bidPrice: bidPrice,
            bidVaultType: Type<@${tokensMap[token]?.title}.Vault>(),
            bidderAddress: self.bidderAddress,
            bidderFungibleReceiver: self.bidderFungibleReceiver,
            bidderFungibleProvider: self.bidVault,
            beneficiarySaleCutReceiver: StarlyCardMarket.SaleCutReceiverV2(
                receiver: self.beneficiarySaleCutReceiver,
                percent: self.beneficiaryCutPercent),
            creatorSaleCutReceiver: StarlyCardMarket.SaleCutReceiverV2(
                receiver: self.creatorSaleCutReceiver,
                percent: self.creatorCutPercent),
            minterSaleCutReceiver: StarlyCardMarket.SaleCutReceiverV2(
                receiver: self.minterSaleCutReceiver,
                percent: self.minterCutPercent)
        )
        self.bidCollection.insert(bid: <- bid)
    }
  }
`;

export async function flowCreateBidTransaction(
  nftId: number,
  starlyId: string,
  bidPrice: number,
  bidCurrency: FungibleTokenType,
  opts = { onError },
) {
  // Get latest block info
  const block = await fcl.send([fcl.getBlock(false) as Function]).then(fcl.decode);

  return tx(
    [
      fcl.transaction(CODE(bidCurrency)),
      fcl.args([
        fcl.arg(nftId, t.UInt64),
        fcl.arg(starlyId, t.String),
        fcl.arg(String(bidPrice.toFixed(8)), t.UFix64),
      ]),
      fcl.payer(fcl.authz),
      fcl.proposer(fcl.authz),
      fcl.authorizations([fcl.authz]),
      fcl.limit(9999),
      fcl.ref(block.id),
    ],
    opts,
  );
}
