import type { FC } from 'react';
import Typography from 'components/Typography';

const HeaderLink: FC = ({ children, ...rest }) => (
  <Typography font="secondary" textColor="white" size="unmedia20" {...rest}>
    {children}
  </Typography>
);

export default HeaderLink;
