import { createReducer } from '@reduxjs/toolkit';
import type { StarlyCardBid } from '@starly/starly-types';

import {
  bidsFetchBids,
  bidsFetchResponse,
  bidsRemoveBid,
  bidsSetBidProgress,
  bidsSetCardProgress,
  bidsSetSuccessPopup,
} from './bidsActions';

interface BidsSlice {
  receivedBids: StarlyCardBid[] | null,
  sentBids: StarlyCardBid[] | null,
  isLoading: boolean
  isSuccessPopupOpen: boolean
  cardsInProgress: { [key: number]: boolean },
  bidsInProgress: { [key: number]: boolean },
}

const initialState: BidsSlice = {
  receivedBids: null,
  sentBids: null,
  isLoading: false,
  isSuccessPopupOpen: false,
  cardsInProgress: {},
  bidsInProgress: {},
};

const vestingReducer = createReducer(
  initialState,
  (builder) => builder
    .addCase(bidsFetchBids, (state) => {
      state.isLoading = true;
    })
    .addCase(bidsSetSuccessPopup, (state, { payload: { isOpen } }) => {
      state.isSuccessPopupOpen = isOpen;
    })
    .addCase(bidsFetchResponse, (state, { payload: { bids, receivedBids } }) => {
      state.sentBids = bids;
      if (receivedBids) {
        state.receivedBids = receivedBids;
      }
      state.isLoading = false;
    })
    .addCase(bidsSetCardProgress, (state, { payload: { nftItemId, inProgress } }) => {
      state.cardsInProgress[nftItemId] = inProgress;
    })
    .addCase(bidsSetBidProgress, (state, { payload: { bidId, inProgress } }) => {
      state.bidsInProgress[bidId] = inProgress;
    })
    .addCase(bidsRemoveBid, (state, { payload: { bidId, isSentBid } }) => {
      if (isSentBid) {
        state.sentBids = state.sentBids!.filter((bid) => bid.bid_id !== bidId);
      } else {
        state.receivedBids = state.receivedBids!.filter((bid) => bid.bid_id !== bidId);
      }
    }),
);

export default vestingReducer;
