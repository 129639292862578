import {
  type FC, useContext, useEffect, useState,
} from 'react';
import { useHistory, useLocation } from 'react-router';

import { AppContext } from '../../context/context';
import ComponentPortal from '../ComponentPortal';
import Selector from './components/Select/Select';

import type { TabsSelectorProps } from './types';

const TabsSelector: FC<TabsSelectorProps> = ({ links }) => {
  const history = useHistory();
  const { pathname } = useLocation();

  const options = links.map((link) => ({ value: link.id, label: link.label, href: link.href }));
  const findCurrentTab = () => {
    const index = options.findIndex(({ href }) => href === pathname);
    return index > -1 ? index : undefined;
  };
  const [currentTab, setCurrentTab] = useState(findCurrentTab());

  useEffect(() => {
    setCurrentTab(findCurrentTab());
  }, [pathname]);

  const { topRef } = useContext(AppContext);

  return (
    <ComponentPortal element={topRef?.current}>
      <Selector
        values={options}
        defaultValueIndex={currentTab}
        valueIndex={currentTab}
        onSelect={(option) => history.push(option?.href || options[0].href)}
      />
    </ComponentPortal>
  );
};

export default TabsSelector;
