import type { StarlyCardBid } from '@starly/starly-types';
import {
  all, call, delay, fork, put, race, select, take, takeEvery,
} from 'redux-saga/effects';

import { flowAcceptBidTransaction } from 'flow/bids/acceptBid.tx';
import { flowCancelBidTransaction } from 'flow/bids/cancelBid.tx';
import { flowCreateBidTransaction } from 'flow/bids/createBid.tx';
import { flowDeclineBidTransaction } from 'flow/bids/declineBid.tx';
import { firestore, type QuerySnapshot, trackException } from 'global/firebase';
import { selectAuthUserId } from 'store/auth/authSelectors';
import { flowBalanceRequest } from 'store/flow/flowActions';

import { cardRequest, cardResponse } from 'store/card/cardActions';
import {
  bidsAcceptBid,
  bidsCancelBid,
  bidsCreateBid,
  bidsDeclineBid,
  bidsFetchBids,
  bidsFetchResponse,
  bidsRemoveBid,
  bidsSetBidProgress,
  bidsSetCardProgress,
  bidsSetSuccessPopup,
} from './bidsActions';

function* waitForConfirmation(transactionId: string) {
  const promise = new Promise((resolve) => {
    firestore.collection('flowTransactions').doc(transactionId).onSnapshot((snapshot) => {
      const snapData = snapshot.data();
      if (snapData && snapData.state === 'processed') {
        resolve(snapData);
      }
    });
  });

  yield race({
    result: promise,
    delay: delay(15000),
  });
}

function* watchBidsFetchBids() {
  yield takeEvery(bidsFetchBids, function* takeEveryBidsFetchBids(action) {
    const { payload: { isSentOnly } } = action;
    try {
      const userId: string = yield select(selectAuthUserId);

      const bidsRef: QuerySnapshot = yield call(() => firestore.collection('bids')
        .where('bidder_user_id', '==', userId)
        .where('bid_state', '==', 'new')
        .get());
      const bids = bidsRef.docs
        .map((doc) => doc.data() as StarlyCardBid)
        .sort((a, b) => b.create_date.seconds - a.create_date.seconds);

      let receivedBids: StarlyCardBid[] = [];
      if (!isSentOnly) {
        const receivedBidsRef: QuerySnapshot = yield call(() => firestore.collection('bids')
          .where('seller_user_id', '==', userId)
          .where('bid_state', '==', 'new')
          .get());
        receivedBids = receivedBidsRef.docs
          .map((doc) => doc.data() as StarlyCardBid)
          .sort((a, b) => b.create_date.seconds - a.create_date.seconds);
      }
      yield put(bidsFetchResponse({ bids, receivedBids }));
    } catch (error: any) {
      trackException(error.message);
    }
  });
}

function* watchBidsCreateBid() {
  yield takeEvery(bidsCreateBid, function* takeEveryBidsCreateBid(action) {
    const {
      payload: {
        nftItemId,
        starlyId,
        amount,
        currency,
      },
    } = action;
    try {
      yield put(bidsSetCardProgress({ nftItemId, inProgress: true }));

      const result = yield call(flowCreateBidTransaction, nftItemId, starlyId, amount, currency);
      const errMessage = result ? result?.errorMessage as string : '';
      const transactionId = result?.events?.find((event) => event.transactionId)?.transactionId;

      if (result && errMessage) {
        return;
      }
      yield call(waitForConfirmation, transactionId);
    } catch (error: any) {
      trackException(error.message);
    } finally {
      yield put(bidsSetCardProgress({ nftItemId, inProgress: false }));
    }
    yield put(bidsSetSuccessPopup({ isOpen: true }));
    yield put(flowBalanceRequest({}));
    yield put(bidsFetchBids({}));
  });
}
function* watchBidsCancelBid() {
  yield takeEvery(bidsCancelBid, function* takeEveryBidsCancelBid(action) {
    const {
      payload: {
        bidId,
      },
    } = action;
    try {
      yield put(bidsSetBidProgress({ bidId, inProgress: true }));
      const result = yield call(flowCancelBidTransaction, bidId);
      const errMessage = result ? result?.errorMessage as string : '';
      const transactionId = result?.events?.find((event) => event.transactionId)?.transactionId;

      if (result && errMessage) {
        return;
      }
      yield call(waitForConfirmation, transactionId);
    } catch (error: any) {
      trackException(error.message);
    } finally {
      yield put(bidsSetBidProgress({ bidId, inProgress: false }));
    }
    yield put(flowBalanceRequest({}));
    yield put(bidsRemoveBid({ bidId, isSentBid: true }));
  });
}
function* watchBidsAcceptBid() {
  yield takeEvery(bidsAcceptBid, function* takeEveryBidsAcceptBid(action) {
    const {
      payload: {
        bidId,
        bidderAddress,
        collectionId,
        cardId,
        edition,
      },
    } = action;
    try {
      yield put(bidsSetBidProgress({ bidId, inProgress: true }));

      yield put(cardRequest({ collectionId, cardId, edition }));

      const cardProps: ReturnType<typeof cardResponse> = yield take(cardResponse);

      const cardEdition = cardProps.payload.cardState.editions[edition];
      const stakeId = cardEdition.staked ? cardEdition.stake_id! : null;
      const result = yield call(flowAcceptBidTransaction, bidId, bidderAddress, stakeId);
      const errMessage = result ? result?.errorMessage as string : '';
      const transactionId = result?.events?.find((event) => event.transactionId)?.transactionId;

      if (result && errMessage) {
        return;
      }
      yield call(waitForConfirmation, transactionId);
    } catch (error: any) {
      trackException(error.message);
    } finally {
      yield put(bidsSetBidProgress({ bidId, inProgress: false }));
    }
    yield put(flowBalanceRequest({}));
    yield put(bidsRemoveBid({ bidId, isSentBid: false }));
  });
}
function* watchBidsDeclineBid() {
  yield takeEvery(bidsDeclineBid, function* takeEveryBidsDeclineBid(action) {
    const {
      payload: {
        bidId,
        bidderAddress,
      },
    } = action;
    try {
      yield put(bidsSetBidProgress({ bidId, inProgress: true }));
      const result = yield call(flowDeclineBidTransaction, bidId, bidderAddress);
      const errMessage = result ? result?.errorMessage as string : '';
      const transactionId = result?.events?.find((event) => event.transactionId)?.transactionId;

      if (result && errMessage) {
        return;
      }
      yield call(waitForConfirmation, transactionId);
    } catch (error: any) {
      trackException(error.message);
    } finally {
      yield put(bidsSetBidProgress({ bidId, inProgress: false }));
    }
    yield put(bidsRemoveBid({ bidId, isSentBid: false }));
  });
}

export default function* flowSaga() {
  yield all([
    fork(watchBidsFetchBids),
    fork(watchBidsCreateBid),
    fork(watchBidsCancelBid),
    fork(watchBidsAcceptBid),
    fork(watchBidsDeclineBid),
  ]);
}
