import { Component, StrictMode } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import './global/config';
import './global/firebase';

let hasChunkLoadError = false;

class ErrorBoundary extends Component {
  componentDidCatch(error) {
    if (error.toString().includes('ChunkLoadError')) {
      if (!hasChunkLoadError) {
        hasChunkLoadError = true;
        window.location.reload();
      }
    }
    this.setState(null);
  }

  render() {
    return (
      <App />
    );
  }
}

ReactDOM.render(
  <StrictMode>
    <ErrorBoundary />
  </StrictMode>,
  document.getElementById('root'),
);
