import { useLayoutEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

const ScrollToTop = () => {
  const { pathname, search } = useLocation();
  const history = useHistory();

  useLayoutEffect(() => () => {
    if (history.action !== 'POP') {
      window.scrollTo(0, 0);
    }
  }, [pathname, search]);

  return null;
};

export default ScrollToTop;
