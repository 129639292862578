import type { FC } from 'react';
import shortid from 'shortid';
import {
  CheckboxItem,
  CustomCheckbox,
  CheckboxLabel,
} from './styled';
import type { CheckBoxProps } from './types';

const CheckBox: FC<CheckBoxProps> = ({
  label,
  isEnable,
  name,
  register,
  onChange,
  id = shortid(),
  $filter,
  'data-test-id': dataTestId,
}) => {
  const registerObject = register && name ? register(name) : {};
  return (
    <CheckboxItem $filter={$filter}>
      <CustomCheckbox
        data-test-id={`cb_checkbox_${name}_${dataTestId}`}
        id={id}
        type="checkbox"
        name={name || 'notifications'}
        data-testid="checkbox"
        checked={isEnable}
        onChange={onChange}
        {...registerObject}
      />
      <CheckboxLabel htmlFor={id} data-test-id={`cb_label_${name}_${dataTestId}`} data-testid="notifications_checkbox">
        {label}
      </CheckboxLabel>
    </CheckboxItem>
  );
};

export default CheckBox;
