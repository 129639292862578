import styled from 'styled-components';
import Table, { ColumnLink, Row } from 'components/Table/Table';
import {
  InfoPanel, PurchaseHeadline, PurchaseValue,
} from '../PurchaseCardPopUp/styled';
import { mediaDesktop } from '../../breakpoints';
import Media from '../Media';

export const TableContainer = styled.div`
  overflow-x: hidden;
  max-width: 100%;
  padding-bottom: 32px;
  &::-webkit-scrollbar {
    width: 2px;
  }
  &::-webkit-scrollbar-thumb {
    background:rgba(255, 255, 255, 0.5) ;
  }
  &::-webkit-scrollbar-track-piece {
    position: relative;
  }
`;

export const StyledInfoPanel = styled(InfoPanel)`
  grid-template-columns: max-content max-content 1fr;
  grid-column-gap: 20px;
  ${mediaDesktop} {
    grid-gap: 88px;
  }
`;

export const RewardSelectContainer = styled.div`
  grid-column: 1 / -1;
  ${mediaDesktop} {
    grid-column: auto;
    align-self: end;
  }
`;

export const RewardValue = styled(PurchaseValue)`
  font-size: 20px;
  ${mediaDesktop} {
    font-size: 30px
  }
`;

export const RewardTable = styled(Table)`
  position: relative;
  overflow-x: auto;
  ${mediaDesktop} {
    overflow-x: unset;
  }
`;

export const RewardPopUpTitle = styled(PurchaseHeadline)`
  margin-top: 10px;
`;

export const RewardRow = styled(Row)`
  padding-left: 0;
  padding-top: 24px;
  padding-bottom: 24px;
  grid-template-columns: 75px 305px 200px minmax(170px, 1fr);
  grid-gap: 10px;
  font-size: 18px;
`;

export const StyledColumnLink = styled(ColumnLink)`
  display: flex;
`;

export const RewardHeaderRow = styled(RewardRow)`
  position: sticky;
  top: 0;
  font-size: 16px;
`;

export const RewardRowUserTextContainer = styled.div`
  display: grid;
  & p:first-of-type {
    font-weight: bold;
  }
  
  & p {
    display: inline-block;
    max-width: 100%;
    overflow: hidden;
    font-size: inherit;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;

export const RewardRowUserContainer = styled.div`
  height: 72px;
  display: grid;
  grid-gap: 20px;
  grid-template-columns: 72px 1fr;
  align-items: center;
`;

export const RewardRowMedia = styled(Media)`
  width: 72px;
  height: 72px;
  border-radius: 100%;
`;
