import {
  type FC, type MouseEvent, useEffect, useRef, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import { type ChildRefFunc } from 'components/Media';
import NotificationsLoader from 'components/NotificationsLoader/NotificationsLoader';
import { isIosMobile } from 'helpers/isIos';
import { ReactComponent as SoundOn } from 'static/ic-sound-off.svg';
import { ReactComponent as SoundOff } from 'static/ic-sound-on.svg';
import { setActiveCard } from 'store/app/appActions';
import { selectActiveCard } from 'store/app/appSelectors';

import CardFullScreen from '../CardFullscreen';
import {
  AnimatedDots,
  FullScreenMask,
  FullScreenVideoContainer,
  MediaLoader,
  MutedIcon,
  Processing,
  StyledAssetContainerWrapper,
  StyledImageContainer,
  StyledVideoMediaLoadContainer,
} from '../styled';

import { type CardProps } from '../types';

const url = new URL(window.location.href);
const headless = url.searchParams.get('headless');

interface CardComponentProps {
  card: CardProps;
  isCardPage?: boolean,
  isVideoEnded?: boolean,
  collectionId?: string,
  isTrimmedCard?: boolean,
  isFlowFest?: boolean,
}

const CardMedia: FC<CardComponentProps> = ({
  card: {
    media,
    media_original,
    edition,
    uploadProgress,
    state: mediaState,
    cardId,
  },
  isCardPage = false,
  isVideoEnded,
  collectionId = '',
  isTrimmedCard = false,
  isFlowFest,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const params = useParams<{ cardId?: string }>();
  const cardContainer = useRef<HTMLDivElement>(null);
  const videoContainer = useRef<ChildRefFunc>(null);
  const fullscreenVideoContainer = useRef<ChildRefFunc>(null);
  const activeCard = useSelector(selectActiveCard);

  const starlyId = `${collectionId}/${cardId}/${edition}`;

  const isActiveCard = isFlowFest
    ? activeCard === cardId
    : activeCard === starlyId;

  const [isFullScreen, setIsFullScreen] = useState<boolean>(false);
  const isVideo = media?.type === 'video' || false;
  const [isMuted, setIsMuted] = useState<boolean>(!isIosMobile() && !isActiveCard);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [fullScreenVideoWidth, setFullScreenVideoWidth] = useState<number>(0);

  const switchMuted = (e: MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setIsMuted(!isMuted);

    if (isFlowFest) {
      if (cardId) {
        dispatch(setActiveCard(!isMuted ? cardId : null));
      }
    } else if (cardId && collectionId) {
      dispatch(setActiveCard(!isMuted ? starlyId : null));
    }
  };

  const setMuted = () => {
    setIsMuted(false);
  };

  // check ios conditional
  useEffect(() => {
    if (isIosMobile() && isFullScreen) setIsMuted(false);
  }, [isFullScreen]);

  const onLoad = () => {
    setIsLoaded(true);
  };

  const switchFullScreen = () => {
    const header = document.getElementsByTagName('header');
    if (isFullScreen) {
      document.body.style.overflow = 'auto';
      if (header[0].parentElement) {
        header[0].parentElement.style.zIndex = '40';
      }
    }
    setIsFullScreen(!isFullScreen);
  };
  const pauseVideo = () => {
    videoContainer.current?.pauseVideo?.();
  };
  const playVideo = () => {
    videoContainer.current?.playVideo?.();
  };
  const onTabBlur = () => {
    if (isFullScreen) {
      fullscreenVideoContainer.current?.pauseVideo?.();
    } else {
      pauseVideo();
    }
  };

  const onFocusTab = () => {
    if (isFullScreen) {
      fullscreenVideoContainer.current?.playVideo?.();
    } else {
      playVideo();
    }
  };

  useEffect(() => {
    if (!isVideo) return undefined;
    const onVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        onFocusTab();
      } else {
        onTabBlur();
      }
    };
    window.addEventListener('visibilitychange', onVisibilityChange);
    return () => {
      window.removeEventListener('visibilitychange', onVisibilityChange);
    };
  }, [isFullScreen]);

  useEffect(() => {
    if (!window.IntersectionObserver) return undefined;
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          if (videoContainer?.current) {
            playVideo();
          }
        } else if (videoContainer?.current) {
          pauseVideo();
        }
      });
    });

    if (cardContainer.current && isVideo) {
      observer.observe(cardContainer.current);
    }

    return () => {
      if (cardContainer.current) {
        observer.unobserve(cardContainer.current);
      }
    };
  }, [cardContainer]);

  useEffect(() => {
    if (!params.cardId) {
      setIsMuted(isActiveCard);
    }
  }, [isActiveCard]);

  // clear sound on
  useEffect(() => () => {
    if (isActiveCard) dispatch(setActiveCard(null));
  }, []);

  // get video width if fullscreen mode
  useEffect(() => {
    if (isVideo && isFullScreen) {
      const video = document.getElementsByTagName('video');
      if (video.length !== 0) {
        setFullScreenVideoWidth(video[0].clientWidth);
      }
    } else {
      setFullScreenVideoWidth(0);
    }
  }, [isFullScreen, isVideo]);

  // switch video if full screen mode and media original type is image
  useEffect(() => {
    if (media_original?.type === 'image') {
      if (isFullScreen) {
        pauseVideo();
      } else {
        playVideo();
      }
    }
  }, [isFullScreen, media_original]);
  return (
    <>
      {isVideo && media ? (
        <StyledAssetContainerWrapper
          data-test-id="video_wrapper"
          $isClickable={isCardPage}
          onClick={() => isCardPage && switchFullScreen()}
        >
          <MediaLoader $isLoaded={isLoaded}>
            <NotificationsLoader small={false} />
          </MediaLoader>
          {!isTrimmedCard && (
            <MutedIcon
              data-test-id="mute_icon"
              onClick={switchMuted}
              $fullscreen={isFullScreen}
              $headless={!!headless}
            >
              {!isMuted ? <SoundOn /> : <SoundOff />}
            </MutedIcon>
          )}
          {(mediaState === 'unprocessed' || (uploadProgress && uploadProgress < 100)) && (
            <Processing>
              <AnimatedDots>{t('card.processing')}</AnimatedDots>
            </Processing>
          )}
          <StyledVideoMediaLoadContainer
            ref={videoContainer}
            media={media}
            controls={false}
            isMuted={!isMuted || isFullScreen}
            lazy={!isCardPage}
            onLoad={onLoad}
            isVideoPlaying={isVideoEnded}
            $fullscreen={isFullScreen}
            $videoWidth={videoContainer.current?.width}
            data-test-id="card_media_video"
            setMuted={setMuted}
          />
          {isFullScreen && media_original?.type !== 'image' && (
            <>
              <FullScreenMask />
              <FullScreenVideoContainer data-test-id="full_screen_video" $width={fullScreenVideoWidth} />
            </>
          )}
        </StyledAssetContainerWrapper>
      ) : (
        <StyledAssetContainerWrapper $isClickable={isCardPage}>
          <MediaLoader $isLoaded={isLoaded}>
            <NotificationsLoader small={false} />
          </MediaLoader>
          {media && (
            <StyledImageContainer
              data-test-id="card_media_picture"
              media={media}
              onClick={() => isCardPage && switchFullScreen()}
              lazy={!isCardPage}
              onLoad={onLoad}
            />
          )}
        </StyledAssetContainerWrapper>
      )}
      {
        isFullScreen && (
          <CardFullScreen
            media={media_original || media}
            onClose={switchFullScreen}
            isMuted={!isMuted}
            switchMuted={setIsMuted}
            videoRef={videoContainer}
            fullscreenVideoRef={fullscreenVideoContainer}
          />
        )
      }
    </>
  );
};

export default CardMedia;
