import {
  all, call, debounce, delay, fork, put, select, take, takeEvery,
} from 'redux-saga/effects';
import type { Notification, User } from '@starly/starly-types';
import type { EventChannel } from 'redux-saga';
import { eventChannel } from 'redux-saga';
import type firebase from 'firebase/compat/app';
import {
  type DocumentSnapshot,
  type QuerySnapshot,
  firestore,
  TimestampNow,
  trackException,
} from '../../global/firebase';

import { flowBalanceRequest } from '../flow/flowActions';
import {
  notificationsLastSeen,
  notificationsLoad,
  notificationsLoading,
  notificationsMoreToLoad,
  notificationsRequest,
  notificationsResponse,
  notificationsSetDelayRequest,
  notificationsSetIsOpen,
  notificationsSetPage,
  notificationsSetRealtimeListener,
  notificationsUnreadRequest,
  notificationsUnreadResponse,
  removeNotification,
  resetNotifications,
} from './notificationsActions';
import type { NotificationsSlice } from './notificationsReducer';

const LIMIT = 20;

function* notificationRequestWatcher() {
  yield takeEvery(notificationsRequest, function* notificationRequestWorker(action) {
    const { pageNumber } = action.payload;
    const { hasMoreToLoad }: NotificationsSlice = yield select((state) => state.notifications);
    const userId: string = yield select(({ auth }) => auth.userId);
    if (!hasMoreToLoad) return;
    yield put(notificationsLoading({ isLoading: true }));
    let notificationsQuery = firestore
      .collection('users')
      .doc(userId)
      .collection('notifications')
      .orderBy('create_time', 'desc')
      .limit(LIMIT);

    if (pageNumber) {
      notificationsQuery = notificationsQuery.startAfter(pageNumber);
    }

    const notificationsSnapshot: QuerySnapshot = yield call(() => notificationsQuery.get());

    yield put(notificationsSetPage({ page: notificationsSnapshot.docs[notificationsSnapshot.docs.length - 1] }));

    const dataRow: Notification[] = notificationsSnapshot
      .docs
      .map((doc) => {
        const docData = doc.data() as Notification;
        docData.create_time = docData.create_time.toDate();
        return docData;
      });

    yield put(notificationsResponse({ notifications: dataRow }));

    if (dataRow.length < LIMIT) {
      yield put(notificationsMoreToLoad({ hasMoreToLoad: false }));
    }

    if (!pageNumber) {
      yield put(flowBalanceRequest({}));
    }

    yield put(notificationsLoad({ isLoad: true }));
    yield put(notificationsLoading({ isLoading: false }));
  });
}

function* unreadNotificationsWatcher() {
  yield takeEvery(notificationsUnreadRequest, function* unreadNotificationsWorker() {
    const userId: string = yield select(({ auth }) => auth.userId);
    try {
      const userSnap: DocumentSnapshot = yield call(() => firestore
        .collection('users')
        .doc(userId)
        .get());
      const user: User = userSnap.data() as User;
      let unread: number = 0;
      const lastSeen = user?.last_seen_notifications_date;
      if (lastSeen) {
        const notifs: QuerySnapshot = yield call(() => firestore
          .collection(`users/${userId}/notifications`)
          .where('create_time', '>', lastSeen)
          .get());
        unread = notifs.docs.length;
      } else {
        const notifs: QuerySnapshot = yield call(() => firestore
          .collection(`users/${userId}/notifications`)
          .get());
        unread = notifs.docs.length;
      }
      yield put(notificationsUnreadResponse({ unread }));
      if (unread > 0) yield put(resetNotifications());
    } catch (error: any) {
      trackException(error.message);
    }
  });
}

function* notificationsLastSeenWatcher() {
  yield debounce(450, notificationsLastSeen, function* notificationsLastSeenWorker() {
    const userId: string = yield select(({ auth }) => auth.userId);
    yield call(() => firestore
      .doc(`users/${userId}`)
      .update('last_seen_notifications_date', TimestampNow()));
    yield delay(300);
    yield put(notificationsUnreadRequest());
  });
}

function* notificationsCloseWatcher() {
  yield takeEvery(notificationsSetIsOpen, function* notificationsCloseWorker({
    payload: { isOpen },
  }) {
    const delayRequest: boolean = yield select(({ notifications }) => notifications.isDelayRequest);
    if (!isOpen && delayRequest) yield put(notificationsUnreadRequest());
  });
}

function* notificationsListenerWatcher() {
  yield takeEvery(notificationsSetRealtimeListener, function* notificationsListenerWorker() {
    const userId: string = yield select(({ auth }) => auth.userId);
    const channel: EventChannel<firebase.firestore.DocumentData> = eventChannel<firebase.firestore.DocumentData>((emitter) => {
      const unsubscribe = firestore
        .collection(`users/${userId}/notifications`)
        .onSnapshot((snap) => {
          const changes = snap.docChanges().find((dc) => dc.type === 'added' || dc.type === 'removed');
          if (changes) {
            emitter(changes);
          }
        });

      return () => {
        unsubscribe();
      };
    });
    while (true) {
      const changes: firebase.firestore.DocumentChange<firebase.firestore.DocumentData> = yield take(channel);
      if (changes.type === 'removed') {
        yield put(removeNotification({ notification: changes.doc.data() as Notification }));
      }
      const isOpen: boolean = yield select(({ notifications }) => notifications.isNotificationsOpen);
      if (isOpen) yield put(notificationsSetDelayRequest({ isDelayRequest: true }));
      else if (!isOpen) yield put(notificationsUnreadRequest());
    }
  });
}

export default function* notificationsSaga() {
  yield all([
    fork(notificationRequestWatcher),
    fork(notificationsLastSeenWatcher),
    fork(unreadNotificationsWatcher),
    fork(notificationsCloseWatcher),
    fork(notificationsListenerWatcher),
  ]);
}
