import styled from 'styled-components';
import Typography from '../../../Typography';
import { NotificationWrapper } from '../../../Notification/styled';

export const NotificationDropdownContainer = styled.div`
  width: 400px;
  max-height: 532px;
  overflow-y: auto;
  border-radius: 12px;
  background: #000;
  padding: 0 0;
  border: 1px solid rgba(255, 255, 255, 0.3);
  text-transform: none;
  &::-webkit-scrollbar {
    width: 2px;
  }
  &::-webkit-scrollbar-thumb {
    background:rgba(255, 255, 255, 0.5) ;
  }
  &::-webkit-scrollbar-track-piece {
    margin: 15px 0;
    position: relative;
  }
`;

export const NotificationContainer = styled.div`
  border-radius: inherit;
  padding: 22px 0;
  display: grid;
  grid-gap: 20px;
  justify-content: center;
  & >${Typography} {
    font-size: 16px;
    padding: 20px;
  }
`;

export const StyledNotification = styled.div`
  width: fit-content;
  transition: .3s;
  & > ${NotificationWrapper} {
    grid-template-columns: max-content 247px max-content;
    grid-gap: 20px;
    
    & ${Typography} {
      font-size: 16px;
    }
  }
`;
