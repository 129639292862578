import { createReducer } from '@reduxjs/toolkit';
import type {
  Card, CardRarity, Collection, Media, MediaState, MediaType, PackDistribution,
} from '@starly/starly-types';
import { getDiffByOrder, getRarityByOrder } from 'util/rarity';

import {
  activeCollectionRequest,
  activeCollectionResponse,
  type CardInfo,
  changeStep3SelectValues,
  collectionResponse,
  type CollectionStats,
  collectionStatsResponse,
  countersRequest,
  countersResponse,
  type CreateCardRarity,
  deleteCollectionError,
  deleteCollectionRequest,
  deleteCollectionResetError,
  deleteCollectionSuccess,
  initEmptyActiveCollection,
  lastCollectionReset,
  lastCollectionResponse,
  postStep2Request,
  postStep2Response,
  publishCollectionError,
  publishCollectionRequest,
  publishCollectionResetStatus,
  publishCollectionResponse,
  removeDeleteCollectionMessage,
  resetValidationStep2,
  setActiveCollection,
  setCoverMediaState,
  setCreateModal,
  setDeleteCollectionMessage,
  setMedia,
  setMediaState,
  setStep,
  setUnavailableModal,
  startEdit,
  startLoading,
  switchCreating,
  topCollectorsResponse,
  toStartStep,
  updateCollectionError,
  updateFlag,
  updateStep1Request,
  updateStep1Response,
  updateStep3Response,
  uploadingCoverMedia,
  uploadingMedia,
  validateStep2,
  viewAllCards,
  writeCardImageResponse,
  writeCoverImageResponse,
} from './collectionActions';

export interface Step2Cards {
  title: string,
  description: string,
  order: number,
  rarity: CardRarity,
  qrcode: string,
  uploadProgress: number,
  isUpload: boolean,
  state: MediaState,
  type: MediaType,
  url?: string,
  // access: 'allowed' | 'default',
  access: string,
}

interface CollectionSlice {
  items: {
    [key: string]: Collection & { _cached_cards_reward: Card[] }
  },
  collectionStatsItems: {
    [key: string]: CollectionStats
  }
  activeCollection: {
    id: string,
    isEdit: boolean,
    isLoad: boolean,
    isLoading: boolean,
    activeStep: number,
    isUploadCover: boolean,
    uploadStatusCover: number,
    showPublishedMsg: boolean,
    publicationInProgress: boolean,
    coverLoading: boolean,
    error: string,
    isUpdated: boolean,
    updateError?: string | null,
    cardInfo: CardInfo,
    packDistribution?: PackDistribution,
    step1: {
      title: string,
      description: string,
      promo_video_url: string,
      imgCover: string,
      mediaObj: Media,
    },
    step2: {
      [key in CreateCardRarity]: Step2Cards[]
    },
    step3: {
      isComplete: boolean,
      priceIndex: number,
      dropDurIndex: number,
      saleDurIndex: number,
    },
    deleteCollection: {
      error: string | null,
      isLoading: boolean,
    },
  },
  itemsCachedState: {
    [key: string]: {
      viewedAllCards: boolean,
      viewedTopCollectors: boolean
    },
  },
  counters: {
    [collectionId: string]: {
      [key: string]: any,
      common_left?: number,
      common_sold?: number,
      legendary_left?: number,
      legendary_sold?: number,
      rare_left?: number,
      rare_sold?: number,
      // loading: boolean,
    }
  },
  lastCreatedCollection: {
    [userId: string]: {
      collection?: Collection,
      isLoaded?: boolean,
    }
  },
  showDeleteMessage: {
    isActive: boolean,
  },
  isCreateModalOpen: boolean,
  isUnavailableModalOpen: boolean,
}

const chooseActiveStep = ({ activeCollection: { step2, step3 } }: CollectionSlice) => {
  const isStep2Complete = step2.legendary.every((card) => card.title && card.url)
    && step2.common.every((card) => card.title && card.url)
    && step2.rare.every((card) => card.title && card.url)
    && step2.reward.every((card) => card.title && card.url);

  if (!step3.isComplete && isStep2Complete) {
    return 3;
  }
  if (isStep2Complete) {
    return 2;
  }
  return 1;
};

const initialState: CollectionSlice = {
  items: {},
  collectionStatsItems: {},
  counters: {},
  itemsCachedState: {},
  activeCollection: {
    id: '',
    isEdit: false,
    isLoad: false,
    isLoading: false,
    activeStep: 1,
    isUploadCover: true,
    uploadStatusCover: 100,
    showPublishedMsg: false,
    publicationInProgress: false,
    coverLoading: false,
    error: '',
    isUpdated: false,
    // cardInfo: {},
    // packDistribution: {},
    step1: {
      title: '',
      description: '',
      promo_video_url: '',
      imgCover: '',
      mediaObj: {},
    },
    step2: {
      reward: [],
      legendary: [],
      rare: [],
      common: [],
    },
    step3: {
      isComplete: false,
      priceIndex: 0,
      dropDurIndex: 0,
      saleDurIndex: 0,
    },
    deleteCollection: {
      error: null,
      isLoading: false,
    },
  },
  lastCreatedCollection: {},
  showDeleteMessage: {
    isActive: false,
  },
  isCreateModalOpen: false,
  isUnavailableModalOpen: false,
};

const mapCard = ({
  order,
  media,
  qrcode,
  rarity,
  title,
  description,
}: Card) => {
  const type = media?.type || 'image';
  const urlFieldName = type === 'image' ? 'url' : 'mp4';
  const url = media?.sizes?.[0][urlFieldName];
  return {
    title,
    description,
    order,
    rarity,
    qrcode,
    uploadProgress: 100,
    isUpload: true,
    state: media?.state ?? 'new',
    type,
    ...(url && { url }),
    access: title && (url || media?.url) ? 'allowed' : 'default',
  };
};

const collectionReducer = createReducer(
  initialState,
  (builder) => builder
    .addCase(collectionResponse, (state, { payload: { collection } }) => {
      if (collection.id) {
        state.items[collection.id] = {
          ...collection,
          _cached_cards_reward: collection._cached_cards
            ?.filter((v) => v.rarity.slice(-5) === '_plus')
            .sort((a, b) => a.order - b.order),
        };
      }
    })
    .addCase(publishCollectionRequest, (state) => {
      state.activeCollection.publicationInProgress = true;
    })
    .addCase(publishCollectionResponse, (state) => {
      state.activeCollection = {
        ...initialState.activeCollection,
        publicationInProgress: false,
        showPublishedMsg: true,
      };
    })
    .addCase(publishCollectionError, (state, { payload: { error } }) => {
      console.error(error);
      state.activeCollection.error = error;
      state.activeCollection.publicationInProgress = false;
    })
    .addCase(publishCollectionResetStatus, (state) => {
      state.activeCollection.showPublishedMsg = false;
      state.activeCollection.error = '';
      state.activeCollection.publicationInProgress = false;
    })
    .addCase(activeCollectionResponse, (state,
      {
        payload: {
          collectionId,
          title,
          description,
          promo_video_url,
          imgCover,
          mediaObj,
          _cached_cards,
          priceIndex,
          dropDurIndex,
          saleDurIndex,
          defaultStep,
          cardInfo,
          packDistribution,
        },
      }) => {
      state.activeCollection.cardInfo = {
        ...cardInfo,
        reward: {
          distinct_cards: (
            cardInfo?.rare_plus?.distinct_cards
            + cardInfo?.common_plus?.distinct_cards
            + cardInfo?.legendary_plus?.distinct_cards
          ),
          card_editions: 0,
        },
      };
      state.activeCollection.error = '';
      state.activeCollection.showPublishedMsg = false;
      state.activeCollection.id = collectionId;
      state.activeCollection.packDistribution = packDistribution;
      state.activeCollection.step1 = {
        ...state.activeCollection.step1,
        title,
        description,
        promo_video_url,
        imgCover,
        mediaObj,
      };
      state.activeCollection.step3 = {
        priceIndex: priceIndex ?? 0,
        dropDurIndex: dropDurIndex ?? 0,
        saleDurIndex: saleDurIndex ?? 0,
        isComplete: [priceIndex, dropDurIndex, saleDurIndex]
          .map((item) => item ?? 'nullish')
          .every((item) => (item === 'nullish')),
      };
      if (_cached_cards) {
        const currL = _cached_cards.filter((card) => card.rarity === 'legendary').map(mapCard);
        currL.forEach((card, i) => {
          state.activeCollection.step2.legendary[i] = card;
        });
        const currR = _cached_cards.filter((card) => card.rarity === 'rare').map(mapCard);
        currR.forEach((card, i) => {
          state.activeCollection.step2.rare[i] = card;
        });
        const currC = _cached_cards.filter((card) => card.rarity === 'common').map(mapCard);
        currC.forEach((card, i) => {
          state.activeCollection.step2.common[i] = card;
        });
        const currReward = _cached_cards.filter((card) => card.rarity.slice(-5) === '_plus').map(mapCard);
        currReward.forEach((card, i) => {
          state.activeCollection.step2.reward[i] = card;
        });
      }
      if (!state.activeCollection.isEdit) {
        state.activeCollection.activeStep = defaultStep || chooseActiveStep(state);
      } else {
        state.activeCollection.isEdit = false;
      }
      state.activeCollection.isLoad = true;
      state.activeCollection.isLoading = false;
    })
    .addCase(setActiveCollection, (state,
      {
        payload: {
          collectionId,
          title,
          description,
          promo_video_url,
          cover_media,
        },
      }) => {
      state.activeCollection.error = '';
      state.activeCollection.showPublishedMsg = false;
      state.activeCollection.id = collectionId;
      state.activeCollection.step1 = {
        title,
        description,
        promo_video_url,
        imgCover: cover_media?.sizes?.[0].url ?? '',
        mediaObj: cover_media,
      };
      state.activeCollection.isLoad = true;
    })
    .addCase(initEmptyActiveCollection, (state) => {
      state.activeCollection = {
        ...initialState.activeCollection,
      };
    })
    .addCase(validateStep2, (state) => {
      state.activeCollection.step2.legendary = state.activeCollection.step2.legendary.map((item) => ({
        ...item,
        access: item.access === 'default' ? 'error' : 'allowed',
      }));
      state.activeCollection.step2.rare = state.activeCollection.step2.rare.map((item) => ({
        ...item,
        access: item.access === 'default' ? 'error' : 'allowed',
      }));
      state.activeCollection.step2.common = state.activeCollection.step2.common.map((item) => ({
        ...item,
        access: item.access === 'default' ? 'error' : 'allowed',
      }));
      state.activeCollection.step2.reward = state.activeCollection.step2.reward.map((item) => ({
        ...item,
        access: item.access === 'default' ? 'error' : 'allowed',
      }));
    })
    .addCase(resetValidationStep2, (state) => {
      state.activeCollection.step2.legendary = state.activeCollection.step2.legendary.map((item) => ({
        ...item,
        access: item.access === 'allowed' ? 'allowed' : 'default',
      }));
      state.activeCollection.step2.rare = state.activeCollection.step2.rare.map((item) => ({
        ...item,
        access: item.access === 'allowed' ? 'allowed' : 'default',
      }));
      state.activeCollection.step2.common = state.activeCollection.step2.common.map((item) => ({
        ...item,
        access: item.access === 'allowed' ? 'allowed' : 'default',
      }));
      state.activeCollection.step2.reward = state.activeCollection.step2.reward.map((item) => ({
        ...item,
        access: item.access === 'allowed' ? 'allowed' : 'default',
      }));
    })
    .addCase(startEdit, (state) => {
      state.activeCollection.isEdit = true;
    })
    .addCase(updateStep1Response, (state, {
      payload: {
        colId,
        title,
        description,
        promo_video_url,
      },
    }) => {
      state.activeCollection.step1 = {
        ...state.activeCollection.step1,
        title,
        description,
        promo_video_url,
      };
      if (colId && state.items[colId]) {
        state.items[colId].title = title;
        state.items[colId].description = description;
        state.items[colId].promo_video_url = promo_video_url;
      }
      state.activeCollection.updateError = null;
    })
    .addCase(changeStep3SelectValues, (state, {
      payload: {
        field,
        value,
      },
    }) => {
      state.activeCollection.step3[field] = value;
    })
    .addCase(updateStep3Response, (state, {
      payload: {
        priceIndex,
        dropDurIndex,
        saleDurIndex,
      },
    }) => {
      state.activeCollection.step3.priceIndex = priceIndex;
      state.activeCollection.step3.dropDurIndex = dropDurIndex;
      state.activeCollection.step3.saleDurIndex = saleDurIndex;
      state.activeCollection.isLoad = true;
    })
    .addCase(postStep2Request, (state, { payload: { type: rarity, data } }) => {
      const cardsCount = state.activeCollection.cardInfo[rarity].distinct_cards;
      for (let i = 1; i <= cardsCount; i += 1) {
        if (data[`cardTitle${i}`] || data[`cardDesc${i}`] || data[`cardTitle${i}`] === '' || data[`cardDesc${i}`] === '') {
          state.activeCollection.step2[rarity][i - 1].title = data[`cardTitle${i}`];
          state.activeCollection.step2[rarity][i - 1].description = data[`cardDesc${i}`];
          state.activeCollection.step2[rarity][i - 1].access = (
            data[`cardTitle${i}`] && (
              data[`image${i}saved`] || (state.activeCollection.step2[rarity][i - 1].access === 'allowed')
            )
          ) ? 'allowed' : 'default';
        }
      }
    })
    .addCase(postStep2Response, (state) => {
      state.activeCollection.activeStep = 2;
    })
    .addCase(setCoverMediaState, (state, { payload: { media, colId } }) => {
      state.activeCollection.step1.imgCover = media?.sizes?.[0].url ?? '';
      state.activeCollection.step1.mediaObj = media;
      state.activeCollection.coverLoading = false;

      if (colId && state.items[colId]) {
        state.items[colId].cover_media = media;
      }
    })
    .addCase(writeCoverImageResponse, (state, { payload: { base64, mediaObj } }) => {
      state.activeCollection.step1.imgCover = base64;
      state.activeCollection.step1.mediaObj = mediaObj;
      state.activeCollection.isUploadCover = true;
      state.activeCollection.coverLoading = true;
    })
    .addCase(uploadingCoverMedia, (state, { payload: { progress } }) => {
      if (state.activeCollection.isUploadCover) {
        state.activeCollection.isUploadCover = false;
      }
      state.activeCollection.uploadStatusCover = progress;
    })
    .addCase(writeCardImageResponse, (state, { payload: { cardId, file } }) => {
      const { cardInfo } = state.activeCollection;
      const rarity = getRarityByOrder(cardId, cardInfo);
      const currCardId = cardId - getDiffByOrder(cardId, cardInfo) - 1;
      state.activeCollection.step2[rarity][currCardId].type = file.type === 'video/mp4' ? 'video' : 'image';
    })
    .addCase(switchCreating, (state) => {
      state.activeCollection.isUploadCover = true;
    })
    .addCase(uploadingMedia, (state, {
      payload: {
        progress,
        rarity,
        index,
      },
    }) => {
      if (state.activeCollection.step2[rarity][index].isUpload) {
        state.activeCollection.step2[rarity][index].isUpload = false;
        state.activeCollection.step2[rarity][index].access = 'default';
      }
      state.activeCollection.step2[rarity][index].uploadProgress = progress;
    })
    .addCase(setMedia, (state, {
      payload: {
        url,
        rarity,
        index,
        type,
        values,
      },
    }) => {
      const cardsCount = state.activeCollection.cardInfo[rarity].distinct_cards;
      for (let i = 1; i <= cardsCount; i += 1) {
        if (values[`cardTitle${i}`] || values[`cardDesc${i}`] || values[`cardTitle${i}`] === '' || values[`cardDesc${i}`] === '') {
          state.activeCollection.step2[rarity][i - 1].title = values[`cardTitle${i}`];
          state.activeCollection.step2[rarity][i - 1].description = values[`cardDesc${i}`];
        }
      }
      state.activeCollection.step2[rarity][index].url = url;
      state.activeCollection.step2[rarity][index].type = type;
      state.activeCollection.step2[rarity][index].isUpload = true;
      state.activeCollection.step2[rarity][index].state = 'new';
      state.activeCollection.step2[rarity][index].access = 'default';
    })
    .addCase(setMediaState, (state, {
      payload: {
        media,
        rarity,
        index,
      },
    }) => {
      const { url } = mapCard({ media });
      state.activeCollection.step2[rarity][index].state = media.state;
      state.activeCollection.step2[rarity][index].type = media.type;
      state.activeCollection.step2[rarity][index].url = url;
      state.activeCollection.step2[rarity][index].access = (
        media.state === 'processed'
          && state.activeCollection.step2[rarity][index].title ? 'allowed' : 'default'
      );
    })
    .addCase(startLoading, (state) => {
      state.activeCollection.isLoad = false;
    })
    .addCase(activeCollectionRequest, (state) => {
      state.activeCollection.isLoading = true;
    })
    .addCase(toStartStep, (state) => {
      state.activeCollection.activeStep = 1;
    })
    .addCase(setStep, (state, { payload: { value } }) => {
      state.activeCollection.activeStep = value;
    })
    .addCase(viewAllCards, (state, { payload: { id, value } }) => {
      if (id) {
        state.itemsCachedState[id] = {
          ...state.itemsCachedState[id],
          viewedAllCards: value,
        };
      }
    })
    .addCase(topCollectorsResponse, (state, { payload: { topCollectors, id } }) => {
      if (id) {
        state.items[id] = {
          ...state.items[id],
          _cached_top_collectors: topCollectors,
        };
        state.itemsCachedState[id] = {
          ...state.itemsCachedState[id],
          viewedTopCollectors: true,
        };
      }
    })
    .addCase(countersRequest, (state, { payload: { id } }) => {
      state.counters[id] = {
        // loading: true,
        id,
      };
    })
    .addCase(countersResponse, (state, { payload: { counterData, id } }) => {
      if (counterData) {
        state.counters[id] = {
          ...counterData,
          id,
          // loading: false
        };
      } else {
        // state.counters[id].loading = false;
      }
    })
    .addCase(lastCollectionResponse, (state, { payload: { userId, data } }) => {
      state.lastCreatedCollection[userId] = {
        collection: data,
        isLoaded: true,
      };
    })
    .addCase(lastCollectionReset, (state, { payload: { id } }) => {
      delete state.lastCreatedCollection[id];
    })
    .addCase(setDeleteCollectionMessage, (state) => {
      state.showDeleteMessage.isActive = true;
    })
    .addCase(removeDeleteCollectionMessage, (state) => {
      state.showDeleteMessage.isActive = false;
    })
    .addCase(deleteCollectionError, (state, { payload: { error } }) => {
      state.activeCollection.deleteCollection.error = error;
      state.activeCollection.deleteCollection.isLoading = false;
    })
    .addCase(deleteCollectionSuccess, (state, { payload: { id } }) => {
      delete state.items[id];
      state.activeCollection.deleteCollection.isLoading = false;
    })
    .addCase(deleteCollectionRequest, (state) => {
      state.activeCollection.deleteCollection.error = null;
      state.activeCollection.deleteCollection.isLoading = true;
    })
    .addCase(deleteCollectionResetError, (state) => {
      state.activeCollection.deleteCollection.error = null;
      state.activeCollection.deleteCollection.isLoading = false;
    })
    .addCase(updateCollectionError, (state, { payload: { error } }) => {
      state.activeCollection.updateError = error;
    })
    .addCase(updateStep1Request, (state) => {
      state.activeCollection.updateError = undefined;
    })
    .addCase(updateFlag, (state, { payload: { id, isUpdated } }) => {
      if (id === state.activeCollection.id) {
        state.activeCollection.isUpdated = isUpdated;
      }
    })
    .addCase(setCreateModal, (state, { payload: { isOpen } }) => {
      state.isCreateModalOpen = isOpen;
    })
    .addCase(collectionStatsResponse, (state, { payload: { collectionId, stats } }) => {
      state.collectionStatsItems[collectionId] = stats;
    })
    .addCase(setUnavailableModal, (state, { payload: { isOpen } }) => {
      state.isUnavailableModalOpen = isOpen;
    }),
);

export default collectionReducer;
