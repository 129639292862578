import { createAction } from '@reduxjs/toolkit';
import type { AdditionalUserInfo } from 'types';

type AuthLoginEmailRequestPayload = {
  email: string,
  password: string,
  closeModal: () => void,
};

type AuthLoginResponsePayload = {
  additionalUserInfo?: AdditionalUserInfo,
  uid: string,
  isAdmin: boolean,
};

type AuthLoginWechatRequestPayload = {
  token?: string,
};

type AuthLoginCustomTokenRequestPayload = {
  id: string,
  closeModal: () => void,
};

type AuthSignupEmailRequestPayload = {
  email: string,
  password: string,
  closeModal: () => void,
};

export interface AuthErrorPayload {
  code: number
  message: string
}

export const authLogoutRequest = createAction('auth/logout/request');

export const authLogoutResponse = createAction('auth/logout/response');

export const authLoginEmailRequest = createAction<AuthLoginEmailRequestPayload>('auth/login/email/request');

export const authLoginEmailResponse = createAction<AuthLoginResponsePayload>('auth/login/email/response');

export const authLoginGoogleRequest = createAction('auth/login/google/request');

export const authLoginGoogleResponse = createAction<AuthLoginResponsePayload>('auth/login/google/response');

export const authLoginTwitterRequest = createAction('auth/login/twitter/request');

export const authLoginTwitterResponse = createAction<AuthLoginResponsePayload>('auth/login/twitter/response');

export const authLoginFacebookRequest = createAction('auth/login/facebook/request');

export const authLoginFacebookResponse = createAction<AuthLoginResponsePayload>('auth/login/facebook/response');

export const authLoginWechatRequest = createAction<AuthLoginWechatRequestPayload>('auth/login/wechat/request');

export const authLoginWechatResponse = createAction<AuthLoginResponsePayload>('auth/login/wechat/response');

export const authLoginCustomTokenRequest = createAction<AuthLoginCustomTokenRequestPayload>('auth/login/custom_token/request');

export const authLoginCustomTokenResponse = createAction<AuthLoginResponsePayload>('auth/login/custom_token/response');

export const authSignupEmailRequest = createAction<AuthSignupEmailRequestPayload>('auth/signup/email/request');

export const authSignupEmailResponse = createAction<AuthLoginResponsePayload>('auth/signup/email/response');

export const authError = createAction<{ error: AuthErrorPayload }>('auth/error');

export const authSetIsAuthorized = createAction<boolean>('auth/SET_IS_AUTHORIZED');

export const authSetIsProfileCreating = createAction<boolean>('auth/SET_IS_PROFILE_CREATING');
