import { trackException } from 'global/firebase';

export const onError = (e: any) => {
  let message = 'Unkown transaction error';

  if (typeof e === 'string') {
    message = e;
  } else if (typeof e?.message === 'string') {
    message = e.message;
  }

  trackException(message, true);

  return {
    errorMessage: message,
  };
};
