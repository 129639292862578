import type { FormEvent } from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';

import { desktopBreakpoint } from 'breakpoints';
import Button from 'components/Button';
import NotificationsLoader from 'components/NotificationsLoader/NotificationsLoader';
import PopUp from 'components/PopUp';
import { useWindowSize } from 'hooks/useWindowSize';
import { RouteTypes } from 'RouteTypes';
import { flowLoginRequest, flowToggleModal } from 'store/flow/flowActions';
import { selectFlowIsLoading } from 'store/flow/flowSelectors';

import {
  ButtonsContainer, ConnectingButtonContainer, LoaderWrapper, TittleText,
} from './styled';

const WalletModal = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const isLoading = useSelector(selectFlowIsLoading);
  const width = useWindowSize();
  const isMobile = width < desktopBreakpoint;
  const closeWalletModal = () => dispatch(flowToggleModal(false));
  const onWalletLogin = (e: FormEvent) => {
    e.preventDefault();

    dispatch(flowLoginRequest({}));
  };
  const getText = () => {
    switch (pathname) {
      case `${RouteTypes.StepDefault}4`: {
        return t('wallet.wrong.collectionCreate');
      }
      case RouteTypes.Packs: {
        return t('wallet.wrong.openPack');
      }
      default: return t('wallet.connectText');
    }
  };
  const getClose = () => {
    switch (pathname) {
      case RouteTypes.Packs: {
        return t('common.close');
      }
      default: return t('wallet.notNow');
    }
  };

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => { document.body.style.overflow = 'auto'; };
  }, []);
  return (
    <PopUp data-test-id="wallet_popup">
      <TittleText data-test-id="wallet_title_text" size="popUpTitle" textColor="black" font="secondary">
        {getText()}
      </TittleText>
      <ButtonsContainer>
        <Button data-test-id="wallet_connect_button" onClick={(e) => onWalletLogin(e)} disabled={isLoading}>
          {isLoading
            ? (
              <ConnectingButtonContainer>
                {t('wallet.connecting')}
                <LoaderWrapper>
                  <NotificationsLoader small={isMobile} />
                </LoaderWrapper>
              </ConnectingButtonContainer>
            )
            : t('wallet.connectWallet')}
        </Button>
        <Button background="black" onClick={closeWalletModal} data-test-id="wallet_close_button">{getClose()}</Button>
      </ButtonsContainer>
    </PopUp>
  );
};

export default WalletModal;
