import { createAction } from '@reduxjs/toolkit';
import type { FungibleTokenType, StarlyCardBid } from '@starly/starly-types';

type BidsFetchRequestPayload = {
  isSentOnly?: boolean,
};

type BidsFetchResponsePayload = {
  bids: StarlyCardBid[],
  receivedBids: StarlyCardBid[],
};

type BidsCreateBidPayload = {
  nftItemId: number,
  starlyId: string,
  amount: number,
  currency: FungibleTokenType,
};

type BidsCancelBidPayload = {
  bidId: number,
};

type BidsAcceptBidPayload = {
  bidId: number,
  bidderAddress: string,
  collectionId: string,
  cardId: string,
  edition: string,
};

type BidsDeclineBidPayload = {
  bidId: number,
  bidderAddress: string,
};

export const bidsFetchBids = createAction<BidsFetchRequestPayload>('bids/fetch/request');

export const bidsFetchResponse = createAction<BidsFetchResponsePayload>('bids/fetch/response');

export const bidsCreateBid = createAction<BidsCreateBidPayload>('bids/create/request');

export const bidsSetSuccessPopup = createAction<{ isOpen: boolean }>('bids/setSuccessPopup');

export const bidsCancelBid = createAction<BidsCancelBidPayload>('bids/cancel/request');

export const bidsAcceptBid = createAction<BidsAcceptBidPayload>('bids/accept/request');

export const bidsDeclineBid = createAction<BidsDeclineBidPayload>('bids/decline/request');

export const bidsSetCardProgress = createAction<{ nftItemId: number, inProgress: boolean }>('bids/set-card-progress');

export const bidsSetBidProgress = createAction<{ bidId: number, inProgress: boolean }>('bids/set-bid-progress');

export const bidsRemoveBid = createAction<{ bidId: number, isSentBid?: boolean }>('bids/remove-bid');
