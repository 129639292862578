import * as fcl from '@blocto/fcl';
import * as t from '@onflow/types';
import { invariant } from '@onflow/util-invariant';
import { onError } from 'helpers/onError';
import { tx } from './util/tx';
import additionalAuthorization from './services/additionalAuthorization';

const CODE = fcl.cdc`
import FungibleToken from 0xFUNGIBLE_TOKEN_ADDRESS
import NonFungibleToken from 0xNON_FUNGIBLE_TOKEN_ADDRESS
import FlowStorageFees from 0xFLOW_STORAGE_FEES_ADDRESS
import FUSD from 0xFUSD_ADDRESS
import FlowToken from 0xFLOW_TOKEN_ADDRESS
import StarlyCard from 0xSTARLY_CARD_ADDRESS
import StarlyCardMarket from 0xSTARLY_CARD_MARKET_ADDRESS

transaction(
    itemID: UInt64,
    price: UFix64,
    beneficiaryAddress: Address,
    beneficiaryCutPercent: UFix64,
    creatorAddress: Address,
    creatorCutPercent: UFix64,
    minterAddress: Address,
    minterCutPercent: UFix64) {

    prepare(signer: AuthAccount, admin: AuthAccount) {
        // we need a provider capability, but one is not provided by default so we create one.
        let StarlyCardCollectionProviderPrivatePath = /private/starlyCardCollectionProvider
        if !signer.getCapability<&StarlyCard.Collection{NonFungibleToken.Provider, StarlyCard.StarlyCardCollectionPublic}>(StarlyCardCollectionProviderPrivatePath)!.check() {
            signer.link<&StarlyCard.Collection{NonFungibleToken.Provider, StarlyCard.StarlyCardCollectionPublic}>(StarlyCardCollectionProviderPrivatePath, target: StarlyCard.CollectionStoragePath)
        }

        let starlyCardCollection = signer.getCapability<&StarlyCard.Collection{NonFungibleToken.Provider, StarlyCard.StarlyCardCollectionPublic}>(StarlyCardCollectionProviderPrivatePath)!
        assert(starlyCardCollection.borrow() != nil, message: "Missing or mis-typed StarlyCardCollection provider")

        let marketCollection = signer.borrow<&StarlyCardMarket.Collection>(from: StarlyCardMarket.CollectionStoragePath)
            ?? panic("Missing or mis-typed StarlyCardMarket Collection")

        let sellerFUSDVault = signer.getCapability<&FUSD.Vault{FungibleToken.Receiver}>(/public/fusdReceiver)!
        assert(sellerFUSDVault.borrow() != nil, message: "Missing or mis-typed seller FUSD receiver")

        let beneficiary = getAccount(beneficiaryAddress);
        let beneficiaryFUSDVault = beneficiary.getCapability<&FUSD.Vault{FungibleToken.Receiver}>(/public/fusdReceiver)!
        assert(beneficiaryFUSDVault.borrow() != nil, message: "Missing or mis-typed FUSD receiver (beneficiary)")

        let creator = getAccount(creatorAddress)
        let creatorFUSDVault = creator.getCapability<&FUSD.Vault{FungibleToken.Receiver}>(/public/fusdReceiver)!
        assert(creatorFUSDVault.borrow() != nil, message: "Missing or mis-typed FUSD receiver (creator)")

        let minter = getAccount(minterAddress)
        let minterFUSDVault = minter.getCapability<&FUSD.Vault{FungibleToken.Receiver}>(/public/fusdReceiver)!
        assert(minterFUSDVault.borrow() != nil, message: "Missing or mis-typed FUSD receiver (minter)")

        assert(beneficiaryCutPercent + creatorCutPercent + minterCutPercent < 1.0,
          message: "Sum of beneficiaryCutPercent, minterCutPercent and creatorCutPercent should be below 1.0")

        let sellerCutPercent = 1.0 - beneficiaryCutPercent - creatorCutPercent - minterCutPercent;
        let offer <- StarlyCardMarket.createSaleOffer (
            itemID: itemID,
            starlyID: starlyCardCollection.borrow()!.borrowStarlyCard(id: itemID)!.starlyID,
            price: price,
            sellerItemProvider: starlyCardCollection,
            sellerSaleCutReceiver: StarlyCardMarket.SaleCutReceiver(
                receiver: sellerFUSDVault,
                percent: sellerCutPercent),
            beneficiarySaleCutReceiver: StarlyCardMarket.SaleCutReceiver(
                receiver: beneficiaryFUSDVault,
                percent: beneficiaryCutPercent),
            creatorSaleCutReceiver: StarlyCardMarket.SaleCutReceiver(
                receiver: creatorFUSDVault,
                percent: creatorCutPercent),
            additionalSaleCutReceivers: [StarlyCardMarket.SaleCutReceiver(
                receiver: minterFUSDVault,
                percent: minterCutPercent)])
        marketCollection.insert(offer: <-offer)

        fun returnFlowFromStorage(_ storage: UInt64): UFix64 {
            let f = UFix64(storage % 100000000 as UInt64) * 0.00000001 as UFix64 + UFix64(storage / 100000000 as UInt64)
            let storageMb = f * 100.0 as UFix64
            let storage = FlowStorageFees.storageCapacityToFlow(storageMb)
            return storage
        }

        var storageUsed = returnFlowFromStorage(signer.storageUsed)
        var storageTotal = returnFlowFromStorage(signer.storageCapacity)
        if (storageUsed > storageTotal) {
            let difference = storageUsed - storageTotal
            let vaultRef = admin.borrow<&FlowToken.Vault>(from: /storage/flowTokenVault)
                ?? panic("Could not borrow reference to the admin's Vault!")
            let sentVault <- vaultRef.withdraw(amount: difference)
            let receiver = signer.getCapability(/public/flowTokenReceiver).borrow<&{FungibleToken.Receiver}>()
                ?? panic("failed to borrow reference to recipient vault")
            receiver.deposit(from: <-sentVault)
        }
    }
}`;

export async function flowCreateSaleOfferTransaction(
  itemID: number,
  price: number,
  beneficiaryAddress: string,
  beneficiaryCutPercent: number,
  creatorAddress: string,
  creatorCutPercent: number,
  minterAddress: string,
  mintingCutPercent: number,
  opts = { onError },
) {
  invariant(itemID != null, 'itemID must be supplied');
  invariant(price > 0, 'price must be positive');
  invariant(beneficiaryCutPercent > 0, 'beneficiaryCutPercent must be positive');
  invariant(beneficiaryCutPercent <= 1, 'beneficiaryCutPercent must no be greater than 1');
  invariant(creatorCutPercent > 0, 'creatorCutPercent must be positive');
  invariant(creatorCutPercent <= 1, 'creatorCutPercent must not be greater than 1');
  invariant(beneficiaryCutPercent + creatorCutPercent + mintingCutPercent <= 1,
    'beneficiaryCutPercent + creatorCutPercent + mintingCutPercent must no be greater than 1');

  // Get latest block info
  const block = await fcl.send([fcl.getBlock(false) as Function]).then(fcl.decode);

  return tx(
    [
      fcl.transaction(CODE),
      fcl.args([
        fcl.arg(BigInt(itemID).toString(), t.UInt64),
        fcl.arg(String(price.toFixed(8)), t.UFix64),
        fcl.arg(String(beneficiaryAddress), t.Address),
        fcl.arg(String(beneficiaryCutPercent.toFixed(8)), t.UFix64),
        fcl.arg(String(creatorAddress), t.Address),
        fcl.arg(String(creatorCutPercent.toFixed(8)), t.UFix64),
        fcl.arg(String(minterAddress), t.Address),
        fcl.arg(String(mintingCutPercent.toFixed(8)), t.UFix64),
      ]),
      fcl.payer(fcl.authz),
      fcl.proposer(fcl.authz),
      fcl.authorizations([fcl.authz, additionalAuthorization]),
      fcl.limit(1000),
      fcl.ref(block.id),
    ],
    opts,
  );
}
