import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { RankingTableRow, RankingTableWrapper } from '../styled';
import Table, { Column } from '../../../components/Table/Table';
import RankingContentRow from '../components/RankingContentRow';
import Typography from '../../../components/Typography';
import Loader from '../../../components/Loader';
import { LoadMarketplaceButton } from '../../Marketplace/styled';
import { rankingCollectionsRequest, resetRankingState } from '../../../store/ranking/rankingActions';
import NotificationsLoader from '../../../components/NotificationsLoader/NotificationsLoader';
import { selectRankingState } from '../../../store/ranking/rankingSelectors';
import Helmet from '../../../components/Helmet/Helmet';

const RankingPage = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const {
    rankings, page, isLoad, isMoreToLoad, isLoading, timeFilter,
  } = useSelector(selectRankingState);

  useEffect(() => {
    dispatch(rankingCollectionsRequest({ page, days: timeFilter }));
  }, [timeFilter]);

  useEffect(() => () => {
    dispatch(resetRankingState());
  }, []);

  return (
    <>
      <Helmet title={t('helmet.topCollections')} />
      {
        isLoad && rankings.length > 0 && (
          <RankingTableWrapper>
            <Table data-test-id="ranking_table_wrapper">
              <RankingTableRow data-test-id="ranking_table_header" header isDark>
                <Column textAlign="center">#</Column>
                <Column>Collection</Column>
                <Column justify="center">Sales</Column>
                <Column textAlign="center">Change</Column>
                <Column textAlign="center">Unique owners</Column>
                <Column textAlign="center">Transactions</Column>
                <Column textAlign="center">Cards in circulation</Column>
              </RankingTableRow>
              {
                rankings.map((props) => (
                  <RankingContentRow {...props} key={props.collectionName} />
                ))
              }
            </Table>
          </RankingTableWrapper>
        )
      }
      {
        isLoad && !rankings.length && (
          <Typography
            size="unmedia21"
            textColor="textWhite"
            bold
          >
            There are no rankings for that period of time
          </Typography>
        )
      }
      {!isLoad && <Loader />}
      {
        isMoreToLoad && isLoad && (
          <LoadMarketplaceButton
            data-test-id="ranking_load_more_button"
            onClick={() => {
              dispatch(rankingCollectionsRequest({
                days: timeFilter, page,
              }));
            }}
            background="dark"
            noneHover={isLoading}
            disabled={isLoading}
            $loading={isLoading}
          >
            {!isLoading && t('common.loadMore')}
            {isLoading && <NotificationsLoader inline />}
          </LoadMarketplaceButton>
        )
      }
    </>
  );
};

export default RankingPage;
