import { t } from 'i18next';
import { createSelector } from '@reduxjs/toolkit';
import type {
  Media, Notification, PackRarity,
} from '@starly/starly-types';

import type { IExtendedUser } from 'store/user/userReducer';
import { capitalize } from '../../helpers/TextHelpers';
import { RouteTypes } from '../../RouteTypes';
import mysteryPack from '../../static/ic-mystery-pack-reward@3x.png';
import commonPack from '../../static/pack/ic-thumbnail-pack-common@3x.png';
import legendaryPack from '../../static/pack/ic-thumbnail-pack-legendary@3x.png';
import rarePack from '../../static/pack/ic-thumbnail-pack-rare@3x.png';
import rewardPack from '../../static/pack/ic-thumbnail-pack-reward@3x.png';

import type { NotificationProps } from '../../components/Notification/types';
import type { RootState } from '../store';

const selectNotificationsFromStore = (state: RootState) => state.notifications;

const packThumbnails: { [key in PackRarity]: string } = {
  legendary: legendaryPack,
  rare: rarePack,
  common: commonPack,
  legendary_plus: rewardPack,
  rare_plus: rewardPack,
  common_plus: rewardPack,
  mystery: mysteryPack,
};

const getThumbnail = (notification: Notification): string | Media => {
  if (notification.type === 'reward_received') {
    return packThumbnails[notification.reward_received?.rarity!];
  }
  if (notification.type === 'pack_sold') {
    return packThumbnails[notification.pack_sold?.rarity!];
  }
  return notification[notification.type]?.card_avatar as Media;
};

const formatAmount = (amount: number) => {
  if (amount < 0.01) return '<0.01';
  return amount.toFixed(2);
};

const getNotificationsText = (notification: Notification): string => {
  const packType = notification.pack_sold?.rarity;
  switch (notification.type) {
    case 'reward_received':
      return t('notifications.rewardReceived', {
        sets: notification.reward_received?.sets,
        collectionName: notification.reward_received?.collection_name,
      });
    case 'card_sold':
      if (!notification.card_sold?.currency || notification.card_sold.currency === 'FUSD') {
        return t('notifications.cardSold', {
          amount: `$${notification.card_sold?.price.toFixed(2)}`,
        });
      }
      return t('notifications.cardSold', {
        amount: `${notification.card_sold.price.toFixed(2)} ${notification.card_sold.currency}`,
      });
    case 'pack_sold':
      if (!notification.pack_sold?.currency || notification.pack_sold.currency === 'FUSD') {
        return t('notifications.packSold', {
          packType: capitalize(packType || ''),
          amount: `$${notification.pack_sold?.price.toFixed(2)}`,
        });
      }
      return t('notifications.packSold', {
        packType: capitalize(packType || ''),
        amount: `${notification.pack_sold.price.toFixed(2)} ${notification.pack_sold.currency}`,
      });
    case 'royalty_received': {
      const amount = formatAmount(notification.royalty_received?.royalty || 0);
      if (!notification.royalty_received?.currency || notification.royalty_received.currency === 'FUSD') {
        return t('notifications.royaltyReceived', {
          amount: `$${amount}`,
        });
      }
      return t('notifications.royaltyReceived', {
        amount: `${amount} ${notification.royalty_received.currency}`,
      });
    }
    case 'minting_royalty_received': {
      const amount = formatAmount(notification.minting_royalty_received?.royalty || 0);
      if (!notification.minting_royalty_received?.currency || notification.minting_royalty_received.currency === 'FUSD') {
        return t('notifications.mintingRoyaltyReceived', {
          amount: `$${amount}`,
        });
      }
      return t('notifications.mintingRoyaltyReceived', {
        amount: `${amount} ${notification.minting_royalty_received.currency}`,
      });
    }
    case 'card_bid_created':
      return `You get a ${notification.card_bid_created?.bid_price.toFixed(2)} ${
        notification.card_bid_created?.bid_currency
      } bid for your card`;
    case 'card_bid_accepted':
      return `Your ${notification.card_bid_accepted?.bid_price.toFixed(2)} ${
        notification.card_bid_accepted?.bid_currency
      } bid was accepted`;
    case 'card_bid_declined':
      return 'Your bid was declined';
    default:
      return '';
  }
};

const getSecondaryText = (notification: Notification) => {
  if (notification.type === 'royalty_received') {
    const amount = formatAmount(notification.royalty_received?.price || 0);
    return !notification.royalty_received?.currency || notification.royalty_received.currency === 'FUSD'
      ? t('notifications.royaltyReceivedSecondary', { amount: `$${amount}` })
      : t('notifications.royaltyReceivedSecondary', {
        amount: `${amount} ${notification.royalty_received.currency}`,
      });
  }
  if (notification.type === 'minting_royalty_received') {
    const amount = formatAmount(notification.minting_royalty_received?.price || 0);
    return !notification.minting_royalty_received?.currency || notification.minting_royalty_received.currency === 'FUSD'
      ? t('notifications.mintingRoyaltyReceivedSecondary', { amount: `$${amount}` })
      : t('notifications.mintingRoyaltyReceivedSecondary', {
        amount: `${amount} ${notification.minting_royalty_received.currency}`,
      });
  }
  return '';
};

const getThumbnailUrl = (notification: Notification, currentUser?: IExtendedUser): string => {
  if (notification.type === 'pack_sold') {
    const collectionId = notification[notification.type]?.collection_id || '';
    const collectionUsername = notification[notification.type]?.collection_creator_username || '';
    return RouteTypes.Collection.replace(':username', collectionUsername).replace(':collectionId', collectionId);
  }
  if (notification.type === 'reward_received') {
    return RouteTypes.Packs;
  }
  if (notification.type === 'card_bid_created') {
    const cardSold = notification[notification.type];
    const cardPath = RouteTypes.Card.replace(':username', currentUser?.username || '')
      .replace(':collectionId', cardSold?.collection_id || '')
      .replace(':cardId', cardSold?.card_id.toString() || '')
      .replace(':edition?', cardSold?.edition.toString() || '');
    return cardPath;
  }
  if (notification.type === 'card_bid_accepted' || notification.type === 'card_bid_declined') {
    const cardSold = notification[notification.type];
    const cardPath = RouteTypes.Card.replace(':username', cardSold?.seller_username || '')
      .replace(':collectionId', cardSold?.collection_id || '')
      .replace(':cardId', cardSold?.card_id.toString() || '')
      .replace(':edition?', cardSold?.edition.toString() || '');
    return cardPath;
  }
  const cardSold = notification[notification.type];
  const cardPath = RouteTypes.Card.replace(':username', cardSold?.buyer_username || '')
    .replace(':collectionId', cardSold?.collection_id || '')
    .replace(':cardId', cardSold?.card_id.toString() || '')
    .replace(':edition?', cardSold?.edition.toString() || '');
  return cardPath;
};

const getLinkPath = (notification: Notification): string => {
  if (notification.type === 'card_bid_created') {
    return RouteTypes.BidsReceived;
  }
  return getThumbnailUrl(notification);
};

const getAvatar = (notification: Notification) => {
  if (notification.type === 'reward_received') {
    return notification.reward_received?.creator_avatar as Media;
  }
  if (notification.type === 'card_bid_created') {
    return notification.card_bid_created?.bidder_avatar as Media;
  }
  if (notification.type === 'card_bid_accepted') {
    return notification.card_bid_accepted?.seller_avatar as Media;
  }
  if (notification.type === 'card_bid_declined') {
    return notification.card_bid_declined?.seller_avatar as Media;
  }
  return notification[notification.type]?.buyer_avatar as Media;
};

const getUsername = (notification: Notification) => {
  if (notification.type === 'reward_received') {
    return notification[notification.type]?.collection_creator_username;
  }
  if (notification.type === 'card_bid_created') {
    return notification.card_bid_created?.bidder_username;
  }
  if (notification.type === 'card_bid_accepted') {
    return notification.card_bid_accepted?.seller_username;
  }
  if (notification.type === 'card_bid_declined') {
    return notification.card_bid_declined?.seller_username;
  }
  return notification[notification.type]?.buyer_username;
};

export const selectNotifications = createSelector(
  ({ notifications, auth, user }: RootState) => ({ notifications, auth, user }),
  ({ notifications: { notifications }, user, auth }) => {
    const authUser = user.items[auth.userId];

    const notificationProps: NotificationProps[] = notifications.map(
      (notification): NotificationProps => ({
        date: notification.create_time,
        linkPath: getLinkPath(notification),
        secondaryText: getSecondaryText(notification),
        notificationText: getNotificationsText(notification),
        thumbnail: getThumbnail(notification),
        thumbnailUrl: getThumbnailUrl(notification, authUser),
        userAvatar: getAvatar(notification),
        // userId: notification[notification.type]?.buyer_user_id || '',
        username: getUsername(notification) || '',
        // collectionId: notification[notification.type]?.collection_id,
        // type: notification.type,
      }),
    );
    return notificationProps;
  },
);

export const selectNotificationsPaginationInfo = createSelector(
  selectNotificationsFromStore,
  ({
    isLoading, hasMoreToLoad, page, isLoad,
  }) => ({
    isLoading,
    hasMoreToLoad,
    page,
    isLoad,
  }),
);

export const selectUnreadNotificationsCount = createSelector(selectNotificationsFromStore, ({ unread }) => unread);
