import * as fcl from '@blocto/fcl';
import * as t from '@onflow/types';
import { onError } from 'helpers/onError';
import { tx } from '../util/tx';
// import additionalAuthorization from '../services/additionalAuthorization';

const CODE = fcl.cdc`
  import FungibleToken from 0xFUNGIBLE_TOKEN_ADDRESS
  import NonFungibleToken from 0xNON_FUNGIBLE_TOKEN_ADDRESS
  import StarlyCard from 0xSTARLY_CARD_ADDRESS
  import StarlyCardBidV3 from 0xSTARLY_CARD_BID_ADDRESS

  transaction(bidID: UInt64) {
    let bidCollection: &StarlyCardBidV3.Collection{StarlyCardBidV3.CollectionManager}

    prepare(signer: AuthAccount) {
        self.bidCollection = signer.borrow<&StarlyCardBidV3.Collection{StarlyCardBidV3.CollectionManager}>(from: StarlyCardBidV3.CollectionStoragePath)!
    }

    execute {
        self.bidCollection.cancel(bidID: bidID)
    }
  }
`;

export async function flowCancelBidTransaction(
  bidID: number,
  opts = { onError },
) {
  // Get latest block info
  const block = await fcl.send([fcl.getBlock(false) as Function]).then(fcl.decode);

  return tx(
    [
      fcl.transaction(CODE),
      fcl.args([
        fcl.arg(BigInt(bidID).toString(), t.UInt64),
      ]),
      fcl.payer(fcl.authz),
      fcl.proposer(fcl.authz),
      fcl.authorizations([fcl.authz]),
      fcl.limit(9999),
      fcl.ref(block.id),
    ],
    opts,
  );
}
