import { mediaDesktop } from 'breakpoints';
import Button from 'components/Button';
import theme from 'components/theme';
import styled from 'styled-components/macro';
import { TittleText } from '../../styled';

export const SelectWalletButtonsContainer = styled.div`
  display: grid;
  grid-gap: 5px;
  width: 100%;

  ${mediaDesktop} {
    grid-gap: 12px; 
  };
`;

export const EthPointerIcon = styled.img`
  width: 15px;
  height: 20px;
  margin-left: 16px;
  display: none;

  ${mediaDesktop} {
    display: block;
  }
`;

export const EthBalanceRow = styled.div`
  display: flex;
  font-size: 13px;
  letter-spacing: 0.5px;

  ${mediaDesktop} {
    font-size: 16px;
    line-height: 24px;
    padding-bottom: 4px;
    width: 75px;
    display: inline;
  }
  & > div {
    margin-left: 5px;
  }
`;

export const BackBtn = styled.img`
  cursor: pointer;
  object-fit: contain;
  justify-self: left;
  user-select: none;
  position: absolute;
  top: 20px;
  left: 20px;
  padding: 15px;
  margin: -15px;

  transition: transform .3s;
  :hover {
    transform: translateX(-5px);
  }
`;

export const OptionContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 64px;
  padding: 10px 16px;
  cursor: pointer;
  font-size: 20px;
  font-weight: 600;

  box-shadow: none;
  border: solid 1px #d8d8d8;
  border-radius: 0;
  background-color: ${theme.colors.white};

  ${mediaDesktop} {
    padding: 24px;
    height: 90px;
    align-items: center;
  }

  &:hover {
    background-color: #F9F9F9;
  }
`;

export const CurrencySubContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  font-size: 16px;
  font-family: Kanit;
  letter-spacing: 0.8px;
  font-weight: 600;

  flex-direction: column;
  flex: 1 1 0%;
  ${mediaDesktop} {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: 24px;
    letter-spacing: 1.2px;
  }
`;

export const ProviderLogo = styled.img`
  width: 30px;
  height: 30px;
  margin-right: 10px;
  ${mediaDesktop} {
    margin-right: 16px;
    width: 42px;
    height: 42px;
  }
`;

export const PointerIcon = styled.img`
  width: 10px;
  height: 19px;

  ${mediaDesktop} {
    display: none;
  }
`;

export const BalanceContainer = styled.div`
  font-family: ${theme.fonts.primary};
  font-weight: 400;
  display: flex;
  justify-content: space-between;

  ${mediaDesktop} {
    text-align: right;
    height: auto;
    flex-wrap: wrap;
    justify-content: flex-end;
    float: right;
  }
`;

export const EthBalanceContainer = styled(BalanceContainer)`
  align-items: center;
`;

export const BalanceRow = styled.div`
  display: flex;
  font-size: 12px;
  letter-spacing: 0.46px;

  ${mediaDesktop} {
    font-size: 16px;
    line-height: 24px;
    padding-bottom: 4px;
    width: 100%;
    display: inline;
  }
  & > div {
    margin-left: 5px;
  }
`;

export const WalletDescriptionContainer = styled.div`
  font-family: ${theme.fonts.primary};
  font-weight: 400;
  font-size: 13px;
  letter-spacing: 0.5px;
  text-align: left;

  ${mediaDesktop} {
    font-size: 16px;
    text-align: right;
    height: auto;
    padding: 0;
  }
`;

export const SupportedTokens = styled.div`
  display: inline;
  font-weight: 600;
`;

export const BalanceTopup = styled(Button)`
  color: ${theme.colors.green};
  letter-spacing: 0.5px;
  background-color: transparent;
  display: none;
  ${mediaDesktop} {
    display: block;
    font-size: 14px;
    text-align: center;
    font-weight: 600;
    color: black;
    letter-spacing: 0.44px;
    background-color: #f0f0f0;
    text-transform: uppercase;
    height: 23px;
    min-width: 75px;
  }
  &:hover:enabled {
    background-color: #1AFF99;
  }
`;

export const SelectWalletTittleText = styled(TittleText)`
  margin-bottom: 20px;
  ${mediaDesktop} {
    letter-spacing: 1.6px;
  }
`;
