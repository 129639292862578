import {
  all,
  fork,
  call,
  takeEvery,
  put,
  select,
} from 'redux-saga/effects';
import * as fcl from '@blocto/fcl';

import i18n from 'i18next';
import { ethers } from 'ethers';
import { type VestingClaimHistoryRecord } from '@starly/starly-types';
import { type Dispatch } from '@reduxjs/toolkit';

import { flowFetchStarlyTokenVestingsScript } from 'flow/vesting/fetchVestings.script';
import { flowInitializeVestingTransaction } from 'flow/vesting/initializeVesting.tx';
import { flowIsVestingInitializedScript } from 'flow/vesting/isVestingInitialized.script';
import { flowReleaseStarlyTokenVestingTransaction } from 'flow/vesting/release.tx';
import {
  type DocumentSnapshot, firestore, type QuerySnapshot, trackEvent, trackException,
} from 'global/firebase';
import Wallet from 'helpers/ethereumWallet';
import { flowBalanceRequest } from 'store/flow/flowActions';
import { selectFlowWalletAddr } from 'store/flow/flowSelectors';
import { type FlowTypes } from 'types';
import { ethConfig, VESTING_WALLET_ABI } from 'util/constants';

import {
  vestingBalanceResponse,
  vestingClaimRequest,
  vestingHistoryRequest,
  vestingHistoryResponse,
  vestingLoginRequest,
  vestingLoginResponse,
  vestingLogout,
  vestingReset,
  vestingRewardBalanceResponse,
  vestingRewardFetchVestings,
  vestingToggleLoading,
  vestingToggleModal,
  vestingToggleRewardLoading,
  vestingToggleWalletLoading,
  type VestingWallet,
} from './vestingActions';
import { selectVestingWallet } from './vestingSelectors';

interface FlowVestingInit {
  StarlyTokenVesting: boolean
}

interface FlowVestingWallet {
  beneficiary: string;
  endTimestamp: string;
  id: number;
  initialVestedAmount: string;
  nextUnlock: { [key: string]: string }
  releasableAmount: string
  releasePercent: string
  remainingVestedAmount: string
  startTimestamp: string
  vestingType: { rawValue: number }
}
interface VestingResponse {
  vestings: { [key: string]: FlowVestingWallet };
  currentTime: string
}

export function getSubscriptionCallback(dispatch: Dispatch) {
  return {
    onDisconnect(error: Error) {
      trackException(`Disconnected from RPC: ${error}`);
      dispatch(vestingToggleModal({ isOpen: true, errorMessage: i18n.t('ethereum.error.disconnect') }));
      dispatch(vestingLogout());
    },
    onAccountsChanged(accounts: string[]) {
      if (accounts.length === 0) {
        dispatch(vestingLogout());
        // return;
      }
      // dispatch(ethereumWalletUpdate({ address: accounts[0] }));
    },
    onChainChanged() {
      trackException('Invalid ChainId');
      dispatch(vestingToggleModal({ isOpen: true, errorMessage: i18n.t('ethereum.error.invalidChain'), errorCode: 1 }));
      dispatch(vestingLogout());
    },
  };
}

function* ethereumLogin(providerId: string, callbacks: {
  onDisconnect: (error: Error) => void
  onAccountsChanged: (accounts: string[]) => void
  onChainChanged: () => void
}) {
  const provider = yield call(Wallet.connectTo, providerId);
  if (!provider) return null;

  const { ethersProvider } = Wallet;
  const { chainId }: ethers.Network = yield call(() => ethersProvider.getNetwork());

  if (chainId !== BigInt(ethConfig.chain.id)) {
    trackException('Invalid ChainId');
    yield put(vestingToggleModal({ isOpen: true, errorMessage: i18n.t('ethereum.error.invalidChain'), errorCode: 1 }));
    yield put(vestingLogout());
    return null;
  }

  yield call(Wallet.subscribeProvider, provider, callbacks.onDisconnect, callbacks.onAccountsChanged, callbacks.onChainChanged);
  const { address }: ethers.JsonRpcSigner = yield call(() => ethersProvider.getSigner());

  return address;
}

function* flowLogin() {
  const flowWallet: FlowTypes.Wallet = yield call(fcl.logIn);

  const init: FlowVestingInit = yield call(flowIsVestingInitializedScript, flowWallet.addr);

  if (!init.StarlyTokenVesting) {
    yield call(flowInitializeVestingTransaction, flowWallet.addr);

    /**
     * Check is user approved init or not
     */
    const recheck: FlowVestingInit = yield call(flowIsVestingInitializedScript, flowWallet.addr);

    if (!recheck.StarlyTokenVesting) return null;
  }

  return flowWallet.addr;
}

function* ethereumFetchVestings(address: string) {
  const docRef: DocumentSnapshot = yield call(() => firestore.collection('bscVestingAccounts').doc(address).get());
  const doc = docRef.data();

  if (!doc?.wallets.length) {
    yield put(vestingToggleModal({ isOpen: true, errorMessage: i18n.t('vesting.error.noVesting') }));
    return;
  }

  const { ethersProvider, providerId } = Wallet;
  const block: { timestamp: number } = yield call(() => ethersProvider.getBlock('latest'));

  yield put(vestingLoginResponse({ wallet: { address, blockchain: 'ethereum', providerId } }));

  const vestingWallets: { [key: string]: VestingWallet } = {};
  yield call(() => Promise.all(
    doc?.wallets.map(async (walletAddress: string) => {
      const vestingWallet = new ethers.Contract(walletAddress, VESTING_WALLET_ABI, ethersProvider);

      const [
        start,
        duration,
        released,
        vestedAmount,
        totalAmount,
      ] = await Promise.all([
        vestingWallet.start(),
        vestingWallet.duration(),
        vestingWallet.released(process.env.REACT_APP_CONTRACT_ETH_STARLY),
        vestingWallet.vestedAmount(process.env.REACT_APP_CONTRACT_ETH_STARLY, Math.round(Date.now() / 1000)),
        vestingWallet.vestedAmount(process.env.REACT_APP_CONTRACT_ETH_STARLY, Date.now()),
      ]) as bigint[];

      const vesting = {
        id: walletAddress,
        start: Number(start),
        duration: Number(duration),
        released: Number(released),
        vestedAmount: Number(totalAmount - vestedAmount),
        totalAmount: Number(totalAmount),
        decimals: 8,
      };

      vestingWallets[walletAddress] = vesting;

      return vesting;
    }),
  ));

  yield put(vestingBalanceResponse({ balance: vestingWallets, currentTime: block.timestamp }));
}

function* watchVestingRewardFetchVestings() {
  yield takeEvery(vestingRewardFetchVestings, function* takeEveryVestingRewardFetchVestings() {
    try {
      yield put(vestingToggleRewardLoading(true));
      const address: string = yield select(selectFlowWalletAddr);
      if (!address) {
        return;
      }

      const vesting: VestingResponse = yield call(flowFetchStarlyTokenVestingsScript, address);

      if (!vesting.vestings) {
        return;
      }

      const vestingWallets: { [key: string]: VestingWallet } = {};

      Object.values(vesting.vestings).forEach((wallet) => {
        const initialVestedAmount = Number(wallet.initialVestedAmount) * 10 ** 8;
        const remainingVestedAmount = Number(wallet.remainingVestedAmount) * 10 ** 8;
        const releasableAmount = Number(wallet.releasableAmount) * 10 ** 8;
        vestingWallets[wallet.id.toString()] = {
          id: wallet.id.toString(),
          nextUnlock: wallet.nextUnlock,
          start: Number(wallet.startTimestamp),
          duration: Number(wallet.endTimestamp) - Number(wallet.startTimestamp),
          released: initialVestedAmount - remainingVestedAmount,
          vestedAmount: remainingVestedAmount - releasableAmount,
          totalAmount: initialVestedAmount,
          decimals: 8,
          vestingType: {
            rawValue: Number(wallet.vestingType.rawValue),
          },
        };
      });

      yield put(vestingRewardBalanceResponse(
        { balance: vestingWallets, currentTime: Number(vesting.currentTime) },
      ));
    } catch (e) {
      console.error(e);
    } finally {
      yield put(vestingToggleRewardLoading(false));
    }
  });
}

function* flowFetchVestings(address: string) {
  const vesting: VestingResponse = yield call(flowFetchStarlyTokenVestingsScript, address);

  if (!vesting?.vestings || !Object.keys(vesting.vestings).length) {
    yield put(vestingToggleModal({ isOpen: true, errorMessage: i18n.t('vesting.error.noVesting') }));
    yield call(fcl.unauthenticate);
    return;
  }
  yield put(vestingLoginResponse({ wallet: { address, blockchain: 'flow' } }));

  const vestingWallets: { [key: string]: VestingWallet } = {};

  Object.values(vesting.vestings).forEach((wallet) => {
    const initialVestedAmount = Number(wallet.initialVestedAmount) * 10 ** 8;
    const remainingVestedAmount = Number(wallet.remainingVestedAmount) * 10 ** 8;
    const releasableAmount = Number(wallet.releasableAmount) * 10 ** 8;
    vestingWallets[wallet.id.toString()] = {
      id: wallet.id.toString(),
      nextUnlock: wallet.nextUnlock,
      start: Number(wallet.startTimestamp),
      duration: Number(wallet.endTimestamp) - Number(wallet.startTimestamp),
      released: initialVestedAmount - remainingVestedAmount,
      vestedAmount: remainingVestedAmount - releasableAmount,
      totalAmount: initialVestedAmount,
      decimals: 8,
      vestingType: {
        rawValue: Number(wallet.vestingType.rawValue),
      },
    };
  });

  yield put(vestingBalanceResponse({
    balance: vestingWallets, currentTime: Number(vesting.currentTime),
  }));
}

function* watchVestingLoginRequest() {
  yield takeEvery(vestingLoginRequest, function* takeEveryVestingLoginRequest(action) {
    try {
      const {
        payload: {
          blockchain,
          providerId,
          callbacks,
        },
      } = action;

      let address: string;
      if (blockchain === 'ethereum' && providerId && callbacks) {
        address = yield call(ethereumLogin, providerId, callbacks);
        if (!address) {
          yield put(vestingToggleLoading(false));
          return;
        }

        yield call(ethereumFetchVestings, address);
      } else {
        address = yield call(flowLogin);
        if (!address) {
          yield put(vestingToggleLoading(false));
          return;
        }

        yield call(flowFetchVestings, address);
      }

      trackEvent('wallet_connect');
    } catch (error: any) {
      console.error('er', error);
      trackException(error?.message);
      yield put(vestingToggleModal({ isOpen: true, errorMessage: i18n.t('ethereum.error.loginFailed') }));
      yield put(vestingReset());
    } finally {
      yield put(vestingToggleLoading(false));
    }
  });
}

function* watchVestingClaimRequest() {
  yield takeEvery(vestingClaimRequest, function* takeEveryVestingClaimRequest(action) {
    const {
      payload: {
        walletId,
        isReward,
      },
    } = action;
    try {
      const vestingWallet: ReturnType<typeof selectVestingWallet> = yield select(
        selectVestingWallet,
      );

      if (vestingWallet?.blockchain === 'ethereum') {
        const { ethersProvider } = Wallet;

        const signer: ethers.JsonRpcSigner = yield call(() => ethersProvider.getSigner());
        const vestingWalletContract = new ethers.Contract(walletId, VESTING_WALLET_ABI, signer);

        let gas: bigint = yield call(() => vestingWalletContract.release.estimateGas(process.env.REACT_APP_CONTRACT_ETH_STARLY));
        gas = gas * 125n / 100n;

        const receipt: ethers.TransactionResponse = yield call(
          () => vestingWalletContract.release(process.env.REACT_APP_CONTRACT_ETH_STARLY, {
            gasLimit: gas,
          }),
        );
        yield call(() => receipt.wait());

        yield fork(ethereumFetchVestings, vestingWallet.address);
      } if (vestingWallet?.blockchain === 'flow' || isReward) {
        const result = yield call(flowReleaseStarlyTokenVestingTransaction, walletId);
        const errMessage = result ? result?.errorMessage as string : '';

        if (result && errMessage) {
          return;
        }
        if (isReward) {
          yield put(vestingRewardFetchVestings());
        } else {
          yield fork(flowFetchVestings, vestingWallet!.address);
        }
      }
      yield put(flowBalanceRequest({}));
    } catch (error: any) {
      console.error('er', error);
      trackException(error?.message);
      yield put(vestingToggleModal({ isOpen: true, errorMessage: i18n.t('Transaction failed') }));
    } finally {
      yield put(vestingToggleWalletLoading({ walletId, isReward }));
    }
  });
}

function* vestingHistoryRequestWatcher() {
  yield takeEvery(vestingHistoryRequest, function* notificationRequestWorker(action) {
    const {
      payload: {
        address,
      },
    } = action;
    let transactions: VestingClaimHistoryRecord[] = [];
    const transactionsRef: QuerySnapshot = yield call(() => firestore
      .collection('vestingClaimHistory')
      .doc(address)
      .collection('claims')
      .orderBy('timestamp', 'desc')
      .get());

    transactions = transactionsRef.docs.map((doc) => doc.data() as VestingClaimHistoryRecord);
    yield put(vestingHistoryResponse({ transactions }));
  });
}

function* watchFlowLogoutRequest() {
  yield takeEvery(vestingLogout, function* takeEveryLogoutRequest() {
    const vestingWallet: ReturnType<typeof selectVestingWallet> = yield select(selectVestingWallet);
    if (vestingWallet?.blockchain === 'ethereum') {
      try {
        if (Wallet.ethersProvider.removeAllListeners) {
          yield Wallet.ethersProvider.removeAllListeners();
        }
        Wallet.unsubscribeProvider(Wallet.provider);
        trackEvent('wallet_disconnect');
      } catch (err) {
        console.error(err);
      }
    } else if (vestingWallet?.blockchain === 'flow') {
      yield call(fcl.unauthenticate);
    }
    yield put(vestingReset());
  });
}

export default function* flowSaga() {
  yield all([
    fork(watchVestingLoginRequest),
    fork(watchVestingRewardFetchVestings),
    fork(watchVestingClaimRequest),
    fork(vestingHistoryRequestWatcher),
    fork(watchFlowLogoutRequest),
  ]);
}
