import * as fcl from '@blocto/fcl';
import { Address } from '@onflow/types';

const CODE = fcl.cdc`
import MetadataViews from 0xMETADATA_VIEWS_ADDRESS
import NonFungibleToken from 0xNON_FUNGIBLE_TOKEN_ADDRESS
import StarlyTokenVesting from 0xSTARLY_TOKEN_VESTING_ADDRESS

pub fun hasStarlyTokenVesting(_ address: Address): Bool {
    return getAccount(address)
        .getCapability<&StarlyTokenVesting.Collection{NonFungibleToken.CollectionPublic, MetadataViews.ResolverCollection, StarlyTokenVesting.CollectionPublic}>(StarlyTokenVesting.CollectionPublicPath)
        .check()
}

pub fun main(address: Address): {String: Bool} {
    let ret: {String: Bool} = {}
    ret["StarlyTokenVesting"] = hasStarlyTokenVesting(address)
    return ret
}`;

export function flowIsVestingInitializedScript(address: string) {
  if (address == null) return Promise.resolve(false);

  return fcl.send([
    fcl.script(CODE),
    fcl.args([
      fcl.arg(address, Address),
    ]),
  ]).then(fcl.decode);
}
