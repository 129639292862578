import styled, { keyframes } from 'styled-components';
import { mediaDesktop } from '../../breakpoints';

const animation = keyframes`
  from {opacity: 1;}
  to {opacity: 0;}
`;

export const SpinnerContainer = styled.div<{ $isWhite?: boolean }>`
  pointer-events: none;
  height: 40px;
  min-width: 50px;
  overflow: hidden;
  background: transparent;
  ${({ $isWhite }) => ($isWhite ? 'background: #fff;' : '')}
  display: grid;
  place-content: center;
`;

export const Spinner = styled.div<{ $isBlack?: boolean, $inline?: boolean, $small?: boolean }>`
  width: 100%;
  transform: translateY(-20px);
  position: relative;
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
  
  & div {
    position: absolute;
    animation: ${animation} linear 1s infinite;
    background: #ffffff;
    ${({ $isBlack }) => ($isBlack ? 'background: #000;' : '')}
    box-sizing: content-box;
    width: 3px;
    height: ${({ $inline }) => ($inline ? '8px' : '9px')};
    border-radius: 3px;
    transform-origin: 2px 18px;
    ${({ $inline }) => ($inline ? 'transform-origin: 2px 15px;' : '')}
    ${({ $small }) => ($small ? `
      transform-origin: 2px 13px;
      height: 7px;
    ` : '')}
    
    ${mediaDesktop} {
      width: 3px;
      height: 10px;
      transform-origin: 1px 20px;
      ${({ $inline }) => ($inline ? 'transform-origin: 1px 17px;' : '')}
      ${({ $small }) => ($small ? `
        transform-origin: 2px 15px;
        height: 9px;
    ` : '')}
    }
  }
  
  & div:nth-child(1) {
    transform: rotate(0deg);
    animation-delay: -0.9166666666666666s;
  }
  & div:nth-child(2) {
    transform: rotate(30deg);
    animation-delay: -0.8333333333333334s;
  }
  & div:nth-child(3) {
    transform: rotate(60deg);
    animation-delay: -0.75s;
  }
  & div:nth-child(4) {
    transform: rotate(90deg);
    animation-delay: -0.6666666666666666s;
  }
  & div:nth-child(5) {
    transform: rotate(120deg);
    animation-delay: -0.5833333333333334s;
  }
  
  & div:nth-child(6) {
    transform: rotate(150deg);
    animation-delay: -0.5s;
  }
  & div:nth-child(7) {
    transform: rotate(180deg);
    animation-delay: -0.4166666666666667s;
  }
  & div:nth-child(8) {
    transform: rotate(210deg);
    animation-delay: -0.3333333333333333s;
  }
  & div:nth-child(9) {
    transform: rotate(240deg);
    animation-delay: -0.25s;
  }
  & div:nth-child(10) {
    transform: rotate(270deg);
    animation-delay: -0.16666666666666666s;
  }
  & div:nth-child(11) {
    transform: rotate(300deg);
    animation-delay: -0.08333333333333333s;
  }
  & div:nth-child(12) {
    transform: rotate(330deg);
    animation-delay: 0s;
  }
`;
