import { createSelector } from '@reduxjs/toolkit';
import { type RootState } from '../store';

export const selectAuth = ({ auth }: RootState) => auth;

export const selectAuthAuthorized = createSelector(selectAuth, ({ authorized }) => authorized);
export const selectAuthIsProfileCreating = createSelector(selectAuth, ({
  isProfileCreating,
}) => isProfileCreating);
export const selectAuthUserId = createSelector(selectAuth, ({ userId }) => userId);

export const selectAuthError = createSelector(selectAuth, ({ error }) => error);

export const selectIsAdmin = createSelector(selectAuth, ({ isAdmin }) => isAdmin);
