import { type CardInfo, type CreateCardRarity } from 'store/collection/collectionActions';

export function getRarityByOrder(order: number, cardInfo: CardInfo): CreateCardRarity {
  if (!cardInfo || !cardInfo.legendary) return 'common';
  const {
    legendary: { distinct_cards: legendary },
    rare: { distinct_cards: rare },
    common: { distinct_cards: common },
  } = cardInfo;

  if (order > legendary + rare + common) return 'reward';
  if (order > legendary + rare) return 'common';
  if (order > legendary) return 'rare';
  return 'legendary';
}

export function getDiffByOrder(order: number, cardInfo: CardInfo): number {
  if (!cardInfo || !cardInfo.legendary) return 0;
  const {
    legendary: { distinct_cards: legendary },
    rare: { distinct_cards: rare },
    common: { distinct_cards: common },
  } = cardInfo;

  if (order > legendary + rare + common) {
    return legendary + rare + common;
  }
  if (order > legendary + rare) {
    return legendary + rare;
  }
  if (order > legendary) return legendary;
  return 0;
}

export function getDiffByRarity(rarity: CreateCardRarity, cardInfo: CardInfo): number {
  if (!cardInfo || !cardInfo.legendary) return 0;
  if (rarity === 'legendary') return 0;
  if (rarity === 'rare') return cardInfo.legendary.distinct_cards;
  if (rarity === 'common') return cardInfo.legendary.distinct_cards + cardInfo.rare.distinct_cards;
  return cardInfo.legendary.distinct_cards
    + cardInfo.rare.distinct_cards
    + cardInfo.common.distinct_cards;
}
