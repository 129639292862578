import styled, { css } from 'styled-components';
import { mediaDesktop } from '../../breakpoints';
import theme from '../../components/theme';
import { Row } from '../../components/Table';
import Typography from '../../components/Typography';
import Media from '../../components/Media';
import Button from '../../components/Button';

export const RankingPageWrapper = styled.div`
  flex: 1;
  flex-direction: column;
  display: flex;
  justify-content: center;
  position: relative;
`;

export const RankingPageContainer = styled.div`
  margin: auto;
  max-width: ${theme.width.mainContainer};
  width: 100%;
  flex: 1;
  display: flex;
  align-content: center;
  flex-direction: column;
  padding: 16px 16px 50px;
  ${mediaDesktop} {
    padding: 50px 0 100px;
  }
  & > ${Button} {
    margin-left: auto;
    margin-right: auto;
  }
`;

export const RankingPageHeader = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  ${mediaDesktop} {
    margin-bottom: 21px;
    height: 40px;
  }
`;

export const RankingHeaderSelectContainer = styled.div`
  width: 100%;
  background: ${theme.colors.dark};
  padding: 24px 16px;
  margin-top: 16px;
  margin-bottom: 16px;
  ${mediaDesktop} {
    width: 260px;
    padding: 0;
    margin: 0;
    background: none;
  }
`;

export const RankingTableWrapper = styled.div`
  flex: 1;
  margin-right: -16px;
  margin-left: -16px;
  overflow: auto;
  ${mediaDesktop} {
    margin: 0;
  }
`;

export const RankingTableRow = styled(Row)<{ withoutChange?: boolean }>`
  grid-gap: 20px;
  padding-left: 20px;
  grid-template-columns: 47px 340px 100px repeat(4, 105px);
  ${({ withoutChange }) => (withoutChange ? css`
    grid-template-columns: 35px 360px repeat(4, 130px);
  ` : '')}
`;

export const RankingTableContentRow = styled(RankingTableRow)`
  padding-top: 24px;
  padding-bottom: 24px;
  font-size: 18px;
  ${mediaDesktop} {
    & > *:nth-of-type(1) {
      font-size: 21px;
    }
  }
`;

export const RankingTableTypography = styled(Typography)`
  max-width: 100%;
  overflow: hidden;
  font-size: inherit;
  display: inline-block;;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const RankingRowCollectionContainer = styled.div<{ fontSize?: 'ranking' | 'collectors' }>`
  display: grid;
  grid-gap: 20px;
  align-items: center;
  grid-auto-flow: column;
  grid-template-columns: max-content 1fr;
  font-size: ${({ fontSize }) => (fontSize === 'collectors' ? '21px' : '18px')};
`;

export const RankingUserAvatar = styled(Media)`
  height: 72px;
  width: 72px;
  border-radius: 100%;
`;

export const RankingRowCollectionText = styled.div`
  display: grid;
  width: 100%;
  align-content: start;
`;

export const RankingDashRune = styled.div`
  width: 12px;
  height: 3px;
  background-color: #fff;
`;

export const RankingChangeContent = styled.div<{ change: number }>`
  font-weight: inherit;
  display: grid;
  align-items: center;
  grid-gap: 8px;
  grid-auto-flow: column;
  color: ${({ change }) => (change >= 0 ? theme.colors.lightGreen : theme.colors.red)};
`;

export const CollectionMedia = styled(Media)`
  border-radius: 5px;
  height: 72px;
  width: 128px;
`;

export const RankingCollectorsHeaderRow = styled(Row)`
  grid-template-columns: 70px 310px 135px repeat(4, 105px);
  grid-gap: 20px;
  padding: 0 25px 0 0;
`;

export const RankingUserRowContainer = styled.div`
    display: block;
    margin-bottom: 25px;
`;

export const RankingCollectorsRow = styled(RankingCollectorsHeaderRow)<{ isOwnProfile?: boolean }>`
  font-size: 18px;
  padding-bottom: 24px;
  padding-top: 24px;
  ${({ isOwnProfile }) => (isOwnProfile ? css`
    background: ${theme.colors.darkLighter};
  ` : '')}
`;

export const TopCollectorsRewardBlockContainer = styled.div`
  overflow-x: auto;
  
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
  
  max-width: calc(100% + 32px);
  margin: 0 -16px;
  margin-bottom: 20px;

  ${mediaDesktop} {
    max-width: 100%;
    margin: -20px 0 20px;
  }
`;
