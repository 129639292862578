import { createSelector } from '@reduxjs/toolkit';
import { type RootState } from '../store';

const selectCollections = ({ collections }: RootState) => collections;

export const selectAllCollections = createSelector(
  selectCollections, ({ items }) => items,
);

export const selectCollectionById = (id : string) => createSelector(
  selectCollections, ({ items }) => items[id],
);

export const selectCollectionStatsById = (id: string) => createSelector(
  selectCollections, ({ collectionStatsItems }) => collectionStatsItems[id],
);

export const selectActiveCollection = createSelector(
  selectCollections, ({ activeCollection }) => activeCollection,
);

export const selectActiveStep = createSelector(
  selectCollections, ({ activeCollection: { activeStep } }) => activeStep,
);

export const selectCollectionCounters = (id : string) => createSelector(
  selectCollections, ({ counters }) => counters[id] || {},
);

export const selectLastCreatedCollection = (id: string) => createSelector(
  selectCollections, ({ lastCreatedCollection }) => lastCreatedCollection[id],
);

export const selectIsCreateModalOpen = createSelector(
  selectCollections, ({ isCreateModalOpen }) => isCreateModalOpen,
);

export const selectIsUnavailableModalOpen = createSelector(
  selectCollections, ({ isUnavailableModalOpen }) => isUnavailableModalOpen,
);
