import type { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as SoundOn } from 'static/ic-sound-off.svg';
import { ReactComponent as SoundOff } from 'static/ic-sound-on.svg';
import { ReactComponent as UploadImage } from 'static/ic-upload-asset.svg';
import { ReactComponent as UploadErrorImage } from 'static/ic-upload-error-asset.svg';

import {
  AnimatedDots,
  ChangeBtn,
  MutedIcon,
  Processing,
  StyledActualImage,
  StyledEmptyImage,
  StyledImageText,
  StyledVideoLoadContainer,
  StyledVideoWrapper,
  StyledVideoWrapperInner,
  UploadTitle,
  UploadValue,
  UploadWrapper,
} from '../styled';

const MainMedia: FC<{
  errors: { [key: string]: string },
  total: number,
  isUpload: boolean,
  uploadProcess: number,
  currMedia: string,
  id: string,
  switchMuted: (e: any) => void,
  isMuted: boolean,
  mediaState: string,
  mediaType: string,
}> = ({
  errors,
  total,
  isUpload,
  uploadProcess,
  currMedia,
  id,
  switchMuted,
  isMuted,
  mediaState,
  mediaType,
}) => {
  const { t } = useTranslation();

  if (!isUpload) {
    return (
      <UploadWrapper>
        <UploadTitle>{t('steps.two.card.uploading')}</UploadTitle>
        <UploadValue>{`${uploadProcess}%`}</UploadValue>
      </UploadWrapper>
    );
  }
  if (currMedia) {
    return mediaType === 'image' ? (
      <>
        <ChangeBtn htmlFor={id}>{t('steps.two.card.change')}</ChangeBtn>
        {/* eslint-disable-next-line */}
        <label htmlFor={id}>
          <StyledActualImage url={currMedia} />
        </label>
      </>
    ) : (
      <StyledVideoWrapper>
        <StyledVideoWrapperInner>
          <ChangeBtn htmlFor={id}>{t('steps.two.card.change')}</ChangeBtn>
          <MutedIcon onClick={switchMuted}>
            {isMuted ? <SoundOn /> : <SoundOff />}
          </MutedIcon>
          {(mediaState === 'new') && (
            <Processing>
              <AnimatedDots>{t('steps.two.card.processing')}</AnimatedDots>
            </Processing>
          )}
          {/* eslint-disable-next-line */}
          <label htmlFor={id}>
            <StyledVideoLoadContainer
              autoPlay
              muted={isMuted}
              controls={false}
              loop
              playsInline
            >
              <source src={currMedia} />
            </StyledVideoLoadContainer>
          </label>
        </StyledVideoWrapperInner>
      </StyledVideoWrapper>
    );
  }
  const { [`image${total}`]: error } = errors;
  return (
    <StyledEmptyImage error={!!error} htmlFor={id}>
      {error ? <UploadErrorImage /> : <UploadImage />}
      <StyledImageText>{t('steps.two.card.uploadText')}</StyledImageText>
    </StyledEmptyImage>
  );
};

export default MainMedia;
