import { createReducer } from '@reduxjs/toolkit';
import type { Listing, SalesHistoryItem } from '@starly/starly-types';
import {
  cardInfoReset,
  cardListingResponse,
  cardInfoSetLoading,
  cardInfoSetMoreToLoad,
  cardInfoSetPage,
  cardSalesResponse,
  cardResponse,
  type CardState,
  getCardCountersResponse,
  setCardPopUp,
  cardRequest,
  updateCardEditionStatus,
  updateCardEditionStakedStatus,
  getCardCountersRequest,
  addCardListenerToMap,
  removeCardListenerFromMap,
  cardSetLoading,
  cardSetStakingRemaining,
} from './cardActions';

export interface CardReducerState {
  isLoading: boolean,
  items: {
    [id: string]: CardState
  }
  listing: {
    isLoading: boolean,
    isMoreToLoad: boolean,
    listing: Listing[],
    page: number
  }
  sales: {
    isLoading: boolean,
    isMoreToLoad: boolean,
    sales: SalesHistoryItem[],
    page: number,
  }
  modalsState: {
    listing: boolean,
    sales: boolean,
  }
  listenersMap: {
    [key: string]: {
      [key: string] : boolean
    }
  }
  remainingMap: {
    [starlyId: string]: number | null
  }
}
const initialState: CardReducerState = {
  isLoading: false,
  items: {},
  listing: {
    isLoading: false,
    isMoreToLoad: true,
    page: 0,
    listing: [],
  },
  sales: {
    isLoading: false,
    isMoreToLoad: true,
    page: 0,
    sales: [],
  },
  modalsState: {
    listing: false,
    sales: false,
  },
  listenersMap: {},
  remainingMap: {},
};

const cardReducer = createReducer(
  initialState,
  (builder) => {
    builder
      .addCase(cardListingResponse, (state, { payload: { listing } }) => {
        state.listing.listing.push(...listing);
      })
      .addCase(cardSalesResponse, (state, { payload: { sales } }) => {
        state.sales.sales.push(...sales);
      })
      .addCase(cardSetLoading, (state, { payload: { isLoading } }) => {
        state.isLoading = isLoading;
      })
      .addCase(cardInfoSetLoading, (state, { payload: { isLoading, infoType } }) => {
        state[infoType].isLoading = isLoading;
      })
      .addCase(cardInfoSetMoreToLoad, (state, { payload: { isMoreToLoad, infoType } }) => {
        state[infoType].isMoreToLoad = isMoreToLoad;
      })
      .addCase(cardInfoSetPage, (state, { payload: { page, infoType } }) => {
        state[infoType].page = page;
      })
      .addCase(cardInfoReset, (state, { payload: { infoType } }) => {
        if (infoType === 'listing') {
          state[infoType] = initialState[infoType];
        }
        if (infoType === 'sales') {
          state[infoType] = initialState[infoType];
        }
      })
      .addCase(cardRequest, (state, { payload: { background } }) => {
        if (!background) {
          state.isLoading = true;
        }
      })
      .addCase(cardResponse, (state, { payload: { cardState } }) => {
        const id = cardState.card?.order;

        state.isLoading = false;

        if (id) {
          state.items[id] = { ...state.items[id], ...cardState };
        }
      })
      .addCase(getCardCountersRequest, (state) => {
        state.isLoading = true;
      })
      .addCase(getCardCountersResponse, (state, {
        payload: {
          cardId,
          listings,
          inCirculation,
          lowestAsk,
          topSale,
        },
      }) => {
        state.isLoading = false;
        state.items[cardId].counters = {
          ...state.items[cardId],
          listings,
          inCirculation,
          lowestAsk,
          topSale,
        };
      })
      .addCase(setCardPopUp, (state, { payload: { type, isOpen } }) => {
        state.modalsState[type] = isOpen;
      })
      .addCase(cardSetStakingRemaining, (state, { payload: { starlyId, remaining } }) => {
        state.remainingMap[starlyId] = remaining;
      })
      .addCase(updateCardEditionStatus, (state, { payload: { cardId, edition, status } }) => {
        const card = state.items[cardId];
        if (
          card?.card?._editions_summary
          && card.card._editions_summary[edition]
        ) {
          card.card._editions_summary[edition].state = status;
        }
      })
      .addCase(updateCardEditionStakedStatus, (state, {
        payload: {
          cardId, edition, status, stake_id,
        },
      }) => {
        const card = state.items[cardId];
        if (card?.editions[edition]) {
          card.editions[edition].staked = status;
          card.editions[edition].stake_id = stake_id;
        }
      })
      .addCase(addCardListenerToMap, (state, { payload: { cardId, collectionId } }) => {
        if (state.listenersMap[collectionId]) {
          state.listenersMap[collectionId] = {
            ...state.listenersMap[collectionId],
            ...{ [cardId]: true },
          };
        } else {
          state.listenersMap[collectionId] = { [cardId]: true };
        }
      })
      .addCase(removeCardListenerFromMap, (state, { payload: { collectionId, cardId } }) => {
        if (state.listenersMap[collectionId]) {
          delete state.listenersMap[collectionId][cardId];
        }
      });
  },
);

export default cardReducer;
