import { createSelector } from '@reduxjs/toolkit';
import i18n from 'i18next';
import { formatToken } from 'helpers/TextHelpers';

import { type MenuItemListProps, type MenuItemProps } from '../../components/Header/components/SideMenu/MenuItem/MenuItem';
import { RouteTypes } from '../../RouteTypes';
import icFlow from '../../static/wallet/ic-header-flow.svg';
import icFusd from '../../static/wallet/ic-header-fusd.svg';
import icStarly from '../../static/wallet/ic-header-starly.svg';
import icUsdc from '../../static/wallet/ic-header-usdc.svg';
import { type RootState } from '../store';

const selectAuthAndPacks = ({
  auth, pack, collections, flow, notifications, user, vesting, bids,
}: RootState) => ({
  auth,
  pack,
  collections,
  notifications,
  flow,
  user,
  vesting,
  bids,
});

export const selectSideMenuItems = createSelector(
  selectAuthAndPacks,
  ({
    auth, pack, collections, flow, notifications, user, vesting, bids,
  }) => {
    const isAuthorized = auth.authorized;
    let createCollectionRoute = `${RouteTypes.StepDefault}${collections.activeCollection.activeStep}`;
    if (isAuthorized) {
      const authUser = user.items[auth.userId];
      if (!authUser || authUser.face_control_state !== 'pass') {
        createCollectionRoute = RouteTypes.FaceControl;
      }
    }

    const solidStartItems: MenuItemProps[] = [
      {
        linkPath: RouteTypes.Drops,
        text: i18n.t('header.drops'),
      },
      {
        linkPath: RouteTypes.Marketplace,
        text: i18n.t('header.marketplace'),
      },
      {
        linkPath: RouteTypes.Ranking,
        text: i18n.t('header.ranking'),
      },
      {
        linkPath: RouteTypes.TokenLanding,
        text: i18n.t('header.token'),
      },
      {
        linkPath: RouteTypes.StakingNft,
        text: i18n.t('header.staking'),
      },
    ];

    const solidEndItems: MenuItemProps[] = [
      {
        text: i18n.t('header.terms'),
        linkPath: RouteTypes.TermsOfUse,
      },
      {
        text: i18n.t('header.writeUs'),
        linkPath: RouteTypes.WriteUs,
      },
    ];

    let items: MenuItemProps[] = [];

    if (isAuthorized) {
      const unreadNotifications = notifications.unread;
      const packsCount = pack.userPacksCount;
      const vestingsCount = Object.keys(vesting.rewardBalance || {}).length;
      const receivedBidsCount = Object.keys(bids.receivedBids || {}).length;
      const sentBidsCount = Object.keys(bids.sentBids || {}).length;
      let balanceArr: MenuItemListProps[] | undefined;
      if (flow.wallet?.addr) {
        balanceArr = [
          {
            logo: icStarly,
            value: formatToken(flow.starlyBalance),
          },
          {
            logo: icUsdc,
            value: formatToken(flow.usdcBalance),
          },
          {
            logo: icFusd,
            value: formatToken(flow.fusdBalance),
          },
          {
            logo: icFlow,
            value: formatToken(flow.flowBalance),
          },
        ];
      }

      const username = user.items[auth.userId] ? user.items[auth.userId].username : '';
      const userPageLink = RouteTypes.User
        .replace(':username', username);
      items = [
        ...solidStartItems,
        {
          linkPath: userPageLink,
          text: i18n.t('header.myPage'),
        },
        {
          linkPath: RouteTypes.Wallet,
          text: i18n.t('header.wallet'),
          list: balanceArr,
        },
        {
          linkPath: RouteTypes.Notifications,
          text: i18n.t('header.notifications'),
          amount: unreadNotifications > 0 ? unreadNotifications.toString() : '',
          isRed: true,
        },
        {
          linkPath: RouteTypes.Packs,
          text: i18n.t('header.packs'),
          amount: packsCount ? packsCount.toString() : '',
          isRed: true,
        },
        {
          linkPath: RouteTypes.Bids,
          text: 'bids',
          amount: `${sentBidsCount.toString() || 0}/${receivedBidsCount.toString() || 0}`,
        },
        {
          linkPath: RouteTypes.VestingPrize,
          text: i18n.t('header.vesting'),
          amount: vestingsCount ? vestingsCount.toString() : '',
        },
        {
          linkPath: createCollectionRoute,
          text: i18n.t('header.create'),
        },
        {
          text: i18n.t('header.blog'),
          linkPath: RouteTypes.Blog,
        },
        {
          linkPath: RouteTypes.FlowNFTNYC,
          text: i18n.t('header.flowNFTNYC'),
        },
        {
          linkPath: RouteTypes.FlowFestMarketplace,
          text: i18n.t('header.flowFest'),
        },
        {
          linkPath: RouteTypes.Settings,
          text: i18n.t('header.settings'),
        },
        ...solidEndItems,
        {
          text: i18n.t('header.logout'),
          linkPath: '',
        },
      ];
    } else {
      items = [
        ...solidStartItems,
        {
          text: i18n.t('header.blog'),
          linkPath: RouteTypes.Blog,
        },
        {
          linkPath: RouteTypes.FlowNFTNYC,
          text: i18n.t('header.flowNFTNYC'),
        },
        {
          linkPath: RouteTypes.FlowFestMarketplace,
          text: i18n.t('header.flowFest'),
        },
        ...solidEndItems,
      ];
    }
    return items;
  },
);
