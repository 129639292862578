export const copyToClipboard = (text: string) => {
  if (navigator) {
    navigator.clipboard.writeText(text).then(() => {
      // eslint-disable-next-line no-console
      console.debug('copied to clipboard!');
    },
    () => {
      console.error('copy error');
    });
  } else {
    const textArea = document.createElement('textarea');
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    try {
      document.execCommand('copy');
      // eslint-disable-next-line no-console
      console.debug('copied to clipboard!');
    } catch (err) {
      console.error('copy error');
    }
    document.body.removeChild(textArea);
  }
};
