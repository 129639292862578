import busdLogo from 'static/wallet/tokens/busd-logo.svg';
import usdtLogo from 'static/wallet/tokens/usdt-logo.svg';
import usdcLogo from 'static/wallet/tokens/usdc-logo.svg';
import starlyLogo from 'static/wallet/ic-starly2.svg';

export const LISTINGS_LIMIT: number = 9;

export const SESSION_ERROR_CODES_REGEX = /(1006|1008|Session expired)/;

// eslint-disable-next-line
export const STARLY_PACK_ABI = [{"inputs":[{"internalType":"string","name":"collectionId","type":"string"},{"internalType":"string[]","name":"packIDs","type":"string[]"},{"internalType":"string","name":"forStarlyUserId","type":"string"},{"internalType":"uint256","name":"price","type":"uint256"},{"internalType":"address","name":"tokenAddress","type":"address"},{"components":[{"internalType":"address payable","name":"receiverAddress","type":"address"},{"internalType":"uint256","name":"amount","type":"uint256"}],"internalType":"struct StarlyPack.SaleCut","name":"beneficiarySaleCut","type":"tuple"},{"components":[{"internalType":"address payable","name":"receiverAddress","type":"address"},{"internalType":"uint256","name":"amount","type":"uint256"}],"internalType":"struct StarlyPack.SaleCut","name":"creatorSaleCut","type":"tuple"},{"components":[{"internalType":"address payable","name":"receiverAddress","type":"address"},{"internalType":"uint256","name":"amount","type":"uint256"}],"internalType":"struct StarlyPack.SaleCut[]","name":"additionalSaleCuts","type":"tuple[]"}],"name":"purchase","outputs":[],"stateMutability":"nonpayable","type":"function"}] as const;
// eslint-disable-next-line
export const VESTING_WALLET_ABI = [{"inputs":[],"name":"duration","outputs":[{"internalType":"uint256","name":"","type":"uint256"}],"stateMutability":"view","type":"function"},{"inputs":[{"internalType":"address","name":"token","type":"address"}],"name":"release","outputs":[],"stateMutability":"nonpayable","type":"function"},{"inputs":[{"internalType":"address","name":"token","type":"address"}],"name":"released","outputs":[{"internalType":"uint256","name":"","type":"uint256"}],"stateMutability":"view","type":"function"},{"inputs":[],"name":"start","outputs":[{"internalType":"uint256","name":"","type":"uint256"}],"stateMutability":"view","type":"function"},{"inputs":[{"internalType":"address","name":"token","type":"address"},{"internalType":"uint64","name":"timestamp","type":"uint64"}],"name":"vestedAmount","outputs":[{"internalType":"uint256","name":"","type":"uint256"}],"stateMutability":"view","type":"function"},{"stateMutability":"payable","type":"receive"}] as const;
// eslint-disable-next-line
export const ERC_20_ABI = [{"inputs":[{"internalType":"address","name":"owner","type":"address"},{"internalType":"address","name":"spender","type":"address"}],"name":"allowance","outputs":[{"internalType":"uint256","name":"","type":"uint256"}],"stateMutability":"view","type":"function"},{"inputs":[{"internalType":"address","name":"spender","type":"address"},{"internalType":"uint256","name":"amount","type":"uint256"}],"name":"approve","outputs":[{"internalType":"bool","name":"","type":"bool"}],"stateMutability":"nonpayable","type":"function"},{"inputs":[{"internalType":"address","name":"account","type":"address"}],"name":"balanceOf","outputs":[{"internalType":"uint256","name":"","type":"uint256"}],"stateMutability":"view","type":"function"},{"inputs":[],"name":"decimals","outputs":[{"internalType":"uint8","name":"","type":"uint8"}],"stateMutability":"view","type":"function"}] as const;

type TokenInfo = {
  icon: string;
  address: string | undefined;
};

export const ethTokensMap = new Map<string, TokenInfo>([
  ['STARLY', {
    icon: starlyLogo,
    address: process.env.REACT_APP_CONTRACT_ETH_STARLY,
  }],
  ['USDT', {
    icon: usdtLogo,
    address: process.env.REACT_APP_CONTRACT_ETH_USDT,
  }],
  ['BUSD', {
    icon: busdLogo,
    address: process.env.REACT_APP_CONTRACT_ETH_BUSD,
  }],
  ['USDC', {
    icon: usdcLogo,
    address: process.env.REACT_APP_CONTRACT_ETH_USDC,
  }],
]);

export const ethConfig = process.env.REACT_APP_CHAIN_ENV === 'mainnet' ? {
  chain: {
    name: 'Binance Smart Chain',
    id: 56,
    rpcUrl: 'https://bsc-dataseed.binance.org/',
    symbol: 'BNB',
    blockExplorerUrl: 'https://bscscan.com',
  },
} : {
  chain: {
    name: 'Binance Smart Chain - Testnet',
    id: 97,
    rpcUrl: 'https://data-seed-prebsc-1-s1.binance.org:8545/',
    symbol: 'BNB',
    blockExplorerUrl: 'https://testnet.bscscan.com',
  },
};
