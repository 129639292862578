import styled, { css } from 'styled-components';
import { mediaDesktop } from '../../breakpoints';
import { type TypographyProps } from './types';

const getMediaSize = (mob: string, desk: string) => css`
  font-size: ${mob};

  ${mediaDesktop} {
    font-size: ${desk};
  }
`;

const getUnmediaSize = (px: string) => css`font-size: ${px};`;

const Typography = styled.p<TypographyProps>`
  ${({ size, isCreate }) => {
    switch (size) {
      case 'header1': return getMediaSize('42px', '120px');

      case 'cardFullScreen': return getMediaSize('11px', '22px');

      case 'cardTitleFullScreen': return getMediaSize('14px', '28px');

      case 'header2': return getMediaSize('20px', '40px'); // 40px

      case 'title': return getMediaSize('16px', '24px');

      case 'rarity': return getMediaSize('16px', '19px');

      case 'titleCaption': return getMediaSize('13px', '18px');

      case 'caption': return getMediaSize('11px', '15px');

      case 'captionSmall': return getMediaSize('11px', '16px');

      case 'microcaption': return getMediaSize('10px', '14px');

      case 'unmedia20': return getUnmediaSize('20px');

      case 'unmedia14': return getMediaSize(isCreate ? '14px' : '0.35625rem', '14px');

      case 'unmedia11': return getMediaSize(isCreate ? '11px' : '0.28125rem', '11px');

      case 'unmedia16': return getMediaSize('13px', '16px');

      case 'unmedia48': return getMediaSize('32px', '48px');

      case 'unmedia72': return getMediaSize('42px', '72px');

      case 'header3': return getMediaSize('36px', '40px');

      case 'unmedia21': return getMediaSize('16px', '21px');

      case 'header4': return getMediaSize('28px', '40px');

      case 'popUpTitle': return getMediaSize('20px', '30px');

      case 'popUpText': return getMediaSize('16px', '24px');

      case 'walletBalance': return getMediaSize('20px', '32px');

      case 'walletCurrency': return getMediaSize('20px', '32px');

      case 'wallet': return getMediaSize('13px', '21px');

      case 'cardTitle': return getUnmediaSize('8px');

      case 'subtitle': return getMediaSize('13px', '24px');

      case 'drops': return getMediaSize('13px', '20px');

      case 'dropsCardStatus': return getMediaSize('11px', '16px');

      case 'dropsTimer': return getMediaSize('11px', '12px');

      case 'p':
      default: return getMediaSize('14px', '21px'); // 21px
    }
  }}

  letter-spacing: ${({ lSpacing }) => (lSpacing ? `${lSpacing}px` : 'inherit')};
  text-transform: ${({ uppercase }) => (uppercase ? 'uppercase' : 'inherit')};
  font-weight: ${({ bold }) => (bold ? '600' : 'inherit')};
  color: ${({ textColor, theme }) => theme.colors[textColor || 'inherit']};
  text-align: ${({ align }) => align || 'inherit'};
  line-height: ${({ lineHeight }) => (lineHeight ?? 'inherit')};
  font-family: ${({ font, theme }) => theme.fonts[font || 'primary']};
  margin: 0;
  ${({ padding }) => (padding ? { padding } : { padding: '0' })}
`;

export default Typography;
