import Typography from 'components/Typography';
import { mediaDesktop } from 'breakpoints';
import styled from 'styled-components/macro';

export const TittleText = styled(Typography)`
  margin-bottom: 50px;
  line-height: 24px;
  letter-spacing: 1px;
  text-align: center;
  ${mediaDesktop} {
    line-height: 37px;
  };
`;

export const ButtonsContainer = styled.div`
  display: grid;
  grid-gap: 16px;
  width: 100%;
  ${mediaDesktop} {
    grid-gap: 20px; 
  };
`;
