import styled from 'styled-components';
import type { PictureWrapperProps } from './types';

export const PictureWrapper = styled.picture<PictureWrapperProps>`
  width: ${({ $width }) => $width};
  height: ${({ $height }) => $height};
  opacity: ${({ $loaded }) => ($loaded ? '1' : '0')};
  transition: opacity linear 0.1s;
`;

export const PictureImage = styled.img`
  width: 100%;
  height: 100%;
  border-radius: inherit;
  object-fit: cover;
`;
