export const shareUrl = async (
  url: string,
  text: string | undefined,
  title: string | undefined,
  callBack?: () => void,
) => {
  if (navigator) {
    try {
      await navigator.share({
        title,
        url,
        text,
      });
      return true;
    } catch (e) {
      console.error(e);
      return false;
    } finally {
      callBack?.();
    }
  }
  callBack?.();
  return null;
};
