import styled from 'styled-components';
import { mediaDesktop } from '../../breakpoints';
import theme from '../theme';

export const ButtonsContainer = styled.div`
  margin-top: 32px;
  display: grid;
  width: 264px;
  grid-gap: 16px;
  ${mediaDesktop} {
    width: 412px;
    grid-gap: 20px;
    margin-top: 50px;
  }
`;

export const PopUpText = styled.p`
  text-transform: uppercase;
  font-family: ${theme.fonts.secondary}, sans-serif;
  font-size: 20px;
  letter-spacing: 1px;
  text-align: center;
  color: ${theme.colors.black};
  ${mediaDesktop} {
    font-size: 30px;
  }
  
  &:last-of-type {
    margin-top: 32px;
  }
`;
