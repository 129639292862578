import { type Dispatch } from '@reduxjs/toolkit';
import { type FaceControlState, type User } from '@starly/starly-types';
import { RouteTypes } from 'RouteTypes';
import { userRequest } from 'store/user/userActions';

interface CheckFollowersProps {
  faceControlState?: FaceControlState,
  connections: User['connections'],
  activeStep: number,
  id: string,
  dispatch: Dispatch<any>,
  callback?: (value: string) => void,
}

const minFollowersCount = parseInt(process.env.REACT_APP_FACE_CONTROL_FOLLOWERS || '', 10);

export const checkFollowers = ({
  faceControlState, connections, activeStep, id, dispatch, callback,
}: CheckFollowersProps) => {
  if (faceControlState === 'pass') {
    return callback ? callback(`${RouteTypes.StepDefault}${activeStep}`) : `${RouteTypes.StepDefault}${activeStep}`;
  }
  if ((connections?.twitter && connections.twitter.followers_count >= minFollowersCount)
    || (connections?.youtube && +connections.youtube.subscribers_count >= minFollowersCount)
    || (connections?.instagram && connections.instagram?.followers_count
      && connections.instagram.followers_count >= minFollowersCount)) {
    dispatch(userRequest({ id }));

    sessionStorage.removeItem('youtubePending');
    sessionStorage.removeItem('twitterPending');
    sessionStorage.removeItem('instagramPending');

    return callback ? callback(`${RouteTypes.StepDefault}${activeStep}`) : `${RouteTypes.StepDefault}${activeStep}`;
  }
  return callback ? callback(RouteTypes.FaceControl) : RouteTypes.FaceControl;
};
