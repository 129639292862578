import styled from 'styled-components/macro';
import { css } from 'styled-components';
import { mediaDesktop } from '../../breakpoints';

export const Overlay = styled.div`
  position: fixed;
  height: 130vh;
  width: 100vw;
  z-index: 30;
  background: rgba(0, 0, 0, 0.7);
`;

export const DropdownContainer = styled.div<{ overlay?: boolean, isOpen: boolean }>`
  position: relative;
  ${({ overlay, isOpen }) => (overlay && isOpen ? 'z-index: 31;' : '')}
`;

export const DropdownMenuContainer = styled.div<{
  isOpen: boolean,
  $overlay?: boolean,
  $translate?: string,
  $fluid?: boolean,
  $mobileTranslate?: string,
}>`
  position: absolute;
  ${({ isOpen, $overlay }) => (isOpen && $overlay ? 'z-index: 31;' : 'z-index: 2;')}
  top: 100%;
  right: 0;
  transform: translate(20px, 20px);
  ${({ $fluid }) => ($fluid ? css`
    width: 100%;
    transform: translate(0%);
  ` : '')}
  ${({ $mobileTranslate }) => (
    $mobileTranslate ? css`
      transform: translate(${$mobileTranslate});
    ` : ''
  )}
  ${mediaDesktop} {
    ${({ $translate }) => (
    $translate ? css`
      transform: translate(${$translate});
    ` : ''
  )}
  }
`;
