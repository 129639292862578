import { type FlowFestProjectId } from '@starly/starly-types';
import { flowFestSellBeamItemTransaction } from './internal/sellBeamItem.tx';
import { flowFestSellBlockleteGamesItemTransaction } from './internal/sellBlockleteGamesItem.tx';
import { flowFestSellChainmonstersRewardsItemTransaction } from './internal/sellChainmonstersRewardsItem.tx';
import { flowFestSellCraveItemTransaction } from './internal/sellCraveItem.tx';
import { flowFestSellShardItemTransaction } from './internal/sellShardItem.tx';
import { flowFestSellEverbloomItemTransaction } from './internal/sellEverbloomItem.tx';
import { flowFestSellFantastecItemTransaction } from './internal/sellFantastecItem.tx';
import { flowFestSellCricketMomentsItemTransaction } from './internal/sellCricketMomentsItem.tx';
import { flowFestSellVouchersItemTransaction } from './internal/sellVouchersItem.tx';
import { flowFestSellKOTDItemTransaction } from './internal/sellKOTDItem.tx';
import { flowFestSellKLKTNItemTransaction } from './internal/sellKLKTNItem.tx';
import { flowFestSellMusicBlockItemTransaction } from './internal/sellMusicBlockItem.tx';
import { flowFestSellMynftItemTransaction } from './internal/sellMynftItem.tx';
import { flowFestSellGaiaItemTransaction } from './internal/sellGaiaItem.tx';
import { flowFestSellNyatheesOVOItemTransaction } from './internal/sellNyatheesOVOItem.tx';
import { flowFestSellRaceDayItemTransaction } from './internal/sellRaceDayItem.tx';
import { flowFestSellRareRoomsItemTransaction } from './internal/sellRareRoomsItem.tx';
import { flowFestSellRCRDSHPItemTransaction } from './internal/sellRCRDSHPItem.tx';
import { flowFestSellSportsIconItemTransaction } from './internal/sellSportsIconItem.tx';
import { flowFestSellStarlyCardItemTransaction } from './internal/sellStarlyCardItem.tx';
import { flowFestSellTheFabricantMysteryBoxItemTransaction } from './internal/sellTheFabricantMysteryBoxItem.tx';
import { flowFestSellCaaPassItemTransaction } from './internal/sellCaaPassItem.tx';
import { flowFestSellTopShotItemTransaction } from './internal/sellTopShotItem.tx';
import { flowFestSellTuneGoItemTransaction } from './internal/sellTuneGoItem.tx';
import { flowFestSellMatrixWorldFlowFestItemTransaction } from './internal/sellMatrixWorldFlowFestItem.tx';
import { flowFestSellCollectibleItemTransaction } from './internal/sellCollectibleItem.tx';

export async function flowFestSellTransaction(
  project: FlowFestProjectId,
  itemId: number,
  price: number,
  marketplaceFlowAccount: string,
  marketplaceSaleCutPercents: number,
) {
  // const isMainnet = process.env.REACT_APP_CHAIN_ENV === 'mainnet';
  const beneficiaryCutPercent = marketplaceSaleCutPercents;
  const beneficiaryAddress = marketplaceFlowAccount;

  switch (project) {
    case 'beam_fright': {
      // const beneficiaryAddress = isMainnet ? '0x12c122ca9266c278' : '0x12c122ca9266c278';
      return flowFestSellBeamItemTransaction(itemId, price, beneficiaryAddress, beneficiaryCutPercent);
    }
    case 'blocklete_golf': {
      // const beneficiaryAddress = isMainnet ? '0x12c122ca9266c278' : '0x12c122ca9266c278';
      return flowFestSellBlockleteGamesItemTransaction(itemId, price, beneficiaryAddress, beneficiaryCutPercent);
    }
    case 'chainmonsters': {
      // const beneficiaryAddress = isMainnet ? '0x12c122ca9266c278' : '0x12c122ca9266c278';
      return flowFestSellChainmonstersRewardsItemTransaction(itemId, price, beneficiaryAddress, beneficiaryCutPercent);
    }
    case 'crave': {
      // const beneficiaryAddress = isMainnet ? '0x12c122ca9266c278' : '0x12c122ca9266c278';
      return flowFestSellCraveItemTransaction(itemId, price, beneficiaryAddress, beneficiaryCutPercent);
    }
    case 'eternal': {
      // const beneficiaryAddress = isMainnet ? '0x12c122ca9266c278' : '0x12c122ca9266c278';
      return flowFestSellShardItemTransaction(itemId, price, beneficiaryAddress, beneficiaryCutPercent);
    }
    case 'everbloom': {
      // const beneficiaryAddress = isMainnet ? '0x12c122ca9266c278' : '0x12c122ca9266c278';
      return flowFestSellEverbloomItemTransaction(itemId, price, beneficiaryAddress, beneficiaryCutPercent);
    }
    case 'fantastec_swap': {
      // const beneficiaryAddress = isMainnet ? '0x12c122ca9266c278' : '0x12c122ca9266c278';
      return flowFestSellFantastecItemTransaction(itemId, price, beneficiaryAddress, beneficiaryCutPercent);
    }
    case 'faze': {
      // const beneficiaryAddress = isMainnet ? '0x12c122ca9266c278' : '0x12c122ca9266c278';
      return flowFestSellCricketMomentsItemTransaction(itemId, price, beneficiaryAddress, beneficiaryCutPercent);
    }
    case 'jambb': {
      // const beneficiaryAddress = isMainnet ? '0x12c122ca9266c278' : '0x12c122ca9266c278';
      return flowFestSellVouchersItemTransaction(itemId, price, beneficiaryAddress, beneficiaryCutPercent);
    }
    case 'king_of_the_dot': {
      // const beneficiaryAddress = isMainnet ? '0x12c122ca9266c278' : '0x12c122ca9266c278';
      return flowFestSellKOTDItemTransaction(itemId, price, beneficiaryAddress, beneficiaryCutPercent);
    }
    case 'klktn': {
      // const beneficiaryAddress = isMainnet ? '0x12c122ca9266c278' : '0x12c122ca9266c278';
      return flowFestSellKLKTNItemTransaction(itemId, price, beneficiaryAddress, beneficiaryCutPercent);
    }
    case 'melos_studio': {
      // const beneficiaryAddress = isMainnet ? '0x12c122ca9266c278' : '0x12c122ca9266c278';
      return flowFestSellMusicBlockItemTransaction(itemId, price, beneficiaryAddress, beneficiaryCutPercent);
    }
    case 'mugen_art': {
      // const beneficiaryAddress = isMainnet ? '0x12c122ca9266c278' : '0x12c122ca9266c278';
      return flowFestSellMynftItemTransaction(itemId, price, beneficiaryAddress, beneficiaryCutPercent);
    }
    case 'mynft': {
      // const beneficiaryAddress = isMainnet ? '0x12c122ca9266c278' : '0x12c122ca9266c278';
      return flowFestSellMynftItemTransaction(itemId, price, beneficiaryAddress, beneficiaryCutPercent);
    }
    case 'nft_genius': {
      // const beneficiaryAddress = isMainnet ? '0x12c122ca9266c278' : '0x12c122ca9266c278';
      return flowFestSellGaiaItemTransaction(itemId, price, beneficiaryAddress, beneficiaryCutPercent);
    }
    case 'ovo': {
      // const beneficiaryAddress = isMainnet ? '0x12c122ca9266c278' : '0x12c122ca9266c278';
      return flowFestSellNyatheesOVOItemTransaction(itemId, price, beneficiaryAddress, beneficiaryCutPercent);
    }
    case 'raceday_nft': {
      // const beneficiaryAddress = isMainnet ? '0x12c122ca9266c278' : '0x12c122ca9266c278';
      return flowFestSellRaceDayItemTransaction(itemId, price, beneficiaryAddress, beneficiaryCutPercent);
    }
    case 'racing_time': {
      // const beneficiaryAddress = isMainnet ? '0x12c122ca9266c278' : '0x12c122ca9266c278';
      return flowFestSellMynftItemTransaction(itemId, price, beneficiaryAddress, beneficiaryCutPercent);
    }
    case 'rarerooms': {
      // const beneficiaryAddress = isMainnet ? '0x12c122ca9266c278' : '0x12c122ca9266c278';
      return flowFestSellRareRoomsItemTransaction(itemId, price, beneficiaryAddress, beneficiaryCutPercent);
    }
    case 'rcrdshp': {
      // const beneficiaryAddress = isMainnet ? '0x12c122ca9266c278' : '0x12c122ca9266c278';
      return flowFestSellRCRDSHPItemTransaction(itemId, price, beneficiaryAddress, beneficiaryCutPercent);
    }
    case 'sportsicon': {
      // const beneficiaryAddress = isMainnet ? '0x12c122ca9266c278' : '0x12c122ca9266c278';
      return flowFestSellSportsIconItemTransaction(itemId, price, beneficiaryAddress, beneficiaryCutPercent);
    }
    case 'starly': {
      // const beneficiaryAddress = isMainnet ? '0x12c122ca9266c278' : '0x12c122ca9266c278';
      return flowFestSellStarlyCardItemTransaction(itemId, price, beneficiaryAddress, beneficiaryCutPercent);
    }
    case 'the_fabricant': {
      // const beneficiaryAddress = isMainnet ? '0x12c122ca9266c278' : '0x12c122ca9266c278';
      return flowFestSellTheFabricantMysteryBoxItemTransaction(itemId, price, beneficiaryAddress, beneficiaryCutPercent);
    }
    case 'thing_fund': {
      // const beneficiaryAddress = isMainnet ? '0x12c122ca9266c278' : '0x12c122ca9266c278';
      return flowFestSellCaaPassItemTransaction(itemId, price, beneficiaryAddress, beneficiaryCutPercent);
    }
    case 'topshot': {
      // const beneficiaryAddress = isMainnet ? '0x12c122ca9266c278' : '0x12c122ca9266c278';
      return flowFestSellTopShotItemTransaction(itemId, price, beneficiaryAddress, beneficiaryCutPercent);
    }
    case 'tunego': {
      // const beneficiaryAddress = isMainnet ? '0x12c122ca9266c278' : '0x12c122ca9266c278';
      return flowFestSellTuneGoItemTransaction(itemId, price, beneficiaryAddress, beneficiaryCutPercent);
    }
    case 'voxel_knight': {
      // const beneficiaryAddress = isMainnet ? '0x12c122ca9266c278' : '0x12c122ca9266c278';
      return flowFestSellMynftItemTransaction(itemId, price, beneficiaryAddress, beneficiaryCutPercent);
    }
    case 'whitematrix': {
      // const beneficiaryAddress = isMainnet ? '0x12c122ca9266c278' : '0x697d72a988a77070';
      return flowFestSellMatrixWorldFlowFestItemTransaction(itemId, price, beneficiaryAddress, beneficiaryCutPercent);
    }
    case 'xtingles': {
      // const beneficiaryAddress = isMainnet ? '0x12c122ca9266c278' : '0x12c122ca9266c278';
      return flowFestSellCollectibleItemTransaction(itemId, price, beneficiaryAddress, beneficiaryCutPercent);
    }
    default:
      throw Error(`Unknown project: ${project}`);
  }
}
