import {
  type FC, useEffect, useLayoutEffect, useRef, useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import Typography from 'components/Typography';

import CardMedia from './components/CardMedia';
import {
  StyledCardBackground,
  StyledCardContainer,
  StyledCardFixedWrapper,
  StyledCardInfoContainer,
  StyledContent,
  StyledContentText,
  StyledHeader,
  StyledQRCode,
  StyledQRCodeContainer,
  StyledTypeContainer,
} from './styled';

import { type CardProps } from './types';

const qrCodeDomain = process.env.REACT_APP_CHAIN_ENV !== 'mainnet' ? 'https://canary.starly.io/qr/' : 'https://starly.io/qr/';

interface CardComponentProps {
  card: CardProps;
  isCardPage?: boolean;
  isVideoEnded?: boolean;
  collectionId?: string;
  isTrimmedCard?: boolean;
  isFlowFest?: boolean;
}

const Card: FC<CardComponentProps> = ({
  card,
  isCardPage = false,
  isVideoEnded,
  collectionId = '',
  isTrimmedCard = false,
  isFlowFest,
}) => {
  const {
    title,
    rarity,
    author,
    collection,
    totalEditions,
    qrCodeUrl,
    edition,
    editions,
    cardId,
    projectName = '',
  } = card;
  const { t } = useTranslation();
  const cardContainer = useRef<HTMLDivElement>(null);
  const starlyId = `${collectionId}/${cardId}/${edition}`;
  const qrCodeWithEdition = qrCodeDomain + starlyId;

  // Default values in px (rem for fonts)
  const [adaptiveStyles, setAdaptiveStyles] = useState({
    fonts: {
      header: 0,
      title: 0,
      cardNumber: 0,
      cardCount: 0,
    },
    height: {
      title: 0,
      header: 0,
    },
  });

  const onResize = () => {
    if (cardContainer.current) {
      const { width, height } = cardContainer.current.getBoundingClientRect();

      setAdaptiveStyles({
        fonts: {
          header: width / 1024,
          title: width / 336,
          cardNumber: width / 336,
          cardCount: width / 624,
        },
        height: {
          title: Math.ceil((width * 1.2 * 9) / 64),
          header: height / 37,
        },
      });
    }
  };

  useEffect(() => {
    window.addEventListener('resize', onResize);

    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, []);

  useLayoutEffect(() => {
    onResize();
  }, []);

  // Because card's inner element's size needs to be recalculated via js
  // Some cards calc sizing while animation playing (at scale 0.5 for example) and sizing stuck
  // This prevent calculating element sizing while animation playing
  useEffect(() => {
    if (typeof window?.ResizeObserver !== 'undefined') {
      const observer = new ResizeObserver(() => {
        setTimeout(onResize);
      });
      observer.observe(cardContainer.current!);
    }
  }, []);

  return (
    <StyledCardBackground data-test-id={`rarity_card_value_${rarity}`} data-rarity-attribute={`${rarity}`} rarity={rarity}>
      <StyledCardContainer data-test-id="card_container" ref={cardContainer}>
        <CardMedia
          key={starlyId}
          card={card}
          isCardPage={isCardPage}
          isVideoEnded={isVideoEnded}
          collectionId={collectionId}
          isTrimmedCard={isTrimmedCard}
          isFlowFest={isFlowFest}
        />
        <StyledCardInfoContainer data-test-id="card_info_container">
          <StyledHeader
            data-test-id="card_info_container_header"
            style={{
              height: `${adaptiveStyles.height.header}px`,
            }}
          >
            <Typography
              data-test-id="card_author_and_collection_name"
              data-author-collection-attribute={`${author}: ${collection}`}
              lSpacing={adaptiveStyles.fonts.cardCount * 1.5}
              lineHeight="normal"
              uppercase
              font="spaceMono"
              textColor="white"
              style={{
                fontSize: `${adaptiveStyles.fonts.header}rem`,
                display: 'inline-block',
                verticalAlign: 'middle',
              }}
            >
              {`${author} // `}
              <span style={{ fontWeight: 'bold' }}>{isFlowFest ? projectName : collection}</span>
            </Typography>
          </StyledHeader>
          <StyledContent>
            <StyledContentText
              data-test-id="card_content_text"
              data-title-attribute={`${title || t('card.titleNa')}`}
              lSpacing={-adaptiveStyles.fonts.cardCount * 1.5}
              align="left"
              bold
              uppercase
              style={{
                maxHeight: `${adaptiveStyles.height.title}px`,
                fontSize: `${adaptiveStyles.fonts.title}rem`,
              }}
            >
              {card.media && (title || t('card.titleNa'))}
            </StyledContentText>
            <StyledQRCodeContainer data-test-id="qr_code_container">
              <StyledQRCode data-test-id="qr_code" src={edition && !isFlowFest ? qrCodeWithEdition : qrCodeUrl} />
            </StyledQRCodeContainer>
          </StyledContent>
          <StyledTypeContainer
            data-test-id="card_type_edition"
            data-edition-attribute={edition}
            data-total-edition-attribute={totalEditions}
            edition={edition}
            $isAvailable={Boolean(edition || editions || totalEditions)}
          >
            <Typography
              lSpacing={adaptiveStyles.fonts.cardCount}
              font="syncopate"
              style={{
                fontSize: `${edition ? adaptiveStyles.fonts.cardNumber : adaptiveStyles.fonts.cardCount}rem`,
                marginRight: `${edition ? '1px' : '0px'}`,
                color: edition ? 'white' : '#aaaaaa',
              }}
              bold
            >
              {edition ? `#${edition}` : '#'}
            </Typography>
            <Typography
              lSpacing={-adaptiveStyles.fonts.cardCount * 1.5}
              font="syncopate"
              style={{ fontSize: `${adaptiveStyles.fonts.cardCount}rem`, color: '#aaaaaa' }}
              bold
            >
              /
              {totalEditions || editions}
            </Typography>
          </StyledTypeContainer>
        </StyledCardInfoContainer>
      </StyledCardContainer>
    </StyledCardBackground>
  );
};

interface UrlWrapperProps extends CardComponentProps {
  cardUrl?: string;
}

const UrlWrapper: FC<UrlWrapperProps> = ({ cardUrl, ...props }) => {
  const { 'data-test-id': dataTestId, 'data-date-attribute': dataDatetId } = props.card;
  if (!cardUrl) {
    return (
      <StyledCardFixedWrapper
        data-testid="card_fixed_wrapper"
        data-test-id={dataTestId}
        data-date-attribute={dataDatetId}
        as="div"
        className="starly-card"
      >
        <Card {...props} />
      </StyledCardFixedWrapper>
    );
  }
  return (
    <StyledCardFixedWrapper
      data-testid="card_fixed_wrapper"
      data-test-id={dataTestId}
      data-date-attribute={dataDatetId}
      to={cardUrl}
      className="starly-card"
    >
      <Card {...props} />
    </StyledCardFixedWrapper>
  );
};

export default UrlWrapper;
