import { type FC } from 'react';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';

import Media from 'components/Media';
import Typography from 'components/Typography';
import { RouteTypes } from 'RouteTypes';

import {
  InfoContainer, NotificationAvatar, NotificationWrapper, Thumbnail,
} from './styled';
import { type NotificationProps } from './types';

const getTime = (date: Date): string => {
  const thisMoment = dayjs();
  if (thisMoment.diff(date, 'seconds') < 60) return 'just now';
  const minutesAgo = thisMoment.diff(date, 'minutes');
  if (minutesAgo < 60) return `${minutesAgo} minute${minutesAgo === 1 ? '' : 's'} ago`;
  const hoursAgo = thisMoment.diff(date, 'hours');
  if (hoursAgo < 24) return `${hoursAgo} hour${hoursAgo === 1 ? '' : 's'} ago`;
  if (hoursAgo < 48) return 'yesterday';
  const yearsAgo = thisMoment.diff(date, 'years');
  if (yearsAgo < 1) return dayjs(date).format('MMMM D');
  return dayjs(date).format('MMMM D, YYYY');
};

const Notification: FC<NotificationProps> = ({
  userAvatar, notificationText, date, thumbnail,
  linkPath,
  thumbnailUrl,
  secondaryText,
  username,
  callbackRef,
}) => (
  <NotificationWrapper ref={callbackRef}>
    <NotificationAvatar to={RouteTypes.User.replace(':username', username)}>
      <Media media={userAvatar} />
    </NotificationAvatar>
    <InfoContainer>
      <Link to={linkPath}>
        <Typography size="title" bold>
          {notificationText}
        </Typography>
      </Link>
      <Typography size="title" as="div">
        {secondaryText && <div>{secondaryText}</div>}
        {getTime(date)}
      </Typography>
    </InfoContainer>
    <Thumbnail to={thumbnailUrl}>
      {typeof thumbnail !== 'string' && thumbnail ? (
        <Media media={thumbnail} />
      ) : (
        <img src={thumbnail} alt="" />
      )}
    </Thumbnail>
  </NotificationWrapper>
);

export default Notification;
