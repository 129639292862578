import type { FormEvent } from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { flowNFTNYCLoginRequest, flowNFTNYCToggleModal } from 'store/flow/flowActions';
import { useDispatch, useSelector } from 'react-redux';
import Button from 'components/Button';
import { useLocation } from 'react-router';
import { RouteTypes } from 'RouteTypes';
import PopUp from 'components/PopUp';
import { selectFlowIsLoading } from 'store/flow/flowSelectors';
import NotificationsLoader from 'components/NotificationsLoader/NotificationsLoader';
import { useWindowSize } from 'hooks/useWindowSize';
import { desktopBreakpoint } from 'breakpoints';
import {
  ButtonsContainer, TittleText, ConnectingButtonContainer, LoaderWrapper,
} from './styled';

const FlowNFTNYCWalletModal = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const isLoading = useSelector(selectFlowIsLoading);
  const width = useWindowSize();
  const isMobile = width < desktopBreakpoint;
  const closeWalletModal = () => dispatch(flowNFTNYCToggleModal(false));
  const onWalletLogin = (e: FormEvent) => {
    e.preventDefault();

    dispatch(flowNFTNYCLoginRequest({}));
  };

  const getClose = () => {
    switch (pathname) {
      case RouteTypes.Packs: {
        return t('common.close');
      }
      default: return t('wallet.notNow');
    }
  };

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => { document.body.style.overflow = 'auto'; };
  }, []);
  return (
    <PopUp>
      <TittleText size="popUpTitle" textColor="black" font="secondary">
        {t('wallet.wrong.openPack')}
      </TittleText>
      <ButtonsContainer>
        <Button onClick={onWalletLogin} disabled={isLoading}>
          {isLoading
            ? (
              <ConnectingButtonContainer>
                {t('wallet.connecting')}
                <LoaderWrapper>
                  <NotificationsLoader small={isMobile} />
                </LoaderWrapper>
              </ConnectingButtonContainer>
            )
            : t('wallet.connectWallet')}
        </Button>
        <Button background="black" onClick={closeWalletModal}>{getClose()}</Button>
      </ButtonsContainer>
    </PopUp>
  );
};

export default FlowNFTNYCWalletModal;
