import type { FC } from 'react';
import { useRef, useState } from 'react';
import type { OnSelectFn, SelectOption } from 'components/Select/types';
import { useOutsideClickHandle } from 'hooks/useOutsideClick';

import * as S from './Select.styled';

interface SelectProps {
  values: SelectOption[];
  defaultValueIndex?: number;
  valueIndex?: number;
  onSelect: OnSelectFn;
}

const Select: FC<SelectProps> = ({
  values, onSelect, defaultValueIndex, valueIndex,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [currentValueIndex, setCurrentValueIndex] = useState(defaultValueIndex || 0);
  const ref = useRef<HTMLDivElement>(null);

  useOutsideClickHandle(ref, () => {
    if (isOpen) setIsOpen(false);
  });

  const setSelectValue = (newValue: SelectOption, i: number) => {
    setIsOpen(false);
    setCurrentValueIndex(i);
    onSelect(newValue, i, '');
  };

  const defaultValue = values[valueIndex ?? currentValueIndex];
  const defaultValueLabel = typeof defaultValue === 'string' ? defaultValue : defaultValue.label;

  return (
    <S.SelectContainer tabIndex={0} ref={ref} $isOpen={isOpen} onClick={() => setIsOpen(!isOpen)}>
      <S.SelectHeader>
        <p>{defaultValueLabel}</p>
      </S.SelectHeader>
      <S.SelectArrow $isMenuOpen={isOpen}>
        <svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false">
          <path
            d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747
            3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695
            4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"
          />
        </svg>
      </S.SelectArrow>
      <S.SelectOptionsList $isOpen={isOpen}>
        {values.map((value, i) => {
          const optionLabel = typeof value === 'string' ? value : value.label;
          return (
            <S.SelectOption onClick={() => setSelectValue(value, i)} key={optionLabel}>
              {optionLabel}
            </S.SelectOption>
          );
        })}
      </S.SelectOptionsList>
    </S.SelectContainer>
  );
};

export default Select;
