import { type FungibleTokenType, type SaleCut } from '@starly/starly-types';
import { createAction } from '@reduxjs/toolkit';

export type TokenBalance = {
  balance: string;
  decimals: number;
};

type EthereumBalanceResponsePayload = {
  token: FungibleTokenType;
  balance: TokenBalance;
};

type EthereumLoginRequestPayload = {
  providerId: string;
  callbacks: {
    onDisconnect: (error: Error) => void
    onAccountsChanged: (accounts: string[]) => void
    onChainChanged: () => void
  }
};

type EthereumLoginResponsePayload = {
  address: string,
};

type EthereumBuyPackRequestPayload = {
  collectionId: string
  packIds: string[]
  price: number
  currency: FungibleTokenType
  paymentCurrency: FungibleTokenType
  beneficiaryAddress: string
  beneficiaryCutPercent: number
  creatorAddress: string
  creatorCutPercent: number
  additionalCuts: SaleCut[]
};

type EthereumToggleModalPayload = {
  isOpen: boolean,
  errorMessage?: string,
  errorCode?: number,
};

export const ethereumToggleLoading = createAction<boolean>('ethereum/TOGGLE_LOADING');

export const ethereumBalanceResponse = createAction<EthereumBalanceResponsePayload>('ethereum/balance/response');

export const ethereumBuyPackRequest = createAction<EthereumBuyPackRequestPayload>('ethereum/buyPack/request');

export const ethereumLoginRequest = createAction<EthereumLoginRequestPayload>('ethereum/login/request');

export const ethereumLoginResponse = createAction<EthereumLoginResponsePayload>('ethereum/login/response');

export const ethereumWalletUpdate = createAction<EthereumLoginResponsePayload>('ethereum/wallet/update');

export const ethereumLogout = createAction('ethereum/logout/request');

export const ethereumToggleModal = createAction<EthereumToggleModalPayload>('ethereum/TOGGLE_MODAL');
