import { object, string } from 'yup';
import { emailRegex } from 'global/constants';

export const getValidationScheme = () => object().shape({
  modalEmail: string()
    .max(200, 'user.error.emailMax')
    .required('user.error.emailRequired')
    .matches(emailRegex, 'user.error.emailInvalid'),
  password: string().min(6).required('auth.error.passwordRequired'),
});
