import * as fcl from '@blocto/fcl';
import * as t from '@onflow/types';
import { invariant } from '@onflow/util-invariant';
import { onError } from 'helpers/onError';
import { tx } from './util/tx';

const CODE = fcl.cdc`
import NonFungibleToken from 0xNON_FUNGIBLE_TOKEN_ADDRESS
import StarlyCard from 0xSTARLY_CARD_ADDRESS

transaction(nftItemIDs: [UInt64]) {
  prepare(acct: AuthAccount) {
    let collection <- acct.load<@StarlyCard.Collection>(from: StarlyCard.CollectionStoragePath)!
    for nftItemID in nftItemIDs {
      let nft <- collection.withdraw(withdrawID: nftItemID)
      destroy nft
    }
    acct.save(<-collection, to: StarlyCard.CollectionStoragePath)
  }
}`;

export async function flowBurnStarlyCardsTransaction(
  itemIDs: number[],
  opts = { onError },
) {
  invariant(itemIDs != null, 'itemIDs must be supplied');
  invariant(itemIDs.length > 0, 'itemIDs cannot be empty');

  // Get latest block info
  const block = await fcl.send([fcl.getBlock(false) as Function]).then(fcl.decode);

  return tx(
    [
      fcl.transaction(CODE),
      fcl.args([
        fcl.arg(itemIDs.map((id) => BigInt(id).toString()), t.Array(t.UInt64)),
      ]),
      fcl.payer(fcl.authz),
      fcl.proposer(fcl.authz),
      fcl.authorizations([fcl.authz]),
      fcl.limit(1000),
      fcl.ref(block.id),
    ],
    opts,
  );
}
