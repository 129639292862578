import { type FC } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';
import Media from 'components/Media';

interface HeaderAvatarProps {
  avatar: any,
  pathLink: string,
  'data-test-id'?: string,
}

const LinkWrapper = styled(Link)`
  height: 39px;
  width: 39px;
`;

const Avatar = styled(Media)`
  border-radius: 100%;
  height: 100%;
  width: 100%;
`;

const HeaderAvatar: FC<HeaderAvatarProps> = ({ avatar, pathLink, 'data-test-id': dataTestId }) => (
  <LinkWrapper to={pathLink} data-test-id={dataTestId}>
    <Avatar
      data-test-id="avatar_picture"
      media={avatar}
    />
  </LinkWrapper>
);

export default HeaderAvatar;
