import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  ClaimRewardButton,
  ClaimRewardLabelContainer,
  ClaimRewardLabelText, ClaimRewardLabelWrapper, NotificationContainer,
} from './styled';
import { selectAuthUserId } from '../../store/auth/authSelectors';
import {
  claimRewardRequest,
  resetRewardClaimState,
  rewardToClaimRequest,
  setClaimingStatus, setClaimRewardClaiming,
} from '../../store/ranking/rankingActions';
import { selectRewardToClaim } from '../../store/ranking/rankingSelectors';
import NotificationsLoader from '../NotificationsLoader/NotificationsLoader';
import { numberWithCommas } from '../../helpers/TextHelpers';
import PopUp from '../PopUp';
import Button from '../Button';
import { selectFlowWalletAddr } from '../../store/flow/flowSelectors';
import { flowToggleModal } from '../../store/flow/flowActions';
import { flowIsAccountInitializedScript } from '../../flow/isAccountInitialized.script';
import { type FlowTypes } from '../../types';
import { flowInitializeAccountTransaction } from '../../flow/initializeAccount.tx';

const ClaimRewardLabel = () => {
  const dispatch = useDispatch();
  const userId = useSelector(selectAuthUserId);
  const walletAddr = useSelector(selectFlowWalletAddr);
  const { reward, isClaiming, status } = useSelector(selectRewardToClaim);

  useEffect(() => {
    if (userId) {
      dispatch(rewardToClaimRequest({ userId }));
    } else {
      dispatch(resetRewardClaimState());
    }
  }, [userId]);

  if (!userId || !reward) {
    return null;
  }

  const onErrorClick = () => {
    dispatch(setClaimingStatus({ status: 'initial' }));
  };

  const onSuccessClick = () => {
    dispatch(resetRewardClaimState());
  };
  return (
    <>
      <ClaimRewardLabelWrapper>
        <ClaimRewardLabelContainer>
          <ClaimRewardLabelText>
            You have received top collector’s reward:
            {' '}
            {numberWithCommas(reward, 2)}
            {' '}
            $STARLY
          </ClaimRewardLabelText>
          <ClaimRewardButton
            size="small"
            background="white"
            textColor="black"
            noneHover={isClaiming}
            onClick={async () => {
              if (!isClaiming) {
                if (!walletAddr) {
                  dispatch(flowToggleModal(true));
                  return;
                }
                dispatch(setClaimRewardClaiming({ isClaiming: true }));
                const init: FlowTypes.Init = await flowIsAccountInitializedScript(walletAddr);
                if (!init.StarlyToken) {
                  await flowInitializeAccountTransaction(walletAddr);
                  const recheck: FlowTypes.Init = await flowIsAccountInitializedScript(walletAddr);
                  if (!recheck.StarlyToken) {
                    dispatch(setClaimRewardClaiming({ isClaiming: false }));
                    return;
                  }
                }

                dispatch(claimRewardRequest());
              }
            }}
          >
            {
              isClaiming ? (
                <NotificationContainer>
                  <NotificationsLoader transparent small isBlack />
                </NotificationContainer>
              ) : 'Claim reward'
            }
          </ClaimRewardButton>
        </ClaimRewardLabelContainer>
      </ClaimRewardLabelWrapper>
      {
        status !== 'initial' && (
          <PopUp
            onHide={status === 'error' ? onErrorClick : onSuccessClick}
            text={status === 'error'
              ? 'Wooops, something went wrong. Please try again later'
              : 'Your reward was successfully claimed'}
          >
            <Button
              onClick={status === 'error' ? onErrorClick : onSuccessClick}
              background={status === 'error' ? 'black' : 'primary'}
              fluid
            >
              Ok
            </Button>
          </PopUp>
        )
      }
    </>
  );
};

export default ClaimRewardLabel;
