import type { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import {
  selectFlowFestModal,
  selectFlowModal,
  selectFlowNFTNYCModal,
  selectLowBalanceModal,
  selectWrongModal,
} from 'store/flow/flowSelectors';
import InsufficientBalanceModal from 'views/Modals/InsufficientBalanceModal';
import PackBattleModal from 'views/Modals/PackBattleModal';
import WalletModal from 'views/Modals/WalletModal';
import FlowFestWalletModal from 'views/Modals/WalletModal/FlowFestWalletModal';
import FlowNFTNYCWalletModal from 'views/Modals/WalletModal/FlowNFTNYCWalletModal';
import WrongModal from 'views/Modals/WalletWrong';

import { RouteTypes } from 'RouteTypes';
import { selectAuthUserId } from 'store/auth/authSelectors';
import { setCreateModal, setUnavailableModal } from 'store/collection/collectionActions';
import { selectIsCreateModalOpen, selectIsUnavailableModalOpen } from 'store/collection/collectionSelectors';
import { selectPackBattleWinner } from 'store/user/userSelectors';
import CreateUnavalible from '../../CreateUnavalible';
import CreateModal from './CreateModal';

interface GlobalModalsProps {
  userPath: string
}

const GlobalModals: FC<GlobalModalsProps> = ({ userPath }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const userId = useSelector(selectAuthUserId);

  const isCreateModalOpen = useSelector(selectIsCreateModalOpen);
  const isUnavailableModalOpen = useSelector(selectIsUnavailableModalOpen);
  const isWrongModalOpen = useSelector(selectWrongModal);
  const isWalletModalOpen = useSelector(selectFlowModal);
  const isFlowFestWalletModalOpen = useSelector(selectFlowFestModal);
  const isFlowNFTNYCWalletModalOpen = useSelector(selectFlowNFTNYCModal);
  const isLowBalanceModalOpen = useSelector(selectLowBalanceModal).isOpen;
  const isPackBattleModalOpen = useSelector(selectPackBattleWinner(userId));

  return (
    <>
      {isCreateModalOpen && (
        <CreateModal
          onClose={() => dispatch(setCreateModal({ isOpen: false }))}
          onCollectionOpen={() => {
            dispatch(setCreateModal({ isOpen: false }));
            history.push(userPath);
          }}
        />
      )}
      {isUnavailableModalOpen && (
        <CreateUnavalible
          onHide={() => {
            dispatch(setUnavailableModal({ isOpen: false }));
          }}
          onContact={() => {
            history.push(RouteTypes.WriteUs);
            dispatch(setUnavailableModal({ isOpen: false }));
          }}
        />
      )}
      {isWrongModalOpen && <WrongModal />}
      {isWalletModalOpen && <WalletModal />}
      {isFlowFestWalletModalOpen && <FlowFestWalletModal />}
      {isFlowNFTNYCWalletModalOpen && <FlowNFTNYCWalletModal />}
      {isLowBalanceModalOpen && <InsufficientBalanceModal />}
      {isPackBattleModalOpen && <PackBattleModal />}
    </>
  );
};

export default GlobalModals;
