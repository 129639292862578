import * as fcl from '@blocto/fcl';
import * as t from '@onflow/types';
import { onError } from 'helpers/onError';
import { tx } from '../util/tx';
// import additionalAuthorization from '../services/additionalAuthorization';

const CODE = fcl.cdc`
  import FungibleToken from 0xFUNGIBLE_TOKEN_ADDRESS
  import NonFungibleToken from 0xNON_FUNGIBLE_TOKEN_ADDRESS
  import StarlyCard from 0xSTARLY_CARD_ADDRESS
  import StarlyCardBidV3 from 0xSTARLY_CARD_BID_ADDRESS

  transaction(bidID: UInt64, bidderAddress: Address) {
      
    prepare(signer: AuthAccount) {        
    }

    execute {
      let bidderAccount = getAccount(bidderAddress)
      let bidCollection = bidderAccount
          .getCapability<&StarlyCardBidV3.Collection{StarlyCardBidV3.CollectionPublic}>(
              StarlyCardBidV3.CollectionPublicPath
          )!
          .borrow()
          ?? panic("Could not borrow bid collection")

      bidCollection.decline(bidID: bidID)
    }
  }
`;

export async function flowDeclineBidTransaction(
  bidID: number,
  bidderAddress: string,
  opts = { onError },
) {
  // Get latest block info
  const block = await fcl.send([fcl.getBlock(false) as Function]).then(fcl.decode);

  return tx(
    [
      fcl.transaction(CODE),
      fcl.args([
        fcl.arg(BigInt(bidID).toString(), t.UInt64),
        fcl.arg(bidderAddress, t.Address),
      ]),
      fcl.payer(fcl.authz),
      fcl.proposer(fcl.authz),
      fcl.authorizations([fcl.authz]),
      fcl.limit(9999),
      fcl.ref(block.id),
    ],
    opts,
  );
}
