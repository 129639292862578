import * as fcl from '@blocto/fcl';

const noop = async () => { };

function fvsTx(env, txId) {
  return `https://flow-view-source.com/${env}/tx/${txId}`;
}

interface Opts {
  onStart?: () => Promise<any>,
  onSubmission?: (...args: any) => Promise<any>,
  onUpdate?: () => Promise<any>,
  onSuccess?: (...args: any) => Promise<any>,
  onError?: (...args: any) => any,
  onComplete?: () => Promise<any>,
}
export async function tx(mods: any[] = [], opts: Opts = {}) {
  const onStart = opts.onStart || noop;
  const onSubmission = opts.onSubmission || noop;
  const onUpdate = opts.onUpdate || noop;
  const onSuccess = opts.onSuccess || noop;
  const onError = opts.onError || noop;
  const onComplete = opts.onComplete || noop;

  let txId;
  try {
    onStart();
    txId = await fcl.send(mods).then(fcl.decode);
    // eslint-disable-next-line no-console
    console.info(
      `%cTX[${txId}]: ${fvsTx(await fcl.config().get('env'), txId)}`,
      'color:purple;font-weight:bold;font-family:monospace;',
    );
    onSubmission(txId);
    const unsub = await fcl.tx(txId).subscribe(onUpdate);
    const txStatus = await fcl.tx(txId).onceSealed();
    unsub();
    // eslint-disable-next-line no-console
    console.info(
      `%cTX[${txId}]: ${fvsTx(await fcl.config().get('env'), txId)}`,
      'color:green;font-weight:bold;font-family:monospace;',
    );
    await onSuccess(txStatus);
    return txStatus;
  } catch (error) {
    console.error(`TX[${txId}]: ${fvsTx(await fcl.config().get('env'), txId)}`, error);
    const onErrorReturn = onError(error);
    if ((onErrorReturn.errorMessage as string).startsWith('Declined: Session expired')) {
      try {
        const wallet = await fcl.logIn();
        if (wallet.addr) {
          return tx(mods, opts);
        }
      } catch (e) {
        window.location.reload();
      }
    }
    return onErrorReturn;
  } finally {
    await onComplete();
  }
}
