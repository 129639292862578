import type { FC } from 'react';
import { useEffect, useState } from 'react';
import Select, { createFilter } from 'react-select';
import { useTranslation } from 'react-i18next';
import { ReactSelectContainer } from 'components/SelectContainer/ReactSelectContainer';
import Typography from 'components/Typography';

import { desktopBreakpoint } from '../../breakpoints';
import { useWindowSize } from '../../hooks/useWindowSize';
import Dropdown from '../Dropdown';
import Filter from '../Filter';
import { StyledFilterSection, StyledSortBySection, StyledWrapper } from './styled';
import type { IFilters } from './types';

const Filters: FC<IFilters> = ({
  isLoading,
  isDisabled,
  options,
  sortTypes,
  onChange,
  onSortChange,
  filters,
  onFiltersApply,
  isFilterActive,
  showOption,
  sortValue,
  'data-test-id': dataTestId,
}) => {
  const { t } = useTranslation();
  const [isFilterOpen, setIsFilerOpen] = useState(false);
  const [isShowSelectFocused, setIsShowSelectFocused] = useState(false);
  const [currentOptions, setCurrentOptions] = useState(options);
  useEffect(() => {
    setCurrentOptions(options);
  }, [options]);

  const isMobile = useWindowSize() < desktopBreakpoint;
  return (
    <StyledWrapper withFilter={!!filters} data-test-id={dataTestId}>
      {filters && (
        <Dropdown
          data-test-id="marketplace_dd"
          menu={(
            <Filter
              data-test-id="RRT"
              onClose={() => setIsFilerOpen(false)}
              filters={filters}
              onApply={onFiltersApply}
              closeBtn={isMobile}
            />
          )}
          fluid={isMobile}
          overlay={isMobile}
          onOutsideClick={() => setIsFilerOpen(false)}
          isOpen={isFilterOpen}
          translate="170px, 5px"
          mobileTranslate="0, -15px"
        >
          <StyledFilterSection isWhite={isFilterOpen || isFilterActive} onClick={() => setIsFilerOpen(!isFilterOpen)}>
            <Select
              inputId="dropdown_filter_input"
              classNamePrefix="react-select"
              defaultValue={{ value: '', label: 'filter' }}
              isSearchable={false}
              isDisabled
              id="marketplace_filter_select"
            />
          </StyledFilterSection>
        </Dropdown>
      )}
      <ReactSelectContainer data-test-id="dropdown_show_wrapper" isFocused={isShowSelectFocused} withLabel>
        <Typography data-test-id="dropdown_show_label" textColor="white" size="unmedia14" uppercase>
          {t('common.filters.show')}
        </Typography>
        <Select
          id="dropdown_show"
          inputId="dropdown_show_input"
          classNamePrefix="react-select"
          value={showOption}
          defaultValue={options[0]}
          options={currentOptions}
          isLoading={isLoading}
          isDisabled={isDisabled}
          onChange={onChange}
          isSearchable
          blurInputOnSelect
          onInputChange={(str) => {
            const checkStr = str.toLowerCase();
            const newOptions = [...options].sort((a, b) => {
              const bIndex = b.label.toLowerCase().indexOf(checkStr);
              const aIndex = a.label.toLowerCase().indexOf(checkStr);
              if (aIndex === -1) return 1;
              if (bIndex === -1) return -1;
              if (aIndex < bIndex) return -1;
              if (bIndex < aIndex) return 1;
              return 0;
            });
            setCurrentOptions(newOptions);
          }}
          onBlur={() => setIsShowSelectFocused(false)}
          onFocus={() => setIsShowSelectFocused(true)}
          onMenuClose={() => document.getElementById('dropdown_show_input')?.blur()}
          filterOption={createFilter({
            matchFrom: 'any',
            ignoreCase: true,
            trim: true,
            stringify: ({ label }) => label,
          })}
        />
      </ReactSelectContainer>
      <StyledSortBySection withLabel>
        <Typography data-test-id="dropdown_sort_by_label" textColor="white" size="unmedia16" uppercase>
          {t('common.filters.sort.title')}
        </Typography>
        <Select
          id="dropdown_sort"
          classNamePrefix="react-select"
          options={sortTypes}
          isSearchable={false}
          isDisabled={isDisabled}
          onChange={onSortChange}
          value={sortValue}
        />
      </StyledSortBySection>
    </StyledWrapper>
  );
};

export default Filters;
