import type { CardRarity } from '@starly/starly-types';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components/macro';
import Media from 'components/Media';
import theme from 'components/theme';
import Typography from 'components/Typography';
import { mediaDesktop } from '../../breakpoints';
import { frames } from './constants';

export const UploadWrapper = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${theme.colors.dark};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: inherit;
  color: ${theme.colors.inputPlaceholder};
  font-family: ${theme.fonts.spaceMono};
  font-weight: bold;
  text-transform: uppercase;
  position: absolute;
  z-index: 3;
  top: 0;
  left: 0;
`;

export const UploadTitle = styled.p`
  font-size: 13px;
  letter-spacing: normal;
`;

export const UploadValue = styled.p`
  font-size: 36px;
  line-height: normal;
  letter-spacing: 1px;
`;

export const StyledCardBackground = styled.div<{ rarity: CardRarity }>`
  background-image: url(${({ rarity }) => frames[rarity]});
  background-size: contain;
  background-repeat: no-repeat;
  aspect-ratio: 0.6177;
  position: relative;
  border-radius: inherit;
  padding: 1px;
  width: 100%;
  height: 100%;
`;

export const StyledCardContainer = styled.div`
  font-size: 16px;  // root for rem
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  color: ${theme.colors.textWhite};
  border-radius: inherit;
  letter-spacing: 1px;
  line-height: 1;
`;

const sideMargin = 9.5;
const sidePadding = 3;

export const StyledAssetContainerWrapper = styled.div<{ $isClickable?: boolean }>`
  position: relative;
  display: flex;
  ${({ $isClickable }) => $isClickable && 'cursor: pointer;'};
  overflow: hidden;
  width: 81%;
  margin: 10.5% ${sideMargin}% 0;
  padding-bottom: 110%;
`;

export const StyledImageContainer = styled(Media)`
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  border-top-left-radius: 5.6% 3.9%;
  border-top-right-radius: 5.6% 3.9%;
  position: absolute;

  > * {
    position: absolute;
    width: 100%;
    height: 100%;
  }
`;

export const FullScreenMask = styled.div`
    position: fixed;
    height: 100vh;
    width: 100vw;
    background-color: rgba(0,0,0,0.9);
    top: 0;
    left: 0;
    z-index: 90;
`;

interface StyledVideoMediaLoadContainerProps {
  $fullscreen?: boolean;
  isCreate?: boolean;
  controls?: boolean;
  $videoWidth?: number;
}

export const StyledVideoMediaLoadContainer = styled(Media)<StyledVideoMediaLoadContainerProps>`
  width: 100%;
  background-size: cover;
  background-position: center;
  ${({ $fullscreen, isCreate }) => !$fullscreen && isCreate && { marginTop: '-18px' }}
  border-top-left-radius: 5.6% 3.9%;
  border-top-right-radius: 5.6% 3.9%;
  object-fit: cover;
  height: 100%;
  position: absolute;
  margin: 0;
  ${({ $fullscreen, $videoWidth }) => ($fullscreen && `
      position: fixed;
      width: ${$videoWidth ? `${$videoWidth}px` : '100%'};
      height: 100vh;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      z-index: 100;
  `)}
  ${({ controls }) => (!controls && `
    &::-webkit-media-controls {
      display: none;
    }
    &::-webkit-media-controls-panel {
      display: none!important;
      -webkit-appearance: none;
    }
  `)}

  ${mediaDesktop} {
    ${({ $fullscreen }) => ($fullscreen && `
      width: auto;
  `)}
  }
`;

interface StyledCardFixedWrapperProps {
  isCreateCard?: boolean,
}

export const StyledCardFixedWrapper = styled(Link) <StyledCardFixedWrapperProps>`
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: ${theme.colors.black};
  z-index: 0;
  border-radius: 5.6% / 3.4%;
  text-decoration: none;

  ${({ isCreateCard }) => (isCreateCard && `
    width: 100%;

    ${mediaDesktop} {
      width: 35%;
    }
  `)}

  position: relative;
`;

export const StyledVideoWrapper = styled.div`
  width: 100%;
  padding-bottom: 137%;
`;

export const StyledVideoWrapperInner = styled.div`
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  position: absolute;
  overflow: hidden;
`;

export const MutedIcon = styled.div<{ $fullscreen?: boolean, $headless?: boolean }>`
  position: absolute;
  width: 8%;
  aspect-ratio: 1;
  display: ${({ $headless }) => ($headless ? 'none' : 'flex')};
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 20;
  right: min(2%, 5px);
  bottom: 0;
  margin-bottom: min(2%, 5px);
  & svg {
    width: 16px;
    height: auto;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 50%;
  }
  ${({ $fullscreen }) => ($fullscreen && `
    position: fixed;
    bottom: 10px;
    right: 10px;
  `)}

  :hover {
    & svg {
      background-color: ${theme.colors.lightGray};
    }
  }
`;

export const StyledCardInfoContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 0 ${sideMargin}%;
  padding: 2% 3%;
  border-radius: 0 0 6px 6px;
  background-color: #0f0f0f;
  z-index: -1;

  ${mediaDesktop} {
    border-radius: 0 0 10px 10px;
  }

  & > p {
    padding-bottom: 10px;
  }
`;

export const StyledHeader = styled.div`
  display: flex;
  align-items: center;

  max-width: calc(100% - ${2 * sidePadding + 1}%);
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

export const StyledContent = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 3%;
`;

export const StyledContentText = styled(Typography)`
  font-family: ${theme.fonts.syncopate};
  color: ${theme.colors.white};
  max-width: 76%;
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  overflow-wrap: anywhere;
  line-height: 1.25;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;

  ${mediaDesktop} {
    line-height: 1.2;
  }
`;

export const StyledQRCodeContainer = styled.div`
  width: 21%;
  height: auto;
  aspect-ratio: 1;
`;

export const StyledQRCode = styled.img`
  width: 100%;
  height: auto;
  display: block;
`;

export const StyledTypeContainer = styled.div<{ edition?: string, $isAvailable?: boolean }>`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  ${({ edition }) => (edition ? 'margin-top: 3%;' : 'margin-top: 5%; margin-bottom: 1%;')}
  line-height: 1.3;
  letter-spacing: 1px;
  transform: translateY(-35%);
  opacity: ${({ $isAvailable }) => ($isAvailable ? '1;' : '0;')};
`;

export const Processing = styled.p`
  font-family: ${theme.fonts.spaceMono};
  letter-spacing: 1px;
  font-size: 12px;
  text-transform: uppercase;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  text-shadow: 0 0 8px black;
  padding: 5px 8px;
  font-weight: bold;
`;

export const AnimatedDots = styled.span`
  position: relative;
  &::after {
    overflow: hidden;
    position: absolute;
    display: inline-block;
    vertical-align: bottom;
    animation: ellipsis steps(4,end) 2000ms infinite;
    content: "...";
    width: 0;
  }

  @keyframes ellipsis {
    to {
      width: 36px;
    }
  }
`;

export const ChangeBtn = styled.label<{ htmlFor: string }>`
  background-color: ${theme.colors.lightTransparentDark};
  border-radius: 4px;
  font-family: ${theme.fonts.primary};
  font-size: 10px;
  font-weight: 600;
  letter-spacing: 1px;
  text-transform: uppercase;
  padding: 6px;
  position: relative;
  left: 5px;
  z-index: 3;
  top: 8px;
  cursor: pointer;

  :hover {
    color: white;
  }
`;

const uploadAssetStyling = css`
  cursor: pointer;
  width: 100%;
  height: 100%;
  margin-top: -16px;
  border-top-left-radius: 5.6% 3.9%;
  border-top-right-radius: 5.6% 3.9%;
`;

export const StyledVideoLoadContainer = styled.video`
  ${uploadAssetStyling}
  object-fit: cover;
`;

export const StyledActualImage = styled.div<{ url: string }>`
  ${uploadAssetStyling}

  padding-bottom: 137%;
  background-color: ${theme.colors.black};
  background-image: url('${({ url }) => url}');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
`;

export const StyledImageLoadContainer = styled.div<{ currMedia: any, isUpload: boolean }>`
  box-shadow: inset 0 0 0 2px ${theme.colors.transparent};
  width: calc(100% - ${sideMargin * 2}%);
  margin: 10.5% ${sideMargin}% 0;

  padding-bottom: ${({ isUpload, currMedia }) => (!(isUpload && currMedia) ? `calc(129% - ${sideMargin * 2}%)}` : 0)};
  position: relative;
`;

export const StyledImage = styled.input`
  display: none;
`;

export const StyledImageText = styled.p`
  text-transform: uppercase;
  padding-top: 15px;
  font-family: ${theme.fonts.spaceMono};
  font-size: 13px;
  letter-spacing: normal;
  text-align: center;
  font-weight: 600;
  color: ${theme.colors.red};
`;

export const StyledEmptyImage = styled.label<{ error: boolean, htmlFor: string }>`
  position: absolute;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  border-radius: inherit;
  background-color: ${theme.colors.dark};
  ${({ error }) => (!error && `
    ${StyledImageText} {
      color: ${theme.colors.inputPlaceholder};
    }
  `)};
`;

export const FullscreenWrapper = styled.div`
  position: fixed;
  z-index: 300;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: rgba(0, 0, 0, .9);
  display: grid;
  place-content: center;
  & * {
    touch-action: inherit;
  }
`;

export const FullscreenMedia = styled(Media)`
  width: inherit;
  height: inherit;
`;

export const FullscreenMediaContainer = styled.div<{ $width: number, $height: number }>`
  position: relative;
  background: ${theme.colors.dark};
  ${({ $width, $height }) => (`
      width: ${$width}px;
      height: ${$height}px;
  `)}
`;
export const FullScreenVideoContainer = styled.div<{ $width?: number }>`
  position: fixed;
  height: 100vh;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 101;
  ${({ $width }) => (`
      width: ${$width}px;
  `)}
`;

export const MediaLoader = styled.div<{ $isLoaded: boolean }>`
  ${({ $isLoaded }) => $isLoaded && { display: 'none' } || { display: 'flex' }};
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: absolute;
`;
