import {
  type Dispatch, type FC, type MouseEventHandler, type RefObject, type SetStateAction, useEffect, useMemo,
} from 'react';
import ReactDOM from 'react-dom';
import type { Media } from '@starly/starly-types';

import type { ChildRefFunc } from 'components/Media';
import { ReactComponent as SoundOn } from 'static/ic-sound-off.svg';
import { ReactComponent as SoundOff } from 'static/ic-sound-on.svg';

import {
  FullscreenMedia, FullscreenMediaContainer, FullscreenWrapper, MutedIcon,
} from './styled';

const CardPortal: FC = ({ children }) => {
  const portalElement = document.querySelector('#fullscreen-root') as Element;

  return ReactDOM.createPortal(children, portalElement);
};

const CardFullScreen: FC<{
  media?: Media;
  onClose: () => void;
  isMuted: boolean;
  switchMuted: Dispatch<SetStateAction<boolean>>;
  videoRef: RefObject<ChildRefFunc>;
  fullscreenVideoRef: RefObject<ChildRefFunc>;
}> = ({
  media, onClose, isMuted, switchMuted, videoRef, fullscreenVideoRef,
}) => {
  useEffect(() => {
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = 'initial';
    };
  }, []);

  const fullScreenMuted: MouseEventHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();

    switchMuted(isMuted);
  };

  useEffect(() => {
    videoRef.current?.pauseVideo?.();
    return () => videoRef.current?.playVideo?.();
  }, []);

  const mediaDimensions = useMemo(() => {
    let mediaHeight;
    let mediaWidth;
    const maxHeight = window.innerHeight - 40;
    const maxWidth = window.innerWidth - 40;
    const windowRatio = maxWidth / maxHeight;
    const largestSize = media ? media.sizes[media.sizes.length - 1] : { width: 0, height: 0 };

    const mediaRatio = largestSize.width / largestSize.height;

    if (windowRatio > mediaRatio) {
      mediaHeight = maxHeight;
      mediaWidth = mediaHeight * mediaRatio;
    } else {
      mediaWidth = maxWidth;
      mediaHeight = mediaWidth / mediaRatio;
    }
    return { height: mediaHeight, width: mediaWidth };
  }, []);

  return (
    <CardPortal>
      <FullscreenWrapper onClick={onClose}>
        <FullscreenMediaContainer
          data-test-id="full_screen_media"
          $width={mediaDimensions.width}
          $height={mediaDimensions.height}
        >
          <FullscreenMedia media={media} lazy isMuted={isMuted} ref={fullscreenVideoRef} />
          {media !== undefined && media.type !== 'image' && (
            <MutedIcon
              data-test-id="mute_icon"
              onClick={fullScreenMuted}
              style={{
                width: 30,
                height: 30,
              }}
            >
              {isMuted ? <SoundOn /> : <SoundOff />}
            </MutedIcon>
          )}
        </FullscreenMediaContainer>
      </FullscreenWrapper>
    </CardPortal>
  );
};

export default CardFullScreen;
