import { type ChangeEvent, type FC } from 'react';
import { useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import shortid from 'shortid';
import {
  InputErrorMessage, InputInput, InputLabel, InputWrapper,
} from './styled';
import { type InputProps } from './types';

const Input: FC<InputProps> = ({
  id = shortid.generate(),
  danger = false,
  type,
  inputMode,
  label,
  placeholder,
  errors,
  value,
  disabled = false,
  gradient = false,
  block = false,
  autocomplete,
  onChange,
  onBlur,
  register,
  name = '',
  maxlength,
  className,
  control,
  isPaginator,
  isFilter,
  defaultValue,
  'data-test-id': dataTestId,
}) => {
  const { t } = useTranslation();
  let params: any = {};

  if (control && onChange) {
    const {
      field: { onChange: controllerOnChange },
    } = useController({
      name,
      control,
    });
    params = {
      ...(!!register && { ...register(name) }),
      onChange: (e: ChangeEvent<HTMLInputElement>) => {
        const currValue = onChange(e);
        if (currValue !== null && currValue !== undefined) {
          const newEvent = { ...e };
          newEvent.target.value = currValue;
          controllerOnChange(newEvent);
        }
      },
    };
  } else {
    params = {
      ...(!!register && { ...register(name) }),
    };
  }

  return (
    <InputWrapper
      $block={block}
      data-testid="inputWrapper"
      className={className}
      isPaginator={isPaginator}
      isFilter={isFilter}
    >
      {label && <InputLabel htmlFor={id} data-testid={`${id}_label`}>{label}</InputLabel>}
      <InputInput
        value={value}
        onChange={onChange}
        {...params}
        id={id}
        type={type || 'text'}
        inputMode={inputMode}
        danger={danger || !!errors?.[name]}
        gradient={gradient}
        placeholder={placeholder}
        disabled={disabled}
        autoComplete={autocomplete}
        $block={block}
        name={name}
        defaultValue={defaultValue}
        onBlur={onBlur}
        data-testid={`input_${id}`}
        maxLength={maxlength}
        data-test-id={`input_${dataTestId}`}
      />
      {errors?.[name] && (
        <InputErrorMessage data-test-id={`error_${dataTestId}`}>{t(errors[name].message)}</InputErrorMessage>
      )}
    </InputWrapper>
  );
};

export default Input;
