import styled from 'styled-components';
import { mediaDesktop } from '../../../../breakpoints';
import Typography from '../../../../components/Typography';
import Button from '../../../../components/Button';

export const MarketplaceCardContainer = styled.div`
  display: grid;
  grid-template-columns: 174px 126px;
  grid-gap: 20px;
  ${mediaDesktop} {
    display: flex;
    grid-gap: 0;
    flex-direction: column;
    width: 312px;
  }
`;

export const PriceContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  & > ${Typography}:first-of-type {
    font-size: 11px;
    ${mediaDesktop} {
      font-size: 16px;
    }
  }
`;

export const MarketplaceControlContainer = styled.div`
  color: #fff;
  display: grid;
  align-content: start;
  grid-gap: 12px;
  padding-top: 20px;
 
  ${mediaDesktop} {
    padding: 32px 24px 0;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    & ${Typography} {
      line-height: 1;
      
      :first-of-type {
        padding-bottom: 5px;
        font-size: 16px;
      }
    }
  }
`;

export const MarketplaceCardTitle = styled(Typography)`
  max-height: 80px;
  max-width: 100%;
  white-space: normal;
  overflow-wrap: anywhere;
  display: -webkit-box;
  text-overflow: ellipsis;
  line-height: 20px;
  overflow: hidden;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  ${mediaDesktop} {
    display: none
  }
`;

export const MarketplaceCardRarity = styled.div`
  ${mediaDesktop} {
    display: none;
  }
`;

export const MarketplaceCardButton = styled(Button)`
  width: 66px;
  height: 28px;
  font-size: 12px;
  display: grid;
  place-content: center;
  ${mediaDesktop} {
    height: 40px;
    width: 70px;
    font-size: 16px;
  }
`;

export const MarketplaceCardIcon = styled.img`
  width: 16px;
  height: 16px;
  ${mediaDesktop} {
    width: 23px;
    height: 23px;
  }
`;

export const MarketplaceCardProgressButton = styled(Button)`
  display: flex;
  width: 66px;
  height: 28px;
  align-items: center;
  justify-content: center;
  padding-top: 8px;

  & > * {
    transform: scale(.6);
  }
  ${mediaDesktop} {
    height: 40px;
    width: 70px;
    padding-top: 7px;

    & > * {
      transform: scale(.7);
    }
  }
`;
