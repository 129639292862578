import { createSelector } from '@reduxjs/toolkit';
import { type RootState } from '../store';

const selectLogin = ({ login }: RootState) => login;

export const selectIsLoginModalOpen = createSelector(selectLogin, ({ isOpen }) => isOpen);

export const selectAuthLogout = createSelector(selectLogin, ({ isLogout }) => isLogout);

export const selectLoginCreateProfileRedirect = createSelector(selectLogin, ({ createProfileRedirect }) => createProfileRedirect);
