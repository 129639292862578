import { mediaDesktop } from 'breakpoints';
import Input from 'components/Input';
import styled from 'styled-components/macro';

export const SecondInput = styled(Input)`
  margin-top: 10px;
`;

export const ButtonsContainer = styled.div`
  display: grid;
  grid-gap: 16px;
  width: 100%;
  margin-top: 10px;
  ${mediaDesktop} {
    grid-gap: 20px; 
  };
`;
