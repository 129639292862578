import { createReducer } from '@reduxjs/toolkit';
import type {
  BasicCollectionInformation,
  CollectedCollection,
  InstagramConnection,
  TwitterConnection,
  User,
  YouTubeConnection,
} from '@starly/starly-types';

import { sortCollectedCollections, sortCreatedCollections } from 'util/collections';

import {
  disconnectUserSocialFailure,
  disconnectUserSocialRequest,
  disconnectUserSocialResponse,
  updateCardsStakedStatus,
  updateUsernameFailure,
  updateUsernameRequest,
  updateUsernameResponse,
  updateWalletResponse,
  userResponse,
  userToggleLoading,
  userUpdateRequest,
  userUpdateResponse,
  writeAvatarImageRequest,
  writeAvatarImageResponse,
} from './userActions';

export interface IExtendedUser extends User {
  collectedCollections: CollectedCollection[];
  createdCollections: BasicCollectionInformation[];
  connections?: {
    instagram?: InstagramConnection & { isLoading?: boolean };
    twitter?: TwitterConnection & { isLoading?: boolean };
    youtube?: YouTubeConnection & { isLoading?: boolean };
  }
}

interface UserSlice {
  items: { [key: string]: IExtendedUser },
  isLoading: boolean,
}

const initialState: UserSlice = {
  items: {},
  isLoading: false,
};

const userReducer = createReducer(
  initialState,
  (builder) => builder
    .addCase(userResponse, (state, { payload: { user } }) => {
      if (user.id) {
        if (user.connections) {
          Object.keys(user.connections).forEach((type) => {
            // eslint-disable-next-line no-param-reassign
            user.connections![type].isLoading = false;
          });
        }

        state.items[user.id] = {
          ...state.items[user.id],
          id: user.id,
          avatar: user.avatar,
          username: user.username,
          name: user.name,
          flow_account: user.flow_account,
          bio: user.bio,
          url: user.url,
          email: user.email,
          collected_collections: user.collected_collections,
          created_collections: user.created_collections,
          collectedCollections: sortCollectedCollections(user.collected_collections),
          createdCollections: sortCreatedCollections(user.created_collections),
          connections: user.connections,
          notifications: user.notifications,
          face_control_state: user.face_control_state,
          allow_create_collection: user.allow_create_collection,
          pack_battle_winner: user.pack_battle_winner,
        };
      }
    })
    .addCase(userUpdateRequest, (state) => {
      state.isLoading = true;
    })
    .addCase(userUpdateResponse, (state, {
      payload: { user },
    }) => {
      state.isLoading = false;
      state.items[user.id] = {
        ...state.items[user.id],
        ...user,
      };
    })
    .addCase(updateCardsStakedStatus, (state, { payload: { userId, cards } }) => {
      if (userId) {
        cards.forEach((card) => {
          if (state.items[userId].collected_collections) {
            state.items[userId].collected_collections![card.collection_id]
              .collected_cards[card.id].staked = card.staked;
            state.items[userId].collected_collections![card.collection_id]
              .collected_cards[card.id].stake_id = card.stake_id;
          }
        });

        state.items[userId].collectedCollections = sortCollectedCollections(
          state.items[userId].collected_collections,
        );
      }
    })
    .addCase(updateUsernameRequest, (state, { payload: { id, username } }) => {
      state.isLoading = true;
      state.items[id].username = username;
    })
    .addCase(updateUsernameResponse, (state, { payload: { id, username } }) => {
      state.isLoading = false;
      state.items[id].username = username;
    })
    .addCase(updateUsernameFailure, (state, { payload: { id } }) => {
      state.isLoading = false;
      state.items[id].username = '';
    })
    .addCase(updateWalletResponse, (state, { payload: { id, address } }) => {
      state.items[id].flow_account = address;
    })
    .addCase(writeAvatarImageResponse, (state, { payload: { id, avatar } }) => {
      state.isLoading = false;
      state.items[id].avatar = avatar;
    })
    .addCase(writeAvatarImageRequest, (state) => {
      state.isLoading = true;
    })
    .addCase(disconnectUserSocialRequest, (state, { payload: { social, id } }) => {
      if (state.items[id].connections?.[social]) {
        state.items[id].connections![social]!.isLoading = true;
      }
    })
    .addCase(disconnectUserSocialResponse, (state, { payload: { social, id } }) => {
      delete state.items[id].connections?.[social];
    })
    .addCase(disconnectUserSocialFailure, (state, { payload: { social, id } }) => {
      if (state.items[id].connections?.[social]) {
        state.items[id].connections![social]!.isLoading = false;
      }
    })
    .addCase(userToggleLoading, (state, { payload }) => {
      state.isLoading = payload;
    })
  ,
);

export default userReducer;
