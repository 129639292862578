import { type FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';

import Button from 'components/Button';
import Typography from 'components/Typography';

import type { FilterOption, FormFilters } from '../../store/marketplace/marketplaceTypes';
import CheckBox from '../CheckBox';
import {
  CheckBoxContainer, FilterButtonsContainer, FilterContainer, RarityContainer,
} from './styled';
import type { FilterProps } from './types';

const Filter: FC<FilterProps> = ({
  filters, onApply, onClose, closeBtn, 'data-test-id': dataTestId,
}) => {
  const { t } = useTranslation();
  const filterKeys = Object.keys(filters) as (keyof FormFilters)[];
  const {
    register, getValues, watch, setValue,
  } = useForm({
    defaultValues: {
      ...Object.fromEntries(
        filterKeys.map((key) => [
          key,
          {
            ...Object.fromEntries(filters[key]?.map(({ active, value }: FilterOption) => [value, active]) || []),
          },
        ]),
      ),
    },
  });

  useEffect(() => {
    watch((data, { name }) => {
      if (data && name) {
        const path = name.split('.');
        const val = path.reduce<any>((prev, curr) => prev?.[curr], data);
        if (val) {
          const option = filters[path[0] as keyof FormFilters]?.find(({ value }) => value === path[1]);
          if (option?.deactivate) {
            setValue(option.deactivate as 'status.something', false);
          }
        }
      }
    });
  }, []);

  const onApplyClick = () => {
    const values = getValues();
    const newValues: Partial<FormFilters> = {};
    filterKeys.forEach((filterKey) => {
      newValues[filterKey] = filters[filterKey]!.map(({ value }, i) => ({
        ...filters[filterKey]![i],
        active: values[filterKey][value],
      })) || [];
    });
    onApply(newValues as FormFilters);
    onClose();
  };

  return (
    <FilterContainer>
      {filterKeys.map((filterKey) => {
        const currentFilters = filters[filterKey];
        return (
          <RarityContainer key={filterKey}>
            <Typography data-test-id={`rarity_label_${dataTestId}`} bold uppercase>
              {filterKey}
            </Typography>
            <CheckBoxContainer>
              {currentFilters?.map(({ value, label }) => (
                <CheckBox
                  key={value}
                  register={register}
                  name={`${filterKey}.${value}`}
                  label={label || t(`card.rarity.${value}`)}
                  $filter
                  data-test-id={dataTestId}
                />
              ))}
            </CheckBoxContainer>
          </RarityContainer>
        );
      })}
      <FilterButtonsContainer>
        <Button data-test-id={`apply_button_${dataTestId}`} onClick={onApplyClick} size="small" background="green" textColor="black" fluid>
          {t('common.apply')}
        </Button>
        {closeBtn && (
          <Button data-test-id={`close_button_${dataTestId}`} onClick={onClose} background="transparent" border="white">
            {t('common.close')}
          </Button>
        )}
      </FilterButtonsContainer>
    </FilterContainer>
  );
};

export default Filter;
