import { type FC, useEffect, useRef } from 'react';
import { CloseBtn, ContentPopUpWrapper, PopUpContainer } from './styled';
import { useOutsideClickHandle } from '../../hooks/useOutsideClick';
import { type ContentPopUpProps } from './types';

import closeIcon from '../../static/ic-close.svg';

const ContentPopUp: FC<ContentPopUpProps> = ({
  children, onClose, 'data-test-id': dataTestId, isMobileFixed,
}) => {
  const ref = useRef(null);
  useOutsideClickHandle(ref, onClose, 'mousedown');
  useEffect(() => {
    const close = (e: KeyboardEvent) => {
      if (e.key === 'Escape' || e.key === 'Esc') {
        onClose();
      }
    };
    window.addEventListener('keydown', close);
    return () => window.removeEventListener('keydown', close);
  }, []);

  return (
    <ContentPopUpWrapper $isMobileFixed={isMobileFixed}>
      <PopUpContainer data-test-id={dataTestId} ref={ref}>
        <CloseBtn data-test-id="close_icon" src={closeIcon} onClick={onClose} />
        {children}
      </PopUpContainer>
    </ContentPopUpWrapper>
  );
};

export default ContentPopUp;
