import {
  type FC, useEffect, useLayoutEffect, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';

import Filters from 'components/Filters';
import Helmet from 'components/Helmet/Helmet';
import Loader from 'components/Loader';
import NotificationsLoader from 'components/NotificationsLoader/NotificationsLoader';
import Typography from 'components/Typography';
import { cardMarketplaceRequest, resetMarketplaceCardState, setMarketplaceFilters } from 'store/marketplace/marketplaceAction';
import {
  selectMarketplaceCardsState,
  selectMarketplaceCollectionsState,
  selectMarketplaceFilters,
} from 'store/marketplace/marketplaceSelectors';

import { checkIsDefaultFilters, isPayloadEqualsToFilters, paramsToFilters } from '../../helpers';
import { CardsWrapper, LoadMarketplaceButton } from '../../styled';
import MarketplaceCardsList from './components/MarketplaceCardsList';
import MarketplacePurchasePopup from './components/MarketplacePurchasePopup';

import type { MarketplacePageProps } from './types';

const MarketplacePage: FC<MarketplacePageProps> = ({ sortTypes }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { search } = useLocation();

  const { t } = useTranslation();
  const defaultCollectionOption = {
    value: '',
    label: t('common.filters.allCollections'),
  };

  const {
    cards, isLoad, page, isMoreToLoad, isLoading,
  } = useSelector(selectMarketplaceCardsState);

  const { rarity, sortBy, collection } = useSelector(selectMarketplaceFilters);

  const { collections, isLoad: isCollectionsLoad } = useSelector(selectMarketplaceCollectionsState);

  const [isFilterActive, setIsFilterActive] = useState(false);
  const [urlFilters] = useState(paramsToFilters(search));

  const [isLocalLoad, setIsLocalLoad] = useState(false);
  const [isScannedUrlState, setIsScannedUrlState] = useState(false);

  useEffect(() => {
    setIsFilterActive(!checkIsDefaultFilters({ rarity }));
  }, [rarity]);

  useLayoutEffect(() => {
    const isFiltersEquals = isPayloadEqualsToFilters({ rarity, sortBy, collection }, urlFilters);

    if (!isScannedUrlState) {
      if (!isFiltersEquals) {
        if (search) {
          dispatch(setMarketplaceFilters({ ...urlFilters }));
        } else if (history.action !== 'POP') {
          dispatch(setMarketplaceFilters({ ...urlFilters, withoutUrlChange: true }));
        } else {
          dispatch(setMarketplaceFilters({ rarity, sortBy, collection }));
        }
        dispatch(resetMarketplaceCardState());
      }
      setIsScannedUrlState(true);
    } else if (!isLoad && !isLocalLoad) {
      dispatch(cardMarketplaceRequest({
        rarity, sortBy, collection, page,
      }));
      setIsLocalLoad(true);
    } else if (history.action !== 'POP') {
      dispatch(cardMarketplaceRequest({
        rarity, sortBy, collection, page: null,
      }));
    }
  }, [rarity, sortBy, collection, isScannedUrlState]);

  return (
    <>
      <Helmet title={t('helmet.marketplace')} />
      <Filters
        filters={{ rarity }}
        onFiltersApply={(rar) => dispatch(setMarketplaceFilters({ rarity: rar.rarity }))}
        isLoading={!isCollectionsLoad}
        showOption={collection}
        options={[defaultCollectionOption, ...collections]}
        onChange={(newValue) => {
          if (newValue) {
            dispatch(setMarketplaceFilters({ collection: newValue }));
          }
        }}
        sortValue={sortBy ? sortTypes.find(({ value: v }) => v === sortBy) : undefined}
        onSortChange={(newValue) => dispatch(setMarketplaceFilters({ sortBy: newValue?.value }))}
        sortTypes={sortTypes}
        isFilterActive={isFilterActive}
        data-test-id="filter_box"
      />
      <CardsWrapper data-test-id="mp_card_wrapper">
        <MarketplaceCardsList />
        {isLoad && !cards.length && (
          <Typography data-test-id="marketplace_empty_listed_label" size="unmedia21" textColor="textWhite" bold>
            {t('marketplace.noListings')}
          </Typography>
        )}
        {!isLoad && <Loader />}
        {isLoad && isMoreToLoad && (
          <LoadMarketplaceButton
            data-test-id="mp_load_more_button"
            onClick={() => dispatch(cardMarketplaceRequest({
              rarity, sortBy, collection, page,
            }))}
            background="dark"
            noneHover={isLoading}
            disabled={isLoading}
            $loading={isLoading}
          >
            {!isLoading && t('common.loadMore')}
            {isLoading && <NotificationsLoader inline />}
          </LoadMarketplaceButton>
        )}
      </CardsWrapper>
      <MarketplacePurchasePopup />
    </>
  );
};

export default MarketplacePage;
