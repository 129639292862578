import { createAction } from '@reduxjs/toolkit';
import type {
  Card, CardEdition, CardEditionState, Collection, Listing, SalesHistoryItem,
} from '@starly/starly-types';

export interface CardState {
  card: Card,
  editions: { [editionId: string]: CardEdition },
  collection: Collection,
  counters?: {
    listings: number,
    inCirculation: number,
    lowestAsk: number,
    topSale: number
  }
}

type CardInfoType = 'listing' | 'sales';

interface CardInfoRequestPayload {
  collectionId: string,
  cardId: string,
  sort: number,
  page: number
}

interface CardListingResponsePayload {
  listing: Listing[]
}

interface CardSalesResponsePayload {
  sales: SalesHistoryItem[]
}

interface LoadingPayload {
  infoType: CardInfoType
  isLoading: boolean
}

interface PagePayload {
  infoType: CardInfoType
  page: number
}

interface IsMoreToLoadPayload {
  infoType: CardInfoType
  isMoreToLoad: boolean
}

interface EditionStatusPayload {
  cardId: string,
  edition: string,
  status: CardEditionState,
}
interface CardRequestPayload {
  background?: boolean,
  purchase?: boolean,
  collectionId: string,
  edition: string,
  cardId: string,
}

interface CardResponsePayload {
  cardState: Pick<CardState, 'card' | 'collection' | 'editions'>
}

interface CardAndCollectionPayload {
  cardId: string,
  collectionId: string,
}

interface GetCardStakingRemainingPayload {
  starlyId: string,
  collectionId: string,
}

interface SetCardStakingRemainingPayload {
  starlyId: string,
  remaining: number | null,
}

interface UpdateCardEditionStakedStatusPayload {
  cardId: string,
  edition: string,
  status: boolean,
  stake_id?: number,
}

export const cardRequest = createAction<CardRequestPayload>('card/request');

export const cardResponse = createAction<CardResponsePayload>('card/response');

export const cardSetLoading = createAction<{ isLoading: boolean }>('card/set-loading');

export const cardListingRequest = createAction<CardInfoRequestPayload>('card/card-listing-request');

export const cardListingResponse = createAction<CardListingResponsePayload>('card/card-listing-response');

export const cardSalesRequest = createAction<CardInfoRequestPayload>('card/card-sales-request');

export const cardSalesResponse = createAction<CardSalesResponsePayload>('card/card-sales-response');

export const cardInfoSetPage = createAction<PagePayload>('card/card-info-page');

export const cardInfoSetLoading = createAction<LoadingPayload>('card/card-info-loading');

export const cardInfoSetMoreToLoad = createAction<IsMoreToLoadPayload>('card/card-info-more-to-load');

export const cardInfoReset = createAction<{ infoType: CardInfoType }>('card/card-info-reset');

export const setCardPopUp = createAction<{ isOpen: boolean, type: CardInfoType }>('card/CARD_SET_POPUP');

export const cardSetStakingRemaining = createAction<SetCardStakingRemainingPayload>('card/set-staking-remaining');

// Counters

// InCirculation

export const getCardCountersRequest = createAction<CardAndCollectionPayload>('card/getCardCountersRequest');
export const getCardStakingRemaining = createAction<GetCardStakingRemainingPayload>('card/getCardStakingRemaining');
export const addCardListenerToMap = createAction<CardAndCollectionPayload>('card/ADD_CARD_LISTENER_TO_MAP');
export const removeCardListenerFromMap = createAction<CardAndCollectionPayload>('card/REMOVE_CARD_LISTENER_FROM_MAP');
export const getCardCountersResponse = createAction<{
  cardId: string,
  listings: number,
  inCirculation: number,
  lowestAsk: number,
  topSale: number
}>('collection/getCardCountersResponse');

// Local Updates
export const updateCardEditionStatus = createAction<EditionStatusPayload>('card/UPDATE_CARD_EDITION_STATUS');

export const updateCardEditionStakedStatus = createAction<UpdateCardEditionStakedStatusPayload>('card/UPDATE_CARD_EDITION_STAKED_STATUS');
