import {
  all, call, fork, put, takeEvery,
} from 'redux-saga/effects';
import type { TransactionHistoryItem } from '@starly/starly-types';
import type { QuerySnapshot } from 'global/firebase';
import { firestore } from 'global/firebase';
import { transactionsLoading, transactionsRequest, transactionsResponse } from './transactionsActions';

function* transactionsRequestWatcher() {
  yield takeEvery(transactionsRequest, function* notificationRequestWorker(action) {
    const { payload: { addr } } = action;

    yield put(transactionsLoading({ isLoading: true }));

    try {
      const transactionsRef: QuerySnapshot = yield call(() => firestore
        .collection('flowAccounts')
        .doc(addr)
        .collection('transactions')
        .orderBy('create_time', 'desc')
        .get());

      const transactions = transactionsRef.docs.map(
        (transaction) => ({
          id: transaction.id,
          ...transaction.data(),
        }),
      ) as TransactionHistoryItem[];

      yield put(transactionsResponse({ transactions }));
    } catch (e) {
      console.error(e);
    } finally {
      yield put(transactionsLoading({ isLoading: false }));
    }
  });
}

export default function* transactionsSaga() {
  yield all([
    fork(transactionsRequestWatcher),
  ]);
}
