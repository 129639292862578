import type { FC } from 'react';

import { trackEvent } from 'global/firebase';

import bloctoswap from '../../static/exchanges/blocto-swap.png';
import huobi from '../../static/exchanges/huobi.png';
import kucoin from '../../static/exchanges/kucoin.png';
import pancakeswap from '../../static/exchanges/pancakeswap.png';
import Cross from '../../static/ic-close-black.svg';
import PopUp from '../PopUp';
import {
  BuyStarlyModalContainer,
  BuyStarlyModalOption,
  BuyStarlyModalOptionContainer,
  BuyStarlyModalOptionsContainer,
  BuyStarlyModalTitle,
  BuyStarlyOptionAdditional,
  BuyStarlyOptionIcon,
  CloseBtn,
} from './styled';

const buyOptions = [
  {
    id: 'Kucoin',
    link: 'https://www.kucoin.com/trade/STARLY-USDT?utm_source=starly-web',
    icon: kucoin,
    additional: 'Get a special 20% discount on all transactions.',
  },
  {
    id: 'Huobi',
    link: 'https://www.huobi.com/en-us/exchange/starly_usdt',
    icon: huobi,
  },
  {
    id: 'PancakeSwap',
    link: 'https://pancakeswap.finance/info/token/0xb0A480E2FA5AF51C733a0Af9FcB4De62Bc48c38B',
    icon: pancakeswap,
  },
  {
    id: 'BloctoSwap',
    link: 'https://swap.blocto.app/#/swap',
    icon: bloctoswap,
  },
];

const onOptionClick = (exchange: string) => {
  trackEvent('BuyStarlyTokenExchangePressed', {
    exchange,
  });
};

const BuyStarlyModal: FC<{ onClose: () => void }> = ({ onClose }) => (
  <PopUp padding="50px 32px" mobilePadding="32px 22px" width="522px" onHide={onClose}>
    <CloseBtn src={Cross} onClick={onClose} />
    <BuyStarlyModalContainer>
      <BuyStarlyModalTitle>Choose an exchange</BuyStarlyModalTitle>
      <BuyStarlyModalOptionsContainer>
        {buyOptions.map(({
          id, link, icon, additional,
        }) => (
          <BuyStarlyModalOptionContainer key={id}>
            <BuyStarlyModalOption href={link} target="_blank" onClick={() => onOptionClick(id)}>
              <BuyStarlyOptionIcon src={icon} />
            </BuyStarlyModalOption>
            {!!additional && <BuyStarlyOptionAdditional>{additional}</BuyStarlyOptionAdditional>}
          </BuyStarlyModalOptionContainer>
        ))}
      </BuyStarlyModalOptionsContainer>
    </BuyStarlyModalContainer>
  </PopUp>
);

export default BuyStarlyModal;
