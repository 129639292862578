import Web3DefaultLogo from 'static/wallet/web3-default.svg';
import MetaMaskLogo from 'static/wallet/metamask.svg';

interface IProviderInfo {
  check: string;
  name: string;
  logo?: string;
}

export const FALLBACK: IProviderInfo = {
  name: 'Web3',
  logo: Web3DefaultLogo,
  check: 'isWeb3',
};

export const METAMASK: IProviderInfo = {
  name: 'MetaMask',
  logo: MetaMaskLogo,
  check: 'isMetaMask',
};

export const SAFE: IProviderInfo = {
  name: 'Safe',
  check: 'isSafe',
};

export const NIFTY: IProviderInfo = {
  name: 'Nifty',
  check: 'isNiftyWallet',
};

export const DAPPER: IProviderInfo = {
  name: 'Dapper',
  check: 'isDapper',
};

export const OPERA: IProviderInfo = {
  name: 'Opera',
  check: 'isOpera',
};

export const TRUST: IProviderInfo = {
  name: 'Trust',
  check: 'isTrust',
};

export const COINBASE: IProviderInfo = {
  name: 'Coinbase',
  check: 'isCoinbaseWallet',
};

export const CIPHER: IProviderInfo = {
  name: 'Cipher',
  check: 'isCipher',
};

export const IMTOKEN: IProviderInfo = {
  name: 'imToken',
  check: 'isImToken',
};

export const STATUS: IProviderInfo = {
  name: 'Status',
  check: 'isStatus',
};

export const TOKENARY: IProviderInfo = {
  name: 'Tokenary',
  check: 'isTokenary',
};

export const FRAMEINJECTED: IProviderInfo = {
  name: 'Frame',
  check: 'isFrame',
};

export const LIQUALITY: IProviderInfo = {
  name: 'Liquality',
  check: 'isLiquality',
};

export const BOLTX: IProviderInfo = {
  name: 'Bolt-X',
  check: 'isBoltX',
};

export const MATHWALLET: IProviderInfo = {
  name: 'Math Wallet',
  check: 'isMathWallet',
};

export const RWALLET: IProviderInfo = {
  name: 'rWallet',
  check: 'isRWallet',
};

export const XDEFI: IProviderInfo = {
  name: 'XDEFI',
  check: '__XDEFI',
};

export const BITPIE: IProviderInfo = {
  name: 'Bitpie',
  check: 'isBitpie',
};

export const CELOINJECTED: IProviderInfo = {
  name: 'Celo extension wallet',
  check: 'isCelo',
};

export const BLOCKWALLET: IProviderInfo = {
  name: 'BlockWallet',
  check: 'isBlockWallet',
};

export const TALLYINJECTED: IProviderInfo = {
  name: 'Tally',
  check: 'isTally',
};

export const PORTAL: IProviderInfo = {
  name: 'Ripio Portal',
  check: 'isPortal',
};

export const SEQUENCEINJECTED: IProviderInfo = {
  name: 'Sequence',
  check: 'isSequence',
};

export const RABBY: IProviderInfo = {
  name: 'Rabby',
  check: 'isRabby',
};

export const BRAVE: IProviderInfo = {
  name: 'Brave',
  check: 'isBraveWallet',
};

export const CLV: IProviderInfo = {
  name: 'CLV',
  check: 'isCloverWallet',
};

export const BITKEEPWALLET: IProviderInfo = {
  name: 'Bitkeep Wallet',
  check: 'isBitKeep',
};

export const CORE: IProviderInfo = {
  name: 'Core',
  check: 'isAvalanche',
};

export const TOKENPOCKET: IProviderInfo = {
  name: 'TokenPocket Wallet',
  check: 'isTokenPocket',
};

export const GAMESTOP: IProviderInfo = {
  name: 'GameStop Wallet',
  check: 'isGamestop',
};

export const ZERION: IProviderInfo = {
  name: 'Zerion Wallet',
  check: 'isZerion',
};

export const PHANTOM: IProviderInfo = {
  name: 'Phantom',
  check: 'isPhantom',
};
